body.single-wcp_event {
  #singleContentArea {

    // _jquery-ui.scss runs inside the post content
    
    // Accordion
    // Style the inner list items on the content
    .elementor-text-editor {
        ul {
          padding: 0;
          margin-left: 0;
          margin-top:10px;
          margin-bottom: 25px;

          li {
            list-style: none;
            position: relative;
            padding-left: 15px;
            margin-bottom: 5px;
            line-height: $text-size-lheight;

            &:before {
              content: "";
              display: block;
              height: 4px;
              width: 4px;
              background: $brand-primary;
              border-radius: 6px;
              position: absolute;
              left: 0;
              top: 10px;
            }
        }         
      }

      p{
        font-size: $txt-size;
        color:$primary-color;
        margin-bottom: 15px;
        line-height: $text-size-lheight;
        padding-bottom:0;
      }

      span{
        font-size: $txt-size;
        color:$primary-color;
        line-height: $text-size-lheight;
      }
      
      h6{
        font-family: $font-primary;
        font-size: $h6-size;
        color: $primary-color;
        margin-bottom: 15px;
        line-height: $text-size-lheight;
        padding-bottom: 0;
        display:block;
        margin-top:25px;
        font-weight: 700;
      }

      strong{
        font-weight: bold !important;
      }

      em{
        font-style: italic !important;
      }

    }

      h1, h2, h2.elementor-heading-title{
          font-family: $font-primary-title !important;
          font-weight: 400;
          color:$hdr-primary;/*#544741 */
      }

      h2, h2.elementor-heading-title{
        font-size:36px;              
      }

        article h3, article h3.elementor-post__title, article h3.elementor-post__title a{
            font-family: $font-primary;
            font-size: $h5-size;
            color:$brand-primary;
            font-weight: 700;
            line-height: $text-size-lheight;
            margin-bottom: 15px;
        }

      
        .elementor-main-swiper .swiper-slide{
          padding:0;

          .elementor-testimonial {
              line-height: 1.5;

              .elementor-testimonial__text{
                font-size: $txt-size;
                font-style: normal;
              }

              .elementor-testimonial__footer{
                margin-top: 25px;
              
                  .elementor-testimonial__name{
                    font-size: $txt-size;
                    color: #544741;
                    font-weight: bold;
                  }
              }
          }
        }

          article{
            .elementor-post__meta-data{
              font-size: 16px;
              color: #544741;
              font-weight: bold;
              margin-bottom: 13px;
              line-height: 1.3em;
            }
            &.elementor-posts .elementor-post__excerpt{
              margin-bottom: 10px;

              p{
                font-size: $txt-size;
                line-height: $text-size-lheight;
                color:$primary-color;
              }
            }
            .elementor-post__read-more{
              font-size: $txt-size;
              font-weight: 700;
            }
          }

            .elementor-accordion .elementor-accordion-item .elementor-tab-content{
              color: #676767;
              font-size: 15px;
              font-weight: bold;
              line-height: 24px;
              font-weight: 400;

              p, > div{
                font-size: 18px;
                line-height: 26px;
                margin-bottom: 15px;
                padding-bottom:0;
              }

              > div > p:last-child{
                margin-bottom:0;
              }

              ul.extended-list-wrapper{
                margin-bottom: 15px;
                
                li{
                  font-size: 18px;
                  line-height: 26px;
                  margin-bottom: 10px;
                }
              }

            }
            .elementor-accordion .elementor-tab-title{
                color: #84628d;
                font-size: 24px;
                line-height: 1;
            }

            #stickyEventSidebar .elementor-text-editor p{
              color:#ffffff;
              padding-top: 10px;
              margin-bottom: 0;
  
              span.yellow{
                color:#fead51;
                font-weight: bold;
              }


            }
    
         /* Accordion / toggle style */
        .elementor-widget-toggle .elementor-widget-container .elementor-toggle{
          margin-top: 12px;
          margin-bottom: 20px;
          padding: 10px 0 0;          
          border-radius: 20px;
          /*background: #fff;
          box-shadow: 4px 7px 38px 7px rgba(132,98,140,.1);*/

          .elementor-toggle-item{
            border-color: #d9d9d9;
            border-width: 0 0 1px;
            border-radius: 20px 20px 0 0;

              .elementor-tab-title{
                padding: 25px 35px;
                a{                
                  color: $primary-color;
                  font-family: $font-primary;
                  font-weight: bold;
                  font-size: $txt-size-lg;
                  line-height: 1.40rem;   
                }
                .elementor-toggle-icon{
                  margin:5px 10px 25px 0;
                  .elementor-toggle-icon-closed .fa-minus:before, 
                  .elementor-toggle-icon-closed .fa-plus:before, 
                  .elementor-toggle-icon-closed .fa-caret-right:before, 
                  .elementor-toggle-icon-opened .fa-minus:before,
                  .elementor-toggle-icon-opened .fa-plus:before,
                  .elementor-toggle-icon-opened .fa-caret-right:before{
                    color: #84628c;
                  }                
                }
              }
              .elementor-tab-content {
                border: 0;
                padding: 8px 60px 35px;
              }
            
            &:last-child .elementor-tab-title{
              border:0;
            }

          }
      }   
  }


  #singleContentArea #stickyEventSidebar {
    .elementor-text-editor.event-summary p{
      color:#ffffff;
      padding-top: 10px;
      margin-bottom: 0;

      span.yellow{
        color:#fead51;
        font-weight: bold;
      }  
    }
    .elementor-widget-text-editor.price .elementor-text-editor{
      font-size: $h5-size;
    }
  }

    #img-vdo-slider{
      position: relative;
      margin-bottom:0;

        .elementor-swiper-button i{
          display: inline-block;
          font-family: "Font Awesome 5 Free";
          /*content: '\e87e';*/
          font-size: 20px;
          font-weight: 900;
          font-style: normal;
          line-height: 1;
          width: 20px;
          text-align: center;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
        .elementor-swiper-button i:before{
            font-size: 20px;
        }
        .elementor-swiper-button-prev i:before {
            content: '\f053';
        }
        .elementor-swiper-button-next i:before {
            content: '\f054';
        }

    }    

    @media (max-width:767px){
      #img-vdo-slider{    
        margin-top: 0;  

        .elementor-main-swiper{
            height: calc(230px - 4.5rem);
        }
      }

      #singleContentArea h2, #singleContentArea h2.elementor-heading-title{
        font-size:26px; 
      }
     
    }

    @media (min-width:768px) and (max-width:1024px){
      #img-vdo-slider{    
        margin-top: 0;  

        .elementor-main-swiper{
            height: calc(230px - 1.2rem);
        }

      }
    }

    @media (min-width:1025px) and (max-width:1199px){
      #img-vdo-slider{    
        margin-top: -60px;

        .elementor-main-swiper{
            height: calc(230px - 6.5rem);
        }

      }
    }

    @media (min-width:1200px) and (max-width:1599px){
      #img-vdo-slider{    
        margin-top: -80px;

        .elementor-main-swiper{
          height: calc(230px - 5rem);
        }

      }
    }

    @media (min-width:1600px){
      #img-vdo-slider{    
        margin-top: -100px;
        
        .elementor-main-swiper{
          height: calc(230px - 2rem);
        }

      }
    }

  

  // Grayed out links on passed events
  .grayed-out-link {
    opacity: .5;
  }

  // Hide the container for the single event end date
  #singleEventEndDate {
    display: none;
  }

  .elementor-posts {
    article{
      .elementor-post__excerpt, .elementor-post__excerpt p{
        color: $primary-color;
      }
    }
  }
}

// Offset the anchor point position so the scroll works better
// If logged
body.admin-bar:not(.elementor-editor-active) {
  .elementor-widget-menu-anchor {
    top: -185px;
    pointer-events: none;
  }
}

body:not(.admin-bar):not(.elementor-editor-active) {
  .elementor-widget-menu-anchor {
    top: -155px;
    pointer-events: none;
  }
}