$hc-offcanvas-nav-transition-duration: .4s !default;
$hc-offcanvas-nav-transition-timing-function: ease !default;
$hc-offcanvas-nav-container-width: 240px !default;
$hc-offcanvas-nav-container-height: auto !default;

html.hc-nav-yscroll {
  overflow-y: scroll;
}

body.hc-nav-open {
  overflow: visible;
  position: fixed;
  width: 100%;
  min-height: 100%;
}

.hc-offcanvas-nav {
  visibility: hidden;
  display: none;
  position: fixed;
  top: 0;
  height: 100%;
  z-index: 9999;

  &.is-ios {
    * {
      cursor: pointer !important;
    }
  }

  .nav-container {
    position: fixed;
    z-index: 9998;
    top: 0;
    width: $hc-offcanvas-nav-container-width;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
    transition: transform $hc-offcanvas-nav-transition-duration $hc-offcanvas-nav-transition-timing-function;
  }

  .nav-wrapper {
    width: 100%;
    height: 100%;
    max-height: 100vh;
    overscroll-behavior: none;
    box-sizing: border-box;
  }

  .nav-content {
    height: 100%;
    max-height: 100vh;
  }

  .nav-wrapper-0 {
    & > .nav-content {
      overflow: scroll;
      overflow-x: visible;
      overflow-y: auto;
      box-sizing: border-box;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    position: relative;
    display: block;

    &.level-open {
      & > .nav-wrapper {
        visibility: visible;
      }
    }
  }

  input[type="checkbox"] {
    display: none;
  }

  label {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    cursor: pointer;
  }

  a {
    position: relative;
    display: block;
    box-sizing: border-box;
    cursor: pointer;

    &,
    &:hover {
      text-decoration: none;
    }
  }

  .nav-item {
    position: relative;
    display: block;
    box-sizing: border-box;
  }

  // Background overlay

  &.disable-body,
  .nav-wrapper {
    &::after {
      content: '';
      position: fixed;
      z-index: 9990;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      overscroll-behavior: none;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s ease $hc-offcanvas-nav-transition-duration, opacity $hc-offcanvas-nav-transition-duration ease;
    }
  }

  &.disable-body.nav-open,
  .sub-level-open {
    &::after {
      visibility: visible;
      opacity: 1;
      transition-delay: .05s;
    }
  }

  &:not(.nav-open) {
    &::after {
      pointer-events: none;
    }
  }

  // Expand Levels
  &.nav-levels-expand {

    .nav-content {
      overflow: scroll;
      overflow-x: visible;
      overflow-y: auto;
      box-sizing: border-box;
    }

    .nav-wrapper {
      &::after {
        display: none;
      }
    }

    ul {
      .nav-wrapper {
        min-width: 0;
        max-height: 0;
        overflow: hidden;
        transition: height 0s ease $hc-offcanvas-nav-transition-duration;
      }
    }

    .level-open {
      & > .nav-wrapper {
        max-height: none;
      }
    }
  }

  // Transform Levels
  &.nav-levels-overlap {

    .nav-content {
      overflow: scroll;
      overflow-x: visible;
      overflow-y: auto;
      box-sizing: border-box;
    }

    ul {

      .nav-wrapper {
        position: absolute;
        z-index: 9999;
        top: 0;
        height: 100%;
        visibility: hidden;
        transition: visibility 0s ease $hc-offcanvas-nav-transition-duration, transform $hc-offcanvas-nav-transition-duration $hc-offcanvas-nav-transition-timing-function;
      }

      li {

        &.nav-parent {
          position: static;
        }

        &.level-open {
          & > .nav-wrapper {
            visibility: visible;
            transform: translate3d(0, 0, 0);
            transition: transform $hc-offcanvas-nav-transition-duration $hc-offcanvas-nav-transition-timing-function;
          }
        }
      }
    }
  }

  // Left
  &.nav-position-left {
    left: 0;

    .nav-container {
      left: 0;
      transform: translate3d(-$hc-offcanvas-nav-container-width, 0, 0);
    }

    &.nav-levels-overlap {

      li {
        .nav-wrapper {
          left: 0;
          transform: translate3d(-100%, 0, 0);
        }
      }
    }
  }

  // Right
  &.nav-position-right {
    right: 0;

    .nav-container {
      right: 0;
      transform: translate3d($hc-offcanvas-nav-container-width, 0, 0);
    }

    &.nav-levels-overlap {

      li {
        .nav-wrapper {
          right: 0;
          transform: translate3d(100%, 0, 0);
        }
      }
    }
  }

  $hc-container-y: $hc-offcanvas-nav-container-height;
  @if $hc-offcanvas-nav-container-height == auto {
    $hc-container-y: 100%;
  }

  // Top
  &.nav-position-top {
    top: 0;

    .nav-container {
      top: 0;
      width: 100%;
      height: $hc-offcanvas-nav-container-height;
      transform: translate3d(0, -$hc-container-y, 0);
    }

    &.nav-levels-overlap {

      li {
        .nav-wrapper {
          left: 0;
          transform: translate3d(0, -100%, 0);
        }
      }
    }
  }

  // Bottom
  &.nav-position-bottom {
    top: auto;
    bottom: 0;

    .nav-container {
      top: auto;
      bottom: 0;
      width: 100%;
      height: $hc-offcanvas-nav-container-height;
      transform: translate3d(0, $hc-container-y, 0);
    }

    &.nav-levels-overlap {

      li {
        .nav-wrapper {
          left: 0;
          transform: translate3d(0, 100%, 0);
        }
      }
    }
  }

  // Open Nav
  &.nav-open[class*='hc-nav-'] {
    div.nav-container {
      transform: translate3d(0, 0, 0);
    }
  }
}