// Header

@media screen and (max-width: 899px) {
  // Button
  #toggleSearchEvents span span {
    margin-right: 20px;
  }

  #toggleSearchEvents span span:after {
    display: inline-block;
    content: "";
    background-image: url('../images/settings-icon.png');
    background-size: 18px auto;
    width: 20px;
    background-repeat: no-repeat;
    height: 20px;
    position: absolute;
    margin-top: 1px;
    margin-left: 20px;
  }

  // Form Wrapper
  #SearchEventsFormWrapper {
    position: absolute;
    background-color: #FFF;
    width: 100%;
    z-index: 2;
    margin-top: 200px;
    display: none;
    box-shadow: 0 0 10px rgba(0,0,0, 0.15);

    &.visible {
      display: block;
    }
  }
}

@media screen and (min-width: 900px) {

  // Wrapper
  #toggleSearchEventsWrapper {
    display: none;
  }

  // Button #toggleSearchEvents
}

