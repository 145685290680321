/* Remove right nav spacing */
#masthead .ast-container {
  padding-right: 0 !important;
}

/* Add top and bottom padding to the logo */
.ast-site-identity {
  padding-top: 4px;
  padding-bottom: 16px;
}

#page .site-header{
  z-index: 100;
}

/* Add letter spacing to the menu items */
.site-header .menu-text {
  letter-spacing: 0.38px;
}

.header-hightlight.infobar{
    position: relative;
    display: block;
    width: 100%;
    padding:0;
    background: #fea108;
    margin: 0 auto auto auto;
    z-index: 99;

    a{
      display: block;
      width: 100%;
      padding:5px;
      background: #fea108;
      border-radius:0;
      margin: 0;
      font-size: 14px;
      color: #000000;
      font-weight: normal;
      text-align: center;
      line-height: 1.2em;
      span{
        display: inline-block;
      }
    }

    /* Black Friday */
    .bestdeal{
      display: flex;
      max-width: 685px;
      margin:auto;
      padding:6px 0 6px 0;

      .content{
        width: 100%;
        max-width: 350px;
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;

        &.mobile{
          display: none;
        }

        span.ico-tag{
          display: inline-block;
          margin-right: 12px;

          i.tag{
            display: block;
            width: 36px;
            height: 36px;
            background:url("../../assets/images/discount-ico.png") no-repeat 0 0;
          }
        }
        
        span{
          font-family: $font-primary;
          font-weight: 500;          
          color: #000000;
          &.title{
            display: inline-block;            
            strong{
              display: block;  
              font-size: 16px;
              line-height: 20px;
              text-transform: uppercase;            
            }
            span{
              display: block;
              font-size: 12px;
              line-height: 18px;
            }
          }          
        }
      }
      
      #timer{
        display:inline-flex;
        width:100%;
        max-width: 225px;   
        background-color: #fea409;
        opacity: 1;
        align-items: center;

        .item{
          margin-left:10px;
          max-width: 50px;
          text-align: center;
        }

        .countdown-item{
          font-family:-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
          color: #000000;
          background:#ffffff;					
          padding: 5px 0;
          border-radius: 5px;				    
          width: 30px;
          display:block;
          overflow:hidden;
          margin:auto;
          text-align: center;
        }
        .countdown-item .count{
          display:block;
          color: #000000;
          font-size: 16px;
          font-weight:700;
          line-height: 18px;
          transition: 0.3s ease all;
        }
        .label{
          display:block;
          font-size: 12px;
          line-height: 14px;
          font-weight:500;
          color:#000000;
          text-align:center;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-top:3px;
          -webkit-font-smoothing: antialiased;
        }
        #days.item{
          margin-left:0;
          margin-right: 7px;
        }
        #hours.item{
          margin-right: 5px;
        }
        #minutes.item{
          margin-right: 5px;
        }
      }
      .btn-container{
        display: inline-flex;
        width: 100%;
        max-width: 110px;
        align-items: center;
        a{
          display: block;
          background:#83618c; /*#dc3900*/
          max-width: 110px;
          border-radius: 5px;
          font-weight: 700;
          font-family: $font-primary;
          font-size: 13px;
          color: #ffffff;
          text-transform: uppercase;
          text-align: center;
          width: 100%;
          line-height: 32px;
          padding:0;
          margin: 0 0 2px 0;
          max-height: 32px;
          overflow: hidden;
        }
      }

      &.off{
        display: none;
        display: block;
        text-align: center;
        font-family: $font-primary;
        font-weight: 500;          
        color: #000000;
        font-size: 16px;
        line-height: 20px;
      }

    }
}

@media(max-width:767px){
  .header-hightlight.infobar{

    .bestdeal{
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      padding:0 10px 6px 10px;
      justify-content: space-around;
      max-width: 340px;

      #timer{
        max-width: 175px;
        .item{
          margin-left: 5px;
        }
        .countdown-item{
          padding:3px 0;
          .count{
            font-size: 13px;
            line-height: 15px;
          }
        } 
      }

      .content{
        text-align: center;
        &.mobile{
          display: block;
          max-width: 100%;
        }
        &.desktop{
          display: none;
        }
        span.title{
          margin:auto;
        }
      }
      .btn-container{
        max-width: 90px;
        justify-content: center;
        a{
          font-size: 12px;
        }
      }      
    }

  }
}

/*------------------------------------------------------------
  2.    Menu Search Bar
-------------------------------------------------------------*/
/*.admin-bar .events-search-bar{
  top: 102px;
}*/

/*------------------------------------------------------------
  1.1.2 Desktop Header Search Bar
-------------------------------------------------------------*/
.header-events-search-bar .events-search-bar {
  box-shadow: 0 2px 35px 0 rgba(0,0,0,0.15);
  z-index: 98;
  max-width: none;

  .events-search-bar.ever_visible {
    box-shadow: none;
  }
}

/*------------------------------------------------------------
  3.    Header search bar
-------------------------------------------------------------*/
.header-events-search-bar {
  
  .events-search-bar {
    background-color: #FFF;

    &:before,
    &:after {
      display: none;
    }
  }
}


/*------------------------------------------------------------
  4.    Breadcrumb
-------------------------------------------------------------*/
.breadcrumb{
  position: absolute;
  top: 70px;
  width: 100%;
  z-index: 1;
  padding: 0 20px;
  margin:0;
  border-radius: 0;
  background-color: rgba(255,255,255,0.9);
  border-top: 1px solid #84628d;
  box-shadow: 0 2px 35px 0 rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10px);

  #breadcrumbs{    
    padding: 0;
    margin: 0;

    span{
      color:#5f3a69;
      a{
        font-size: 16px;
        color:#5f3a69;
          &:hover{
            color:#3C3247;
            i.fa-home{
              color:#3C3247;
            }
          }
      }
      .breadcrumb_last{
        font-size: 16px;
        color:#3C3247;
      }
      i{
        font-style: normal;
        padding: 0 0;
        height: 5px;
        width: 20px;
        font-size: 11px;
        color:#5f3a69;
        text-align: center;
      }
      i.fa-home{
        font-size: 14px;        
      }
    }
  }
}