/*------------------------------------------------------------
  1.2.  Astra Mobile Menu
-------------------------------------------------------------*/
@media screen and (max-width: $mobile-max) {

  /* Prevent the top menu of overlapping content */
  /*#page #content #primary {
    margin-top: 48px;
  }*/

  body.admin-bar{          
      #page .site-header{
        top:32px!important;
      }
      #page > .site-content {
        padding-top: 0; /*52px*/
      }  
      .dialog-type-lightbox{
        position:fixed;
      }

  }

  #intercom-container{
    display: none !important;
  }

  // Offset the body when the top nav is fixed, on mobile
  #page > .site-content {
    /*padding-top: calc(48px + 50px);*/  // To offset it; 98px added for covid-19 infobox
    padding-top:48px; /* 98px */
  }


  /* Covid 19 message */
  .admin-bar .header-hightlight.infobar{
    top:0; /* 48px */
  }
  .header-hightlight.infobar{    
    a{
      font-size: 80%;
      p{
        margin:0;
        padding:0;

        &.desktop-msg{
          display: none;
        }
        &.mobile-msg{
          display: block;
          font-size: 80%;
        }
      }
    }
  }

  /* Remove the shadow */
  .ast-header-break-point .site-header {
    border-bottom: none;
    box-shadow: 0 2px 35px 0 rgba(0,0,0,0.15);
    position: fixed;
    width: 100%;
    top: 0;
  }

  /* Style the bar */
  #masthead .ast-container {
    padding-left: 10px;
  }

  /* Limit the logo width */
  .ast-header-break-point .site-header .main-header-bar-wrap .site-branding {
    padding-right: 0;
  }

  .ast-logo-title-inline .site-logo-img {
    max-width: 85px;
    width:100%;
    padding-right: 0;
  }

  /* Style the offcanvas toggle */
  .ast-header-break-point .ast-mobile-menu-buttons {
    background-color: $brand-primary;
    height: 48px;
  }

  .ast-header-break-point .ast-mobile-menu-buttons-minimal.menu-toggle {
    color: #FFFFFF;
  }

  .ast-header-break-point .ast-mobile-menu-buttons .ast-button-wrap .ast-mobile-menu-buttons-minimal {
    font-size: 1.4em;
    height: 48px;
    padding-left: 0;
    padding-right: 0;
  }

  .ast-mobile-menu-buttons .ast-button-wrap {
    line-height: 1;
  }

  .ast-header-break-point .ast-site-identity {
    padding-bottom: 12px;
  }

  /* Extra buttons: mobile */
  .ast-mobile-menu-buttons .mobile-call-button {
    background-color: $brand-primary;
    display: inline-block;
    padding-left: 14px;
    padding-right: 14px;
    position: relative;
    line-height: 48px;

    a{
      display: block;
    }

    .fa-phone:before {
      color: #FFF;
      //line-height: 48px;
      font-size: 14px;
      top: -1px;
    }

    &:after {
      background-color: #D8D8D8;
      opacity: .2;
      position: absolute;
      width: 1px;
      height: 100%;
      content: "";
      right: -0px;
      top: 0;
    }
  }

  .ast-mobile-menu-buttons .join-a-retreat {
    background-color: $brand-primary;
    line-height: 48px;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;

    &.header-search-icon{
      max-width: 21px; /*auto*/
      max-height: 22px;
      width:100%;        
    }

    a{
      padding: 0;
      display: block;

      .icon{
        width: 20px;
        height: 25px;
        position: relative;
        top: 6px;
      }
    }

    img {
      max-width: 75%; /*auto*/
      max-height: 100%;
      height:auto;
      position: relative;
      top: -3px;
    }

    &:after {
      background-color: #D8D8D8;
      opacity: .2;
      position: absolute;
      width: 1px;
      height: 100%;
      content: "";
      right: -0px;
      top: 0;
    }
  }

  // Limit the toggle button width
  .ast-mobile-menu-buttons .menu-toggle {
    width: 42px;
  }

  .ast-mobile-menu-buttons #main-nav {
    display: none;
  }

  .ast-mobile-menu-buttons .menu-toggle .mobile-toggle-offcanvas-wrapper {
    display: block;
    padding: 0;
    height: 48px;
    position: relative;/* absolute */
    line-height: 42px;
    top: 0;
    width: 41px;

      a{
        img{
          width:14px;
          height:11px;
        }
        .icon{
          width:30px;
          height:11px;
        }
    }

  }

  //Offset the location
  @media screen and (max-width: 374px) {
        .ast-site-identity{
          .location{
            display:flex;
            margin-left:12px;
            padding-left:10px;
            border-left:1px solid rgba(187, 189, 191, 0.35);

            a{
              display:flex;
              align-items: center;

                span.map, .icon{
                  margin:auto 8px auto 0;
                  width:16px;
                  height:20px;
                  /*background:url(../../assets/images/map-ico.svg) no-repeat;*/
                }

                span.loc{
                  text-transform: uppercase;
                  color:#676767;
                  font-size: .55rem;
                  font-weight: 700;
                  letter-spacing: .38px;
                  line-height: 1.3;
                }

            }
          }
      }
  }

  //Offset the logo
  @media screen and (max-width: $mobile-max) and (min-width: 375px){

    .ast-site-identity{
        .location{
          display:flex;
          margin-left:17px;
          padding-left:16px;
          border-left:1px solid rgba(187, 189, 191, 0.35);

          a{
            display:flex;
            align-items: center;

              span.map, .icon{
                margin:auto 8px auto 0;
                width:16px;
                height:20px;
                /*background:url(../../assets/images/map-ico.svg) no-repeat;*/
              }

              span.loc{
                text-transform: uppercase;
                color:#676767;
                font-size: .55rem;
                font-weight: 700;
                letter-spacing: .38px;
                line-height: 1.3;
              }

          }
        }
    }

  }

  // Offset the nav bar
  body.admin-bar {
    
    @media screen and (max-width: 600px) {
      #wpadminbar {
        position: fixed;
      }

     .site-header {
        top: 46px !important;
      }
    }

    #masthead {
      @media screen and (min-width: 601px) and (max-width: 782px) {
        top: 46px;
      }
  
      @media screen and (min-width: 783px) {
        top: 32px;
      }
    }
    
  }

  @media (min-width: 767px) and (max-width: 782px) {
    .breadcrumb{
      top:52px;
      padding:0 10px;
    }
    .admin-bar .breadcrumb{
      top:6px;
    }
  }

  /*
  /*------------------------------------------------------------
  1.2.1  Header events search bar
  -------------------------------------------------------------*/
  .header-events-search-bar .events-search-bar{

    .ast-container {
      padding: 0 10px;
    }

  }
  

  // Fix the bar offset offset
  @media (max-width: 980px){
    .header-events-search-bar .events-search-bar{
      top: 50px;
    }
    
    body.admin-bar .header-events-search-bar .events-search-bar{
      top: 82px;
    }
  }
  
  @media (max-width: 768px){
    .admin-bar .header-events-search-bar .events-search-bar{
      top: 96px;
    }
    .breadcrumb{
      top:52px;
    }
    .admin-bar .breadcrumb{
      top:6px;
    }
  }

  // Specific media query where the bar toggles between desktop and mobile
  @media screen and (max-width: 899px) {
    .header-events-search-bar .events-search-bar{

      .events-search-bar.ever_visible {
        padding-top: 15px;
      }
  
    }

    #close_events_bar {
      display: block !important;
      padding: 0px 0 20px 0;
      font-size: 20px;
      font-weight: 500;
      position: relative;
      z-index: 0;
    }
  
  }

 
}