//Swiper Slider
.customRetreatSlider{

  .swiper-container {
    width: 100%;
    height: 100%;
  }  
  /*.swiper-slide {
    padding: 10px 10px 15px 10px;        
  }*/

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: transparent;
    padding: 10px 10px 15px 10px;  

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  // Add box shadow to the custom slider
  .swiper-slide .swiper-slide-bg {
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.5);
    display: block;
  }

  /* Adjust the slider arrows */
  .elementor-swiper-button, .elementor-swiper-button:hover, .elementor-swiper-button:focus {
    box-shadow: 0px -20px 20px 0 rgba(0,0,0,0.5);
    background: #84628d;
    border-radius: 100%;
    width: 50px;
    height: 50px;
  }

  .elementor-swiper-button:hover {
  box-shadow: 0px -20px 20px 0 rgba(0,0,0,0.5) !important; 
  }

  .elementor-swiper-button:before {
    border-color: #FFF;
    color: #666;
    width: 12px;
    height: 12px;
    border-width: 1px 1px 0 0;
    border-style: solid;
    font-size: 0;
    position: absolute;
    top: 38%;
  }

  .elementor-swiper-button i{
    color: #666;
    width: 12px;
    height: 12px;
    border-width: 1px 1px 0 0;
    border-color: #fff;
    border-style: solid;
    position: absolute;

    &:before{
      content:"";
    }    
  }

  .elementor-swiper-button.elementor-swiper-button-prev {
    left: -25px;
    position: absolute;

    &:before {
      left: 43%;
      transform: rotate(-135deg);
    } 
    
    i{
      transform: rotate(-135deg);
      top: 18px;
      left: 20px;
    }
  }

  .elementor-swiper-button.elementor-swiper-button-next {
    right: -25px;
    position: absolute;

    &:before {
      right: 43%;
      transform: rotate(45deg);
    }

    i{
      transform: rotate(45deg);
      top: 18px;
      left: 16px;
    }
  }
  
  /* Tablet */
  @media screen and (max-width: 768px) {
    .elementor-swiper-button.elementor-swiper-button-prev {
        left: 4px !important;
    }
    
    .elementor-swiper-button.elementor-swiper-button-next {
        right: 4px !important;
    }
    
    .slick-disabled {
        display: none !important;
    }
  }


  // Add the slide overlay with the white lines
  .swiper-slide .swiper-slide-bg:before {
    content: "";
    width: 100%;
    height: 100%;
    /*background-image: url('/wp-content/uploads/2019/01/retreat-section-overlay.png');*/
    background-repeat: no-repeat;
    background-position: -20px 10%;
    position: absolute;
    pointer-events: none;
  }

  // Style the titles for each slide
  .swiper-slide-inner {
    padding-left: 0;
    padding-right: 0;

    .elementor-slide-heading {
      text-shadow: 0 2px 15px rgba(0,0,0,0.75);
      font-family: $font-primary-title;
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 0; // Prevent bottom margin on mobile

      // arrow after the title
      &:after {
        /*content: ">";
        width: 10px;
        height: 30px;
        top: 4px;
        left: 5px;;
        display: inline-block;
        position: relative;
        right: 0;*/ 
      }
    }
  }

}

// Swiper Slider for Retreat
// .customSwiperRetreat{
//   .elementor-widget-heading{
//     height:100%;

//     .elementor-widget-container{
//       height:100%;

//       .elementor-heading-title {        
//         width: 100%;
//         height:100%;        

//         a{
//           display: flex;
//           align-items: center;
//           justify-content: center;
//           height:100%;   

//           text-shadow: 0 2px 15px rgba(0,0,0,.75);
//           font-family: Ernest and Emily,Sans-serif;
//           font-size: 40px;
//           font-weight: 500;
//           margin-bottom: 0;
//           line-height: 1;
//           text-align: left;
//           padding:50px;
//           color:#ffffff;

//           &:after{
//             content: ">";
//             width: 10px;
//             height: 30px;
//             top: 4px;
//             left: 5px;
//             display: inline-block;
//             position: relative;
//             right: 0;      
//           }
//         }

//       }
//     }

//   }

//   .elementor-container > .elementor-row > .elementor-element.elementor-column{
//     height:400px;
//   }

// }
//


// Slick Slider 
.customSliderRetreats {

  .slick-slider:not(.slick-initialized) {
    display: none;
  }

  .slick-slide {
    padding: 10px 10px 15px 10px;    
  }

  // Add box shadow to the custom slider
  .slick-slide .slick-slide-bg {
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.5);
    display: block;
  }

  /* Adjust the slider arrows */
  .slick-arrow, .slick-arrow:hover, .slick-arrow:focus {
    box-shadow: 0px -20px 20px 0 rgba(0,0,0,0.5);
    background: #84628d;
    border-radius: 100%;
    width: 50px;
    height: 50px;
  }

  .slick-arrow:hover {
  box-shadow: 0px -20px 20px 0 rgba(0,0,0,0.5) !important; 
  }

  .slick-arrow:before {
    border-color: #FFF;
    color: #666;
    width: 12px;
    height: 12px;
    border-width: 1px 1px 0 0;
    border-style: solid;
    font-size: 0;
    position: absolute;
    top: 38%;
  }

  .slick-arrow.slick-prev {
    left: -25px;
    position: absolute;

    &:before {
      left: 43%;
      transform: rotate(-135deg);
    }
  }

  .slick-arrow.slick-next {
    right: -25px;
    position: absolute;

    &:before {
      right: 43%;
      transform: rotate(45deg);
    }
  }



  /* Tablet */
  @media screen and (max-width: 768px) {
    .slick-arrow.slick-prev {
        left: 4px !important;
    }
    
    .slick-arrow.slick-next {
        right: 4px !important;
    }
    
    .slick-disabled {
        display: none !important;
    }
  }


  // Add the slide overlay with the white lines
  .slick-slide .slick-slide-bg:before {
    content: "";
    width: 100%;
    height: 100%;
    /*background-image: url('/wp-content/uploads/2019/01/retreat-section-overlay.png');*/
    background-repeat: no-repeat;
    background-position: -20px 10%;
    position: absolute;
    pointer-events: none;
  }

  // Style the titles for each slide
  .slick-slide-inner {
    padding-left: 0;
    padding-right: 0;

    .elementor-slide-heading {
      text-shadow: 0 2px 15px rgba(0,0,0,0.75);
      font-family: $font-primary-title;
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 0; // Prevent bottom margin on mobile

      // arrow after the title
      &:after {
        /*content: ">";
        width: 10px;
        height: 30px;
        top: 4px;
        left: 5px;;
        display: inline-block;
        position: relative;
        right: 0;*/
      }
    }
  }

}