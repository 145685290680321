/*************************************************************
                        CUSTOM CODE
                  Group Rental Page - Post Template
--------------------------------------------------------------
NOTES FOR DEVELOPERS:
- Use tabs of two spaces for best reading experience
--------------------------------------------------------------
  INDEX
  1.    Global Styles
  2.    Content area
--------------------------------------------------------------
  CODE STARTS HERE
*-------------------------------------------------------------
  1.    Global Styles
-------------------------------------------------------------*/ 
    $gro-primary-font:  $font-primary;
    $gro-general-font: $font-primary;
    $border-radius: 20px;

    #gro-hero-banner{
      h1, h2, h3, h4, h5{
        font-family: $gro-primary-font;
        font-weight: 500;
        font-style: normal;
      }
    }

  /*------------------------------------------------------------
    2.    Content area
  -------------------------------------------------------------*/ 
  #primary {

    .inner-wrapper {
      position: relative;
    }

    //max-width: 1170px;
    position: relative;

    h1, h2, h3, h4, h5, h6 {
      /*font-weight: 400;
      letter-spacing: -.35px;*/
    }

    .elementor-widget-heading h1.elementor-heading-title{
      font-size: $h1-size;
      line-height: 68px;
    }
    .elementor-widget-heading h2.elementor-heading-title{
      font-size: $h2-size;
    }

    #gro-hero-banner{      
        .elementor-inner-section{
            .elementor-widget-heading .elementor-widget-container{
              text-align:center;
              text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.45);

                h1.elementor-heading-title, h2.elementor-heading-title, h3.elementor-heading-title{                 
                  color:#ffffff;
                  font-family: $gro-primary-font;
                  font-weight: 500;
                  font-style: normal;
                  font-size: 56px;
                  margin-bottom: 15px;
                }
            }          
        }

        .elementor-widget-text-editor .elementor-text-editor, .elementor-widget-text-editor .elementor-widget-container{
          color:#ffffff;
          font-family: $gro-general-font;
          font-weight: 400;
          font-style: normal;
          font-size: 28px;   
          text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.45);               

          ul{
            margin:0;
            padding:0;
            display: flex;
            flex-flow: row;
            justify-content: center;
            align-items: center;    

              li{
                margin:0;
                padding:0 50px 0 0;                
                text-align:center;
                font-size: 28px;
                color:#ffffff;
                
                &:first-child{
                  list-style-type: none;
                }
                
              }
          }
        }

        .elementor-widget-button{
          margin-top: 15px;

          .elementor-button-link{
            border:3px solid #ffffff;
            width: 100%;
            max-width: 278px;
            min-height:62px;
            text-transform: uppercase;
            color:#ffffff;
            background-color: transparent;
            font-size:22px;
            line-height: 35px;
            font-weight: bold;
            text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.45);

                &:hover{
                    background-color: #ffffff;
                    color:#000000;
                    text-shadow: 1px 2px 3px rgba(0, 0, 0, 0);                    
                }
          }
        }
    }

    // Content Area
    #groContentArea{
        h1, h2, h3, h4, h5{
          /*color:$brand-primary;
          font-family: $gro-primary-font;
          font-weight: 500;
          font-style: normal;*/
        }

        p, .elementor-icon-box-description{
          font-size: $txt-size;
          margin-bottom:18px;
          color:$primary-color; /* #707070 */
          line-height: $text-size-lheight;
        }

        p:last-child{
          margin-bottom:0;
        }

        h1{
          font-size: $h1-size;
          line-height: 46px;        
        }
        h2{
          font-size: $h2-size;
          line-height: 56px;
        }
        .grey h2{
          color:#808080;
        }
        h3{
          font-size: $h3-size;
          line-height: 34px;
          color:$hdr-primary;
        }
        h5{
          font-size: $h5-size;
          line-height: 24px;
          color:$hdr-primary;
        }
        
        .elementor-widget-heading h2.elementor-heading-title{
          margin-bottom: 0;
        }

        .elementor-widget-heading + .elementor-widget-heading h2.elementor-heading-title{
          margin-top:-15px;
        }

        .elementor-widget-image{
          margin:0;
        }   
        #sample .elementor-slick-slider ul.slick-dots{
          bottom:-40px;
        }
        .elementor-slick-slider ul.slick-dots{
          bottom:-10px;
        }     
        .elementor-slick-slider ul.slick-dots li{
          width:25px;
            button{
              &:before{
                font-size:11px;
                color:#bfbfbf;
                opacity:1;
              }
            }
            &.slick-active button:before{
              color:$brand-primary;
            }
        }

        .elementor-slick-slider .slick-dotted.slick-slider{
          margin-bottom:0;
        }

        

        .slick-slide{
          margin-bottom:37px;
          display:block;

          .elementor-image-carousel-caption{
            position: relative;
            width:100%;
            padding:28px 0 0 0;
            bottom:0;
            margin-top:-85px;
            min-height:85px;
            background:rgba(0, 0, 0, 0.6);
            color:#ffffff;
          }
        }

        /* Video Testimonial Carousel Skin */
        .elementor-skin-carousel{
          background:#ffffff;
          box-shadow: 4px 7px 38px 7px rgba(132, 98, 140, 0.1);
          padding: 20px 20px;
          border-radius: $border-radius;

          .swiper-container-horizontal{
            max-height: 180px;
          }

          &.elementor-pagination-position-outside{
            .elementor-widget-container{
              
                .swiper-container-horizontal{
                  .swiper-slide{
                    
                  }
                               
                  .swiper-pagination{
                    position: relative;
                    margin-top: 8px;

                    .swiper-pagination-bullet{ 
                      background:#bfbfbf; 
                      opacity: 1;
                      width: 10px;
                      height: 10px;

                      &.swiper-pagination-bullet-active{              
                        background:$brand-primary;
                      }               
                    }

                  }
                }

            }

          }

        }

        /* Tab Desktop and Tablet Skin */
        .elementor-widget-tabs{
            margin-bottom: 25px;
            /* Title */
            &.elementor-tabs-view-horizontal .elementor-tabs{

              .elementor-tab-title{

                &.elementor-tab-desktop-title{
                  &.elementor-active{
                    background: #ffffff;
                    box-shadow: 4px 7px 38px 7px rgba(132, 98, 140, 0.1);
                    padding: 20px 20px;
                    border-radius: 20px 20px 0 0;
                    border-color: #ffffff;
                  }
                }

              }
            }

          .elementor-tabs-content-wrapper{
            .elementor-tab-content{
              background:#ffffff;
              box-shadow: 4px 7px 38px 7px rgba(132, 98, 140, 0.1);
              padding: 20px 20px;
              border-radius: 0 0 20px 20px;

                ul{
                  margin:0;
                  padding:0;
                  list-style: none;
                  display: flex;
                  flex-flow: row nowrap;
                  border-radius: 3px;
                  
                  &:nth-of-type(odd){
                    background:#f4f4f4;
                  }

                  &:first-of-type{
                    background:#7f5d88;
                    color:#ffffff;  
                    li{
                      color:#ffffff;
                    }                
                  }

                  li{
                    padding:10px;
                    flex: 1 0;
                    color:#5f5e5e;
                      &:nth-child(3){
                        flex: 4 0;
                      }                      
                  }
                }
            }
          }
        }

        /* Tab Mobile Skin */
        
        /* Image Gallery */
        .elementor-widget-image-gallery{
          margin:10px 0 30px 0;
        }
        .white-shadow-container{
          /*display: block;*/
          margin-top:25px;
          margin-bottom:15px;
          padding:28px 28px 28px 8px;
          background:#ffffff;
          border-radius: $border-radius;
          box-shadow: 4px 7px 38px 7px rgba(132, 98, 140, 0.1);

          &.padding{
            padding:28px;
          }

            .elementor-skin-carousel{  
              padding-top:0;
              padding-bottom: 0;           
              background:transparent;
              box-shadow: 0 0 0;

              .swiper-container-horizontal{
                max-height: 600px;
                height:100%;
                padding-bottom: 0;

                  .elementor-carousel-image{
                    height:100%;
                    min-height: 375px;
                  }
              }
            }
            .elementor-widget-divider{
              .elementor-divider-separator{
                color:#d9d9d9;
                padding-top:0;
                padding-bottom:4px;
              }
            }
            .elementor-text-editor{
              h6{
                font-size: 20px;
                font-size: 1.113rem;
                color: $hdr-primary;
                line-height: 1.55;
                font-family: $gro-general-font;
              }
              strong{
                font-weight: normal;
                color:$primary-color;
              }
              h6 + ul{
                margin-top:0;
                margin-left:20px;

                  li{
                    color:$primary-color;
                  }
              }
              ul{
                margin-bottom: 15px;  
                margin-left:20px;
                margin-top:-10px;
                  li{
                    font-size:$txt-size;
                  }            
              }
              p:last-child{
                margin-bottom: 0;
              }
            }
            .duration-price{
              margin-bottom: 10px;
              ul{
                margin:0;
                padding:0;
                list-style: none;
                  li{
                    margin-bottom:3px;
                    color:#5c5c5c;
                    display: inline-block;
                    margin-right:15px;

                    &:last-child{
                      margin-right:0;
                    }

                    &:nth-child(1)::before{
                        width: 1em;
                        height: 1em;
                        margin-right:6px;
                        position: relative;
                        display: inline-block;
                        font-family: "Font Awesome 5 Free";
                        font-weight: 400;
                        transform: rotate(0deg);                        
                        color:#a8a8a8;
                        content: "\f017";
                      }                     
                      
                  }
                  li:nth-child(2){

                    margin-bottom: 3px;

                    &::before{
                      width: 1em;
                      height: 1em;
                      margin-right:5px;
                      position: relative;
                      display: inline-block;
                      font-family: "Font Awesome 5 Free";
                      font-weight: 400;
                      transform: rotate(0deg);                        
                      color:#a8a8a8;
                      content: "\f0f3";
                    }
                  }
              }
            }
        }

        /* Swiper Slider */
        .customSwiperMenus {
       
          .swiper-container{
            padding: 15px;
            background: #fff;
            border-radius: 20px;
            box-shadow: 4px 7px 38px 7px rgba(132,98,140,.1);          

            .swiper-slide {
              padding: 15px 18px;
              flex-direction: column; // To make image on top of the text
              //flex-wrap: wrap;
            }
          
            // Style the background
            .swiper-slide .swiper-slide-bg{
              min-height: 45%;
            }
          
            // Style the content area
            .swiper-slide-inner {
              display: block;
              padding: 0;
              position: relative;
              min-height: 55%;

                .swiper-slide-contents{
                  position: relative;
                  margin-top: 0;
                  width: 100%;
                  max-width: 100%;        
          
                  .elementor-slide-heading {
                    font-family: $gro-primary-font;
                    font-size: 20px;
                    margin-bottom: 15px;
                    margin-top: 0;
                    padding-top: 30px;
                    color: $brand-primary;
                    font-weight: 500;
                    line-height: 1.2;
                  }
                  .elementor-slide-description{
                    font-size: $txt-size;
                    margin-bottom: 18px;
                    color: $primary-color;
                    line-height: 22px;
                    font-family: $gro-general-font;
                  }

                }
            }
            
            /* Adjust the slider arrows */
            .elementor-swiper-button, .elementor-swiper-button:hover, .elementor-swiper-button:focus {
              box-shadow: 0px -20px 20px 0 rgba(0,0,0,0.5);
              background: #84628d;
              border-radius: 100%;
              width: 50px;
              height: 50px;
            }

            .elementor-swiper-button:hover {
              box-shadow: 0px -20px 20px 0 rgba(0,0,0,0.5) !important; 
            }

            .elementor-swiper-button:before {
              border-color: #FFF;
              color: #666;
              width: 12px;
              height: 12px;
              border-width: 1px 1px 0 0;
              border-style: solid;
              font-size: 0;
              position: absolute;
              top: 38%;
            }

            .elementor-swiper-button i{
              color: #666;
              width: 12px;
              height: 12px;
              border-width: 1px 1px 0 0;
              border-color: #fff;
              border-style: solid;
              position: absolute;

              &:before{
                content:"";
              }    
            }

            .elementor-swiper-button.elementor-swiper-button-prev {
              left: -25px;
              position: absolute;

              &:before {
                left: 43%;
                transform: rotate(-135deg);
              } 
              
              i{
                transform: rotate(-135deg);
                top: 18px;
                left: 20px;
              }
            }

            .elementor-swiper-button.elementor-swiper-button-next {
              right: -25px;
              position: absolute;

              &:before {
                right: 43%;
                transform: rotate(45deg);
              }

              i{
                transform: rotate(45deg);
                top: 18px;
                left: 16px;
              }
            }           
            
            /* Tablet */
            @media screen and (max-width: 768px) {
              .elementor-swiper-button.elementor-swiper-button-prev {
                  left: 4px !important;
              }
              
              .elementor-swiper-button.elementor-swiper-button-next {
                  right: 4px !important;
              }
              
              .slick-disabled {
                  display: none !important;
              }
            }
            //
            
          }
          //

          &.elementor-pagination-position-outside{
            .swiper-pagination-bullets{
              margin-top: 0;
              margin-bottom: -55px;
              padding: 55px 0 0 0;

                .swiper-pagination-bullet{
                  width:10px;
                  height: 10px;
                  background:#bfbfbf;
                  opacity: 1;
                    &.swiper-pagination-bullet-active{
                      background:$brand-primary;
                    }
                }         
            }
          }

        } 
        /* End Swiper Slider */


        .customSliderMenus{
            .slick-slider .slick-list{
              padding:15px;
              background:#ffffff;
              border-radius: $border-radius;
              box-shadow: 4px 7px 38px 7px rgba(132, 98, 140, 0.1);
            }

            .slick-track{              
              margin:auto;                  
               
                .slick-slide{
                  display:flex;
                  padding:15px;
                  margin-bottom:20px;

                    .slick-slide-inner{
                      display:block;
                      padding:0 15px;

                        .elementor-slide-content{
                          margin-top:0;

                            .elementor-slide-heading{
                              font-family: $gro-primary-font;
                              font-size: 24px;
                              margin-bottom:23px;
                              margin-top:0;
                              padding-top:30px;
                            }
                        }

                        .elementor-slide-description{
                          font-size: 20px;
                          font-size: 0.95rem;
                          margin-bottom: 18px;
                          color: $primary-color;
                          line-height: 1.30;                            
                        }
                    }
                          
                }
            }

        }
        .aolrc-expanding-cards{
          padding:0;
          .card{
            display:block;
            margin-left:auto;
            margin-right: auto;
          }
        }
        
        .aolrc-expanding-cards .card__inner{
          .elementor-slide-description{
              p{
                font-size:$txt-size;
                line-height: 24px;
              }
          }
        }
        .aolrc-expanding-cards .card__expander{
          .description-content-expanded{
            p{
              font-size:$txt-size;
              line-height: 24px;
              margin-bottom: 0;;
            }
          }
        }
        
        #download-copy{
          padding:0;
          background:transparent;
          color: $brand-primary;
            .elementor-button-text{
              font-size:1.113rem;
              font-weight: 500;
            }
            :hover{
              text-decoration: underline;
            }
          
        }

        .elementor-accordion{
          margin-top:12px;
          margin-bottom: 20px;
          padding:10px 0 0 0;
          background:#ffffff;
          border-radius: $border-radius;
          box-shadow: 4px 7px 38px 7px rgba(132, 98, 140, 0.1);

            .elementor-tab-title{
              padding:25px 35px;

                .elementor-accordion-icon .elementor-accordion-icon-opened{
                  .fa-minus:before, .fa-plus:before{
                    color:$brand-primary;
                  }
                }
                .elementor-accordion-icon .elementor-accordion-icon-closed{
                  .fa-minus:before, .fa-plus:before{
                    color:$brand-primary;
                  }
                }

                a{
                font-family: $gro-primary-font;
                font-weight: 700;
                font-size: $txt-size;
              }
            }

            .elementor-accordion-item{
              border-color:#d9d9d9;
              border-width: 0 0 1px 0;
              border-radius: $border-radius $border-radius 0 0;
            }
            .elementor-tab-content{
              border:0;
              padding:8px 60px 35px 60px;
                ul{
                  margin-left:20px;
                  margin-bottom: 20px;
                    &:last-child{
                      margin-bottom:0;
                    }                    
                }
                h6 {
                  font-family: $gro-primary-font;
                  font-size: 22px;
                  color:$brand-primary;
                  margin-bottom: 3px;
                  display: block;

                  + ul{
                  margin:0;
                  list-style:none;

                    li{
                      margin-bottom:3px;
                      color:#5c5c5c;

                      &::before{
                        width: 1em;
                        height: 1em;
                        margin-right:10px;
                        position: relative;
                        display: inline-block;
                        font-family: "Font Awesome 5 Free";
                        font-weight: 400;
                        transform: rotate(0deg);
                        content: "\f017";
                        color:#a8a8a8;
                      }
                    }
                  }

                }
                hr{
                  background:#d9d9d9;
                  margin:25px 0 20px 0;
                }
                p:last-child{
                  margin-bottom: 0;
                }
                p{
                  a{
                    &.download{
                      &:hover{
                        text-decoration: underline;
                      }
                      &::before{
                        content:"\f56d";
                        width: 1em;
                        height: 1em;
                        margin-right:5px;
                        position: relative;
                        display: inline-block;
                        font-family: "Font Awesome 5 Free";
                        font-weight: 900;
                        transform: rotate(0deg);                       
                      }
                    }
                  }
                }
            }
            .elementor-accordion-item:last-child{
              border:0;
            }
        }

        /* Treatment Accordion only */
        .treatment-container .elementor-accordion{
          .elementor-tab-content{
            display: flex;
            flex-flow: row wrap;

            hr{
              width: 100%;
            }

            div.accor-block{
              display: flex;
              
              ul + hr{
                width:0;
                
              }
              ul:first-of-type{
                display:inline-block;
                width:100%;
                max-width: 200px;
                margin:0 10px 0 0;
                list-style: none;
              }

              ul:last-of-type{
                display: inline-flex;
                flex-direction: column;
                list-style: none;
                justify-content: flex-start; /* space-between */
                margin-bottom: 0;
                max-width: 100%;

                li{
                  margin-bottom: 15px;

                  &:last-child{
                    margin-bottom:0;
                  }

                  h3, h4, h5, h6{
                    font-size: 28px;
                    line-height: 28px;
                    margin:0;
                  }

                  &.alert{
                    color: #805802;
                    background: #faf3dd;
                    border-radius: 5px;
                    padding: 8px 15px;
                  }

                  a.elementor-button{
                    background-color: #83618d;
                    font-size: 18px;
                  }

                  ul{
                    margin: 15px 0 0 40px;
                    list-style: initial;

                    li{
                      margin-bottom: 10px;
                        &:last-child(){
                          margin-bottom: 0;
                        }
                    }

                  }

                }
              }
            }

          }
        }

        #form-sticky{
          h3.elementor-heading-title{
            color:#ffffff;
            font-family: $gro-general-font;
            font-size: 20px;
            text-align: center;
            text-transform: uppercase;
            font-weight: bold;
            margin-top:0;
            margin-bottom:-10px;
          }

          .smallgroup .elementor-widget-text-editor .elementor-text-editor{
            p{
              color: #ffffff;
              a{
                display: inline-block;
                color: #ffffff;
                text-decoration: underline;
              }
            }
          }
          .hbspt-form{
            margin-top:12px;

            form{
              fieldset{
                &.form-columns-1 .input{
                  margin-right:0;
                }                
                .input input.hs-input{
                  padding: 0.4em .50em!important;
                  color:#000000!important;
                  &.error{
                    color:#ff0000 !important;
                  }
                }
                &.form-columns-1{
                  .hs_subscribe_to_our_mailing_list{
                    float: left;
                    width: 100%;
                    margin-bottom: 5px;
                    .input{
                      ul{
                        li{
                          input.hs-input{
                            width: 18px;
                            margin-right: 10px;
                          }
                          input.hs-input, span{
                            float: left;
                          }
                        }                        
                      }
                    }
                  }
                }
                &.form-columns-2 .input{
                  margin-right: 0;
                }
                &.form-columns-2 .hs-firstname .input, &.form-columns-2 .hs-event_start_date .input, &.form-columns-2 .hs-email .input, &.form-columns-2 .hs-projected_enrollment .input,
                &.form-columns-2 .hs_projected_enrollment_as_group .input{
                  margin-right:8px;
                }
                &.form-columns-2 .hs-event_start_date .input, &.form-columns-2 .hs-event_end_date .input{
                  margin-bottom: 10px;
                }
                &.form-columns-2 .hs-projected_enrollment .input, &.form-columns-2 .hs-what_is_the_focus_of_your_event_ .input{
                  margin-bottom: 0;
                }
                &.form-columns-2{
                  margin-top:-10px;
                  .hs_projected_enrollment_as_group{
                    ul.hs-error-msgs{
                      display:block!important;
                      position:absolute;
                      width:100%;
                    }
                  }
                }

                ul.hs-error-msgs{
                  display:none !important;

                  li{
                    label.hs-error-msg{
                      //color:rgba(255,223,155,.8);
                      color:#ff0000;
                      font-size: 14px!important;
                      line-height: 16px;
                    }
                  }
                }
                .hs-form-field{
                  margin-bottom: 10px;

                  label {
                    span{
                      color: #ffffff!important;
                      text-transform: uppercase;
                      font-weight: 700!important;
                      font-size: 11px!important;
                      letter-spacing: .10px;

                        &.hs-form-required{
                          color: #ffffff!important;
                          font-size: $txt-size!important;
                          padding-left: 3px;
                          display:none;
                        }

                    }
                  }
                }

                input, select{
                  &.invalid.error{
                    border-color:red!important;
                  }
                }
              }
              
              .hs_recaptcha{
                height: 30px;
                overflow: hidden;
                margin-bottom: 5px;
                .grecaptcha-badge{
                  height:15px;
                }
              }
              .actions {
                margin-top:0;
                margin-bottom: 0;
                padding-bottom: 10px;
                padding-top:5px;

                input.hs-button.primary.large{
                  background:#3c3247!important;
                  width:100%;
                  margin-bottom: 0;
                  height: 48px;
                  line-height: 48px;
                }
              }
              ul.hs-error-msgs{
                margin: 0;
                list-style: none;

                li{
                  font-size: 13px;
                  background: #fdf4d3; /*#fbefc1*/
                  line-height: 14px;
                  border-radius: 3px;
                  padding: 10px 10px 8px 10px;
                  margin-top: 3px;
                  font-weight: normal;

                  label{
                    color:#ff0000;
                    font-weight: 500;
                    font-size: $txt-size!important;
                  }

                }
              }

            }
            //

          }
          .elementor-widget-divider{
            .elementor-divider-separator{
              opacity: 0.2;
            }
          }

          .elementor-widget-html + .elementor-widget-divider + .elementor-widget-text-editor p, .elementor-widget-heading + .elementor-widget-text-editor p{
            font-size: 13px;
            color:#ffffff;            
            margin-bottom: 0;
            margin-top:2px;
            line-height: 18px;
            font-weight: bold;
            a{
              display: inline-block;
              color:#ffffff;
              font-size: 13px;
              text-decoration: underline;
              cursor: pointer;
              line-height: 18px;
              font-weight: bold;
            }
          }
          .elementor-widget-html + .elementor-widget-divider + .elementor-widget-text-editor p:first-child{
            margin-top:3px;
          }
        }

        #fixed-floater{
          position: fixed;
          top:40%;
          left:2.5%;
          width:300px;

            a{
              display: block;
              width: 100%;
              color:#7a7a7a;
              font-family: $gro-primary-font;
              font-size: 18px;
              font-weight: 500;

                &.active{
                  color:$brand-primary;
                    &:before{
                      content:"\f192";
                      font-weight: 900;
                    }
                }
                &:before{
                  content:"\f111";
                  width: 1.5em;
                  height: 1em;
                  margin-right:5px;
                  position: relative;
                  display: inline-block;
                  font-family: "Font Awesome 5 Free";
                  font-weight: 500;
                  font-size:0.7rem;
                  transform: rotate(0deg);
                }
            }
        }
        
               
        .sticky-nav{
          display:block;
          margin:0;
          padding:0;
            ul{
              margin:0;
              padding:0;
              list-style:none;
                li{
                  display:inline-block;
                    a{
                      color:#ffffff;
                      padding:13px 20px;
                    }                  
                }
            }
        }

        /* Getaway */
        #packages{
          .elementor-column.elementor-col-50{
            ul{
              margin:0;
              padding:0;

                li{
                  display: flex;
                  justify-content: left;
                  align-items:center;
                  margin:0 0 25px 0;
                  padding:10px 50px 10px 120px;
                  min-height:100px;
                  background:#ffffff url("../images/correct.png") no-repeat 7% 50%;
                  list-style:none;   
                  box-shadow: 4px 7px 38px 7px rgba(132, 98, 140, 0.1); 
                  border-radius: 20px;
                  line-height: $text-size-lheight;
                  font-size: $txt-size; 
                  text-align: left; 
                  
                    p{
                      line-height: $text-size-lheight;
                      font-size: $txt-size;
                      margin:0;
                      padding:0;
                      text-align: left;
                    }
                }              
            }
          }
        }

        /* Getaways */
        .getaway-container{
          .slick-slide{
            margin-bottom: 0;
          }

          .price-info-box, .terms-info-box{
            min-width: 300px;
            max-width: 300px;
            margin-top:5px; 
              .elementor-widget-container{
                  min-height: 180px;
              }
          }

          .price-info-box{            
            margin-right: 15px;

              .elementor-widget-container{
                background:#ffffff;
                box-shadow: 4px 7px 38px 7px rgba(132, 98, 140, 0.1); 
                border-radius: 15px;                
                font-size: 17px;
                line-height: 25px;

                  .elementor-cta__content{
                      padding:30px 20px;
                      background:transparent;                   
                  }

                  .elementor-content-item{
                    text-align:center;
                      strong{
                        font-size: 24px;
                        color:#000000;
                        font-weight: 500;
                      }
                    &.elementor-cta__description button.disabled{
                      display: block;
                      background:#cbcbcb;
                      border-radius: 5px;
                      border:0;
                      font-size:$txt-size;
                      font-weight: 500;
                      max-height: 40px;
                      margin:15px 0 10px 0;
                      cursor: default;
                      width:100%;
                      padding-left:10px;
                      padding-right: 10px;
                    }
                  }
                  .elementor-cta__button{
                    background:#83618D;
                    border-radius: 5px;
                    border:0;
                    color: #ffffff;
                    font-size: $txt-size;
                    font-weight: 500;
                  }
              }
          }

          .terms-info-box{
            margin-left: 15px;

            .elementor-widget-container{
              background:#ffffff;
              box-shadow: 4px 7px 38px 7px rgba(132, 98, 140, 0.1); 
              border-radius: 15px;                
              font-size: 17px;
              line-height: 25px;
              padding:25px;

                ul{
                  margin:0 0 0 15px;
                  padding:0;
                    li{
                      list-style: circle;
                      line-height: 20px;
                      margin-bottom: 5px;
                    }
                }

            }
          }
        }

        /* Footer Getaways */
        #footer-getaways{
          margin:0;

          .elementor-inner-section{
            h1, h2, h3, h4, h5, h6{
              color:$primary-color;
              font-size: 24px;
              padding: 0;
              line-height: 30px;
              max-width: 280px;
              margin: 0 auto; 
            }
          }

          .price-info-box{
            min-width: 300px;
            max-width: 300px;
            text-align:center;
            margin:auto;

            .elementor-widget-container{
              background:#ffffff;
              box-shadow: 4px 7px 38px 7px rgba(132, 98, 140, 0.1); 
              border-radius: 15px 15px 0 0;                
              font-size: 17px;
              line-height: 25px;

                .elementor-cta__content{
                    padding:30px 20px;
                    background:transparent;                   
                }

                .elementor-content-item{
                  text-align:center;
                    strong{
                      font-size: 24px;
                      color:#000000;
                      font-weight: 500;
                    }
                }
                .elementor-cta__button{
                  background:#83618D;
                  border-radius: 5px;
                  border:0;
                  color: #ffffff;
                  font-size: $txt-size;
                  font-weight: 500;
                }                
            }         

          }
          .more-info{
            min-width: 300px;
            max-width: 300px;
            text-align:center;
            margin:auto; 
              .elementor-widget-container{
                  .elementor-button-wrapper{
                    background:#ffffff;
                    box-shadow: 4px 7px 38px 7px rgba(132, 98, 140, 0.1); 
                    border-radius: 0 0 15px 15px;                
                    font-size: 17px;
                    line-height: 25px;
                      .elementor-button{
                        background:transparent;
                        color:#83618D;
                          &:hover{
                            text-decoration:underline;
                          }
                      }
                  }
              } 
          }

        }
        
        .elementor-section-full_width .elementor-slides{
          .swiper-slide-inner{
            .elementor-slide-description, .elementor-slide-description p{
              color:#ffffff;              
            }
          }
        }
        
    }

    #sticky-menu{
      .elementor-element-populated{
        padding:6px;            
      }
    }

    /*.progress-bar{
      margin-top:-2px;

      //Reading progress bar
      progress {
        width: 100%;
        position: fixed;
        height: 4px;
        appearance: none;
        background-color: transparent;
        border: none;
        color: #fda32b;        
        z-index:99!important;        
      }
      
      progress::-webkit-progress-bar {
        background-color: transparent;
      }
      
      progress::-webkit-progress-value {
        background-color: #fda32b;
      }
      
      progress::-moz-progress-bar {
        background-color: #fda32b;
      }      
    
    }*/

    
    /* Meditation Mindfulness */
    .white-shadow-container{
      .elementor-widget-call-to-action, .elementor-widget-media-carousel, &.shadow-coln .elementor-container, .white-shadow{
        display: flex;
        /*display: block;*/
        margin-top:0;
        margin-bottom:0;
        padding:28px 28px 28px 28px;
        background:#ffffff;
        border-radius: $border-radius;
        box-shadow: 4px 7px 38px 7px rgba(132, 98, 140, 0.1);
        &.no-padding{
          padding:0 28px;
        }
        .elementor-widget-container{
          width: 100%;
        }
      }
      .elementor-cta--skin-classic .elementor-cta__content{
        background:#ffffff;
      }
      .elementor-widget-call-to-action .elementor-cta__title, &.shadow-coln .elementor-container .elementor-heading-title{
        font-family: $font-primary;        
        font-weight: 700;
        color: $brand-primary;        
      }
      
      &.type-two .elementor-widget-call-to-action{
        padding:0;
      }

      .elementor-widget-call-to-action .elementor-cta__title{
        font-size: 22px;
        line-height: 30px;
      }
      
      .shadow-inner-coln{
        margin:0 20px;
        padding:20px;
        background:#ffffff;
        border-radius: $border-radius;
        box-shadow: 4px 7px 38px 7px rgba(132, 98, 140, 0.1);

        .elementor-heading-title{
          font-family: $font-secondary;        
          font-weight: 500;
          color: $brand-primary;
        }

        ul{
          margin:0 0 40px 40px;
          padding:0;

          li{
              margin-bottom: 10px;
          }

        }
        &.no-mpadding{
          margin:0;
          padding:0;
        }
        &.no-padding{
          padding:0;
        }
        &.no-margin{
          margin:0;
        }

        &.no-mpadding, &.no-padding, &.no-margin{
          ul{
            margin:0;
            padding:0;

            li{
                margin-bottom: 0;
            }

          }
        }

      }
      
    }

    .cards{
      .elementor-column{
        .elementor-column-wrap{
          .elementor-widget-wrap, .elementor-widget-wrap{
            background: #ffffff;
            box-shadow: 4px 7px 38px 7px rgba(132, 98, 140, 0.1);
            padding: 20px;
            border-radius: 20px;
            margin: 0 10px;

            .elementor-widget-heading, .elementor-image-box-content{
              h2, h3, h5, h6{
                &.elementor-heading-title, &.elementor-image-box-title{
                  font-family: $font-primary;
                  font-size: 24px;
                  line-height: 32px;
                  color: $brand-primary;
                }
              }
            }
          }
        }
      }
    }
    
  }


  /* Popup Style */
  #smallgroup .elementor-widget-html{
    form{
      fieldset{
        &.form-columns-1, &.form-columns-2{
          max-width: 100%;
          .input .hs-input, .hs-phone input{
            background-color: #f5f8fa;
            border:1px solid #84628d;
            border-radius: 4px;
            margin-bottom: 10px;
            font-size: 16px;
            font-weight: 500;
            font-family: $font-primary;
            padding:0.5em 0.75em;
            height: 40px;
          }

          .input{
            margin:0;
            input{
              width: 100%;
            }
          }

          &.form-columns-2 {  
            margin-bottom: 10px;    
            .hs-form-field + .hs-form-field {
              margin-left:8px;              
            }
            ul.hs-error-msgs{
              margin:0;
            }
          }
        }
        &.form-columns-2 .hs-form-field{
          width:48%;
          display: inline-block;          
          label span{
            font-weight: 700;
          }
          .hs-dateinput{
            height: 40px;
          }
        }
        &.form-columns-2 .hs-form-field + .form-columns-2 .hs-form-field{
          margin-left: 5px;
        }
        ul.hs-error-msgs{
          margin:-10px 0 10px 0;
          padding:0;
          li{
            list-style: none;
            .hs-error-msg{
              font-size: $txt-size-s;
              line-height: $text-size-lheight;
              color:red;
              font-weight: normal;
              font-style: italic;
            }
          }
        }
                   
      }

      .hs_error_rollup{
        ul.hs-error-msgs{
          margin:-10px 0 10px 0;
          padding:0;
          li{
            list-style: none;
            .hs-error-msg{
              font-size: $txt-size-s;
              line-height: $text-size-lheight;
              color:red;
              font-weight: normal;
              font-style: italic;
            }
          }
        }
      }
      
      .hs_recaptcha{
        margin-top:0;
        margin-bottom: 10px;
      }      
      .hs-submit{
        .actions{
          input{
            margin-top:10px;
            font-weight: bold;
            font-family: $font-primary;
            font-size: 16px!important;
            border-radius: 4px;
            cursor: pointer;
            background-color: #3c3247;
            letter-spacing: 2.29px;
            text-transform: uppercase;
            height: 48px;
            line-height: 28px;
          }
        }
      } 
    }
    .submitted-message{
      p, div{
        font-size: $h5-size;
        font-weight: bold;
      }
    }
  }


  @media(min-width:1024px){
    .admin-bar #primary #reading-progress-bar, .admin-bar #primary .progress-bar progress {
      top: 102px;
    }
    #primary #reading-progress-bar, #primary .progress-bar progress {
      top: 70px;
    }
  }

  @media (max-width:1919px){
    #primary #groContentArea #fixed-floater{
      display:none;
    }
  }

  @media(min-width:768px){
    #primary{
      #groContentArea{
        .white-shadow-container{
          .elementor-skin-carousel{
            margin-bottom:-20px;
            .elementor-widget-container{
              width:100%;
            }
          }
        }
      }
    }
  }

  /**
  * Mobile media Queries
  **/
  @media( max-width:767px ){    
    #primary{
      .progress-bar progress{
        top:94px;
      }

      #gro-hero-banner{
        .elementor-inner-section .elementor-widget-heading .elementor-widget-container h1.elementor-heading-title{
          font-size:32px;
          margin-top:25px;
          margin-bottom:15px;
          line-height: 2.5rem;
        }
        .elementor-widget-button .elementor-button-link{
          font-size:14px;
          padding:6px 12px;
          min-height: 32px;
          border-width:2px;
        }
        .elementor-widget-text-editor .elementor-text-editor ul{
          display:block;
          text-align:center;
          line-height: 1.2;

          li{
            display:inline-block;
            font-size:20px;
            padding-right:10px;
            line-height: 2rem;
          }
        }
        .elementor-widget-button{
          margin-top: 0;
          margin-bottom:25px;
        }
      }

      #groContentArea{
        h3{
          font-size: 24px;
          line-height: 26px;
        }
        p, .white-shadow-container .elementor-text-editor ul li{
          font-size:$txt-size;
        }
        p:last-child{
          margin-bottom:0;
        }
        h2{
          font-size: 28px;
          line-height: 32px;
        }
        .elementor-widget-heading{
          margin-bottom: 10px;
        }
        .elementor-widget-heading h2.elementor-heading-title{
          margin-bottom:0;
        }
        .elementor-widget-image{
          margin-bottom: 22px;
        }
        .slick-slide {
          margin-bottom:15px;

          .elementor-image-carousel-caption{
            margin-top:0;
            padding:10px;
            background:rgba(0, 0, 0, 1);
            line-height: 24px;
            min-height: 20px;
          }
        }
        .elementor-slick-slider .slick-dotted.slick-slider{
          margin-bottom: 15px;
        }
        .elementor-skin-carousel{
          padding:5px;
          margin-bottom:15px;

          .swiper-slide{
            width:100%!important;
            margin-right:0!important;
          }
        }

        .elementor-widget-tabs{
            margin-bottom: 25px;
            margin-top: 25px;
            /* Title */
            &.elementor-tabs-view-horizontal .elementor-tabs{

              .elementor-tab-title{
                font-family: $font-primary;
                font-weight: 500;
                font-size: 19px;
                background: #ffffff;                
                padding: 20px 20px;
                border-top: 1px solid #d9d9d9;

                &.elementor-tab-mobile-title{
                    &:before{
                      font-family: "Font Awesome 5 Free";
                      font-weight: 900;
                      display: inline-block;
                      text-rendering: auto;
                      line-height: 1;
                      color: $brand-primary;
                      content: "\f105";
                      padding-right: 10px;
                    }

                    &.elementor-active{
                      background: #ffffff;
                      box-shadow: 4px 7px 38px 7px rgba(132, 98, 140, 0.1);
                      padding: 20px 20px;                      

                      &:before{
                        content: "\f107";
                      }
                    }
                }

              }

              &.elementor-tabs-view-horizontal .elementor-tabs .elementor-tabs-content-wrapper{
                > div:first-child{
                  border-radius: 20px 20px 0 0;
                }
                > div:last-child{
                  border-radius: 0 0 20px 20px;
                }
              }

            }

          .elementor-tabs-content-wrapper{
            box-shadow: 4px 7px 38px 7px rgba(132, 98, 140, 0.1);
            background:#ffffff;

            .elementor-tab-content{                        
              padding: 0;
              border-radius: 0;

                ul{
                  margin:0;
                  padding: 10px 20px;
                  list-style: none;
                  display: flex;
                  flex-flow: column nowrap;
                  border-radius: 3px;
                  
                  &:nth-of-type(odd){
                    background:#f4f4f4;
                  }

                  &:first-of-type{
                    display:none;              
                  }

                  li{
                    padding: 5px 10px;
                    flex: 1 0;
                    color:#5f5e5e;

                    &:first-child:before{
                      content: "Time: ";
                      display: block;
                      font-weight: bold;
                    }  
                    &:nth-child(2):before{
                      content: "Activity: ";
                      display: block;
                      font-weight: bold;
                    }
                    &:nth-child(3):before{
                      content: "Description: ";
                      display: block;
                      font-weight: bold;
                    }
                    &:nth-child(4):before{
                      content: "Location: ";
                      display: block;
                      font-weight: bold;
                    }                                      
                  }
                }
            }
          }
        }


        .white-shadow-container{
          margin-bottom:15px;

          padding:15px;
            .elementor-element-populated{
              padding:5px;
            }
            .elementor-skin-carousel{
              padding:0;
              margin-bottom:10px;

              .swiper-container-horizontal .elementor-carousel-image{
                min-height: 200px;
              }
            }
        }
        .elementor-accordion {
          .elementor-accordion-icon{
            display: inline-block;
          }
          .elementor-tab-title{
            padding:15px 20px;
              a{
                font-size: $txt-size;
                line-height: 1.2rem;
                display: inline-block;
                width: 90%;
              }
          }
          .elementor-tab-content{
            padding-left:20px;
            padding-right:20px;
              h6{
                font-size:20px;
                line-height: 22px;
                margin-bottom: 10px;                
              }
              h6+ul{
                margin-bottom: 5px;
              }
          }
        }
        .elementor-slick-slider ul.slick-dots{
          bottom:-25px;
        }
        .elementor-skin-carousel.elementor-pagination-position-outside .elementor-widget-container .swiper-container-horizontal .swiper-pagination{
          margin-top:10px;
        }
        .aolrc-expanding-cards .card{
          padding:0;
        }
        .white-shadow-container{
            .elementor-widget-heading{
              margin-bottom: 10px;
            }
            .duration-price{
              margin-bottom: 5px;
            }
        }
        #spa{
          .elementor-widget-image-carousel{
            margin-bottom: 10px;
          }
          .elementor-slick-slider ul.slick-dots{
            bottom: -40px;
          }
          .elementor-widget-image-gallery{
            margin-bottom: 15px;
          }          
        }
        .elementor-skin-carousel .swiper-slide{
          margin-right:10px !important;
        } 
        
        /* Getaways */
        #packages .elementor-column.elementor-col-50 ul li{
            padding-left:20%;
            padding-right:20px;
            font-size: $txt-size;
            line-height: $text-size-lheight;
            background-position: 6% 50%;
              p{
                font-size: $txt-size;
                line-height: $text-size-lheight;
              }
        }
        .getaway-container .price-info-box, .getaway-container .terms-info-box{
          margin-left:auto;
          margin-right:auto;
        }
        .aolrc-expanding-cards .card{
          margin-bottom:25px;
        }
        #footer-getaways{
          padding-bottom:35px;

          .elementor-col-33{
            margin-bottom: 15px;
          }
        }
        
        .treatment-container .elementor-accordion {
          .elementor-tab-content {
              div.accor-block {
                flex-flow: column wrap;
                ul:first-of-type{
                  margin: 0 0 15px 0;
                  max-width: 300px;
                }
                ul:last-of-type{

                }
              }
          }
        }
      }
      //
      .elementor-testimonial-wrapper .elementor-testimonial-meta.elementor-testimonial-skin-skin-1{
        padding:15px;
        
        .elementor-testimonial-divider-style{
          margin-top:15px;
        }
        .elementor-testimonial-content{
          margin-top:15px;
        }
      }

      .elementor-column-gap-default>.elementor-row>.elementor-column>.elementor-element-populated{
        padding:0;
      }

      .elementor-widget-heading{
        .elementor-heading-title{
          font-size: $h3-msize;
        }
      }

      .white-shadow-container{
        .elementor-cta--skin-classic .elementor-cta__content{
          padding: 30px 20px;
        }
      }
    }
    #sticky-menu{
      display:none;
    }
  }

  @media(min-width: 1025px) and (max-width: 1521px){
    #primary #groContentArea{
      .getaway-container{
        .price-info-box{
            min-width: 200px;
            max-width: 200px;
            margin-right:10px;
            margin-top:0;

          .elementor-widget-container{
            min-height: 120px;

            .elementor-content-item{
              line-height: 18px;
              strong{
                font-size: 22px;
              }
              em{
                font-size: 13px;
              }
            }
            .elementor-cta__content{
              padding: 15px 15px 15px 15px;
              font-size: $txt-size;
            }
            .elementor-cta__button{
              padding:12px 18px;
              font-size: 14px;
            }

          }
        }
        .terms-info-box{
            min-width: 300px;
            max-width: 310px;
            width:100%;
            margin-left:10px;
            margin-top:0;

              .elementor-widget-container{
                min-height: 120px;
                padding:15px 15px 10px 25px;
                ul{
                  li{
                    strong{
                      color:#000000;
                      font-weight: 500;
                    }
                    a{
                      font-size: $txt-size;
                    }
                  }
                }
              }
        }
        .elementor-widget-heading{
          margin-bottom:10px;
          h2.elementor-heading-title, h3.elementor-heading-title{
            font-size: 36px;
          }
        }
        .elementor-widget-text-editor{
          margin-bottom:10px;
          p{
            margin-bottom:10px;
            line-height: 23px;
          }
        }
      }
      //
    }
  }


  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    .admin-bar #sticky-menu{
      top:94px!important;
    }
    #primary #groContentArea .sticky-nav ul li a{
      padding: 13px 8px;
    }

    /* Getaways */
    #primary #groContentArea{
      #packages .elementor-column.elementor-col-50 ul li{
        padding-left:20%;
        padding-right:20px;
        font-size: $txt-size;
        line-height: $text-size-lheight;
        background-position: 6% 50%;
          p{
            font-size: $txt-size;
            line-height: $text-size-lheight;
          }
      }
      .getaway-container .price-info-box, .getaway-container .terms-info-box{
        margin-left:auto;
        margin-right:auto;
      }
      .getaway-container .price-info-box{
        min-width: 200px;
        max-width: 225px;
      }
      .getaway-container .terms-info-box{
        max-width: 100%;
      }
      .aolrc-expanding-cards .card{
        margin-bottom:25px;
      }
      #footer-getaways{
        padding-bottom:35px;

        .elementor-col-33{
          margin-bottom: 15px;
        }
        .price-info-box, .more-info{
          min-width: 200px;
          max-width: 100%;
        }
        .price-info-box .elementor-widget-container .elementor-cta__button{
          padding-left:12px;
          padding-right:12px;
          font-size: 14px;
        }
        .elementor-inner-section{
          h1, h2, h3, h4, h5, h6{
            font-size: 18px;
          }
        }      

      }
      .treatment-container{
        .elementor-tab-content{
          padding-left:30px;
          padding-right:30px;
            div.accor-block{
              flex-flow: row nowrap;
                ul:first-of-type{
                  max-width: 125px;
                  margin-right:15px;
                }
            }
        }
      }

    }
    //
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    #primary #groContentArea{

      #packages .elementor-column.elementor-col-50 ul li{
        padding-left:20%;
        padding-right:20px;
        font-size: $txt-size;
        line-height: $text-size-lheight;
        background-position: 6% 50%;
          p{
            font-size: $txt-size;
            line-height: $text-size-lheight;
          }
      }

      .getaway-container .price-info-box{
        min-width: 300px;
        max-width: 300px;
      }
      .getaway-container .terms-info-box{
        max-width: 100%;
      }

      .treatment-container{
        .elementor-tab-content{
          padding-left:30px;
          padding-right:30px;            
        }
      }

    }

  }
