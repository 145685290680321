
// Colors
$brand-primary:         #84628d;
$brand-secondary:       #F9AB1D;
$primary-color:         #3F484F; /*#676767 | #3f484f | #0f131a*/ 
$secondary-color:       #595e67;
$hdr-primary:           #1d1d1f;/* #484848 */
$hover-brand-primary:   #634c65;


// Breakpoints
//$mobile-max:              1291px;
//$mobile-max:              1023px;
$mobile-max:              1279px;

//$desktop-min:             1292px;
$desktop-min:             1024px;

$desktop-small-max:       1279px;

$container-max-width:     1295px;


// Fonts
//Previous Font
///wp-content/uploads/2018/12/Ernest_Emily.ttf

@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Noto Sans'), local('NotoSans'),
    url('/wp-content/themes/astra-child/dist/fonts/gfonts/notosans-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/wp-content/themes/astra-child/dist/fonts/gfonts/notosans-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    font-display: swap;
   }

/*@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 500;
    src: local('Noto Sans Medium'), local('NotoSans-Medium'),
    url('/wp-content/themes/astra-child/dist/fonts/gfonts/notosans-medium.woff2') format('woff2'), 
    url('/wp-content/themes/astra-child/dist/fonts/gfonts/notosans-medium.woff') format('woff');
    font-display: swap;
    }*/

@font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 400;
    src: local('Playfair Display'), local('PlayfairDisplay'),
    url('/wp-content/themes/astra-child/dist/fonts/gfonts/playfairdisplay-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/wp-content/themes/astra-child/dist/fonts/gfonts/playfairdisplay-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    font-display: swap;
   }

// Type
$font-primary:            'Noto Sans', sans-serif;
//$font-primary-bold:       'Noto Sans Bold', sans-serif;
//$font-primary:            'Brandon-Grotesque','brandon-grotesque',sans-serif;

$font-secondary:          'Playfair Display', serif;
//$font-secondary:          'Nunito Sans', sans-serif;
//$font-secondary:          'futura-pt', sans-serif;
//$font-blog-primary:       'playfair-display', serif; // Added in Adobe TypeKit  

$font-blog-primary:       'Playfair Display', serif; // Added in Adobe TypeKit 
$font-primary-title:      'Playfair Display', serif;
//$font-secondary-title:    'Lato Bold', sans-serif;

// Font-size
$txt-size: 0.891rem; /* D-15.3px * M-14.5px */
$txt-size-lg: 0.9rem;
$txt-size-xlg: 1rem;
$txt-size-s: 0.81rem; /* 0.725rem */

$txt-label-s: 0.725rem;
$txt-label-m: 0.745rem;

// Font letter spacing
$txt-space: .00313em;
$nav-txt-space: .06471em; /* .01471em */

// Font Line height
$text-size-lheight: 1.34rem; //24px

// Headers
$h1-size: 46px; /* 60px */
$h2-size: 36px; /* 48px */
$h3-size: 30px; /* 32px */
$h4-size: 24px; /* 24px */
$h5-size: 22px; /* 22px */
$h6-size: 0.85rem; /* 0.85rem */

// Mobile Headers
$h1-msize: 2.25rem; //36px // 2.3125rem / 38px 
$h2-msize: 1.875rem; // 30px  // 2.25rem / 36px
$h3-msize: 1.6rem; //28px // 1.875rem / 30px
$h4-msize: 1.5rem; //24px // 1.5rem / 24px
$h5-msize: 1.25rem; //20px // 1.25rem / 20px
$h6-msize: 0.85rem; // 16px // 0.85rem / 14px

$h1-msize-bold: 2.1125rem; //34.67px

//Tablet Headers
$h1-tsize: 3.3125rem; //38px
$h1-tsize-bold: 2.1125rem; //34.67px

// Mobile Blog Header Line height
$h1-msize-lheight: 2.7rem; //44px
$h2-msize-lheight: 2.7rem; //44px


// Blog Headers
$h1-blogsize: 42px;
$h2-blogsize: 38px;

// Blog Header Line height
$h2-blogsize-lheight: 2.7rem; //44px