.aa-input-search::-webkit-search-decoration, .aa-input-search::-webkit-search-cancel-button,
.aa-input-search::-webkit-search-results-button, .aa-input-search::-webkit-search-results-decoration {
  display: none;
}
.aa-input-search:focus {
  outline: 0;
  border-color: #3a96cf;
  box-shadow: 4px 4px 0 rgba(58, 150, 207, 0.1);
  }
.aa-input-icon {
  height: 25px;
  width: 25px;
  position: absolute;
  top: 50%;
  left: 16px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  fill: #b1b4bb; /* e4e4e4 */
  pointer-events: none;
  z-index:3;
}
.aa-hint {
  left: 64px !important;
  color: #e4e4e4;
}
.aa-dropdown-menu {
  background-color: #fff;
  border: 1px solid rgba(226, 226, 226, 1);
  border-top-width: 0;
  width: 100%;
  margin-top: -3px;
  /*box-shadow: 4px 4px 0 rgba(241, 241, 241, 0.35);
  box-shadow: 4px 7px 38px 6px rgba(0, 0, 0, 0.1);*/
  font-size: 11px;
  border-radius: 0 0 4px 4px;
  box-sizing: border-box;
  padding-bottom:5px;
}
.aa-suggestion {
  padding: 0 12px;
  cursor: pointer;
  -webkit-transition: .2s;
  transition: .2s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.aa-suggestion:hover .record-list, .aa-suggestion.aa-cursor {
  /*background-color: rgba(241, 241, 241, 0.35);*/
  background-color: #f5f5f5;
}
.aa-suggestion > span:first-child {
    color: #333;
    }
.aa-suggestion > span:last-child {
    text-transform: uppercase;
    color: #a9a9a9;
}
.aa-suggestions-category {
  text-transform: uppercase;
  border-bottom: 0 solid rgba(228, 228, 228, 0.6);
  border-top: 1px solid rgba(226, 226, 226, 1);
  padding: 6px 12px;
  color: #484848;
  text-align: left;
}
.aa-empty{
  color: #8c8b8b;
  font-size: 14px;
  line-height: 18px;
  padding: 6px 15px;
  font-style: italic;
  display: block;
}
.aa-suggestion > span:first-child em, .aa-suggestion > span:last-child em {
  font-weight: 700;
  font-style: normal;
  background-color: rgba(58, 150, 207, 0.1);
  padding: 2px 0 2px 2px;
}
.aa-suggestions .record-list{
  border-bottom: 1px dashed #e2e2e2;
  padding: 8px 0;  
}
.aa-suggestion:last-child .record-list{
  border: 0;
}
.record-list{
  display: block;
  width: 100%;
}
.record-list a{
  display: flex;
  width:100%;
  flex-flow: row;
  justify-content:left;
  align-items: flex-start; /* flex-start */
  align-content: space-around;
  text-decoration: none;
  color:#a8a29f;
}
.record-list .record-image{
  margin-right: 10px;
  max-width:40px;
  width:100%;
   img{
     width:40px;
     height:40px;
   }
}
.record-desc{
  width: 100%;

    .record-left{
      display: inline-block;
      width:75%;
    }
    .record-right{
      width: 25%;
      display: inline-flex;
      text-align: right;
      align-items: center;
      justify-content: flex-end;
      span.period{
        background-color: #f5f5f5; /* 84628d */
        color: #484848;
        border-radius: 4px;
        width:auto;
        display: inline;
        padding:2px 5px;
        height:22px;
        line-height: 18px;
      }
    }
}
.aa-dataset-program{
  .record-desc{
    display: flex;
    width: 100%;
  }
}

.record-list .record-desc span{
  display: block;
  text-align:left;
}
.record-list .record-desc span.title{
  font-weight: bold;
  color:#000000; /* 484848 */
  display: block;
  margin-bottom:5px;
  font-size: 16px;
  line-height: 16px;
}
.record-list .record-desc span.summary{
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  height: 18px;
  color:#484848;
  font-size: 14px;
  line-height: 18px;
}
.record-list .record-desc span.summary:after{
  content: "...";
}
.record-list .record-desc span.title em, .record-list .record-desc span.summary em{
  color:#83618D; /* #ed9124 */
  font-style: normal;
}
.algolia-branding{
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 5px 15px;
}
.algolia-branding img{
  width:50px;
  margin-left:5px;
}

.searchbox__reset {
  position: absolute;
  top: 15px;
  right: 40px;
  margin: 0;
  border: 0;
  background: none;
  cursor: pointer;
  padding: 0;
  font-size: inherit;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  fill: rgba(0, 0, 0, 0.5);
  &.hide{
    display:none;
  }
}

.searchbox__reset:focus, .searchbox__reset:hover {
  outline: 0;
  background-color: transparent;
  border-color: transparent;
}

.searchbox__reset svg {
  display: block;
  margin: 4px;
  width: 13px;
  height: 13px;
}

.aa-input-search:valid ~ .searchbox__reset {
  display: block;
  -webkit-animation-name: sbx-reset-in;
          animation-name: sbx-reset-in;
  -webkit-animation-duration: .15s;
          animation-duration: .15s;
}

@-webkit-keyframes sbx-reset-in {
  0% {
    -webkit-transform: translate3d(-20%, 0, 0);
            transform: translate3d(-20%, 0, 0);
    opacity: 0;
  }
  100% {
    -webkit-transform: none;
            transform: none;
    opacity: 1;
  }
}

@keyframes sbx-reset-in {
  0% {
    -webkit-transform: translate3d(-20%, 0, 0);
            transform: translate3d(-20%, 0, 0);
    opacity: 0;
  }
  100% {
    -webkit-transform: none;
            transform: none;
    opacity: 1;
  }
}

/* Algolia Custom Search */
.aolrc-search-bar{
  display: flex;
  flex-flow: row;
  width:100%;
  padding: 35px 25px 35px 25px;
  max-width: 900px;
  background:#ffffff;
  border-radius: 10px;

  .aa-input-container{
    /* max-width: 665px; */
    vertical-align: top;  
    padding-right: 25px;
    border-right: 1px solid #e2e2e2;
  }
  .aa-dataset-post{ 
    
  }

}
.aa-input-container{
  display: inline-block;
  position: relative;
  width: 100%; 

  input[type="search"]{
    color: #a8a29f;
    display: inline-block;
    background: #ffffff;
    height: 50px;
    font-size: 22px;
    font-weight: 500;
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    position: relative;
    padding: 0 0 0 4em;
    max-width: 650px;
    width:100%;
    outline: none;
    /*box-sizing: content-box;*/      
    transition: all .2s linear;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

  }

  .algolia-autocomplete{
    width:100%;
    color: #a8a29f;
    display: inline-block;
    background: #ffffff;
    font-size: 16px;
    font-weight: 500;
    border: 0;
    border-radius: 4px;
    position: relative;
    padding: 0 0 0 4em;
    border: 1px solid #e2e2e2;
    box-shadow: 4px 7px 38px 6px rgba(0, 0, 0, 0.1);

    .aa-input-search, input[type="search"]{
      color: #484848; /* a8a29f */
      display: inline-block;
      background: #ffffff;
      height: 50px;
      font-size: 20px;
      font-weight: 500;
      border: 0;/*1px solid #e2e2e2;*/
      border-radius: 4px;
      position: relative;
      padding: 0;
      max-width: 650px;
      width:100%;
      outline: none;
      /*box-sizing: content-box;*/      
      transition: all .2s linear;
      box-sizing: border-box;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
  
    }

  }
  
  &.inbanner, &.inheader{
    display: block;
    width: 100%;
    padding: 0;/* 10px 25px 35px */
    border-radius: 3px;
    max-width: 900px;
    margin: auto;
    
    .algolia-autocomplete{
      width:100%;
      color: #a8a29f;
      display: inline-block;
      background: #ffffff;
      font-size: 16px;
      font-weight: 500;
      border: 0;
      border-radius: 4px;
      position: relative;
      padding: 0 0 0 4em;
      border: 1px solid #e2e2e2;
      box-shadow: 4px 7px 38px 6px rgba(0, 0, 0, 0.1);

      .aa-input-search{
        color: #484848;
        display: inline-block;
        background: #ffffff;
        height: 50px;
        font-size: 20px;
        font-weight: 500;
        border: 0;/*1px solid #e2e2e2;*/
        border-radius: 4px;
        position: relative;
        padding: 0;
        max-width: 550px;
        width:100%;
        outline: none;
        /*box-sizing: content-box;*/      
        transition: all .2s linear;
        box-sizing: border-box;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &::placeholder{
          line-height:50px;
        }

        &.aa-hint {
          color: #e4e4e4;
        }
    
      }

    }

    .calendar-wrapper {
      display: inline-block;
      position: relative;
      height: 50px;
      line-height: 20px;
      padding: 0;
      border: 1px solid transparent;
      max-width: 157px;
      width: 100%;
      bottom: 0; 
      margin-left:20px;

      .button-default {
        background-color: #84628d;
        border-radius: 4px;
        color: #fff;
        letter-spacing: 2.29px;
        font-weight: 700;
        font-size: 14px;
        line-height: 50px;
        height: 50px;
        text-transform: uppercase;
        position: relative;
        text-align: center;
        width: 100%;
        cursor: pointer;
        display: inline-block;
        padding-left: 13px;
        padding-right: 13px;
        outline: none;
      }

      span {
        font-size: 11px;
        text-align: center;
        width: 100%;
        display: inline-block;
        line-height: 30px;
      }

    }
    
  }

  &.inheader {
    .algolia-autocomplete{
      .aa-input{
        margin-left: -1.5em;
      }
    }
  }

}




@media (max-width:767px){
  .aa-input-container {
    input[type=search]{
      padding: 0 0 0 3.5em;
      font-size: 16px;
    }
    .algolia-autocomplete{
      padding: 0 0 0 3.5em;
      font-size: 16px;
      .aa-input-search, input[type="search"]{
        max-width: 180px;
        font-size: 16px;
      }
    }

    .aolrc-search-bar{
      display: block;
      padding:10px;
      box-shadow: 4px 7px 38px 6px rgba(0, 0, 0, 0.1);

      .aa-input-container{
        padding:0;
        border-right:0;
        max-width: 100%; /*305*/
        font-size: 16px;
      }
    }
  }
  .aa-input-container.inbanner, .aa-input-container.inheader {
    .algolia-autocomplete{
      padding: 0 0 0 3.5em;
      .aa-input-search{
        max-width: 96%;
        font-size: $txt-size-xlg;
        line-height: $txt-size-xlg; 
          &::placeholder{
            font-size: $txt-size-xlg;
            line-height: 1.35rem; 
          }

        &input::placeholder,
        &input::-webkit-input-placeholder,
        &input::-moz-placeholder {
          line-height: normal !important;
        }

        &.aa-hint{
          margin-left:-4em;
        }
      }
      
    }   

    .calendar-wrapper{
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-top: 10px;
      height:auto;
    }
  }
  .record-list a{
    align-items: flex-start;
  }
  .aa-suggestions .record-list{
    border-bottom: 1px dashed #e2e2e2;
    padding-bottom: 8px;
  }
  .aa-suggestion:last-child .record-list{
    border: 0;
  }
  
  .aa-dataset-program .record-desc{
    display: block;
    width: 100%;   
      .record-left{
        display: inline-block;
        width:100%;
      }
      .record-right{
        display: inline-block;
        width: 100%;
        text-align: left;          
      }
  }
}

@media (min-width:768px) and (max-width:1024px){
  .aa-input-container.inbanner, .aa-input-container.inheader {
    .algolia-autocomplete{
      .aa-input-search.aa-hint{
        max-width: 360px;
      }
    }
  }
  .record-list a{
    align-items: flex-start;
  }
  .aa-suggestion .record-list .record-desc{
    .record-left{
      width:70%;
    }
    .record-right{
      width:30%;
    }
  }  
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
  .aa-input-container.inbanner, .aa-input-container.inheader {
    .algolia-autocomplete{
      .aa-input-search.aa-hint{
        max-width: 360px;
      }
      .aa-input-search{
        font-size: 20px;
        line-height: 2;
          &input::placeholder,
          &input::-webkit-input-placeholder,
          &input::-moz-placeholder {
            line-height: normal !important;
          }
      }
    }
  }
  .record-list a{
    align-items: flex-start;
  }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
  .search-header .search-wrapper{
    padding-bottom: 60px;
  }
}

