#fliptoRiver {

  &.ft-photo-river-parent {
    position: relative;
    margin-top: 20px;
  }

  .ft-river-heading,
  .ft-river-info,
  .ft-river-divider-v {
    display: none;
  }

  .ft-photo-river-app .ft-river-photo {
    box-shadow: none;
  }

  // Arrows
  .ft-river-nav {
    position: absolute;
    top: -40px;
    left: 0;
    right: 0;
  }

  .ft-photo-river-app .ft-river-nav .ft-river-btn-nav {
    background-color: #84628d;
    opacity: 1;
    border-radius: 100px;
    height: 50px;
    width: 50px;
    margin-right: 5px;
    margin-left: 5px;

    .ft-river-icon-arrow {
      background-color: transparent;

      &:before {
        color: #666;
        width: 12px;
        height: 12px;
        border-width: 1px 1px 0 0;
        border-color: white;
        border-style: solid;
        position: absolute;
        border-radius: 0;
        background-color: transparent;
      }

      &:after {
        display: none;
      }

      &.ft-river-icon-arrow-left:before {
        transform: rotate(-135deg);
        left: 17px;
        top: -2px;;
      }

      &.ft-river-icon-arrow-right:before {
        transform: rotate(45deg);
        top: 1px;
        right: 13px;
      }

    }


  }

  // Blocks
  .ft-river-photo-details {
    padding: 15px;
  }

  .ft-river-profile-pic {
    margin-bottom: 0;
    box-shadow: none;
    border: 2px solid rgba(255,255,255, 0.35);
    margin-bottom: 15px;
  }

  .ft-river-profile-name {
    text-transform: none;
    font-family: $font-primary-title;
    font-size: 20px;
    line-height: 24px;
  }

  .ft-river-guest-caption,
  .ft-river-guest-quote {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 14px;
    font-family: $font-primary;
  }

  .ft-river-gallery-info {
    font-family: $font-primary;
  }

}