/*************************************************************
                        CUSTOM CODE
                  Pillar Page - Post Template
--------------------------------------------------------------
NOTES FOR DEVELOPERS:
- Use tabs of two spaces for best reading experience
--------------------------------------------------------------
  INDEX
  1.    Global Styles
  2.    Content area
  3.    Related Cluster Posts
--------------------------------------------------------------
  CODE STARTS HERE
*-------------------------------------------------------------
  1.    Global Styles
-------------------------------------------------------------*/ 
// Progress Bar
body.post-template-pillar-single-post {
  #reading-progress-bar {
    width: 100%;
    display: block;
    position: fixed;
    z-index: 9;

    @media screen and (max-width: 1023px) {
      top: 48px;
      height: 3px;
    }

    @media screen and (min-width: 1024px) {
      top: 70px;
      height: 4px;
    }
  }

  // Adding top offset on the progress bar if the admin is logged in
  &.admin-bar {
    #reading-progress-bar {
      @media screen and (max-width: 1023px) {
        top: 80px;
      }

      @media screen and (min-width: 1024px) {
        top: 102px;
      }
    }
  }

  /*------------------------------------------------------------
    2.    Content area
  -------------------------------------------------------------*/ 
  #primary {

    .inner-wrapper {
      position: relative;
    }

    //max-width: 1170px;
    position: relative;

    h1,h2,h3 {
      font-weight: 400;
      letter-spacing: -.35px;
    }

    h2{
      margin-top:10px;
    }

    .pillar-content-area {

      // Remove the default padding on the content wrapping widget
      .elementor-column-gap-default>.elementor-row>.elementor-column>.elementor-element-populated {
        padding: 0;
      }

      .elementor-container {
        margin-bottom: 30px;

        // Make the elements use full width
        padding-left: 0;
        padding-right: 0;

        // Make text and title elements have padding
        .elementor-widget-heading,
        .elementor-widget-text-editor {
          padding-left: 25px;
          padding-right: 25px;
        }

        // Add top padding to the top elements of each elementor container
        .elementor-widget-heading:first-child,
        .elementor-widget-text-editor:first-child {
          padding-top: 10px;
        }

        // Add bottom padding to the bottom elements of each elementor container
        .elementor-widget-heading:last-child,
        .elementor-widget-text-editor:last-child {
          padding-bottom: 20px;
        }

        // Make the image use full width if in a 100% width column
        .elementor-col-100 .elementor-widget-image img {
          width: 100%;
        }

        // Create the default shadow with border look
        box-shadow: 0 2px 35px 0 rgba(0,0,0,0.08);
        border-radius: 4px;
        overflow: hidden;

        // Fix the first heading top spacing
        .elementor-text-editor h2:first-child{
          margin-top: 0;
        }

        // Decrease the elementor section bottom margin
        h2 {
          margin-bottom: 0;
        }

        h3{
          margin-top:0;
        }
        
        h4{
          font-family: $font-primary-title;
          font-weight: 400;          
        }

      }

      //Fixing the top section alignments
      .elementor-top-section{

        p img{
          margin-top:20px;
          margin-bottom: 20px;
        }

      }      

      //Fixing the inner section padding issue for two column container
      .elementor-inner-section{

        .elementor-column-gap-default{
          margin-top:-20px;
        }

        .elementor-col-50{
            .elementor-widget-text-editor{
              margin-bottom:0;
            }          
        }

        + div.elementor-widget-image{
          margin-top:20px;
        }

      }
  

      .pull20{

        .elementor-container{
          margin-top:-20px;
        }

      }
      
    }

    @media (max-width: 767px) {
      padding-top: 0; // Remove the top spacing
    }

    @media (min-width: 768px) {
      width: 750px;
    }

    @media (min-width: 992px) {
      width: 970px;
    }

    @media (min-width: 1200px) {
      width: 90%;
    }

    #left-floater {
      width: 20%;
      margin-top: 20px;
      position: absolute;
    }

    #left-floater.fixed{
      position:fixed;
      top: 80px;
      width: 14%;
    }

    #left-floater.absolute {
      top: auto;
      bottom: 0;
    }

    @media screen and (max-width: 768px) {
      #left-floater {
        display: none;
      }
    }

    @media screen and (min-width: 769px) {
      .reading-time {
        width: 100%;
        display: block;
        color: #959595;
        line-height: 22px;
        font-size: 17px;
        max-width: 200px;
        font-weight: 500;
      }

      .pillar-content-area {
        display: block;
        width: 80%;
        margin-left: auto;
        margin-right: 0;
      }

    }

    // Left cta button
    .left-cta-button-link {
      background-color: $brand-primary;
      color: white;
      display: inline-block;
      border-radius: 4px;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: bold;
      padding: 10px 25px;
      margin-top: 20px;
      line-height: auto;
      text-align: center;
      width: 90%;
      letter-spacing: 0.04px;
    }
    
  }

  // Offset the left floater if logged in
  &.admin-bar {
    #primary #left-floater.fixed{
      top: 112px;
    }
  }


  #side-nav {
    padding-right: 20px;
    margin-top: 20px;
    display: block;
    color: #959595;
    line-height: 22px;
    font-size: 17px;

    .navigation {
      font-weight: 500;
      position: relative;
      display: inline-block;
      width: 100%;
      padding-bottom: 15px;

      &:after {
        display: block;
        width: 100%;
        background: #D8D8D8;
        height: 1px;
        position: absolute;
        content: "";
        bottom: 0;
      }
    }
    

    ul {
      margin-left: 0;
    }

    li {
      list-style: none;
    }

    a {
      color: #464646;
      display: inline-block;
      width: 100%;
      position: relative;
      padding-top: 8px;
      padding-bottom: 8px;
      font-size: 16px;
      line-height: 23px;

      &.active {
        font-weight: 500;
      }

      &:before{
        display: block;
        width: 100%;
        background: #D8D8D8;
        height: 1px;
        position: absolute;
        content: "";
        bottom: 0;
      }
  
    }

    
  }

  /*------------------------------------------------------------
    3.    Related cluster posts
  -------------------------------------------------------------*/ 
  .single-pillar-page-related-cluster-posts {
    h2 {
      font-family: $font-primary-title;
      letter-spacing: -1.2px;
      color: #3c3247;
      font-size: 2em;
    }
    .events-recently-viewed-wrapper .events-recently-viewed-slider .slick-track .slick-slide .slide-inner .content {
      padding-bottom: 15px;
    }

    .post-date {
      color: #3C3247;
      font-size: 14px;
      line-break: 26px;
      font-weight: 500;
      margin-bottom: 5px;
    }

    .title {
      color: #544741;
      font-weight: bold;
      line-height: 20px;
      font-size: 17px;
      padding-bottom: 0;
    }
  }

}