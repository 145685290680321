$hc-offcanvas-nav-trigger-width:              30px !default;
$hc-offcanvas-nav-trigger-height:             24px !default;
$hc-offcanvas-nav-trigger-line-width:         4px !default;
$hc-offcanvas-nav-trigger-color:              #3C3247 !default;
$hc-offcanvas-nav-trigger-transform-speed:    .2s !default;
$hc-offcanvas-nav-background-color:           #3C3247 !default;
$hc-offcanvas-nav-text-color:                 #fff !default;

.hc-nav-trigger {
  @include hc-hamburger;
}

.hc-offcanvas-nav {
  text-transform: uppercase;

  /*&,
  .nav-wrapper {
    &::after {
      background: rgba(0, 0, 0, .3);
    }
  }*/

  .nav-container,
  .nav-wrapper,
  ul {
    background: $hc-offcanvas-nav-background-color;
  }

  h2 {
    font-size: 9px;
    font-weight: 500;
    letter-spacing: 0.45px;
    text-align: left;
    padding: 20px 25px;
    //color: darken($hc-offcanvas-nav-background-color, 20%);
    color: $hc-offcanvas-nav-text-color;
    font-family: $font-primary;
  }

  a,
  .nav-item {
    padding: 20px 25px;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.31px;
    color: $hc-offcanvas-nav-text-color;
    z-index: 1;
    background: rgba(0, 0, 0, 0);
    //border-bottom: 1px solid darken($hc-offcanvas-nav-background-color, 6%);

    &:after{
      content: "";
      width: 100%;
      height: 1px;
      background-color: #D8D8D8;
      display: block;
      bottom: -20px;
      position: relative;
      opacity: 0.2;
    }
  }

  /*&:not(.touch-device) {
    a {
      &:hover {
        background: darken($hc-offcanvas-nav-background-color, 2%);
      }
    }
  }*/

  ul {
    &:first-of-type:not(:first-child) {
      & > li {
        &:first-child:not(.nav-back):not(.nav-close) {
          & > a {
            border-top: 1px solid darken($hc-offcanvas-nav-background-color, 6%);
            margin-top: -1px;
          }
        }
      }
    }
  }

  li {
    text-align: left;

    &.nav-close,
    &.nav-back {
      /*a {
        background: darken($hc-offcanvas-nav-background-color, 6%);
        border-top: 1px solid darken($hc-offcanvas-nav-background-color, 8%);
        border-bottom: 1px solid darken($hc-offcanvas-nav-background-color, 8%);

        &:hover {
          background: darken($hc-offcanvas-nav-background-color, 6.5%);
        }
      }*/

      &:not(:first-child) {
        a {
          margin-top: -1px;
        }
      }
    }

    /*&.nav-parent {
      .nav-item {
        padding-right: 58px;
      }
    }*/

    &.nav-close span,
    &.nav-parent span.nav-next,
    &.nav-back span {
      width: 45px;
      position: absolute;
      top: 0;
      right: 8px;
      bottom: 0;
      text-align: center;
      cursor: pointer;
      transition: background .2s ease;
      transform: rotate(180deg);
      opacity: .3;
    }

    &.nav-close {
      display: none;
      /*
      span {
        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 6px;
          height: 6px;
          margin-top: -3px;
          border-top: 2px solid #fff;
          border-left: 2px solid #fff;
        }

        &::before {
          margin-left: -9px;
          transform: rotate(135deg);
        }

        &::after {
          transform: rotate(-45deg);
        }
      }*/
    }
  }

  /*a[href]:not([href="#"]) > span.nav-next {
    border-left: 1px solid darken($hc-offcanvas-nav-background-color, 6%);
  }*/

  span.nav-next,
  li.nav-back span,
  span.menu-level-divider {
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 8px;
      height: 8px;
      margin-left: -2px;
      box-sizing: border-box;
      border-top: 1px solid #fff;
      border-left: 1px solid #fff;
      transform-origin: center;
    }
  }

  span.nav-next {
    &::before {
      transform: translate(-50%, -50%) rotate(135deg);
    }
  }

  li.nav-back {
    span {
      &::before {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }

  span.menu-level-divider {
    display: inline-block;
    position: relative;
    width: 30px;
    height: 6px;

    &::before {
      transform: translate(-50%, -50%) rotate(135deg);
      width: 6px;
      height: 6px;
      opacity: .35;
    }
  }

  /*&.nav-position-left {
    &.nav-open {
      .nav-wrapper {
        box-shadow: 1px 0 2px rgba(0, 0, 0, .2);
      }
    }
  }*/

  &.nav-position-right {

    /*&.nav-open {
      .nav-wrapper {
        box-shadow: -1px 0 2px rgba(0, 0, 0, .2);
      }
    }*/

    span.nav-next {
      &::before {
        margin-left: 0;
        margin-right: -2px;
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }

    li.nav-back {
      span {
        &::before {
          margin-left: 0;
          margin-right: -2px;
          transform: translate(-50%, -50%) rotate(135deg);
        }
      }
    }
  }

  /*&.nav-position-top {

    &.nav-open {
      .nav-wrapper {
        box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
      }
    }

    span.nav-next {
      &::before {
        margin-left: 0;
        margin-right: -2px;
        transform: translate(-50%, -50%) rotate(-135deg);
      }
    }

    li.nav-back {
      span {
        &::before {
          margin-left: 0;
          margin-right: -2px;
          transform: translate(-50%, -50%) rotate(45deg);
        }
      }
    }
  }

  &.nav-position-bottom {

    &.nav-open {
      .nav-wrapper {
        box-shadow: 0 -1px 2px rgba(0, 0, 0, .2);
      }
    }

    span.nav-next {
      &::before {
        margin-left: 0;
        margin-right: -2px;
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }

    li.nav-back {
      span {
        &::before {
          margin-left: 0;
          margin-right: -2px;
          transform: translate(-50%, -50%) rotate(-135deg);
        }
      }
    }
  }
  */

  &.nav-levels-expand,
  &.nav-levels-none {

    .nav-container {

      ul {

        .nav-wrapper {
          box-shadow: none;
          background: transparent;
        }

        h2 {
          display: none;
        }

        ul {

          .nav-item {
            font-size: 14px;
          }
        }
      }

      li {
        transition: background .3s ease;

        &.level-open {
          background: darken($hc-offcanvas-nav-background-color, 4%);

          a {
            border-bottom: 1px solid darken($hc-offcanvas-nav-background-color, 8%);

            /*&:hover {
              background: darken($hc-offcanvas-nav-background-color, 3%);
            }*/
          }

          & > .nav-item {

            .nav-next {
              &::before {
                margin-top: 2px;
                transform: translate(-50%, -50%) rotate(45deg);
              }
            }
          }
        }
      }

      span.nav-next {
        &::before {
          margin-top: -2px;
          transform: translate(-50%, -50%) rotate(-135deg);
        }
      }
    }
  }
}

// Fix the admin bar overlap when active
body.admin-bar.hc-nav-open {
  top: 0;

  @media screen and (max-width: 782px) {
    .site-header {
      top: 45px;
    }
  }

  @media screen and (min-width: 783px) {
    .site-header {
      top: 32px;
    }
  }

} 
