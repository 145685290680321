.repeatable_fields_shortcode {
  #mySelect {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 40px;
    padding-top: 0; // Fix the issue with the height on firefox
    padding-bottom: 0; // Fix the issue with the height on firefox
  }

  #mySubmit, .register-button {
    font-size: 16px;
    font-weight: bold;
    line-height: 23px;
    letter-spacing: 2px;
    background-color: #3c3247;
    padding: 14px 20px 14px 20px;
    width: 100%;
    text-transform: uppercase;

    &.isDisabled {
      opacity: .5;
    }
  }
  

  // Mobile version
  &.mobile {
    padding-left: 0;
    padding-right: 10px;

    #mySelect {
      height: 46px;
      margin-top: 5px;
      margin-bottom: 0px;
      padding:0 10px;
    }

    #mySelect.drpDwnHighlight{
      box-shadow: 0px 0px 12px #f4c208;
      border: 2px solid #f4c208;
    }
  }
}

#singleEventMobileRegister.drpDwnHighlight{    
    border-color:#f4c208;
    /*transition: background-color 0.5s ease-out;*/
}

.repeatable_fields_shortcode.desktop{
  #mySelect {
    margin-top: 5px;
  }
}

// Since its outside the shortcode, I need to target it manually
#singleEventMobileRegister.isDisabled {
  opacity: .5;
  pointer-events: none;
}

/*=========================
  Highlight the sticky button, Dropdown, Button with Review 
  Mobile Only
===========================*/ 

@media (max-width: 767px){
  .sticky-footer-highlight{
    .acuity-embed-button{
      border: 1px solid #f4c208 !important;
      box-shadow: 0px 0px 4px #f4c208 !important;
    }
  }
  .tabbottomsticky{
    .elementor-tabs-wrapper{
      display:block !important;
      margin-top: -35px;
    }    
    .elementor-widget-tabs.elementor-tabs-view-horizontal .elementor-tab-desktop-title{
      display:table-cell !important;
    }

    .elementor-widget-tabs.elementor-tabs-view-horizontal .elementor-tab-desktop-title.elementor-active,
    .elementor-widget-tabs.elementor-tabs-view-horizontal .elementor-tab-desktop-title{
      padding: 12px 12px !important;
      opacity: 1;    
    }
    .elementor-widget-tabs.elementor-tabs-view-horizontal .elementor-tab-desktop-title{
      background: #3c3247;
      font-size: $txt-size;
    }
    .elementor-widget-tabs.elementor-tabs-view-horizontal .elementor-tab-desktop-title.elementor-active{
      background-color: #84628d;
      font-size: $txt-size;
    }
    .elementor-widget-tabs.elementor-tabs-view-horizontal .elementor-tab-content .mySelectDropdown{
      height: 46px;
      margin-top: 0;
      margin-bottom: 0;
      padding: 0 10px;
      max-width: 240px;
      width:65%;
      margin-right:5px;
    }
    .repeatable_fields_shortcode .register-button {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 1.45px;
      border-width: 2px 2px 2px 2px;
      padding: 12px 0px;
      width:32%;
      max-width:115px;
    }

    .elementor-tabs .elementor-tabs-content-wrapper .elementor-tab-mobile-title, .elementor-tab-mobile-title.elementor-active{
        display: none !important;
        border:0 !important;
    }
    /* .elementor-tabs-view-horizontal .elementor-tabs{
        display:none !important;
    } */
    .elementor-tabs .elementor-tab-mobile-title{
        display: none !important;
    }   


  }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
  .repeatable_fields_shortcode #mySubmitOne, .repeatable_fields_shortcode #mySubmitTwo{
    width: 25%;
  }

  .repeatable_fields_shortcode .register-button{
    width:auto;
  }
  
  .tabbottomsticky{
    .elementor-widget-tabs.elementor-tabs-view-horizontal .elementor-tab-content .mySelectDropdown{
      width: 48%;
      margin-right: 1%;
    }
    .elementor-widget-tabs.elementor-tabs-view-horizontal .elementor-tab-desktop-title.elementor-active,
    .elementor-widget-tabs.elementor-tabs-view-horizontal .elementor-tab-desktop-title{
      padding: 10px 10px !important;
      background-color: #84628d;
    }
    .elementor-widget-tabs.elementor-tabs-view-horizontal .elementor-tab-content{
      padding: 15px 0 0 0;
      border:0;
    }
    .elementor-tabs-wrapper{
      margin-top: -45px;
    }
    .elementor-widget-tabs.elementor-tabs-view-horizontal .elementor-tab-desktop-title{
      background: #3c3247;
    }
    
  }

}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
  .repeatable_fields_shortcode #mySubmitOne, .repeatable_fields_shortcode #mySubmitTwo{
    width: 25%;
  }
  .tabbottomsticky{
    .elementor-widget-tabs.elementor-tabs-view-horizontal .elementor-tab-content .mySelectDropdown{
      width: 48%;
      margin-right: 1%;
    }
    .elementor-widget-tabs.elementor-tabs-view-horizontal .elementor-tab-desktop-title.elementor-active, 
    .elementor-widget-tabs.elementor-tabs-view-horizontal .elementor-tab-desktop-title{
      padding: 10px 10px !important;
      background-color: #84628d;
    }
    .elementor-widget-tabs.elementor-tabs-view-horizontal .elementor-tab-content{
      padding: 15px 0 0 0;
      border:0;
    }
    .elementor-tabs-wrapper{
      margin-top: -48px;
    }
    .elementor-widget-tabs.elementor-tabs-view-horizontal .elementor-tab-desktop-title{
      background: #3c3247;
    }

  }
}

@media only screen and (min-device-width : 1024px) and (max-device-width : 1366px) and (orientation : portrait) {
}