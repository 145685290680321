.event_taxonomy_shortcode {
  padding-top: 10px;

  .title {
    color: #544741;
    font-size: $txt-size;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 23px;
    display: block;
    margin-bottom: 15px;
  }

  .event_taxonomy_terms {
    .event_taxonomy_term {
      display: flex;
      min-height: 40px;
      margin-bottom: 10px;

      .image-wrapper {
        background: #84628D;
        padding: 5px;
        width: 40px;
        height: 40px;
        min-width: 40px;
        border: 100px;
        border-radius: 40px;
        position: relative;
        margin-right: 15px;
        margin-bottom: -10px;


        img,
        svg {
          max-width: 20px;
          max-height: 20px;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
        }

        svg {

          #Relationships{
            fill:#fff;
          }

          #icon-certificate-new{
              rect#svg_2{
                display:none;
              }
          }

          // Paint most icons
          path {
            stroke: #fff;
          }

          .st0 {
            fill: #fff;
          }

          // Paint the circles
          circle {
            stroke-width: 1.5;
            stroke: white;
          }

          // For the spiritual icon
          rect {
            fill: #fff;
          }
        }


      } // End of the image

      .name {
        position: relative;
        line-height: 16px;
        font-size: 14px;
        display: flex;
        justify-content: center;
        flex-direction: column;

        @media screen and (min-width: 768px) {
          line-height: 22px;
          font-size: $txt-size;
        }
      }

    }
  }
}