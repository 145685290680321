/*
  /*------------------------------------------------------------
  1.2. Astra Mobile Footer
  -------------------------------------------------------------*/
#ast-seach-full-screen-form{
  display:none;
}

@media screen and (max-width: $mobile-max) {
    #leadinModal-403616{
        display: none;
    }
  }

  .footer{  
    margin:0 auto;    

    ul.subscribe{
      width:100%;
      margin:0 auto;
      width: 100%;
      max-width: 1275px;
      background-color: #F9F6F9;
      border-radius: 4px;
      padding: 20px 35px 20px 35px;
      box-shadow: 4px 7px 38px 6px rgba(0, 0, 0, 0.1);
      border:1px solid #e5d4e5;
      position: relative;
      z-index: 0;

      h5, h6{
        color:#634C65;
        font-family: $font-primary-title;
        font-size: 30px;
        font-weight: 400;
      }
      p{
        color:#676767;
        line-height: 23px;
        margin-bottom: 0;
      }

      li{
        display: inline-block;
        list-style: none;
        vertical-align: top;
        padding:0 10px;        

        &.intro{
          width:29%;
          p{
            font-size: 15px;
            margin-top:5px;
          }
        }
        &.form{
          width:40%;
          padding-top:5px;
          .hbspt-form{
            margin:15px 0 0 0;
            width:95%;

            .hs_email.hs-email{
              margin:0;              
            
              .input{
                input.hs-input{
                  padding: 0 15px;
                  min-height: 27px;
                  height: 40px;
                  background: #ffffff !important;
                  border:1px solid #cbd6e2;
                }
              }
            }

            .actions{
              padding: 0!important;
              .hs-button{
                background: $brand-primary;
                border-color: $brand-primary;
                color: #ffffff;
                font-size: 13px;
                line-height: 13px;
                font-family: $font-primary;
                padding:12px 24px;
                border-radius: 4px;
                font-weight: 700;
                text-align: center;
                letter-spacing: .5px;
                text-transform: uppercase;
                transition: none;
              }
            }
          }
          
        }
        &.social{
          width:30%;
          padding-top: 8px;
            h6{
              font-family: $font-primary;
              color: #242424;
              font-size: 14px;
              font-weight: bold;
              text-transform: uppercase;
              letter-spacing: 0.3px;
              display: block;
              margin-bottom: 20px;
            }
            ul{
              margin:10px 0 0 0;
              padding:0;
              line-height: 25px;
              max-height: 25px;
              overflow:hidden;

              li{
                background-image:url("/wp-content/uploads/2022/11/ficons1.svg");                  
                background-repeat: no-repeat;
                text-indent: -999999px;
                width:25px;
                margin:0 15px;

                &:first-child{
                  margin-left:0;
                }

                &.in{                  
                  background-position: -1px 0;                  
                }
                &.fb{
                  background-position: -1px -30px;
                }
                &.yt{
                  background-position: -1px -56px;
                }
                &.pi{
                  background-position: -46px 0;
                }
                &.tw{
                  background-position: -1px -79px;
                } 
                &.ma{
                  background-position: -1px -105px;
                }

                a{
                  display: block;
                  width:25px;
                  height:25px;
                  margin-left: -10px;
                }

              }
            }
        }
      }

    }

    .fcontainer{
      width:100%;
      margin:-100px auto 0 auto;
      padding:110px 0 0 0;
      width: 100%; 
      background: #3B3247 url("/wp-content/uploads/2021/01/footer-bg-11.jpg") no-repeat 100% 0;

      .fcontent{
        display: block;
        padding:0 0 10px 0;
        background: url("/wp-content/uploads/2021/01/footer-bg-21.jpg") no-repeat 0 100%;        
        
        ul{
          width:100%;
          margin:0 auto;
          width: 100%;
          max-width: 1275px;        

            li{
              list-style:none;
              padding:20px;
              display:inline-block;
              width: calc(20% - 5px);
              vertical-align: top;

                h5, h6{
                  margin-bottom: 14px;
                  color:#ffffff;
                  font-family: $font-primary;
                  font-size: $txt-size;
                  font-weight: bold;
                  text-transform: uppercase;
                  letter-spacing: 0.88px;
                }

                ul{
                  margin:0;
                  padding:0;

                  li{
                    margin:0;
                    padding:0;
                    display: block;
                    width: 100%;

                    a{
                      display: block;
                      color:#E0C2D4;
                      font-size: $txt-size;
                      line-height: 32px;
                      text-align: left;
                      text-decoration: none;       
                      
                      &:hover{
                        color:#ffffff;
                      }                   

                    }  
                    
                    &:last-child{
                        a{
                          text-align: left;
                        }
                    }

                  }
                }


                &:last-child{
                  h5, h6{
                    text-align:center;
                  }
                  a{
                    display: block;
                    color:#E0C2D4;
                    font-size: $txt-size;
                    line-height: 32px;
                    text-align: center;
                    text-decoration: none;

                    img{
                      margin: 0 auto 10px auto;
                    }
                    &:hover{
                      color:#ffffff;
                    }

                  }
                }
                
            }

        }
        ul.address{
          display: block;
          width:415px;
          margin:15px auto 0 auto;
          border:0 solid #29272C;
          padding: 20px;

          li{
            display: inline-block;
            margin-bottom: 20px;
            width:100%;
            padding:0;
            text-align: center;

            &:last-child{
              margin-bottom: 0;
            }

            span{
              display: block;
              width: 179px;
              height: 87px;
              margin: auto auto 5px auto;
              background-image: url(/wp-content/uploads/2022/11/ficons1.svg);     
              background-repeat: no-repeat;
              background-position: 0 -133px;
              text-indent: -999999px;
            }
            p{
              text-align: center;
              color: #E0C2D4;
              font-size: 13px;
              margin-bottom: 5px;

              a{
                display: inline-block;
                color: #E0C2D4;
                font-size: 13px;
                text-decoration: none;
                  &:hover{
                    color:#ffffff;
                  }
              }

            }
          }

        }

        p.donate{
          display: block;
          padding-top:25px;
          padding-bottom:35px;
          margin:0;
          text-align: center;
          color: #e0c2d4;
          font-family: $font-primary-title;
          font-size: 36px;
          span{
            opacity: 0.7;
          }
          a{
            color: #e0c2d4;
            opacity: 0.7;
            font-size: 36px;
            text-decoration: none;
            
            &:hover{
              opacity:1;
              color:#ffffff;
            }
          } 

        }
      }

    }
  }

  @media(max-width:767px){
    .single-wcp_event{
      #hubspot-messages-iframe-container{
        bottom:75px !important;
      }
    }
    .home.page{
      #hubspot-messages-iframe-container{
        bottom:0 !important;
      }
    }

    .footer{
      .fcontainer{
        background-image: none;
        .fcontent{
          background-image: none;
          ul{
            li{
              display: inline-block;
              width:49%;
              padding:20px 30px;
            }
          }
          p.donate{
            padding: 25px 30px;
            font-size: 32px;
            line-height: 40px;
          }
        }
      }
      ul.subscribe{
        width:90%;
        padding:20px 30px 25px 30px;
        li{
          &.intro{
            width:100%;
          }
          &.form{
            width:100%;
            margin-bottom: 25px;
          }
          &.social{
            width:100%;
          }
        }
      }
    }

  }

  @media(max-width:767px){
    .footer{

      ul.subscribe{
        padding:15px 20px 25px 20px;

        h6{
          font-size: $h3-msize;
          line-height: 38px;
          margin-bottom: 10px;
        }
      }
      .fcontainer{
        .fcontent{
          ul{
            &.address{
              width:90%;
              padding: 20px 10px;
            }
            li{
              padding:20px;
              &:last-child a{
                font-size: $txt-size;
                line-height: $text-size-lheight;
              }
              &:last-child{
                width:100%;
              }
              ul{
                &.menu{
                  background:transparent;
                  border:0;
                  li{
                    margin-bottom: 12px;
                    a{
                      padding:0;
                      line-height: $text-size-lheight;
                    }
                  }
                }
              }

            }
          }
        } 
      } 

    } 

  }

  @media (min-width:768px) and (max-width:1024px){
    .footer {
      
      ul.subscribe{
        padding-left:15px;
        padding-right:15px;
        padding-bottom:5px;
        h6{
          line-height: 36px;
          margin-bottom: 10px;
        }
        .field{
          margin-bottom:0;
        }
        li.form{
          width:40%;
        }
        li.social{
          width:29%;
          ul{
            overflow: visible;
            li{
              margin:0 6px 15px 6px;
              &:last-child{
                margin-right: 0;
              }
            }
          }
        }
      }       
    } 
  }