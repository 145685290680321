// New Search Result styles

.search-header{
    background:url("https://artoflivingretreatcenter.org/wp-content/uploads/2014/12/MindMeditation1.jpg") no-repeat 50% 50%;
    padding:70px 0 0 0;
    display: block;
    clear: both;

    .elementor-background-overlay{
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        position: absolute;
        background-color: transparent;
        /*background-image: radial-gradient(at center center, #000000 0%, #7a7a7a 100%);*/
        opacity: 0.29;
        transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
        z-index: 1;
        max-height: 350px;
    }
    .search-wrapper{
        display: block;
        width:100%;
        background: url("https://artoflivingretreatcenter.org/wp-content/uploads/2019/01/hero-frame.png") repeat-x bottom center;
        background-size: contain;
        padding-bottom: 184px;
        z-index: 2;
        position: relative;
    }

    .sh-container{
        display: block;
        width:100%;
        padding:0;
        background: #ffffff;
        border-radius: 3px;
        max-width: 900px;
        margin:auto;
    }
    
    form{
        label{
            display: block;
            width:100%;
            text-transform: uppercase;
            font-size: 13px;
            letter-spacing: 1px;

                span{
                    font-weight: bold;
                    color:#4d4d4d;
                }
        }

        .field{
            display: inline-block;
            padding: 0 5px;
            margin: 0;
            position: relative;
            cursor: pointer!important;
            font-size: 16px;

            &.month-select{
                width: calc(26% - 5px);
                margin-left:0;
                padding-left:0;

                .mselector{
                    margin-left: 0;
                    padding-left:0; 
                    width:100%; 
                    position: relative;
                    display: inline-block;                   
                    
                    &::before{
                        right: 20px;
                        top: calc(50% - 2px);
                        content: "";
                        display: block;
                        position: absolute;
                        cursor: pointer!important;
                        width: 0;
                        height: 0;
                        border-left: 5px solid transparent;
                        border-right: 5px solid transparent;
                        border-top: 6px solid #84628d;
                        pointer-events: none;
                    }

                    
                }
            }

            &.keyword-input{
                max-width: 385px;
                width:100%;
            }

            &.submit-wrapper{
                width: 100%;
                height:50px;
                line-height: 50px;
                max-width: 80px;
            }
            
        }
        

        ._text-field {
            color: #a8a29f;
            display: inline-block;
            background: #fbfbfb;
            height: 24px;
            font-size: 16px;
            font-weight: 500;
            border: 1px solid #e2e2e2;
            border-radius: 4px;
            position: relative;
            padding: .75em;
            max-width: 350px;
            width:100%;
        }

        select {
            height: 50px;
            border: 1px solid #e2e2e2;
            font-size: 16px;
            font-weight: 500;
            color: #a8a29f;
            padding: .75em;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border-radius: 4px;
            background:#fbfbfb;
            cursor: pointer!important;
            outline:0;
          }

        .submit-wrapper{
            display: inline-block;
            padding: 0 5px 0 5px;
            position: relative;
            bottom: 0;
            ._go{
                display: inline-block;
                background: #84628d;
                cursor: pointer!important;
                height: 50px;
                line-height: 50px;
                padding: 0;
                width: 65px;
                font-size: 14px;
                border: 1px solid transparent;
                text-transform: uppercase;
                border-radius: 0;
                letter-spacing: 2.29px;
                font-weight: 700;
                border-radius: 4px;
                outline:none;
                color: #ffffff;
            }
        }
        .calendar-wrapper{
            display: inline-block;
            position: relative;
            height: 50px;
            line-height: 50px;
            padding: 0;
            border: 1px solid transparent;
            max-width: 157px;
            width:100%;

            .button-default {
                background-color: #84628d;
                border-radius: 4px;
                color: #fff;
                letter-spacing: 2.29px;
                font-weight: 700;
                font-size: 14px;
                line-height: 50px;
                height: 50px;
                text-transform: uppercase;
                position: relative;
                text-align:center;
                width: 100%;
                cursor: pointer;
                display: inline-block;
                padding-left: 13px;
                padding-right: 13px;
                outline: none;
                
            }
        }

    }
    
}

#primary.search{
    width:100%;
    padding:0;
    border:0;

    .ast-archive-description{
        border:0;
    }
        
    .ast-archive-description h1.ast-archive-title{
        font-size: 2.05rem;
        color:#242424;
        font-weight: 400;
        font-family: $font-primary-title;

        span{
            color:#84628d;
            font-style: italic;            
            font-weight: bold;
        }
    }

    .wcp-events-template.faculty{
        .event-thumb{
            .inner-wrapper{
                &.white{
                    box-shadow:0 2px 20px 0 rgba(0,0,0,.1);
                }
            }
        }
    }
   

    .wcp-events-template{

        .event-thumb{
            .inner-wrapper{
                min-height:475px;
                background-color:#fffbf3;
                box-shadow: 0 0 0 4px #f9ab1d;
                border-radius: 5px;

                &.white{
                    background-color: #fff;
                    box-shadow: 0 0 0 4px #ecebea;

                    .content .post-date{
                        background-color: #ebebeb;
                        color:#1b1b1b;
                    }                    
                }

                

                .image{
                    border-radius: 5px 5px 0 0;
                    .featured-section{                    
                        span{
                            display:inline-block;
                        
                            &.retreat{
                                background-color: #6b34f8;
                            }            
                            &.featured{
                                background-color: #f9ab1d;
                            }                            
                            &.ayurveda{
                                background-color: #7c9d4a;
                            }
                        }

                    }
                }

                .content{
                    padding:0 0 5px 0;

                    .discount-txt{
                        margin-bottom: -5px;
                        padding: 2px 10px;
                        font-size: 16px;
                        color: #000000;
                        font-weight: bold;
                        background-color: #f9ab1d;                        
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 20 20'%3E%3Cg %3E%3Cpolygon fill='%23eba11b' points='20 10 10 0 0 0 20 20'/%3E%3Cpolygon fill='%23eba11b' points='0 10 0 20 10 20'/%3E%3C/g%3E%3C/svg%3E");
                    }

                    .disc-per-txt{
                        float: right;
                        /*background: rgba(249,171,29,0.75);*/
                        padding: 0;
                        font-size: $txt-size;
                        line-height: 27px;
                        font-weight: 500;
                        color: #4a2607;
                        border-radius: 5px 0 0 5px;

                        &.signature{
                            /*background-color: rgba(132, 98, 141, 0.75);*/
                            background-color: rgba(249, 171, 29, 0.75);
                        } 
                        &.retreat{
                            background-color: rgba(107, 52, 248, 0.75);
                        }            
                        &.featured{
                            background-color: rgba(249, 171, 29, 0.75);
                        }                            
                        &.ayurveda{
                            background-color: rgba(124, 157, 74, 0.75);
                        }

                        .featured-section{                    
                            span{
                                display:inline-block;
                                padding:4px 10px 3px 10px;
                                border-radius: 5px 0 0 5px;
                                font-size: $txt-label-s;

                                &.signature{
                                    /*background-color: rgba(132, 98, 141, 0.75);*/
                                    background-color: rgba(249, 171, 29, 0.75);
                                } 
                                &.retreat{
                                    background-color: rgba(107, 52, 248, 0.75);
                                }            
                                &.featured{
                                    background-color: rgba(249, 171, 29, 0.75);
                                }                            
                                &.ayurveda{
                                    /*background-color: rgba(124, 157, 74, 0.75);*/
                                    background-color: rgba(126, 194, 97, 0.75);
                                    color:#265101;                                   
                                }
                                &.grentals{
                                    background-color: rgba(137, 157, 232, 0.75);
                                    color:#001f85; 
                                }
                            }    
                        }

                    }

                    .events_teacher.authors{
                        margin:16px 0 12px 0;
                        padding:0 15px;
                        font-size: $txt-label-m;
                        color:#505050;
                        font-weight: 500;
                        line-height: 16px;

                        p{
                            font-size: $txt-label-m;
                            color:#505050;
                            font-weight: 500;
                            padding:0;
                            line-height: 16px;
                        }
                    }

                    .excerpt{
                        padding:0 15px;
                        font-weight: 400;
                        font-size: $txt-label-m;
                        line-height:22px;
                        margin-bottom: 0;
                        position: relative;
                        z-index:1;

                        .more{
                            display:block;
                            text-align:right;
                            margin:12px 0 10px 0;
                            
                            &.coln{
                                .special-disc-txt{
                                    float: left;
                                    background-color: rgba(249,171,29,0.5);
                                    padding: 3px 8px;
                                    border-radius: 5px;
                                    color: #5E340F;
                                    font-weight: 500;
                                }
                            }

                            .learn{
                                padding:2px 10px;
                                display: inline-block;
                                color:#ffffff;
                                font-size:10px;
                                font-weight: 700;
                                background-color:#84628c;
                                border-radius: 3px;
                                text-transform: uppercase;
                            }
                        }
                    }

                    .taxonomies{
                        position: absolute;
                        padding:0 15px 5px 15px;
                        display: block;                        
                        bottom:0;
                        width:100%;
                        max-width: 294px;

                        .listtaxo{
                            display:block;
                            border-top:1px solid #ebe7e1;
                            padding:2px 0;
                        }

                        img{
                            height:15px;
                        }
                    }

                    .post-date{
                        display:inline;
                        padding:7px 15px;
                        background-color:#ece8e0;
                        font-size: $txt-label-s;
                        font-weight: 500;
                        color:$primary-color;
                        border-radius: 0 5px 5px 0;
                    }

                    .title{
                        margin-top:15px;
                        clear:both;
                        display:block;

                        h4{
                            color: #544741;
                            font-weight: 700;
                            line-height: 22px;
                            font-size: 16px;
                            padding:0 15px;
                            margin:0 0 5px 0;
                            font-family: $font-primary;
                        }                    
                    }

                }
                //

            }            
        }

        .blog-thumb, .page-thumb{
            .inner-wrapper{
                min-height:475px;
                background-color: #ffffff;
                box-shadow: 0 0 0 4px #ecebea;
                border-radius: 5px;

                .image{
                    border-radius: 5px 5px 0 0;
                    .featured-section{  
                        span{
                            display:inline-block;

                            &.blog{
                                background-color: #7b4a4b;
                            }
                        }
                    }
                }
                //

                .content{
                    padding:0 0 5px 0;

                    .events_teacher.authors{
                        margin:16px 0 12px 0;
                        padding:0 15px;
                        font-size: $txt-size-s;
                        color:#727272;
                        font-weight: 500;
                        line-height: 16px;

                        p{
                            font-size: 14px;
                            color:#727272;
                            font-weight: 500;
                            padding:0;
                            line-height: 16px;
                        }
                    }

                    .excerpt{
                        padding:0 15px;
                        font-weight: 400;
                        font-size: $txt-size;
                        line-height:22px;
                        margin-bottom: 0;
                        display: block;
                        height:72px;

                        .more{
                            display:block;
                            text-align:right;
                            margin:12px 0 10px 0;

                            .read{
                                padding:0;
                                display: inline-block;
                                color:#84628c;
                                font-size:16px;
                                font-weight: 700;
                                cursor: pointer;
                            }
                        }
                    }

                    .taxonomies{
                        position: absolute; /* absolute */
                        padding:0 15px 5px 15px;
                        display: block;
                        bottom:0;
                        width:100%;
                        max-width: 294px;
                        height:43px;

                        .listtaxo{
                            display:none;
                            height:0;
                        }

                        img{
                            height:15px;
                        }   

                        .tags{
                            font-size: 13px;
                            display:block;
                            padding:9px 0 0 0;
                            display:block;
                            border-top:1px solid #ebe7e1;                            
                            
                            span{
                                display: inline-block;
                            }

                            .tag-ico{
                                display: inline-block;
                                margin-right:5px;
                                width:20px;
                                height: 16px;
                                background:url("../images/tags.png") no-repeat center center;
                            }

                            .tag-list{
                                height:20px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                width: 100%;
                                max-width: 225px;
                            }
                        }                        
                    }

                    .post-date{
                        display:inline;
                        padding:7px 15px;
                        background-color:#ebebeb;
                        font-size: 14px;
                        font-weight: 500;
                        color:$primary-color;
                        border-radius: 0 5px 5px 0;
                    }

                    .title{
                        margin-top:12px;
                        clear:both;
                        display:block;

                        h4{
                            color: #544741;
                            font-weight: 700;
                            line-height: 22px;
                            font-size: 18px;
                            padding:0 15px;
                            margin:0 0 10px 0;
                            font-family: $font-primary;
                        }                    
                    }                    
                }
                //

            }
        }

        .teacher-thumb, .staff-thumb{            
            .inner-wrapper{
                min-height:475px;
                background-color: #ffffff;
                box-shadow: 0 0 0 4px #ecebea;
                border-radius: 5px;

                .image{
                    border-radius: 5px 5px 0 0;
                    .featured-section{  
                        span{
                            display:inline-block;

                            &.presenter{
                                background-color: #347ef8;
                            }
                        }
                    }
                }
                //
                .content{
                    padding:0 0 5px 0;

                    .events_teacher.authors{
                        margin:16px 0 12px 0;
                        padding:0 15px;
                        font-size: 16px;
                        color:#544741;
                        font-weight: 700;
                        line-height: 18px;

                        p{
                            font-size: 14px;
                            color:#727272;
                            font-weight: 500;
                            padding:0;
                            line-height: 16px;
                        }
                    }

                    .excerpt{
                        padding:0 15px;
                        font-weight: 400;
                        font-size: $txt-size;
                        line-height:22px;
                        margin-bottom: 0;

                        .more{
                            display:block;
                            text-align:right;
                            margin:12px 0 10px 0;

                            .read{
                                padding:0;
                                display: inline-block;
                                color:#84628c;
                                font-size:16px;
                                font-weight: 700;
                                cursor: pointer;
                            }
                        }
                    }

                    .taxonomies{
                        &.hidden{
                            display: none;
                        }
                    }
                    .post-date{
                        display:inline;
                        padding:7px 15px;
                        background-color:#ebebeb;
                        font-size: 14px;
                        font-weight: 500;
                        color:#1b1b1b;
                        border-radius: 0 5px 5px 0;
                    }
                    .title{
                        margin-top:12px;
                        clear:both;
                        display:block;

                        h4{
                            color: #544741;
                            font-weight: 700;
                            line-height: 22px;
                            font-size: 18px;
                            padding:0 20px;
                            margin:0 0 10px 0;
                            font-family: $font-primary;
                        }                    
                    }                
                    .upcoming-event{                        
                        display: block;
                        padding:0;
                        
                        h6{
                            font-size:12px;
                            text-transform: uppercase;
                            color:#544741;
                            display:block;
                            border-top:1px solid #ebe7e1;
                            font-weight: 700;
                            padding:9px 0 7px 0;
                            margin:0 15px;
                            font-family: $font-primary;
                        }
                        .title{
                            padding:0 15px;
                            margin-top:10px;
                            margin-bottom: 8px;                       
                            line-height: 17px;

                            a{
                                font-size: 16px;
                                text-decoration: none;                                
                                font-family: $font-primary;
                            }
                        }
                        .more{
                            display:block;
                            text-align:right;
                            padding:0 15px;

                            a{
                                padding: 0 10px;
                                display: inline-block;
                                color: #fff;
                                font-size: 16px;
                                font-weight: 700;
                                background-color: #84628c;
                                border-radius: 3px;
                            }

                        }
                        .no-recentpost{
                            display:block;
                            padding:0 15px;
                            font-size: 15px;
                            color: #5a5a5a;
                            line-height: 22px;
                        }
                    }
                }
                //
            }
        }

        .staff-thumb{
            .inner-wrapper{
                .image{
                    .featured-section{  
                        span{
                            display:inline-block;

                            &.staff{
                                background-color: #347ef8;
                            }
                        }
                    }
                }
            }
        }

        .page-thumb{
            .inner-wrapper{
                .image{
                    margin-bottom:16px;                    
                }
            }
        }

        .ast-pagination {
            .nav-links{
                text-align:center;
                
                .next.page-numbers{
                    float:none;
                }
            }
            
        }

        .calendar-btn-wrapper{
            display: inline-block;
            position: relative;
            height: 50px;
            line-height: 50px;
            padding: 0;
            border: 1px solid transparent;
            max-width: 100%;
            width:100%;
            margin:50px auto 0 auto;
            text-align:center;
    
            .button-default {
                background-color: #84628d;
                border-radius: 4px;
                color: #fff;
                letter-spacing: 2.29px;
                font-weight: 700;
                font-size: 14px;
                line-height: 50px;
                height: 50px;
                text-transform: uppercase;
                position: relative;
                text-align:center;
                width: auto;
                cursor: pointer;
                display: inline-block;
                padding-left: 25px;
                padding-right: 25px;
                outline: none;                
            }
        }

    }
}


@media screen and (max-width: 767px) and (orientation:portrait){
    .search-header form ._text-field{
        width:91%;
        max-width: 100%;
        margin-bottom:15px;
    }
    .search-header{
        padding-top:50px;
    }
    .search-header form .submit-wrapper ._go{
        margin-bottom: 5px;
    }
    .search-header .search-wrapper{
        padding-bottom:50px;
    }
    .search-header .elementor-background-overlay{
        max-height: 325px;
    }
    #primary.search .ast-archive-description{
        margin-bottom: 0;
    }
    #primary.search .ast-archive-description h1.ast-archive-title{
        margin-top:20px;
        font-size: 1.25rem;
    }
    #primary.search .ast-archive-description h1.ast-archive-title span{
        display: block;
        margin-top:5px;
    }
    .search-header form .field.month-select, .calendar-search-bar form .field.month-select{
        width:100%;
        margin-bottom: 10px;
    }

    .search-header form .field {
        padding: 0;
    }
    .calendar-search-bar form label{
        text-align:left;
    }
}

@media screen and (max-width: 767px) and (orientation:landscape){
    .search-header{
        padding-top:50px;
    }
    .search-header form ._text-field{
        width:96%;
        max-width: 100%;
        margin-bottom:15px;
    }
    .search-header form .submit-wrapper ._go{
        margin-bottom: 5px;
    }
    .search-header .search-wrapper{
        padding-bottom:80px;
    }
    #primary.search .ast-archive-description{
        margin-bottom: 0;
    }
    .search-header .elementor-background-overlay{
        max-height: 350px;
    }
    #primary.search .ast-archive-description h1.ast-archive-title{
        margin-top:20px;
        font-size: 1.75rem;
    }
    #primary.search .wcp-events-template .wcp-event-col{
        max-width:309px;
        display: inline-block;
        clear: none;
    }
    #primary.search .wcp-events-template .wcp-event-col:nth-child(2n+1){
        padding-left:0;
    }
    #primary.search .wcp-events-template .wcp-event-col:nth-child(2n){
        padding-right:0;
    }
    .search-header form .field.month-select{
        width:auto;
    }

    .header-search-bar .calendar-search-bar{
        form{
            text-align:left;

            label{
                text-align:left;
            }
            .field.month-select{
                width:100%;
            }
            .field.keyword-input{
                padding-left:0;
            }
            ._text-field{
               width: 90% !important;
            }
            .calendar-wrapper{
                max-width: 120px !important;
            }
        }
    }    
}