/* Swiper Carousel/Slider */
.customMediaCarousel{
       
  .swiper-container{
    padding: 0;
    background: #fff;              

    .swiper-slide {
      padding: 0;
      flex-direction: column; // To make image on top of the text
      //flex-wrap: wrap;
      border-radius: 4px;
      box-shadow: 4px 7px 38px 7px rgba(132,98,140,.1);
    }
  
    // Style the background
    .swiper-slide .swiper-slide-bg{
      min-height: 45%;
    }
  
    // Style the content area
    .swiper-slide-inner {
      display: block;
      padding: 0 12px;
      position: relative;
      min-height: 55%;

        .swiper-slide-contents{
          position: relative;
          margin-top: 0;
          width: 100%;
          max-width: 100%;        
  
          .elementor-slide-heading {
            font-family: $gro-primary-font;
            font-size: 24px;
            margin-bottom: 23px;
            margin-top: 0;
            padding-top: 30px;
            color: $brand-primary;
            font-weight: 500;
          }
          .elementor-slide-description{
            font-size: 20px;
            font-size: .95rem;
            margin-bottom: 18px;
            color: #707070;
            line-height: 1.3;
            font-family: $gro-general-font;
          }

        }
    }
    
    /* Adjust the slider arrows */
    .elementor-swiper-button, .elementor-swiper-button:hover, .elementor-swiper-button:focus {
      box-shadow: 0px 0 10px 0 rgba(0,0,0,0.25);
      background: #84628d;
      border-radius: 100%;
      width: 50px;
      height: 50px;
    }

    .elementor-swiper-button:hover {
      box-shadow: 0px 0 10px 0 rgba(0,0,0,0.25) !important; 
    }

    .elementor-swiper-button:before {
      border-color: #FFF;
      color: #666;
      width: 12px;
      height: 12px;
      border-width: 1px 1px 0 0;
      border-style: solid;
      font-size: 0;
      position: absolute;
      top: 38%;
    }

    .elementor-swiper-button{

      i{
        color: #666;
        width: 12px;
        height: 12px;
        border-width: 1px 1px 0 0;
        border-color: #fff;
        border-style: solid;
        position: absolute;

        &:before{
          content:"";
        }       
      }
      
      svg{
        height:auto;
        width:1em;
        margin-left: 15px;
      }
    }

    .elementor-swiper-button.elementor-swiper-button-prev {
      left: -25px;
      position: absolute;

      &:before {
        left: 43%;
        transform: rotate(-135deg);
      } 
      
      i{
        transform: rotate(-135deg);
        top: 18px;
        left: 20px;
      }
    }

    .elementor-swiper-button.elementor-swiper-button-next {
      right: -25px;
      position: absolute;

      &:before {
        right: 43%;
        transform: rotate(45deg);
      }

      i{
        transform: rotate(45deg);
        top: 18px;
        left: 16px;
      }
    }           
    
    /* Tablet */
    @media screen and (max-width: 768px) {
      .elementor-swiper-button.elementor-swiper-button-prev {
          left: 4px !important;
      }
      
      .elementor-swiper-button.elementor-swiper-button-next {
          right: 4px !important;
      }
      
      .slick-disabled {
          display: none !important;
      }
    }
    //
    
  }
  //

  &.elementor-pagination-position-outside{
    .swiper-pagination-bullets{
      margin-top: 0;
      margin-bottom: -55px;
      padding: 55px 0 0 0;

        .swiper-pagination-bullet{
          width:10px;
          height: 10px;
          background:#bfbfbf;
          opacity: 1;
            &.swiper-pagination-bullet-active{
              background:#84628c;
            }
        }         
    }
  }

} 


.customMediaCarouselLarge{
       
  .swiper-container{
    
    .swiper-slide {
      
    }
    .elementor-swiper-button{
      box-shadow: 3px 8px 16px 0 rgba(0, 0, 0, 0.6);
      background-color: #ffffff;
      width: 50px;
      height: 50px;
      border-radius: 100%;
      position: absolute;
      font-size: 27px;
      color: #ffffff;

        i{      
          &:before{
            border-color: #979797;
            width: 13px;
            height: 13px;
            border-width: 2px 2px 0 0;
            border-style: solid;
            font-size: 0;
            position: absolute;
            top: 18px;            
          }
          &.eicon-chevron-left{
            &:before{
              transform: rotate(-135deg);
              right: 16px;
            }
          }  
          &.eicon-chevron-right{
            &:before{
              transform: rotate(45deg);
              right: 20px;
            }
          }   

        }

    }
  }
}




/* End Swiper Carousel/Slider */
