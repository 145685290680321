// Connects to WcpEvents_Calendar.php

// Events List
.wcp-events-template {  
  float: left;
  clear: both;
  display: block;
  padding: 10px 0 40px 0;
  margin-bottom: 40px;

  .wcp-event-col {
    margin-bottom: 30px;

    // Gray out passed events

    &.passed-event {
      -webkit-filter: grayscale(1); /* Webkit */
      filter: gray; /* IE6-9 */
      filter: grayscale(1); /* W3C */
    }

    .inner-wrapper {
      box-shadow: 0 2px 35px 0 rgba(0,0,0,0.08);

      .image {
        height: 15vw;
        min-height: 180px;
        max-height: 200px;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        background-position: top center;
        
        &:after {
          display: block;
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          /*background-image: radial-gradient(52% -20%, rgba(0,0,0,0.00) 64%, rgba(0,0,0,0.25) 100%);*/
        }

        .featured-section {
          position: absolute;
          left: 10px;
          z-index: 1;

          span {
            letter-spacing: 2.5px;
            color: #FFF;
            background-color: #84628D;
            font-size: 10px;
            font-weight: bold;
            padding: 5px 15px;
            text-transform: uppercase;
            display: inline-block;
            margin-top: 10px;
            border-radius: 5px;

            &:not(.signature) {
              background-color: #f9ab1d;
            }
          }
          
        }

        // Title
        .event-title {
          position: absolute;
          color: #FFF;
          z-index: 1;
          bottom: 0;
          padding: 10px 15px;
          font-size: 19px;
          font-family: $font-primary-title;
          line-height: 24px;
          text-shadow: 0 2px 15px #000;
          margin-bottom: 0;
        }
      }

      span.post-date {
        color: #3C3247;
        font-size: 14px;
        font-weight: 500;
        line-height: 26px;
        padding: 10px 20px 10px 20px;
        display: inline-block;
        width: 100%;
      }

      // Tweak the borders if the event is repeatable
      &.is_repeatable {
        box-shadow: 0px 0px 0px 4px $brand-secondary;

        span.post-date {
          background: $brand-secondary;
          padding-top: 5px;
          padding-bottom: 5px;
          margin-bottom: 10px;
          color: white;
        }
      }

      // Content
      .content {
        padding: 0px 20px 20px 20px;
        display: block;
        min-height: 105px;
        height:100% !important;

        .events_teacher {
          color: #544741;
          font-weight: bold;
          line-height: 20px;
          font-size: 17px;
          margin-top: 2px;
              margin-bottom: 8px;

          p {
            margin-bottom: 0;
          }
        }

        .excerpt {
          font-size: 16px;
          color: $primary-color;
          line-height: 22px;
          margin-bottom: 35px;
        }

        .taxonomies {
          margin-top: 10px;
          margin-bottom: 0;
          position: relative;
          bottom: 15px;

          img {
            height: 20px;
            width: auto;
            display: inline-block;
            margin-right: 5px;
          }
        }

      }


    }
    
  }
  .ast-pagination{
    clear:both;
    display: block;
    width: auto;
    margin: auto;
    text-align: center;
      .navigation{
        width: auto;
        display: inline-block;
        margin: auto;

        .nav-links{
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .page-numbers{
          padding: 0 15px;
          margin: 0 10px;
          border-radius: 4px;

          &.current{
          font-weight: 700;
          background-color: #84628c;
          color: #fff;
          }
        }

        
      }
  }
}


.wcp-events-template .wcp-event-col:nth-child(4n + 1) {
  clear: both;
}


@media screen and (min-width: 1200px) {
  .wcp-events-flexslider .wcp-events-template {
    margin: 0 -110px;
  }
}
.wcp-events-flexslider .wcp-events-template article {
  padding: 0 !important;
  position: relative;
}
.wcp-events-flexslider .wcp-events-template article:nth-child(n + 5) {
  display: none;
}
.wcp-events-flexslider .wcp-events-template article > * {
  padding-left: 15px;
  padding-right: 15px;
}

/* Tablet */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  #primary .wcp-events-template .col-sm-3 {
    width: 50%;
  }
  #primary .calendar-search-bar form .calendar-wrapper{
      max-width:100%;
      text-align:center;
      margin-top: 15px;
      margin-bottom: 5px;
      text-align:center;

    a{
      max-width: 157px;
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}