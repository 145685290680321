
/*************************************************************
                        CUSTOM CODE
      Astra Child theme for Art Of Living Retreat Center 
--------------------------------------------------------------
NOTES FOR DEVELOPERS:
- Use tabs of two spaces for best reading experience
--------------------------------------------------------------
  INDEX
  1.    General Styles	
--------------------------------------------------------------
  CODE STARTS HERE
--------------------------------------------------------------
  1.    General Styles	
-------------------------------------------------------------*/

body {
  overflow-x: hidden;
}

body.elementor-page-65016{
  overflow-x: hidden;
  overflow-y: auto;
}

body, button, input, select, textarea, .ast-button, .ast-custom-button{
  font-family: $font-primary;
}

.entry-content p, .elementor-text-editor p, .elementor-text-editor, .elementor-text-editor div, ol, ol li, ul, ul li, .elementor-text-editor a, .elementor-cta .elementor-cta__content .elementor-cta__description, .custom-featured-image-box .elementor-image-box-content .elementor-image-box-description, .author-bio, .elementor-widget-image-box .elementor-image-box-description, .single-post .single-post-container #primary p, .elementor-widget-text-editor .elementor-widget-container, .elementor-widget-theme-post-content div, .elementor-widget-theme-post-content p{
  font-size: $txt-size;
  line-height: 24px;
  letter-spacing: 0.065px;
}

.elementor-text-editor h1 a, .elementor-text-editor h2 a, .elementor-text-editor h3 a, .elementor-text-editor h4 a, .elementor-text-editor h5 a, .elementor-text-editor h6 a{
  font-size: inherit;
}

.single-post .single-post-container #primary{
  ul {
    li{
      margin-bottom: 5px;
    }
  }
}

.elementor-text-editor p a, .elementor-text-editor a, .elementor-text-editor div a, .elementor-cta .elementor-cta__content .elementor-cta__content-item a, .custom-featured-image-box .elementor-image-box-content .elementor-image-box-description a, .elementor-widget-image-box .elementor-image-box-description a{
  color: $brand-primary;
}

.elementor-text-editor p a:hover, .elementor-text-editor a:hover, .elementor-text-editor div a:hover, .elementor-cta .elementor-cta__content .elementor-cta__description a:hover, .custom-featured-image-box .elementor-image-box-content .elementor-image-box-description a:hover, .elementor-widget-image-box .elementor-image-box-description a:hover{
  color:$hover-brand-primary;
}

h1, .entry-content h1{
  font-size: $h1-size;
  font-family: $font-primary-title;
  line-height: 60px;
}
h2, .entry-content h2{
  font-size: $h2-size;
  font-family: $font-primary-title;
}
h3, .entry-content h3{
  font-size: $h3-size; 
  font-family: $font-primary-title; 
}
h4, .entry-content h4, .elementor-posts h4.elementor-post__title{
  font-size: $h4-size;
}
h5, .entry-content h5, .elementor-posts h5.elementor-post__title{
  font-size: $h5-size;
  font-family: $font-primary;
  font-weight: 700;
}
h6, .entry-content h6, .elementor-posts h6.elementor-post__title{
  font-size: $h6-size;
  font-family: $font-primary;
  font-weight: 700;
}

p, .elementor-text-editor div, ol, ol li, ul, ul li{
  color: $primary-color; /*#150f1a */  
}

// Fix astra page top spacing issue
#primary { margin-top: 0; }

/* Remove the focus effect on the whole website */
a, a:focus {
  outline: 0;
  text-decoration: none;
}

/* Set the placeholder font-family */
input:-moz-placeholder,
input::placeholder {
  font-family: $font-primary !important;
  opacity: 1;
  line-height: 40px;
}

/* Youtube icon */
.fa-youtube:before {
  /*content: "";
  background-image: url(../images/youtube-icon.svg);*/
  width: 26px;
  height: 18px;
  margin-top: 0;
  background-repeat: no-repeat;
}

/* Slider addon - fix the z-index */
.premium-carousel-wrapper a.carousel-arrow, .premium-carousel-wrapper a.ver-carousel-arrow, .premium-fb-rev-container a.carousel-arrow, .premium-fb-rev-container a.ver-carousel-arrow {
  z-index: 90;
}

.swiper-slide{
  will-change: auto !important;
}

// New expandable Generic Section
.toggleReadMoreSectionTarget {
  transition: all 1s;
}

.toggleReadMoreSectionTarget:not(.expanded) {
  display: none;
}

@keyframes bubble-border-transform {
 
  0%, 100% {
      border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
  }
  
  14% {
      border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
  }
  28% {
      border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
  }
  42% {
      border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
  }
  56% {
      border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
  }
  70% {
      border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
  }
  84% {
      border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
  }
  
}

@keyframes headerSlideDown {
  0% {
      opacity: 0;
      transform: translateY(-100%);
  } 
  100% {
      opacity: 0.9;
      transform: translateY(0);
  } 
}


@media(max-width:767px){
  #primary.content-area{
    .elementor-widget-heading{
      h1.elementor-heading-title{
        font-size: $h1-msize;
        line-height: 1.3em;
      }
      h2.elementor-heading-title{
        font-size: $h2-msize;
      }
    }
  } 
}

.no-padding{  
  &.e-container, .elementor-widget-wrap{
    padding:0 !important;
  }
}
