/*************************
Schedule/Timetable Plugin style
e.g., /restore-revive/restore-revive-wellness-getaway/
**************************/
.elementor-widget-wp-widget-wcs-events-schedule{
    .wcs-timetable__wrapper{
        .wcs-timetable--style-3{        
            .wcs-timetable--week {
                .wcs-day{
                    .wcs-class--term-id-1571{
                        background-color: #008fc5;
                        color: #ffffff;
                    }
                    .wcs-class--term-id-1579{
                        background-color:#a4c35d;
                        color: rgba( 0,0,0,0.85);
                    }
                    .wcs-class--term-id-1578{
                        background-color:#008fc5;
                        color: #ffffff;
                    }
                    .wcs-class--term-id-1576{
                        background-color:#8c6a23;
                        color: #ffffff;
                    }
                    .wcs-class--term-id-1572{
                        background-color:#007549;
                        color: #ffffff;
                    }
                    .wcs-class--term-id-1580{
                        background-color:#83618d;
                        color: #ffffff;
                    }
                }

            }
        }
    }
}