.button-shop {
  background-color: #016789;
  border-radius: 4px;
  border: 2px solid $brand-primary;
  color: #fff;
  display: block;
  font-family: "Gibson";
  height: 29px;
  line-height: 29px;
  text-align: center;
  text-transform: uppercase;
  width: 85px;
}
.button-shop:hover {
  background-color: #7fc261;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#7fc261), to(#a3ce5d));
  background-image: linear-gradient(#7fc261, #a3ce5d);
  border: 2px solid #158152;
}

.button-donation {
  background: #0090c6;
  border-radius: 4px;
  border: 1px solid #104b71;
  color: #fff;
  display: block;
  /*font-family: "Capita";*/
  font-size: 0.9375em;
  height: 30px;
  line-height: 28px;
  margin: 0 auto;
  width: 102px;
  font-weight: lighter;
}

.button-landing {
  background-color: #ff972a;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#ff972a), to(#ff632a));
  background-image: linear-gradient(#ff972a, #ff632a);
  color: #fff;
  font-size: 1.5em;
  border: 2px solid #ff632a;
  height: 50px;
  width: 100%;
  border-radius: 4px;
}

.button-landing:hover {
  background-color: #fab36c;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fab36c), to(#ff713d));
  background-image: linear-gradient(#fab36c, #ff713d);
}

.button-default, .ee_view_cart {
  color: #fff;
  border-radius: 4px;
  line-height: 46px;
  cursor: pointer;
  display: inline-block;
  padding-left: 13px;
  padding-right: 13px;
  outline: none;
}
@media screen and (max-width: 320px) {
  .button-default, .ee_view_cart {
    height: 25px;
    line-height: 23px;
  }
}
.button-default.button-blue, .button-blue.ee_view_cart, .ee_view_cart.ee_add_item_to_cart {
  background-color: $brand-primary;
  border: 1px solid $brand-primary;
  font-weight: lighter;
}
.button-default.button-blue:hover, .button-blue.ee_view_cart:hover, .ee_view_cart.ee_add_item_to_cart:hover {
  background-color: $brand-primary;
  color: #FFF;
}
.button-default.button-blue:active, .button-blue.ee_view_cart:active, .ee_view_cart.ee_add_item_to_cart:active {
  background-color: #0082b1;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#0082b1), to(#036a95));
  background-image: linear-gradient(#0082b1, #036a95);
  border: 1px solid #17567c;
}
.button-default.button-blue-reverse, .ee_view_cart {
  background-color: #7fc261;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#7fc261), to(#a3ce5d));
  background-image: linear-gradient(#7fc261, #a3ce5d);
  border: 1px solid #158152;
}
.button-default.button-blue-reverse:hover, .ee_view_cart:hover {
  background-color: #016789;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#016789), to(#0f4a71));
  background-image: linear-gradient(#016789, #0f4a71);
  border: 1px solid #175479;
}
.button-default.button-blue-reverse.active, .active.ee_view_cart {
  background-color: #0a7773;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#0a7773), to(#1e738c));
  background-image: linear-gradient(#0a7773, #1e738c);
  border: 1px solid #f2f2ee;
}

// Hero Button
.button-default.button-celeste, .button-celeste.ee_view_cart {
  background-color: $brand-primary;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 15px;
  letter-spacing: 0.91px;
  padding: 15px 30px;

  &:hover, &:active, &:focus {
    color: #FFF;
  }
}
// End of Hero Button

.button-default.button-purple, .button-purple.ee_view_cart {
  background-color: #82618b;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#82618b), to(#5a416b));
  background-image: linear-gradient(#82618b, #5a416b);
  border: 1px solid #554a5d;
}
.button-default.button-purple:hover, .button-purple.ee_view_cart:hover {
  background-color: #7fc261;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#7fc261), to(#a3ce5d));
  background-image: linear-gradient(#7fc261, #a3ce5d);
  border: 1px solid #218652;
}
.button-default.button-purple:active, .button-purple.ee_view_cart:active {
  background-color: #72577c;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#72577c), to(#c1a8b7));
  background-image: linear-gradient(#72577c, #c1a8b7);
  border: 1px solid #564a5d;
}
.button-default.button-light-purple, .button-light-purple.ee_view_cart {
  background-color: #ae92ab;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#ae92ab), to(#5a416b));
  background-image: linear-gradient(#ae92ab, #5a416b);
  border: 1px solid #554a5d;
}
.button-default.button-light-purple:hover, .button-light-purple.ee_view_cart:hover {
  background-color: #7fc261;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#7fc261), to(#a3ce5d));
  background-image: linear-gradient(#7fc261, #a3ce5d);
  border: 1px solid #218652;
}
.button-default.button-light-purple:active, .button-light-purple.ee_view_cart:active {
  background-color: #72577c;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#72577c), to(#c1a8b7));
  background-image: linear-gradient(#72577c, #c1a8b7);
  border: 1px solid #564a5d;
}

.button-load-more {
  width: 100%;
  text-align: center;
}

.item-grid .button-default, .item-grid .ee_view_cart {
  font-size: 10px;
  line-height: 15px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.91px;
  padding: 12px 20px;
}
