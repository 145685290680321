@media screen and (max-width: $mobile-max) {
 /* .events-search-bar ._date-fancy-field {
    height: 60px;
  }*/
  .events-search-bar ._date-fancy-field ._label {
    font-size: 14px;
    line-height: 16px;
  }
  .events-search-bar ._date-fancy-field ._text {
    font-size: 20px;
    line-height: 38px;
  }

  .header-events-search-bar .events-search-bar {
    top: 106px; /* 48 */
    z-index: 110;
    padding-top: 15px;
  }
}

.search-overlay{
  display: none;
  position: absolute;
  top:0;
  left:0;
  padding:0;
  z-index: 98;
  width:100%;
  height:100%;
  background-color: rgba(0, 0, 0, 0.5);
}

body.admin-bar .header-events-search-bar .search-overlay{
  top:30px;
}

.header-events-search-bar .events-search-bar .ast-container{
  position: relative;
  z-index: 2;
}

.header-events-search-bar .events-search-bar #close_events_bar{
  position: relative;
  z-index: 2;
}