/**************************************************
Refer assets/style.css for old/custom page styles
***************************************************/

.double-line-title {
  line-height: 0.5;
  text-align: center;
}
.double-line-title .between-lines {
  display: inline-block;
  position: relative;
  width: 100%;
}
.double-line-title .between-lines:before,
.double-line-title .between-lines:after {
  content: "";
  position: absolute;
  height: 7px;
  border-bottom: 2px solid #a8a8aa;
  border-top: 1px solid #a8a8aa;
  top: 50%;
  margin-top: -4px;
  bottom: 40%;
  width: 32%;
}
@media screen and (max-width: 640px) {
  .double-line-title .between-lines:before,
  .double-line-title .between-lines:after {
    width: 15%;
  }
}
.double-line-title .between-lines:before {
  left: 0;
}
.double-line-title .between-lines:after {
  right: 0;
}

.double-line-box {
  line-height: 0.5;
  text-align: center;
}
.double-line-box .between-lines {
  display: inline-block;
  position: relative;
  width: 100%;
}
.double-line-box .between-lines:after {
  content: "";
  position: absolute;
  height: 7px;
  border-bottom: 1px solid #a8a8aa;
  border-top: 2px solid #a8a8aa;
  top: 50%;
  margin-top: -4px;
  bottom: 40%;
  width: 100%;
}
.double-line-box .between-lines:after {
  right: 0;
}

.container.container-gutter {
  padding-left: 15px;
  padding-right: 15px;
}
.container.container-gutter-small {
  padding-left: 5px;
  padding-right: 5px;
}
@media screen and (max-width: 640px) {
  .container.container-gutter-mobile {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media screen and (max-width: 320px) {
  .container.container-gutter-mobile {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.container.container-medium {
 /* max-width: 750px;*/
}
@media screen and (max-width: 640px) {
  .container.container-medium {
    width: 100%;
    max-width: 640px;
    min-width: 200px;
  }
}
.container.container-large {
  width: 940px;
}
@media screen and (max-width: 640px) {
  .container.container-large {
    width: 100%;
    max-width: 640px;
    min-width: 200px;
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media screen and (max-width: 320px) {
  .container.container-large {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.container.container-blog {
  padding-left: 15px;
  padding-right: 15px;
}
@media screen and (max-width: 640px) {
  .container.container-blog {
    padding-left: 0;
    padding-right: 0;
  }
}
.container.container-blog .section-aside {
  padding-top: 3em;
}
.container.container-event-details {
  padding-left: 15px;
  padding-right: 15px;
}
@media screen and (max-width: 640px) {
  .container.container-event-details {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media screen and (max-width: 320px) {
  .container.container-event-details {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.container.container-event-details .empty_cart_notice {
  color: red;
  text-align: center;
  margin-bottom: 30px;
}

.book-you-stay {
  margin: 40px 0;
}

.pull-left, .box-text img.alignleft, .box-text figure.alignleft, .box-post img.alignleft, .box-post figure.alignleft {
  float: left;
}

.pull-right, .box-text img.alignright, .box-text figure.alignright, .box-post img.alignright, .box-post figure.alignright {
  float: right;
}

.map {
  margin-bottom: 40px;
}
.map img {
  width: 100%;
}

.list-diamond {
  list-style-image: url("../images/list_diamond.png");
  text-align: left;
  list-style-position: inside;
}
.list-diamond.list-diamond-purple {
  list-style-image: url("../images/list_diamond_purple.png");
}
.list-diamond.list-diamond-blue {
  list-style-image: url("../images/list_diamond_blue.png");
}
.list-diamond li {
  line-height: 30px;
  margin-bottom: 11px;
}
@media screen and (max-width: 640px) {
  .list-diamond li {
    margin-bottom: 20px;
    line-height: 37px;
  }
}

.box-list {
  margin-top: 45px;
}

.list-two-columns {
  list-style: none;
}
@media screen and (max-width: 640px) {
  .list-two-columns .column {
    width: 100% !important;
  }
}
.list-two-columns .column li {
  margin-bottom: 30px;
}
.list-two-columns .first.column {
  padding-right: 15px;
}
@media screen and (max-width: 640px) {
  .list-two-columns .first.column {
    padding-right: 0;
  }
}
.list-two-columns .last.column {
  padding-left: 15px;
}
@media screen and (max-width: 640px) {
  .list-two-columns .last.column {
    padding-left: 0;
  }
}

.split-list ul {
  float: left;
  margin-left: 1em;
}

.split-list li {
  padding-right: 2em;
  line-height: 1.5em;
}

body .flex-control-nav {
  display: none;
}
body .quoteslidercontainer {
  /*margin-top: -24px;*/
  margin-bottom: 100px;
}
body .quotetext {
  color: #777;
  /* font-family: Capita; */ /* Removed font */
  font-size: 22px;
  font-style: italic;
  font-weight: 500;
  margin: 0 0 18px;
  text-align: center;
}
body .flex-control-nav.quoteslides {
  display: block;
}
body .flex-control-nav li {
  margin: 0 14px;
}
body ol.flex-control-nav-quotes {
  text-align: center;
  margin: 0;
}
body .flex-control-nav-quotes li {
  margin: 0 18px;
  display: inline-block;
  zoom: 1;
  cursor: pointer;
}
body .flex-control-nav-quotes img {
  padding-bottom: 4px;
}
body .flex-control-nav-quotes img.flex-active {
  padding-bottom: 0px;
  border-bottom: solid 4px #6bc8f3;
  -webkit-transition: border-bottom .1s;
  transition: border-bottom .1s;
}
body .flex-control-thumbs img {
  max-height: 79px;
  max-width: 212px;
}

* .green {
  color: #0a7773;
}

* .purple2 {
  color: #564755;
}

* .dark-blue {
  color: #84628d;
}

/** .blue, * .blue a {
  color: #84628d;
}*/

* .brown {
  color: #825622;
}

* .blue-chill {
  color: #3E919C;
}

* .tea-blue {
  color: #327A8C;
}

* .ed-green {
  color: #0a7773 !important;
}

* .ed-purple2 {
  color: #564755 !important;
}

* .ed-dark-blue {
  color: #84628d !important;
}

* .ed-blue, * .ed-blue a {
  color: #84628d !important;
}

* .ed-brown {
  color: #825622 !important;
}

* .ed-purple-color {
  color: #c3aab9 !important;
}

* .ed-celeste-color {
  color: #62cdf6 !important;
}

* .ed-orange-color {
  color: #ff632a !important;
}

* .ed-grey-color {
  color: #676767 !important;
}

* .ed-red-color {
  color: #ec1c24 !important;
}

* .ed-beige-color {
  color: #ede5d7 !important;
}

.image-border {
  border: 1px solid #d1d3d4;
}
.image-border img {
  width: 100%;
  display: block;
}

.image-border-white, .box-text > img, .coming-up.coming-up-spa figure > img {
  background: #fff;
  border: 1px solid #d1d3d4;
  padding: 10px;
}
@media screen and (max-width: 320px) {
  .image-border-white, .box-text img, .coming-up.coming-up-spa figure img {
    padding: 5px;
  }
}
.image-border-white img, .box-text img img, .coming-up.coming-up-spa figure img img {
  width: 100%;
  display: block;
}

.thumbnail-photo {
  margin-bottom: 20px;
}
.thumbnail-photo:after {
  content: "";
  display: table;
  clear: both;
}
@media screen and (max-width: 320px) {
  .thumbnail-photo figcaption {
    font-size: 0.5625em;
  }
}
.thumbnail-photo.x-small-photo {
  width: 165px;
}
.thumbnail-photo.small-photo {
  width: 263px;
}
@media screen and (max-width: 640px) {
  .thumbnail-photo.small-photo {
    width: 165px;
  }
}
.thumbnail-photo.medium-photo {
  width: 360px;
}
@media screen and (max-width: 640px) {
  .thumbnail-photo.medium-photo {
    width: 165px;
  }
}
.thumbnail-photo.large-photo {
  width: 750px;
}
@media screen and (max-width: 640px) {
  .thumbnail-photo.large-photo {
    width: 100%;
  }
}
.thumbnail-photo.large-photo img {
  width: 100%;
}
.thumbnail-photo.large-blog {
  width: 750px;
}
@media screen and (max-width: 640px) {
  .thumbnail-photo.large-blog {
    width: 100%;
  }
}
.thumbnail-photo.large-blog img {
  height: auto;
  width: 100%;
  margin-bottom: 15px;
}
.thumbnail-photo.medium-blog {
  width: 360px;
}
.thumbnail-photo.medium-blog.pull-left, .box-text img.thumbnail-photo.medium-blog.alignleft, .box-text figure.thumbnail-photo.medium-blog.alignleft, .box-post img.thumbnail-photo.medium-blog.alignleft, .box-post figure.thumbnail-photo.medium-blog.alignleft {
  margin-right: 30px;
}
.thumbnail-photo.medium-blog.pull-right, .box-text img.thumbnail-photo.medium-blog.alignright, .box-text figure.thumbnail-photo.medium-blog.alignright, .box-post img.thumbnail-photo.medium-blog.alignright, .box-post figure.thumbnail-photo.medium-blog.alignright {
  margin-left: 30px;
}
@media screen and (max-width: 640px) {
  .thumbnail-photo.medium-blog {
    width: 280px;
  }
}
.thumbnail-photo.medium-blog img {
  width: 100%;
  height: auto;
  margin-bottom: 15px;
}
.thumbnail-photo.x-small-photo.pull-left, .box-text img.thumbnail-photo.x-small-photo.alignleft, .box-text figure.thumbnail-photo.x-small-photo.alignleft, .box-post img.thumbnail-photo.x-small-photo.alignleft, .box-post figure.thumbnail-photo.x-small-photo.alignleft, .thumbnail-photo.small-photo.pull-left, .box-text img.thumbnail-photo.small-photo.alignleft, .box-text figure.thumbnail-photo.small-photo.alignleft, .box-post img.thumbnail-photo.small-photo.alignleft, .box-post figure.thumbnail-photo.small-photo.alignleft, .thumbnail-photo.medium-photo.pull-left, .box-text img.thumbnail-photo.medium-photo.alignleft, .box-text figure.thumbnail-photo.medium-photo.alignleft, .box-post img.thumbnail-photo.medium-photo.alignleft, .box-post figure.thumbnail-photo.medium-photo.alignleft {
  margin-right: 30px;
}
.thumbnail-photo.x-small-photo.pull-right, .box-text img.thumbnail-photo.x-small-photo.alignright, .box-text figure.thumbnail-photo.x-small-photo.alignright, .box-post img.thumbnail-photo.x-small-photo.alignright, .box-post figure.thumbnail-photo.x-small-photo.alignright, .thumbnail-photo.small-photo.pull-right, .box-text img.thumbnail-photo.small-photo.alignright, .box-text figure.thumbnail-photo.small-photo.alignright, .box-post img.thumbnail-photo.small-photo.alignright, .box-post figure.thumbnail-photo.small-photo.alignright, .thumbnail-photo.medium-photo.pull-right, .box-text img.thumbnail-photo.medium-photo.alignright, .box-text figure.thumbnail-photo.medium-photo.alignright, .box-post img.thumbnail-photo.medium-photo.alignright, .box-post figure.thumbnail-photo.medium-photo.alignright {
  margin-left: 30px;
}
.thumbnail-photo.x-small-photo img, .thumbnail-photo.small-photo img, .thumbnail-photo.medium-photo img {
  margin-bottom: 15px;
}
.thumbnail-photo figcaption {
  position: relative;
  padding-top: 5px;
}
.thumbnail-photo figcaption:before {
  background: #bec0c1;
  top: 0px;
  content: "";
  height: 1px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 100%;
}
.thumbnail-photo img {
  display: block;
  width: 100%;
  margin-bottom: 15px;
}

.box-single-post .thumbnail-photo.post-thumbnail-figure {
  display: none;
}

.title-page {
  text-align: center;
  font-weight: 400;
  margin-bottom: 5px;
  position: relative;
}
/*.title-page:before {
  background: #bec0c1;
  bottom: -18px;
  content: "";
  height: 1px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 166px;
}
@media screen and (max-width: 320px) {
  .title-page:before {
    bottom: -7px;
  }
}*/
.title-page.type-a {
  font-style: italic;
  font-size: 2.5em;
  padding-top: 35px;
  color: #84628e;
}
@media screen and (max-width: 320px) {
  .title-page.type-a {
    font-size: 1.25em;
    padding-top: 18px;
  }
}
/*title-page.type-b {
  font-size: 2.25em;
  text-transform: uppercase;
  color: #84628e;
  margin-bottom: 45px;
  padding-top: 35px;
}
@media screen and (max-width: 320px) {
  .title-page.type-b {
    font-size: 1.125em;
    padding-top: 18px;
  }
}*/
.title-page.remove-padding {
  padding-top: 0;
}
.title-page.brown {
  color: #825622;
}
/*.title-page.blue {
  color: #84628d;
}*/
.title-page.blue-chill {
  color: #3E919C;
}

.sub-head-title {
  margin-bottom: 10px;
}
.sub-head-title.purple {
  color: #84628e;
}
.sub-head-title.grey {
  color: #404041;
}
.sub-head-title.brown {
  color: #825622;
}
.sub-head-title.large-sub-head {
  font-size: 1.875em;
  font-weight: 500;
  margin-top: 35px;
  margin-bottom: 15px;
}
.sub-head-title.large-sub-head.small {
  font-size: 1.75em;
}
@media screen and (max-width: 320px) {
  .sub-head-title.large-sub-head.small {
    font-size: 1.125em;
  }
}
@media screen and (max-width: 320px) {
  .sub-head-title.large-sub-head {
    font-size: 1.125em;
  }
}
.sub-head-title.medium-sub-head {
  font-size: 1.375em;
  font-style: italic;
}
.sub-head-title.small-sub-head {
  font-size: 1.125em;
  font-weight: 600;
}
.sub-head-title.optional-sub-head {
  font-size: 1.5em;
  font-weight: 300;
  font-style: italic;
  margin-bottom: 5px;
}
@media screen and (max-width: 320px) {
  .sub-head-title.optional-sub-head {
    font-size: 0.875em;
    margin-bottom: 2px;
  }
}

.text-default {
  font-size: 1.125em;
  line-height: 30px;
  color: #404041;
  margin-bottom: 20px;
}
@media screen and (max-width: 320px) {
  .text-default {
    font-size: 0.6875em;
    line-height: 16px;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 320px) {
  .text-default span {
    font-size: 10px !important;
    line-height: 16px !important;
  }
}
.text-default.padding-top {
  padding-top: 20px;
}
.text-default a {
  color: #84628d;
}
.text-default a:hover {
  color: #84628d;
}
.text-default .videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}
.text-default .videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
.text-default p .text-default iframe {
  width: 100%;
  border: none;
}
.text-default iframe {
  width: 100%;
  border: none;
}

.box-text:after {
  content: "";
  display: table;
  clear: both;
}
@media screen and (max-width: 640px) {
  .box-text.whit-list .thumbnail-photo {
    display: none;
  }
}
.box-text img {
  width: 100%;
}
.box-text .mapWrapper {
  position: relative;
  padding-bottom: 75%;
  height: 0;
}
.box-text .mapWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
.box-text img.alignleft, .box-text figure.alignleft {
  margin-right: 30px;
}
.box-text img.alignright, .box-text figure.alignright {
  margin-left: 30px;
}
.box-text img.aligncenter, .box-text figure.aligncenter {
  margin-left: auto;
  margin-right: auto;
}

.bg-purple-square {
  background-color: #3c3347;
  background-image: url("../images/bg_spa_banner_intro.jpg");
  background-position: bottom;
  background-repeat: repeat-x;
}

.bg-grey-explorer {
  background-color: #6b6b6c;
  background-image: url("../images/bg_explorer.jpg");
  background-size: 100% 100%;
}

.bg-beige {
  background-image: url("../images/bg_beige.jpg");
}

/*.bg-blue-diamond {
  background-image: url("../images/bg_blue_diamond_light.png");
  background-repeat: repeat-x;
  background-color: #fff;
  border-top: 1px solid #cccecf;
}*/

.bg-beige-diamond {
  background-image: url("../images/bg_beige_diamond.png");
  background-repeat: repeat-x;
  background-color: #fdf5e9;
  border-top: 1px solid #cccecf;
}

.bg-green-diamond {
  background-image: url("../images/bg_green_diamond.png");
  background-repeat: repeat-x;
  background-color: #f2f2ee;
  border-top: 1px solid #cccecf;
}

.bg-purple-diamond {
  background-image: url("../images/bg_purple_diamond.png");
  background-repeat: repeat-x;
  background-color: #f2eff5;
  border-top: 1px solid #cccecf;
}

.bg-celeste-diamond {
  background-image: url("../images/bg_celeste_diamond.png");
  background-repeat: repeat-x;
  background-color: #eff2fb;
  border-top: 1px solid #cccecf;
}

.bg-blue-diamond-light {
  background-image: url("../images/bg_blue_diamond_light.png");
  background-repeat: repeat-x;
  background-color: #fff;
}

.bg-beige-diamond-light {
  background-image: url("../images/bg_beige_diamond_light.png");
  background-repeat: repeat-x;
  background-color: #fdf5e9;
}

.bg-green-diamond-light {
  background-image: url("../images/bg_green_diamond_light.png");
  background-repeat: repeat-x;
  background-color: #f2f2ee;
}

.bg-purple-diamond-light {
  background-image: url("../images/bg_purple_diamond_light.png");
  background-repeat: repeat-x;
  background-color: #f2eff5;
}

.bg-celeste-diamond-light {
  background-image: url("../images/bg_celeste_diamond_light.png");
  background-repeat: repeat-x;
  background-color: #eff2fb;
}

.bg-autumn {
  background-image: url("../images/testimonial_autumn.jpg");
  background-repeat: repeat-x;
  background-position: center center;
  background-size: cover;
}

.bg-spring {
  background-image: url("../images/testimonial_spring.jpg");
  background-repeat: repeat-x;
  background-position: center center;
  background-size: cover;
}

.bg-summer {
  background-image: url("../images/testimonial_summer.jpg");
  background-position: center center;
  background-repeat: repeat-x;
  background-size: cover;
}

.bg-winter {
  background-image: url("../images/testimonial_winter.jpg");
  background-repeat: repeat-x;
  background-position: center center;
  background-size: cover;
}

.remove-borders {
  border: none;
}

  @media screen and (max-width: 640px) {
    .home-template .flexslider.full-width figcaption {
      margin-top: 0px;
      margin-left: auto;
      margin-right: auto;
      display: block;
      position: relative;
      right: 0;
    }
  }
  @media screen and (max-width: 425px) {
    body.home .responsive-image.slider-size.slider-gradient {
      background-position: 75% !important;
    }
  }
  .footer-slider {
    height: 450px;
    position: relative;
  }
  @media screen and (max-width: 640px) {
    .footer-slider {
      height: 600px;
    }
  }
  @media screen and (max-width: 450px) {
    .footer-slider {
      height: 770px;
    }
  }
  .footer-slider ul {
    height: 100%;
  }
  .footer-slider ul li {
    height: 100%;
    display: block;
  }
  .footer-slider ul li figure {
    height: 100%;
  }
  .footer-slider ul li figure img {
    display: block;
    width: 100%;
    height: 100%;
    max-height: 450px;
  }
  
  .footer-medium {
    border-bottom: 1px solid #62cdf6;
    height: 225px;
    position: absolute;
    right: 0px;
    bottom: 0px;
    left: 0px;
    padding-top: 50px;
  }
  @media screen and (max-width: 640px) {
    .footer-medium {
      height: 300px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  @media screen and (max-width: 450px) {
    .footer-medium {
      height: 470px;
      padding-left: 10px;
      padding-right: 10px;
    }
    .footer-medium .box-icon-weather {
      text-align: right;
    }
    .footer-medium .box-icon-weather i {
      padding-right: 20px;
    }
  }
  @media screen and (max-width: 768px) {
    .footer-medium .box-info .ranking i {
      font-size: 1.5em;
    }
  }
  @media screen and (max-width: 1024px) {
    .footer-medium {
      font-weight: 300;
      font-size: .9em;
    }
    .footer-medium .weather-plugin i {
      font-size: 72px;
    }
    .footer-medium .box-temp {
      font-size: 27px;
    }
    .footer-medium .box-temp .currently {
      font-size: 19px;
    }
    .footer-medium .box-info p {
      line-height: 20px;
    }
    .footer-medium .box-info .ranking i {
      font-size: 1.75em;
    }
  }
  
  .box-info {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 23.23176%;
    text-align: center;
  }
  .box-info:last-child {
    margin-right: 0;
  }
  @media screen and (max-width: 450px) {
    .box-info {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 100%;
    }
    .box-info:last-child {
      margin-right: 0;
    }
  }
  @media screen and (max-width: 640px) {
    .box-info {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 48.82117%;
      min-height: 100px;
    }
    .box-info:last-child {
      margin-right: 0;
    }
    .box-info:nth-child(2n) {
      margin-right: 0;
    }
    .box-info:nth-child(2n+1) {
      clear: left;
    }
  }
  .box-info .title-box-info {
    margin-bottom: 15px;
  }
  @media screen and (max-width: 320px) {
    .box-info .title-box-info {
      font-size: 1.5em;
      margin-bottom: 7px;
    }
  }
  .box-info p {
    margin-bottom: 10px;
  }
  .box-info .ranking i {
    margin-right: 7px;
    margin-left: 7px;
  }
  .box-info .rank-text {
    padding-left: 50px;
    padding-right: 50px;
  }
  @media screen and (max-width: 640px) {
    .box-info .rank-text {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  @media screen and (max-width: 320px) {
    .box-info .rank-text {
      font-size: 1em;
      line-height: 30px;
    }
  }
  
  @media screen and (max-width: 640px) {
    .box-donation p {
      display: none;
    }
  }
  
  .footer-bottom {
    height: 106px;
    text-align: center;
    padding-top: 40px;
  }
  
  .landing .content {
    max-width: 73.125em;
    margin-left: auto;
    margin-right: auto;
    padding: 0 16px;
    margin-top: 78px;
  }
  .landing .content:after {
    content: "";
    display: table;
    clear: both;
  }
  @media screen and (max-width: 640px) {
    .landing .content {
      padding: 0 40px;
    }
  }
  .landing .box-content {
    margin-top: 28px;
    margin-bottom: 110px;
    min-height: 775px;
    height: 100%;
    position: relative;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 640px) {
    .landing .box-content {
      width: 100%;
    }
  }
  .landing p {
    padding-top: 30px;
    width: 950px;
    margin: 0 auto 35px auto;
    position: relative;
    z-index: 2;
  }
  @media screen and (max-width: 640px) {
    .landing p {
      width: 100%;
    }
  }
  
  .image-background {
    position: absolute;
    z-index: 0;
    top: 0;
    width: 100%;
    height: inherit;
  }
  .image-background .image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 340px;
  }
  @media screen and (min-width: 641px) {
    .image-background .image.full-height {
      height: inherit;
    }
  }
  @media screen and (max-width: 640px) {
    .image-background {
      position: static;
      max-height: 450px;
      margin-bottom: 34px;
    }
  }
  
  .form-landing_wrapper {
    width: 394px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
  }
  .form-landing_wrapper form {
    padding: 11px 15px 12px 15px;
  }
  @media screen and (max-width: 640px) {
    .form-landing_wrapper {
      width: 100%;
      margin-bottom: 60px;
    }
  }
  .form-landing_wrapper input {
    width: 100%;
    margin-bottom: 13px;
  }
  .form-landing_wrapper input.input-zip-code {
    margin-bottom: 2px;
  }
  .form-landing_wrapper label {
    margin-bottom: 6px;
    display: block;
  }
  
  .landing-page.type-b .landing .content {
    margin-top: 89px;
  }
  .landing-page.type-b .landing .form-landing_wrapper {
    padding-top: 17px;
  }
  .landing-page.type-b .landing .form-landing_wrapper input {
    margin-bottom: 24px;
  }
  
  .landing-page.type-a.type-1 .box-content, .landing-page.type-b.type-1 .box-content {
    min-height: 10px;
  }
  .landing-page.type-a.type-1 .text p, .landing-page.type-b.type-1 .text p {
    width: 100%;
  }
  .landing-page.type-a.type-1 .image-background, .landing-page.type-a.type-1 .text, .landing-page.type-a.type-1 .form-landing_wrapper, .landing-page.type-b.type-1 .image-background, .landing-page.type-b.type-1 .text, .landing-page.type-b.type-1 .form-landing_wrapper {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 31.76157%;
    position: static;
  }
  .landing-page.type-a.type-1 .image-background:last-child, .landing-page.type-a.type-1 .text:last-child, .landing-page.type-a.type-1 .form-landing_wrapper:last-child, .landing-page.type-b.type-1 .image-background:last-child, .landing-page.type-b.type-1 .text:last-child, .landing-page.type-b.type-1 .form-landing_wrapper:last-child {
    margin-right: 0;
  }
  .landing-page.type-a.type-1 .image-background:nth-child(3n), .landing-page.type-a.type-1 .text:nth-child(3n), .landing-page.type-a.type-1 .form-landing_wrapper:nth-child(3n), .landing-page.type-b.type-1 .image-background:nth-child(3n), .landing-page.type-b.type-1 .text:nth-child(3n), .landing-page.type-b.type-1 .form-landing_wrapper:nth-child(3n) {
    margin-right: 0;
  }
  .landing-page.type-a.type-1 .image-background:nth-child(3n+1), .landing-page.type-a.type-1 .text:nth-child(3n+1), .landing-page.type-a.type-1 .form-landing_wrapper:nth-child(3n+1), .landing-page.type-b.type-1 .image-background:nth-child(3n+1), .landing-page.type-b.type-1 .text:nth-child(3n+1), .landing-page.type-b.type-1 .form-landing_wrapper:nth-child(3n+1) {
    clear: left;
  }
  @media screen and (max-width: 640px) {
    .landing-page.type-a.type-1 .image-background, .landing-page.type-a.type-1 .text, .landing-page.type-a.type-1 .form-landing_wrapper, .landing-page.type-b.type-1 .image-background, .landing-page.type-b.type-1 .text, .landing-page.type-b.type-1 .form-landing_wrapper {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 100%;
    }
    .landing-page.type-a.type-1 .image-background:last-child, .landing-page.type-a.type-1 .text:last-child, .landing-page.type-a.type-1 .form-landing_wrapper:last-child, .landing-page.type-b.type-1 .image-background:last-child, .landing-page.type-b.type-1 .text:last-child, .landing-page.type-b.type-1 .form-landing_wrapper:last-child {
      margin-right: 0;
    }
  }
  @media screen and (max-width: 640px) {
    .landing-page.type-a.type-1 .form-landing_wrapper, .landing-page.type-b.type-1 .form-landing_wrapper {
      padding: 0;
    }
  }
  
  .landing-page-content .sub-head-title.large-sub-head {
    margin-top: 0;
  }
  
  .testimonial-area {
    text-align: center;
    padding-top: 80px;
    padding-bottom: 80px;
  }
  @media screen and (max-width: 768px) {
    .testimonial-area {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }
  @media screen and (max-width: 640px) {
    .testimonial-area {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }
  .testimonial-area .text {
    padding-left: 195px;
    padding-right: 196px;
  }
  .testimonial-area .text p{
    color:#ffffff;
  }
  @media screen and (max-width: 768px) {
    .testimonial-area .text {
      padding-left: 60px;
      padding-right: 60px;
    }
  }
  @media screen and (max-width: 640px) {
    .testimonial-area .text {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
  .testimonial-area .author {
    margin-top: 10px;
  }
  
  .banner-info {
    padding-bottom: 30px;
  }
  .banner-info .bg-image {
    padding: 10px;
  }
  @media screen and (max-width: 640px) {
    .banner-info .bg-image {
      min-height: 250px;
    }
  }
  .banner-info .box-content {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 640px) {
    .banner-info .box-content {
      padding: 10px;
    }
  }
  .banner-info .box-content .container {
    margin-bottom: 30px;
  }
  .banner-info .title-module {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .banner-info input {
    height: 40px;
    width: 190px;
    margin-right: 14px;
  }
  @media screen and (max-width: 640px) {
    .banner-info input {
      width: 100%;
      margin-bottom: 9px;
      height: 60px;
    }
  }
  @media screen and (max-width: 320px) {
    .banner-info input {
      height: 30px;
    }
  }
  .banner-info .button-default, .banner-info .ee_view_cart {
    height: 40px;
    vertical-align: bottom;
    line-height: 35px;
  }
  @media screen and (max-width: 640px) {
    .banner-info .button-default, .banner-info .ee_view_cart {
      width: 100%;
    }
  }
  @media screen and (max-width: 320px) {
    .banner-info .button-default, .banner-info .ee_view_cart {
      height: 30px;
      line-height: 28px;
    }
  }
  .banner-info .one-line {
    text-align: center;
    padding-top: 35px;
  }
  .banner-info .one-line .title-module, .banner-info .one-line .button-default, .banner-info .one-line .ee_view_cart {
    display: inline-block;
    vertical-align: middle;
  }
  
  .recent-photos {
    padding-bottom: 30px;
    padding-top: 30px;
  }
  @media screen and (max-width: 1024px) {
    .recent-photos {
      padding-left: 55px;
      padding-right: 55px;
    }
  }
  @media screen and (max-width: 640px) {
    .recent-photos {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .recent-photos .title-recent-photos {
    margin-bottom: 25px;
  }
  .recent-photos .title-recent-photos span {
    position: relative;
    z-index: 2;
  }
  .recent-photos .slides .items-slide {
    max-width: 166px;
    width: 46%;
    margin-right: 5%;
    display: inline-block;
    vertical-align: top;
  }
  @media screen and (max-width: 640px) {
    .recent-photos .slides .items-slide {
      margin-right: 0px;
      margin-bottom: 20px;
      display: block;
      width: 100%;
    }
  }
  .recent-photos .slides .items-slide:nth-last-child(1) {
    margin-right: 0;
  }
  .recent-photos .slides li {
    margin-right: 30px;
  }
  @media screen and (max-width: 640px) {
    .recent-photos .slides li {
      min-width: 90px;
      width: 165px;
      margin-right: 20px;
    }
  }
  .recent-photos .flexslider {
    margin-bottom: 25px;
  }
  .recent-photos ol {
    display: none;
  }
  .hero-slider{
      .swiper-button-prev, .swiper-button-next{
        box-shadow: 3px 8px 16px 0 rgba(0, 0, 0, 0.6);
        background-color: #84628d;
        width: 50px;
        height: 50px;
        border-radius: 100%;
        position: absolute;
        font-size: 27px;
        color: #ffffff;
        background-image: none;

        i{      
          &:before{
            border-color: #ffffff;/* 979797*/
            width: 13px;
            height: 13px;
            border-width: 2px 2px 0 0;
            border-style: solid;
            font-size: 0;
            position: absolute;
            top: 18px;            
          }
          &.eicon-chevron-left{
            &:before{
              transform: rotate(-135deg);
              right: 16px;
            }
          }  
          &.eicon-chevron-right{
            &:before{
              transform: rotate(45deg);
              right: 20px;
            }
          }   

        }
      }
  }
  
  .hero-slider .flex-viewport {
    width: 100%;
  }
  .hero-slider .flex-control-nav {
    bottom: 17px;
  }
  .hero-slider .slides li {
    position: relative;
  }
  .hero-slider figcaption {
    position: absolute;
    right: 80px;
    bottom: 90px;
  }
  @media screen and (max-width: 640px) {
    .hero-slider figcaption {
      right: 25px;
      bottom: 75px;
    }
  }
  
  .sub-headline-text {
    float: right;
    width: 245px;
  }
  
  .list-events.home-boxes .event:nth-child(4n) {
    margin-right: 0;
  }
  .list-events.home-boxes .event:nth-child(4n+1) {
    clear: left;
  }
  .list-events.coming-up-events .event:nth-child(4n-1) {
    margin-right: 0;
  }
  @media screen and (max-width: 1024px) {
    .list-events.coming-up-events .event:nth-child(4n-1) {
      margin: inherit;
    }
  }
  .list-events.coming-up-events .event:nth-child(4n) {
    clear: left;
  }
  @media screen and (max-width: 1024px) {
    .list-events.coming-up-events .event:nth-child(4n) {
      clear: none;
    }
  }
  .list-events .event {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 23.23176%;
    margin-bottom: 30px;
  }
  .list-events .event:last-child {
    margin-right: 0;
  }
  @media screen and (max-width: 640px) {
    .list-events .event {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 100%;
    }
    .list-events .event:last-child {
      margin-right: 0;
    }
    .list-events .event:nth-child(n) {
      margin-right: 0;
    }
  }
  @media screen and (max-width: 1024px) {
    .list-events .event {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 48.82117%;
    }
    .list-events .event:last-child {
      margin-right: 0;
    }
    .list-events .event:nth-child(2n) {
      margin-right: 0;
    }
    .list-events .event:nth-child(2n+1) {
      clear: left;
    }
  }
  @media screen and (max-width: 640px) {
    .list-events .event figure {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 100%;
    }
    .list-events .event figure:last-child {
      margin-right: 0;
    }
  }
  @media screen and (max-width: 640px) {
    .list-events .event .box-content {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 100%;
    }
    .list-events .event .box-content:last-child {
      margin-right: 0;
    }
    .list-events .event .box-content:nth-child(n) {
      margin-right: 0;
    }
  }
  .list-events .event .box-content .espresso-cart-link-container {
    display: inline-block;
  }
  .list-events .event .box-content .espresso-cart-link-container a {
    margin: 0 auto;
  }
  .list-events .event .title-event {
    margin-bottom: 10px;
  }
  @media screen and (max-width: 640px) {
    .list-events .event .title-event {
      margin-bottom: 25px;
    }
  }
  @media screen and (max-width: 320px) {
    .list-events .event .title-event {
      margin-bottom: 12px;
    }
  }
  .list-events .event .excerpt-event {
    line-height: 1.5;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 320px) {
    .list-events .event .excerpt-event {
      margin-bottom: 10px;
    }
  }
  @media screen and (max-width: 640px) {
    .list-events .event.type-2 {
      width: 46%;
      margin-right: 29px;
    }
    .list-events .event.type-2:nth-child(2n) {
      margin-right: 0;
    }
    .list-events .event.type-2:nth-child(2n+1) {
      clear: left;
    }
  }
  @media screen and (max-width: 450px) {
    .list-events .event.type-2 {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 100%;
    }
    .list-events .event.type-2:last-child {
      margin-right: 0;
    }
  }
  .list-events .event.type-2 figure, .list-events .event.type-2 .box-content {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%;
    max-height: 195px;
  }
  .list-events .event.type-2 figure:last-child, .list-events .event.type-2 .box-content:last-child {
    margin-right: 0;
  }
  @media screen and (max-width: 640px) {
    .list-events .event.type-2 .excerpt-event {
      margin-bottom: 23px;
    }
  }
  @media screen and (max-width: 320px) {
    .list-events .event.type-2 .excerpt-event {
      margin-bottom: 5px;
    }
  }
  .list-events .event.type-2 .learn-more, .list-events .event.type-2 .add-event {
    margin-right: 9px;
  }
  @media screen and (max-width: 640px) {
    .list-events .event.type-2 .learn-more, .list-events .event.type-2 .add-event {
      width: 150px;
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 5px;
    }
  }
  @media screen and (max-width: 320px) {
    .list-events .event.type-2 .learn-more, .list-events .event.type-2 .add-event {
      width: 76px;
    }
  }
  @media screen and (max-width: 1024px) {
    .list-events .event:nth-child(2n+1) {
      clear: none;
    }
  }
  @media screen and (max-width: 640px) {
    .list-events .event:nth-child(2n+1) {
      clear: none;
    }
  }
  
  @media screen and (max-width: 1024px) {
    .home-coming-up .list-coming-up.thumbnail-view li.seperator {
      display: none;
    }
  }
  
  @media screen and (max-width: 1024px) {
    .list-events.coming-up-events .event:nth-child(4n-1) {
      margin-right: 2.35765%;
    }
  }
  
  .event-list-box {
    min-height: 160px;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 640px) {
    .event-list-box {
      min-height: initial;
    }
  }
  
  .event-small-list-box {
    min-height: 110px;
  }
  
  .event-list-button {
    clear: both;
  }
  
  .event-list-description {
    
    font-size: 1em;
    color: #404041;
    line-height: 150%;
  }
  
  @media screen and (max-width: 640px) {
    .list-events .event.type-2:nth-child(2n+1) {
      clear: none;
    }
  }
  
  @media screen and (max-width: 640px) {
    .list-events .event.type-2:nth-child(2n) {
      margin-right: 2.35765%;
    }
  }
  
  .list-events .related-event-size {
    width: 21.23176%;
    margin-bottom: 30px;
    float: none;
    display: inline-block;
    margin-right: 1.35765%;
    height: 390px;
  }
  @media screen and (max-width: 640px) {
    .list-events .related-event-size {
      height: 490px;
    }
  }
  @media screen and (max-width: 543px) {
    .list-events .related-event-size {
      width: 100% !important;
    }
  }
  
  .center-content {
    text-align: center;
  }
  
  .default-article {
    max-width: 73.125em;
    margin-left: auto;
    margin-right: auto;
    padding-top: 50px;
    padding-bottom: 20px;
  }
  .default-article:after {
    content: "";
    display: table;
    clear: both;
  }
  @media screen and (max-width: 1024px) {
    .default-article {
      padding-left: 5%;
      padding-right: 5%;
    }
  }
  @media screen and (max-width: 640px) {
    .default-article {
      padding-left: 35px;
      padding-right: 35px;
    }
  }
  @media screen and (max-width: 320px) {
    .default-article {
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 25px;
    }
  }
  .default-article.image-left .box-article {
    margin-right: 0;
  }
  .default-article.image-right .box-image {
    float: right;
    margin-right: 0;
  }
  .default-article .content-box-article p {
    margin-bottom: 10px;
    font-weight: 300;
  }
  .default-article .content-box-article a.button-default, .default-article .content-box-article a.ee_view_cart {
    color: #fff;
  }
  @media screen and (max-width: 640px) {
    .default-article .content-box-article {
      text-align: center;
    }
  }
  .default-article .content-box-article ul {
    list-style-image: url("../images/list_diamond.png");
    text-align: left;
    list-style-position: inside;
  }
  .default-article .content-box-article ul li {
    line-height: 30px;
    margin-bottom: 11px;
  }
  @media screen and (max-width: 640px) {
    .default-article .content-box-article ul li {
      margin-bottom: 20px;
      line-height: 37px;
    }
  }
  @media screen and (max-width: 320px) {
    .default-article .content-box-article ul li {
      margin-bottom: 10px;
      line-height: 13px;
    }
  }
  .default-article .box-image, .default-article .box-article {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%;
  }
  .default-article .box-image:last-child, .default-article .box-article:last-child {
    margin-right: 0;
  }
  @media screen and (max-width: 640px) {
    .default-article .box-image, .default-article .box-article {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 100%;
    }
    .default-article .box-image:last-child, .default-article .box-article:last-child {
      margin-right: 0;
    }
  }
  .default-article .box-article .large-sub-head,
  .default-article .box-article .sub-head-title {
    margin-top: 0;
  }
  .default-article .box-image figure {
    width: 100%;
    height: 100%;
  }
  .default-article .box-image figure img {
    width: 100%;
    height: auto;
  }
  @media screen and (max-width: 640px) {
    .default-article .box-image {
      margin-bottom: 27px;
      margin-right: 0;
    }
  }
  @media screen and (max-width: 320px) {
    .default-article .box-image {
      margin-bottom: 8px;
    }
  }
  .default-article .title-article {
    margin-bottom: 16px;
  }
  .default-article .button-default, .default-article .ee_view_cart {
    margin-top: 36px;
  }
  @media screen and (max-width: 320px) {
    .default-article .button-default, .default-article .ee_view_cart {
      height: 30px;
      font-size: 0.6875em;
      line-height: 30px;
      margin-top: 5px;
      width: 75px;
    }
  }
  
  .menu-slider.flexslider {
    padding-top: 35px;
    padding-bottom: 50px;
  }
  .menu-slider .slides img {
    height: 100%;
  }
  .menu-slider .slides > li {
    margin-right: 29px;
  }
  .menu-slider .box-date-weather {
    margin-bottom: 10px;
    height: 35px;
  }
  .menu-slider .box-date-weather:after {
    content: "";
    display: table;
    clear: both;
  }
  .menu-slider .box-date-weather .date {
    text-align: left;
    float: left;
    padding-top: 5px;
  }
  .menu-slider .box-date-weather .icons {
    vertical-align: middle;
    text-align: right;
  }
  .menu-slider .box-date-weather .icons img {
    display: inline-block;
    max-width: 30px;
    max-height: 30px;
  }
  @media screen and (max-width: 1024px) {
    .menu-slider .box-date-weather .icons img {
      max-width: 20px;
    }
  }
  .menu-slider .flex-control-nav.flex-control-paging {
    display: none;
  }
  .menu-slider figure {
    margin-bottom: 15px;
  }
  @media screen and (min-width: 640px) {
    .menu-slider figure {
      margin-bottom: 7px;
    }
  }
  .menu-slider .title-coming {
    margin-bottom: 10px;
    height: 82px;
  }
  @media screen and (max-width: 320px) {
    .menu-slider .title-coming {
      margin-bottom: 5px;
    }
  }
  @media screen and (max-width: 640px) {
    .menu-slider .title-coming {
      height: 102px;
    }
  }
  .menu-slider .details-coming {
    margin-bottom: 10px;
  }
  .menu-slider .list-recipes {
    line-height: 1.5;
    padding-left: 15px;
    padding-right: 15px;
  }
  .menu-slider .list-recipes > li {
    margin-bottom: 15px;
  }
  @media screen and (max-width: 640px) {
    .menu-slider .list-recipes > li {
      margin-bottom: 20px;
    }
  }
  @media screen and (max-width: 320px) {
    .menu-slider .list-recipes > li {
      margin-bottom: 7px;
    }
  }
  .menu-slider .title-menus {
    margin-bottom: 10px;
  }
  @media screen and (max-width: 640px) {
    .menu-slider .title-menus {
      margin-bottom: 7px;
    }
  }
  @media screen and (max-width: 320px) {
    .menu-slider .title-menus {
      margin-bottom: 2px;
    }
  }
  
  .page-template-template-dining .container-gutter {
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (max-width: 1250px) {
    .page-template-template-dining .container-gutter {
      margin-right: 40px;
      margin-left: 40px;
    }
  }
  @media screen and (max-width: 640px) {
    .page-template-template-dining .container-gutter {
      margin-left: auto;
      margin-right: auto;
    }
  }
  
  .tree-columns .item-grid {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 31.76157%;
    text-align: center;
    padding-bottom: 40px;
    margin-bottom: 40px;
  }
  .tree-columns .item-grid:last-child {
    margin-right: 0;
  }
  @media screen and (min-width: 641px) {
    .tree-columns .item-grid:nth-child(3n) {
      margin-right: 0;
    }
    .tree-columns .item-grid:nth-child(3n+1) {
      clear: left;
    }
  }
  @media screen and (max-width: 640px) {
    .tree-columns .item-grid {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 48.82117%;
      margin-bottom: 10px;
    }
    .tree-columns .item-grid:last-child {
      margin-right: 0;
    }
    .tree-columns .item-grid:nth-child(2n) {
      margin-right: 0;
    }
    .tree-columns .item-grid:nth-child(2n+1) {
      clear: left;
    }
  }
  @media screen and (max-width: 450px) {
    .tree-columns .item-grid {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 100%;
      margin-bottom: 10px;
    }
    .tree-columns .item-grid:last-child {
      margin-right: 0;
    }
  }
  .tree-columns .item-grid figure {
    width: 100%;
    height: 220px;
    overflow: hidden;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 640px) {
    .tree-columns .item-grid figure {
      height: 180px;
    }
  }
  @media screen and (max-width: 320px) {
    .tree-columns .item-grid figure {
      margin-bottom: 5px;
      height: 90px;
    }
  }
  .tree-columns .item-grid figure .responsive-image {
    height: 100%;
  }
  .tree-columns .item-grid figure img {
    display: block;
    width: 100%;
    height: auto;
  }
  .tree-columns .item-grid .details-box {
    margin-bottom: 15px;
  }
  
  .video {
    margin-bottom: 80px;
  }
  @media screen and (max-width: 640px) {
    .video {
      margin-bottom: 40px;
    }
  }
  
  .thumbnail-video.large-video {
    width: 750px;
    height: 360px;
  }
  .thumbnail-video.full-widht {
    width: 100%;
    height: 560px;
  }
  .thumbnail-video.video-responsive {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    padding-top: 25px;
    height: 0;
  }
  .thumbnail-video.video-responsive iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .title-accordion {
    padding-top: 40px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 320px) {
    .title-accordion {
      padding-top: 19px;
      padding-bottom: 14px;
    }
  }
  
  .article-accomodation {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%;
    padding-left: 0;
    padding-right: 0;
  }
  .article-accomodation:last-child {
    margin-right: 0;
  }
  .article-accomodation:nth-child(2n) {
    margin-right: 0;
  }
  .article-accomodation:nth-child(2n+1) {
    clear: left;
  }
  @media screen and (max-width: 640px) {
    .article-accomodation {
      width: 45%;
      margin-right: 10%;
      margin-left: 0;
      padding: 0;
    }
    .article-accomodation:nth-child(2n) {
      margin-right: 0;
    }
    .article-accomodation:nth-child(2n+1) {
      clear: left;
    }
  }
  @media screen and (max-width: 320px) {
    .article-accomodation {
      padding-left: 0px;
      padding-left: 0px;
    }
  }
  .article-accomodation ul {
    padding-left: 15px;
  }
  .article-accomodation .button-default, .article-accomodation .ee_view_cart {
    margin-top: 20px;
    height: 30px;
  }
  @media screen and (max-width: 640px) {
    .article-accomodation .button-default, .article-accomodation .ee_view_cart {
      height: auto;
    }
  }
  
  .form-group-inquiries {
    margin-top: 50px;
  }
  .form-group-inquiries .box-left, .form-group-inquiries .box-right {
    margin-bottom: 5em;
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%;
  }
  .form-group-inquiries .box-left:last-child, .form-group-inquiries .box-right:last-child {
    margin-right: 0;
  }
  @media screen and (max-width: 640px) {
    .form-group-inquiries .box-left, .form-group-inquiries .box-right {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 100%;
    }
    .form-group-inquiries .box-left:last-child, .form-group-inquiries .box-right:last-child {
      margin-right: 0;
    }
  }
  .form-group-inquiries .box-right {
    margin-right: 0;
  }
  .form-group-inquiries input, .form-group-inquiries textarea {
    width: 100%;
  }
  .form-group-inquiries textarea {
    resize: none;
    height: 350px;
    margin-bottom: 28px;
  }
  @media screen and (max-width: 320px) {
    .form-group-inquiries textarea {
      margin-bottom: 14px;
      height: 295px;
    }
  }
  
  .form-contact, .extra-contact {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%;
  }
  .form-contact:last-child, .extra-contact:last-child {
    margin-right: 0;
  }
  .form-contact:last-child, .extra-contact:last-child {
    margin-bottom: 30px;
  }
  @media screen and (max-width: 640px) {
    .form-contact, .extra-contact {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 100%;
    }
    .form-contact:last-child, .extra-contact:last-child {
      margin-right: 0;
    }
  }
  
  form.full-width, .gform_wrapper.full-width_wrapper {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%;
  }
  form.full-width:last-child, .gform_wrapper.full-width_wrapper:last-child {
    margin-right: 0;
  }
  
  .form-contact {
    padding-right: 20px;
  }
  @media screen and (max-width: 640px) {
    .form-contact {
      padding-right: 0;
    }
  }
  .form-contact input, .form-contact textarea {
    width: 100%;
  }
  .form-contact textarea {
    height: 210px;
    resize: none;
  }
  .form-contact .button-default, .form-contact .ee_view_cart {
    float: right;
  }
  @media screen and (max-width: 640px) {
    .form-contact .button-default, .form-contact .ee_view_cart {
      width: 100%;
      float: none;
      margin-top: 20px;
    }
  }
  .form-contact .legend-text {
    margin-bottom: 15px;
  }
  @media screen and (max-width: 320px) {
    .form-contact .row-form, .form-contact .form-additional-spa .gfield, .form-additional-spa .form-contact .gfield, .form-contact .form-additional-spa .gform_footer, .form-additional-spa .form-contact .gform_footer {
      margin-bottom: 15px;
    }
  }
  @media screen and (max-width: 320px) {
    .form-contact .gfield_checkbox li {
      width: 100% !important;
    }
  }
  @media screen and (max-width: 640px) {
    .form-contact .gform_footer .button-landing {
      margin-top: 60px;
    }
  }
  @media screen and (max-width: 320px) {
    .form-contact .gform_footer .button-landing {
      height: 30px;
    }
  }
  
  .extra-contact {
    padding-left: 80px;
  }
  @media screen and (max-width: 640px) {
    .extra-contact {
      padding-left: 0;
    }
  }
  
  .container-single-post {
    padding-left: 95px;
  }
  @media screen and (max-width: 640px) {
    .container-single-post {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  
  @media screen and (max-width: 640px) {
    .box-post {
      padding-top: 0;
      font-size: 1em;
    }
  }
  .box-post .comment-notes {
    margin-top: 90px;
  }
  @media screen and (max-width: 320px) {
    .box-post .comment-notes {
      margin-top: 35px;
    }
  }
  @media screen and (max-width: 320px) {
    .box-post img, .box-post figure {
      max-width: 100% !important;
    }
  }
  .box-post img.alignleft, .box-post figure.alignleft {
    margin-right: 30px;
  }
  .box-post img.alignright, .box-post figure.alignright {
    margin-left: 30px;
  }
  .box-post img.aligncenter, .box-post figure.aligncenter {
    margin-left: auto;
    margin-right: auto;
  }
  
  section.image-blog-banner {
    width: 100%;
    height: 450px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  @media screen and (max-width: 640px) {
    section.image-blog-banner {
      max-height: 320px;
    }
  }
  @media screen and (max-width: 640px) {
    section.image-blog-banner {
      display: none;
    }
  }
  @media screen and (max-width: 640px) {
    section.image-blog-banner.mobile {
      display: block;
    }
  }
  
  .box-post {
    padding-top: 2em;
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 65.88078%;
    padding-left: 95px;
  }
  .box-post:last-child {
    margin-right: 0;
  }
  @media screen and (max-width: 640px) {
    .box-post {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 100%;
      padding-left: 0;
    }
    .box-post:last-child {
      margin-right: 0;
    }
  }
  .box-post.box-single-post {
    padding-left: 0px;
  }
  @media screen and (max-width: 640px) {
    .box-post.box-single-post .container-single-post {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .box-post img {
    max-width: 100%;
    height: auto;
  }
  
  body.single-post {
    padding-left: 0;
    padding-right: 0;
  }
  /*
  @media screen and (max-width: 640px) {
    body.single-post {
      padding-top: 64px;
    }
  }
  @media screen and (max-width: 320px) {
    body.single-post {
      padding-top: 43px;
    }
  }*/
  
  .single-post {
    margin-bottom: 0;
    word-wrap: break-word;
  }
  @media screen and (max-width: 640px) {
    .single-post {
      margin-bottom: 0;
      padding-top: 30px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .single-post figure {
    max-width: 100%;
  }
  .single-post figure img {
    display: block;
  }
  @media screen and (max-width: 640px) {
    .single-post .title-large, .single-post .title-medium, .single-post .text-default {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  
  .single-post.primary-post figure {
    display: none;
  }
  @media screen and (max-width: 640px) {
    .single-post.primary-post figure {
      display: block;
    }
  }
  
  .single-post.normal {
    position: relative;
    padding-bottom: 60px;
  }
  @media screen and (max-width: 640px) {
    .single-post.normal {
      padding-bottom: 100px;
    }
  }
  .single-post.normal .text-default p {
    padding: 0px;
  }
  .single-post.normal .single-post-extra {
    bottom: 0;
    left: 0;
    right: 0;
  }
  @media screen and (max-width: 640px) {
    .single-post.normal .single-post-extra {
      padding-left: 15px;
      padding-right: 15px;
      font-size: 1em;
    }
  }
  
  .share {
    float: right;
  }
  .share span {
    margin-right: 15px;
  }
  
  .text-default {
    font-size: 1.25em;
    font-weight: 300;
    line-height: 150%;
  }
  @media screen and (max-width: 640px) {
    .text-default {
      font-size: 1.375em;
      line-height: 140%;
    }
  }
  .text-default strong {
    font-weight: 500;
  }
  
  @media screen and (max-width: 640px) {
    .blog-single .large-blog {
      display: none;
    }
  }
  .blog-single .head-post .title-large {
    padding-left: 0;
    padding-right: 0;
  }
  
  @media screen and (max-width: 640px) {
    .box-inside {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  @media screen and (max-width: 640px) {
    .box-inside figure {
      width: 100% !important;
    }
  }
  
  .return-to {
    padding-top: 20px;
  }
  @media screen and (max-width: 640px) {
    .return-to {
      padding-left: 20px;
      padding-top: 40px;
      padding-bottom: 20px;
    }
  }
  @media screen and (max-width: 320px) {
    .return-to {
      padding-left: 20px;
      padding-top: 40px;
      padding-bottom: 20px;
    }
  }
  .return-to .icon-arrow-left {
    margin-right: 10px;
  }
  
  .tag-post, .share, .social-icon {
    display: inline-block;
  }
  
  .share {
    margin-top: 10px;
  }
  
  .social-icon a {
    vertical-align: middle;
    display: inline-block;
  }
  
  .tag-post {
    width: 300px;
    min-height: 30px;
  }
  @media screen and (max-width: 640px) {
    .tag-post {
      width: 100%;
    }
  }
  
  .single-comment {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .single-comment.reply {
    padding-left: 90px;
  }
  .single-comment .user-name, .single-comment .date-comment, .single-comment .time-comment {
    display: inline-block;
    vertical-align: middle;
  }
  
  ul.children .single-comment {
    padding-left: 90px;
  }
  
  .head-comment, .comment-form {
    margin-bottom: 20px;
  }
  .head-comment textarea, .comment-form textarea {
    border: 1px solid #6f7070;
    background: #fff;
    background-color: #f4fafd;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f4fafd), to(#fff));
    background-image: linear-gradient(#f4fafd, #fff);
    padding: 0 10px;
    height: 50px;
    border-radius: 2px;
    margin-top: 6px;
    width: 100%;
    resize: none;
    height: 135px;
  }
  .head-comment textarea:focus, .comment-form textarea:focus {
    border: 3px solid #676767;
    outline-offset: 0px;
    outline: none;
  }
  
  .number-comment {
    display: inline-block;
    vertical-align: middle;
    padding-top: 15px;
  }
  @media screen and (max-width: 640px) {
    .number-comment {
      padding-bottom: 20px;
    }
  }
  @media screen and (max-width: 320px) {
    .number-comment {
      padding-top: 0;
    }
  }
  
  .button-leave-comment {
    display: inline-block;
    float: right;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  
  .comment-post {
    margin-bottom: 40px;
  }
  .comment-post .comment-respond .comment-reply-title {
    display: none;
  }
  .comment-post .comment-respond .comment-form .form-submit input[type=submit], .comment-post .comment-respond .comment-form .logged-in-as, .comment-post .comment-respond .comment-form .form-allowed-tags {
    display: none;
  }
  .comment-post .comment-respond .comment-form .comment-form-comment label, .comment-post .comment-respond .comment-form .comment-form-author label, .comment-post .comment-respond .comment-form .comment-form-email label, .comment-post .comment-respond .comment-form .comment-form-url label {
    display: block;
    font-weight: 600;
  }
  @media screen and (max-width: 320px) {
    .comment-post .comment-respond .comment-form .comment-form-comment input, .comment-post .comment-respond .comment-form .comment-form-author input, .comment-post .comment-respond .comment-form .comment-form-email input, .comment-post .comment-respond .comment-form .comment-form-url input {
      padding: 0 !important;
    }
  }
  
  .nav-post {
    margin-bottom: 60px;
  }
  .nav-post .container-nav-arrows {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .nav-post .box-prev, .nav-post .box-next {
    width: 49%;
    display: inline-block;
    vertical-align: top;
  }
  .nav-post .nav-arrow {
    margin-bottom: 15px;
    display: block;
  }
  .nav-post .box-prev i {
    margin-right: 5px;
  }
  .nav-post .box-prev .title-post {
    padding-left: 30px;
  }
  @media screen and (max-width: 320px) {
    .nav-post .box-prev .title-post {
      padding-left: 20px;
    }
  }
  .nav-post .box-next {
    text-align: right;
  }
  .nav-post .box-next i {
    margin-left: 5px;
  }
  .nav-post .box-next .title-post {
    padding-right: 30px;
  }
  @media screen and (max-width: 320px) {
    .nav-post .box-next .title-post {
      padding-right: 20px;
    }
  }
  
  .single-post-extra {
    margin-bottom: 30px;
  }
  .single-post-extra .author, .single-post-extra .date, .single-post-extra .share {
    display: inline-block;
    vertical-align: middle;
  }
  @media screen and (max-width: 640px) {
    .single-post-extra .author, .single-post-extra .date, .single-post-extra .share {
      font-size: 1em;
    }
  }
  .single-post-extra .share {
    float: right;
  }
  @media screen and (max-width: 640px) {
    .single-post-extra .share {
      display: none;
    }
  }
  .single-post-extra .share span, .single-post-extra .share .social-icon {
    display: inline-block;
    vertical-align: middle;
  }
  .single-post-extra .share .social-icon a {
    vertical-align: top;
    display: inline-block;
  }
  
  @media screen and (max-width: 640px) {
    .blog-single .share {
      float: none;
      width: 100%;
      display: block;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  @media screen and (max-width: 640px) {
    .blog-single .social-icon a {
      display: inline-block;
      margin-right: 5px;
      vertical-align: middle;
    }
  }
  
  .head-post .title-large {
    padding-left: 0;
    padding-right: 0;
  }
  
  .section-aside {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 23.23176%;
    margin-left: 8.5298%;
    margin-left: 5.5298%;
    word-wrap: break-word;
  }
  .section-aside:last-child {
    margin-right: 0;
  }
  @media screen and (max-width: 640px) {
    .section-aside {
      display: none;
    }
  }
  .section-aside .form-signup#gform_8 button {
    color: #676767;
    background: #e6e7e8;
    border: 1px solid #969490;
    border-radius: 0;
    border-top-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    padding: 0;
  }
  .section-aside .list-popular-post, .section-aside .widget ul {
    line-height: 1.5;
    padding-left: 25px;
  }
  .section-aside .list-popular-post li, .section-aside .widget ul li {
    margin-bottom: 20px;
  }
  .section-aside .list-tags {
    list-style: none;
  }
  .section-aside .list-tags li {
    margin-bottom: 5px;
    margin-right: 10px;
  }
  
  .widget.gform_widget ul li {
    margin-bottom: 0px;
  }
  .widget.gform_widget .form-signup .gform_body {
    height: initial;
  }
  .widget.gform_widget .gform_confirmation_wrapper.form-signup div {
    color: #a3ce5d;
    text-align: center;
  }
  
  @media screen and (max-width: 640px) {
    .banner-event-details {
      min-height: 185px;
    }
  }
  
  .content-event-details {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 65.88078%;
  }
  .content-event-details p {
    font-size: 1.125em;
    line-height: 30px;
    margin-bottom: 10px;
  }
  .content-event-details:last-child {
    margin-right: 0;
  }
  @media screen and (max-width: 640px) {
    .content-event-details {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 100%;
    }
    .content-event-details:last-child {
      margin-right: 0;
    }
  }
  
  .event-slider {
    margin-bottom: 40px;
  }
  .event-slider .flexslider .slides img {
    height: auto;
  }
  
  .overview .sub-head-title {
    margin-bottom: 30px;
  }
  .overview .button-default, .overview .ee_view_cart {
    display: none;
  }
  @media screen and (max-width: 640px) {
    .overview .button-default, .overview .ee_view_cart {
      display: block;
    }
  }
  
  .accordion-event-details .title-event-accordion {
    min-height: 68px;
  }
  .accordion-event-details .content-accordion figure, .accordion-event-details .content-accordion .content-text {
    display: inline-block;
    vertical-align: top;
  }
  .accordion-event-details .content-accordion figure {
    width: 165px;
    height: 165px;
    margin-right: 30px;
  }
  @media screen and (max-width: 640px) {
    .accordion-event-details .content-accordion figure {
      float: left;
      display: block;
    }
  }
  .accordion-event-details .content-accordion .content-text {
    width: 540px;
  }
  @media screen and (max-width: 640px) {
    .accordion-event-details .content-accordion .content-text {
      width: 100%;
      display: block;
    }
  }
  
  .aside-event-details {
    margin-left: 8.5298%;
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 23.23176%;
  }
  .aside-event-details:last-child {
    margin-right: 0;
  }
  @media screen and (max-width: 640px) {
    .aside-event-details {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 100%;
      margin-left: 0%;
    }
    .aside-event-details:last-child {
      margin-right: 0;
    }
  }
  .aside-event-details .button-default, .aside-event-details .ee_view_cart {
    width: 100%;
    text-align: center;
    margin-bottom: 55px;
  }
  @media screen and (max-width: 640px) {
    .aside-event-details .button-default, .aside-event-details .ee_view_cart {
      display: none;
    }
  }
  
  .box-aside-follow:after {
    content: "";
    display: table;
    clear: both;
  }
  .box-aside-follow .sub-head-title, .box-aside-follow .social-icon {
    display: inline-block;
    vertical-align: middle;
  }
  .box-aside-follow .sub-head-title {
    margin-top: 5px;
    margin-bottom: 0px !important;
  }
  .box-aside-follow .social-icon {
    float: right;
  }
  .box-aside-follow .social-icon a {
    display: inline-block;
  }
  
  @media screen and (max-width: 890px) {
    .page-template-teacher-detail .content-event-details {
      float: left;
      width: 100%;
      display: block;
      margin-right: 2.35765%;
    }
  }
  .page-template-teacher-detail .aside-event-details {
    float: right;
    width: auto;
    margin-left: 0;
  }
  .page-template-teacher-detail .aside-event-details h3 {
    padding-right: 5px;
  }
  @media screen and (max-width: 890px) {
    .page-template-teacher-detail .aside-event-details {
      float: left;
      display: block;
      width: 100%;
      margin-left: 0;
    }
    .page-template-teacher-detail .aside-event-details .sub-head-title {
      font-size: 1.25em;
    }
  }
  
  @media screen and (max-width: 640px) {
    .banner-event-master-details {
      min-height: 185px;
    }
  }
  @media screen and (max-width: 640px) {
    .banner-event-master-details .return-to {
      padding-left: 0;
    }
  }
  
  .video-master-details {
    margin-bottom: 40px;
  }
  
  .content-event-master-details {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 65.88078%;
  }
  .content-event-master-details:last-child {
    margin-right: 0;
  }
  @media screen and (max-width: 640px) {
    .content-event-master-details {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 100%;
    }
    .content-event-master-details:last-child {
      margin-right: 0;
    }
  }
  
  @media screen and (max-width: 640px) {
    .video-master-details iframe {
      width: 100%;
      max-height: 330px;
    }
  }
  
  .aside-event-master-details {
    margin-left: 8.5298%;
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 23.23176%;
  }
  .aside-event-master-details:last-child {
    margin-right: 0;
  }
  @media screen and (max-width: 640px) {
    .aside-event-master-details {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 100%;
      margin-left: 0%;
    }
    .aside-event-master-details:last-child {
      margin-right: 0;
    }
  }
  
  .featured-packages {
    text-align: center;
    padding-bottom: 25px;
  }
  .featured-packages .title-page {
    color: #fff;
  }
  .featured-packages .title-page:before {
    height: 0;
  }
  .featured-packages figure {
    margin-bottom: 10px;
  }
  @media screen and (max-width: 640px) {
    .featured-packages figure {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 48.82117%;
    }
    .featured-packages figure:last-child {
      margin-right: 0;
    }
  }
  @media screen and (max-width: 450px) {
    .featured-packages figure {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 100%;
    }
    .featured-packages figure:last-child {
      margin-right: 0;
    }
  }
  @media screen and (max-width: 640px) {
    .featured-packages .box-content {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 48.82117%;
      text-align: left;
    }
    .featured-packages .box-content:last-child {
      margin-right: 0;
    }
  }
  @media screen and (max-width: 450px) {
    .featured-packages .box-content {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 100%;
      text-align: left;
      margin: 20px auto;
    }
    .featured-packages .box-content:last-child {
      margin-right: 0;
    }
  }
  @media screen and (max-width: 640px) {
    .featured-packages .button-default, .featured-packages .ee_view_cart {
      width: 100%;
      height: 50px;
    }
  }
  @media screen and (max-width: 320px) {
    .featured-packages .button-default, .featured-packages .ee_view_cart {
      height: 30px;
    }
  }
  
  .list-packages {
    max-width: 73.125em;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
  }
  .list-packages:after {
    content: "";
    display: table;
    clear: both;
  }
  .list-packages figure {
    margin-bottom: 10px;
  }
  .list-packages li {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 23.23176%;
  }
  .list-packages li:last-child {
    margin-right: 0;
  }
  @media screen and (max-width: 640px) {
    .list-packages li {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 100%;
    }
    .list-packages li:last-child {
      margin-right: 0;
    }
    .list-packages li:nth-child(n) {
      margin-right: 0;
    }
  }
  .list-packages .title-package {
    margin-bottom: 15px;
  }
  
  .list-explorer {
    max-width: 73.125em;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5px;
  }
  .list-explorer:after {
    content: "";
    display: table;
    clear: both;
  }
  .list-explorer figure {
    margin-bottom: 10px;
  }
  @media screen and (max-width: 640px) {
    .list-explorer figure {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 48.82117%;
    }
    .list-explorer figure:last-child {
      margin-right: 0;
    }
  }
  @media screen and (max-width: 450px) {
    .list-explorer figure {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 100%;
    }
    .list-explorer figure:last-child {
      margin-right: 0;
    }
  }
  @media screen and (max-width: 640px) {
    .list-explorer .box-content {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 48.82117%;
      text-align: left;
    }
    .list-explorer .box-content:last-child {
      margin-right: 0;
    }
  }
  @media screen and (max-width: 450px) {
    .list-explorer .box-content {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 100%;
      text-align: center;
      margin: 20px auto;
    }
    .list-explorer .box-content:last-child {
      margin-right: 0;
    }
  }
  .list-explorer li {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 23.23176%;
  }
  .list-explorer li:last-child {
    margin-right: 0;
  }
  @media screen and (max-width: 640px) {
    .list-explorer li {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 100%;
      margin-bottom: 50px;
    }
    .list-explorer li:last-child {
      margin-right: 0;
    }
    .list-explorer li:nth-child(n) {
      margin-right: 0;
    }
  }
  .list-explorer li .details-explorer {
    margin-bottom: 10px;
  }
  .list-explorer .title-explorer {
    margin-bottom: 25px;
  }
  .list-explorer .button-default, .list-explorer .ee_view_cart {
    height: 30px;
    line-height: 25px;
  }
  @media screen and (max-width: 640px) {
    .list-explorer .button-default, .list-explorer .ee_view_cart {
      height: 45px;
      line-height: 45px;
    }
  }
  @media screen and (max-width: 320px) {
    .list-explorer .button-default, .list-explorer .ee_view_cart {
      height: 24px;
      line-height: 21px;
    }
  }
  
  .coming-up {
    padding-bottom: 25px;
  }
  .coming-up .title-page:before {
    height: 0;
  }
  
  .list-coming-up.flexslider .event {
    margin-right: 29px;
  }
  .list-coming-up.flexslider .event.type-2 {
    margin-right: 29px;
  }
  .list-coming-up li {
    margin-right: 29px;
  }
  @media screen and (max-width: 768px) {
    .list-coming-up li {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 48.82117%;
      margin-bottom: 50px;
    }
    .list-coming-up li:last-child {
      margin-right: 0;
    }
    .list-coming-up li:nth-child(2n) {
      margin-right: 0;
    }
    .list-coming-up li:nth-child(2n+1) {
      clear: left;
    }
  }
  .list-coming-up .box-date-weather {
    margin-bottom: 10px;
    height: 35px;
  }
  .list-coming-up .box-date-weather:after {
    content: "";
    display: table;
    clear: both;
  }
  .list-coming-up .box-date-weather .date {
    color: #59595a;
    text-align: left;
    float: left;
    padding-top: 5px;
  }
  .list-coming-up .box-date-weather .icons {
    vertical-align: middle;
    text-align: right;
  }
  .list-coming-up .box-date-weather .icons img {
    display: inline-block;
    max-width: 30px;
    max-height: 30px;
  }
  .list-coming-up figure {
    margin-bottom: 10px;
  }
  @media screen and (max-width: 320px) {
    .list-coming-up figure {
      margin-bottom: 5px;
    }
  }
  .list-coming-up .title-coming {
    margin-bottom: 10px;
    height: 82px;
  }
  @media screen and (max-width: 320px) {
    .list-coming-up .title-coming {
      margin-bottom: 5px;
    }
  }
  @media screen and (max-width: 640px) {
    .list-coming-up .title-coming {
      height: 102px;
    }
  }
  .list-coming-up .details-coming {
    margin-bottom: 10px;
  }
  .list-coming-up .flex-control-nav {
    display: none;
  }
  .list-coming-up .button-default, .list-coming-up .ee_view_cart {
    height: 30px;
    line-height: 30px;
  }
  @media screen and (max-width: 640px) {
    .list-coming-up .button-default, .list-coming-up .ee_view_cart {
      height: 45px;
      line-height: 45px;
    }
  }
  @media screen and (max-width: 320px) {
    .list-coming-up .button-default, .list-coming-up .ee_view_cart {
      height: 24px;
      line-height: 22px;
    }
  }
  
  .remove-margin-left {
    margin-left: 0;
  }
  
  .filters-fixed-width {
    width: 266px;
  }
  
  .box-pagination {
    padding-top: 40px;
    padding-bottom: 20px;
  }
  .box-pagination i {
    height: 34px;
    width: 34px;
    line-height: 32px;
  }
  .box-pagination i.icon-arrow-left {
    margin-right: 20px;
  }
  .box-pagination i.icon-arrow-right {
    margin-left: 20px;
  }
  .box-pagination i, .box-pagination ul {
    display: inline-block;
    vertical-align: middle;
  }
  .box-pagination li {
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
  }
  .box-pagination li:nth-last-child(1) {
    margin-right: 0px;
  }
  
  .spa-suite-options {
    margin-bottom: -5em;
    padding-bottom: 5em;
  }
  
  .form-field.bg-beige {
    margin-bottom: -5em;
    padding-bottom: 5em;
  }
  
  .home-contact {
    padding-top: 40px;
    padding-bottom: 30px;
  }
  .home-contact .gform_body ul {
    list-style: none;
  }
  .home-contact .box-left, .home-contact .box-right {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%;
  }
  .home-contact .box-left:last-child, .home-contact .box-right:last-child {
    margin-right: 0;
  }
  @media screen and (max-width: 640px) {
    .home-contact .box-left, .home-contact .box-right {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 100%;
    }
    .home-contact .box-left:last-child, .home-contact .box-right:last-child {
      margin-right: 0;
    }
  }
  .home-contact form label, .home-contact form input {
    width: 100%;
  }
  .home-contact form input {
    margin-bottom: 20px;
  }
  .home-contact form button {
    width: 125px;
  }
  @media screen and (max-width: 640px) {
    .home-contact form button {
      width: 100%;
      margin-bottom: 40px;
    }
  }
  .home-contact .info-appointment {
    padding-left: 100px;
  }
  .home-contact .info-appointment:after {
    content: "";
    display: table;
    clear: both;
  }
  @media screen and (max-width: 640px) {
    .home-contact .info-appointment {
      padding-left: 0px;
    }
  }
  .home-contact .info-appointment .location, .home-contact .info-appointment .hours {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%;
    margin-right: 0;
  }
  .home-contact .info-appointment .location:last-child, .home-contact .info-appointment .hours:last-child {
    margin-right: 0;
  }
  @media screen and (max-width: 450px) {
    .home-contact .info-appointment .location, .home-contact .info-appointment .hours {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 100%;
      margin-bottom: 10px;
    }
    .home-contact .info-appointment .location:last-child, .home-contact .info-appointment .hours:last-child {
      margin-right: 0;
    }
  }
  .home-contact .info-appointment .box-map {
    margin-top: 30px;
  }
  @media screen and (max-width: 640px) {
    .home-contact .info-appointment .box-map {
      width: 100%;
    }
  }
  
  .spa-hero-unit figure {
    width: 100%;
    position: relative;
  }
  .spa-hero-unit figure img {
    display: block;
    width: 100%;
  }
  @media screen and (max-width: 640px) {
    .spa-hero-unit figure img {
      min-height: 480px;
    }
  }
  .spa-hero-unit figcaption {
    position: absolute;
    bottom: 70px;
    left: 50px;
  }
  @media screen and (max-width: 640px) {
    .spa-hero-unit figcaption {
      left: 35px;
      top: 45px;
      bottom: initial;
    }
  }
  @media screen and (max-width: 320px) {
    .spa-hero-unit figcaption {
      left: 20px;
      top: 30px;
    }
  }
  .spa-hero-unit .content-banner-intro {
    padding-bottom: 40px;
  }
  .spa-hero-unit .foot-image {
    height: 20px;
    background: url(../images/testimonial_spring.jpg);
    background-position: bottom;
    background-repeat: no-repeat;
    border-bottom: 5px solid #fff;
  }
  
  @media screen and (max-width: 640px) {
    .hero-unit.home-template figure .responsive-image {
      background-position: -220px;
    }
  }
  @media screen and (max-width: 640px) {
    .hero-unit.home-template figure .responsive-image {
      background-position: -135px;
    }
  }
  .hero-unit figure {
    position: relative;
    width: 100%;
  }
  .hero-unit figure img {
    width: 100%;
    min-height: 610px;
  }
  @media screen and (max-width: 640px) {
    .hero-unit figure img {
      min-height: 500px;
    }
  }
  .hero-unit .relative {
    position: relative;
  }
  .hero-unit figcaption {
    position: absolute;
    right: 0;
    top: 80px;
    width: 530px;
  }
  @media screen and (max-width: 640px) {
    .hero-unit figcaption {
      width: 325px;
      right: 21px;
      top: 85px;
    }
  }
  @media screen and (max-width: 320px) {
    .hero-unit figcaption {
      width: 60%;
      right: 15px;
      top: 15px;
    }
  }
  .hero-unit .title {
    margin-bottom: 15px;
  }
  .hero-unit .details {
    margin-bottom: 40px;
    padding: 0 50px;
  }
  @media screen and (max-width: 640px) {
    .hero-unit .details {
      padding: 0 30px;
      margin-bottom: 60px;
    }
  }
  @media screen and (max-width: 320px) {
    .hero-unit .details {
      margin-bottom: 20px;
      padding: 0 15px;
    }
  }
  
  .hero-unit .bulletslide figcaption {
    text-align: left;
    right: auto;
    left: 0;
    top: 48px;
  }
  
  .hero-unit .bulletslide .title {
    font-size: 2.2em;
  }
  
  .hero-unit .bulletslide .details {
    /* font-family: Capita; */ /* Removed font */
    color: #fff;
    font-size: 1.4em;
    padding: 0;
    line-height: 1em;
    font-style: italic;
  }
  
  .hero-unit .bulletslide .button-gold {
    /*background: url(../images/bg_yellow.jpg) repeat-x;*/
    background: #e9a52e;
    font-size: 22px;
    padding: 0 10px;
    text-align: center;
    text-shadow: 0 0 2px #666;
    width: 180px;
    position: absolute;
    left: 82%;
  }
  
  .hero-unit .bulletslide .button-gold:hover {
    /*background: url(../images/bg_yellow.jpg) repeat-x;*/
    filter: brightness(110%);
    -webkit-filter: brightness(110%);
  }
  
  .hero-unit .bulletslide ul {
    color: #fff;
    list-style-type: disc;
  }
  
  .hero-unit .bulletslide .slider-bullet-paragraph li {
    font-size: 18px;
    /* font-family: Gibson */ /* Removed font */;
    margin-left: 30px;
    margin-top: 10px;
  }
  
  .hero-list-event {
    margin-top: -80px;
  }
  @media screen and (max-width: 640px) {
    .hero-list-event {
      margin-top: 0;
      min-height: 310px;
      height: 100%;
    }
  }
  .hero-list-event .hero-event {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 31.76157%;
  }
  .hero-list-event .hero-event:last-child {
    margin-right: 0;
  }
  @media screen and (max-width: 640px) {
    .hero-list-event .hero-event {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 100%;
    }
    .hero-list-event .hero-event:last-child {
      margin-right: 0;
    }
  }
  .hero-list-event .hero-event figure {
    border: 2px solid #fff;
  }
  .hero-list-event .hero-event figure img {
    height: 180px;
  }
  .hero-list-event .list-events {
    position: relative;
  }
  .hero-list-event .list-events:after {
    content: "";
    display: table;
    clear: both;
  }
  @media screen and (max-width: 640px) {
    .hero-list-event .list-events {
      position: static;
      padding-top: 30px;
    }
  }
  
  .home-coming-up .list-coming-up.thumbnail-view {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 65.88078%;
    margin-bottom: 40px;
  }
  .home-coming-up .list-coming-up.thumbnail-view:last-child {
    margin-right: 0;
  }
  @media screen and (max-width: 1024px) {
    .home-coming-up .list-coming-up.thumbnail-view {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 65.88078%;
    }
    .home-coming-up .list-coming-up.thumbnail-view:last-child {
      margin-right: 0;
    }
  }
  @media screen and (max-width: 640px) {
    .home-coming-up .list-coming-up.thumbnail-view {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 100%;
    }
    .home-coming-up .list-coming-up.thumbnail-view:last-child {
      margin-right: 0;
    }
  }
  .home-coming-up .list-coming-up.thumbnail-view li {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 31.76157%;
  }
  .home-coming-up .list-coming-up.thumbnail-view li:last-child {
    margin-right: 0;
  }
  @media screen and (max-width: 1024px) {
    .home-coming-up .list-coming-up.thumbnail-view li {
      width: 47%;
      min-height: 450px;
      margin-bottom: 0;
    }
  }
  @media screen and (max-width: 640px) {
    .home-coming-up .list-coming-up.thumbnail-view li {
      min-height: 500px;
    }
  }
  @media screen and (max-width: 450px) {
    .home-coming-up .list-coming-up.thumbnail-view li {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 100%;
    }
    .home-coming-up .list-coming-up.thumbnail-view li:last-child {
      margin-right: 0;
    }
  }
  .home-coming-up .list-coming-up.thumbnail-view li.seperator {
    border-bottom: 1px solid #c4c5c7;
    width: 100%;
    margin-bottom: 40px;
  }
  .home-coming-up .list-coming-up.thumbnail-view li figure {
    height: 144px;
  }
  @media screen and (max-width: 640px) {
    .home-coming-up .list-coming-up.thumbnail-view li figure {
      height: 150px;
    }
  }
  @media screen and (max-width: 450px) {
    .home-coming-up .list-coming-up.thumbnail-view li figure {
      height: 200px;
    }
  }
  .home-coming-up .list-coming-up.list-view {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 65.88078%;
    margin-bottom: 40px;
  }
  .home-coming-up .list-coming-up.list-view:last-child {
    margin-right: 0;
  }
  @media screen and (max-width: 640px) {
    .home-coming-up .list-coming-up.list-view {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 100%;
    }
    .home-coming-up .list-coming-up.list-view:last-child {
      margin-right: 0;
    }
  }
  .home-coming-up .list-coming-up.list-view li {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%;
    border-bottom: 1px solid #c4c5c7;
    padding-bottom: 20px;
    margin-bottom: 20px;
    text-align: left;
  }
  .home-coming-up .list-coming-up.list-view li:last-child {
    margin-right: 0;
  }
  .home-coming-up .list-coming-up.list-view li .box-date-weather {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 14.70196%;
  }
  .home-coming-up .list-coming-up.list-view li .box-date-weather:last-child {
    margin-right: 0;
  }
  @media screen and (max-width: 890px) {
    .home-coming-up .list-coming-up.list-view li .box-date-weather.list-view-date {
      width: 100%;
    }
  }
  .home-coming-up .list-coming-up.list-view li figure {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 23.23176%;
    height: 115px;
    overflow: hidden;
  }
  .home-coming-up .list-coming-up.list-view li figure:last-child {
    margin-right: 0;
  }
  @media screen and (max-width: 768px) {
    .home-coming-up .list-coming-up.list-view li figure {
      width: 35%;
    }
  }
  @media screen and (max-width: 640px) {
    .home-coming-up .list-coming-up.list-view li figure {
      width: 100%;
    }
  }
  @media screen and (max-width: 500px) {
    .home-coming-up .list-coming-up.list-view li figure {
      height: 200px;
    }
  }
  .home-coming-up .list-coming-up.list-view li figure img {
    height: 100%;
    width: auto;
  }
  @media screen and (min-width: 1150px) {
    .home-coming-up .list-coming-up.list-view li figure img {
      width: 100%;
      height: auto;
    }
  }
  @media screen and (max-width: 500px) {
    .home-coming-up .list-coming-up.list-view li figure img {
      height: auto;
      width: 100%;
    }
  }
  .home-coming-up .list-coming-up.list-view li .box-content {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 57.35098%;
  }
  .home-coming-up .list-coming-up.list-view li .box-content:last-child {
    margin-right: 0;
  }
  @media screen and (min-width: 641px) {
    .home-coming-up .list-coming-up.list-view li .box-content {
      margin-left: 2%;
    }
  }
  @media screen and (max-width: 500px) {
    .home-coming-up .list-coming-up.list-view li .box-content {
      width: 100%;
    }
  }
  .home-coming-up .list-coming-up.list-view li .box-content .title-event, .home-coming-up .list-coming-up.list-view li .box-content .title-coming {
    margin-bottom: 5px;
    padding-top: 0;
    display: block;
  }
  .home-coming-up .list-coming-up.list-view li .box-content .icons {
    float: left;
    display: inline-block;
  }
  @media screen and (max-width: 500px) {
    .home-coming-up .list-coming-up.list-view li .box-content .icons {
      width: 100%;
      text-align: center;
      margin: 5px 0;
      float: none;
    }
  }
  @media screen and (max-width: 1024px) {
    .home-coming-up .section-aside {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 31.76157%;
    }
    .home-coming-up .section-aside:last-child {
      margin-right: 0;
    }
  }
  @media screen and (max-width: 1024px) {
    .home-coming-up .filters {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 31.76157%;
      margin-left: 0;
    }
    .home-coming-up .filters:last-child {
      margin-right: 0;
    }
  }
  @media screen and (max-width: 640px) {
    .home-coming-up .filters {
      width: 100%;
    }
  }
  @media screen and (max-width: 1024px) {
    .home-coming-up .filters .row-form, .home-coming-up .filters .form-additional-spa .gfield, .form-additional-spa .home-coming-up .filters .gfield, .home-coming-up .filters .form-additional-spa .gform_footer, .form-additional-spa .home-coming-up .filters .gform_footer {
      margin-bottom: 20px;
    }
  }
  .home-coming-up .filters .box-filter-type input[type=checkbox].css-checkbox + label.css-label-checkbox, .home-coming-up .filters .box-filter-type .form-additional-spa .gfield_checkbox input[type=checkbox] + label, .form-additional-spa .gfield_checkbox .home-coming-up .filters .box-filter-type input[type=checkbox] + label {
    font-size: 0.9em;
  }
  @media screen and (max-width: 1024px) {
    .home-coming-up .filters .box-filter-type input[type=checkbox].css-checkbox + label.css-label-checkbox, .home-coming-up .filters .box-filter-type .form-additional-spa .gfield_checkbox input[type=checkbox] + label, .form-additional-spa .gfield_checkbox .home-coming-up .filters .box-filter-type input[type=checkbox] + label {
      font-size: 0.8em;
    }
  }
  
  .mobile.filter {
    display: none;
  }
  @media screen and (max-width: 640px) {
    .mobile.filter {
      display: block;
    }
  }
  @media screen and (max-width: 640px) {
    .mobile.filter .row-form, .mobile.filter .form-additional-spa .gfield, .form-additional-spa .mobile.filter .gfield, .mobile.filter .form-additional-spa .gform_footer, .form-additional-spa .mobile.filter .gform_footer {
      margin-bottom: 10px;
    }
  }
  
  .show-in-mobile {
    display: none;
  }
  @media screen and (max-width: 640px) {
    .show-in-mobile {
      display: block;
    }
  }
  
  .hide-in-mobile {
    display: block;
  }
  @media screen and (max-width: 640px) {
    .hide-in-mobile {
      display: none;
    }
  }
  
  @media screen and (max-width: 640px) {
    .fix-margin {
      margin-bottom: 0;
    }
  }
  
  @media screen and (max-width: 640px) {
    .remove-top-border {
      border-top: none;
    }
  }
  
  @media screen and (max-width: 640px) {
    .remove-bottom-border {
      border-bottom: none;
    }
  }
  
  @media screen and (max-width: 640px) {
    .list-view-thumbnail {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 25%;
    }
  }
  @media screen and (max-width: 500px) {
    .list-view-thumbnail {
      float: none;
      width: 100%;
      margin: 5px auto;
    }
  }
  
  .list-view-button {
    float: right;
  }
  @media screen and (max-width: 500px) {
    .list-view-button {
      float: none;
    }
  }
  
  @media screen and (max-width: 500px) {
    .list-view-title {
      text-align: center;
    }
  }
  
  .weather-plugin .box-icon-weather, .weather-plugin .box-temp {
    display: inline-block;
    vertical-align: middle;
    width: 49%;
  }
  
  .box-icon>a:first-child{
      margin-right: 5px;
      color: #82cfe1;
  }
  
  .box-search {
    height: 0px;
  }
  .box-search .container {
    height: 0px;
    position: relative;
  }
  @media screen and (max-width: 640px) {
    .box-search .container {
      position: fixed;
      left: 0;
      right: 0;
      top: 85px;
    }
  }
  .box-search .search-form {
    position: absolute;
    right: 0;
    top: 0px;
    z-index: 9;
    width: 177px;
    display: none;
    border: 1px solid #bfb8ad;
  }
  @media screen and (max-width: 640px) {
    .box-search .search-form {
      top: 0px;
    }
  }
  @media screen and (max-width: 320px) {
    .box-search .search-form {
      top: -43px;
    }
  }
  .box-search .screen-reader-text {
    display: none;
  }
  .box-search input {
    border: 0;
    background: #f8f1e4;
    border-radius: 0;
    -webkit-appearance: none;
  }
  .box-search input:hover, .box-search input:active, .box-search input:focus {
    outline: none;
    border: 0;
  }
  .box-search input.search-field {
    padding: 15px 50px 15px 5px;
    width: 100%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    background: #f8f1e4;
  }
  @media screen and (max-width: 320px) {
    .box-search input.search-field {
      padding: 5px 50px 5px 2px;
    }
  }
  .box-search input[type="submit"] {
    position: absolute;
    right: 1px;
    top: 1px;
    background-image: url("../images/arrow-right.png");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 50px;
    height: 49px;
  }
  @media screen and (max-width: 320px) {
    .box-search input[type="submit"] {
      height: 29px;
    }
  }
  
  .no-results .page-title {
    color: #069;
    font-weight: 500;
  }
  .no-results .search-form input {
    height: 30px;
    vertical-align: top;
  }
  .no-results .search-form .search-submit {
    height: 32px;
    color: white;
    background-color: #175479;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#175479), to(#0f4a71));
    background-image: linear-gradient(#175479, #0f4a71);
    border: 1px solid #175479;
  }
  .no-results .search-form .search-submit:hover {
    background-color: #7fc261;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#7fc261), to(#a3ce5d));
    background-image: linear-gradient(#7fc261, #a3ce5d);
    border: 1px solid #158152;
  }
  
  .header {
    /*font-family: "Capita";*/
  }
  .header.type-a {
    background-color: white;
  }
  .header.type-b {
    background-color: white;
  }
  
  .logo img {
    margin: 0;
    display: block;
  }
  
  @media screen and (max-width: 640px) {
    .logo img {
      background-size: 163px 82px;
    }
  }
  
  @media screen and (max-width: 640px) {
    .box-menu {
      background: #f7eddd;
    }
  }
  
  @media screen and (max-width: 640px) {
    .box-social-mobile {
      text-align: center;
    }
  }
  .box-social-mobile .social-icon .icon-facebook {
    font-size: 22px;
  }
  .box-social-mobile .social-icon .icon-google-plus {
    font-size: 20px;
    padding-left: 3px;
    padding-top: 1px;
  }
  .box-social-mobile .social-icon .icon-instagram {
    font-size: 18px;
    padding-right: 1px;
  }
  .box-social-mobile .social-icon .icon-instagram:before {
    display: inline-block;
    width: 15px;
  }
  
  .phone {
    color: #c3aab9;
    
  }
  .phone.color-blue a {
    color: #84628d;
  }
  
  .link-to-cart {
    
    color: #c3aab9;
  }
  .link-to-cart.color-blue {
    color: #84628d;
  }
  .link-to-cart span {
    font-weight: 600;
    margin-left: 2px;
  }
  
  @media screen and (max-width: 640px) {
    .item-search {
      display: none !important;
    }
  }
  .item-search a {
    color: #fff;
    font-size: 1.5625em;
    margin-right: 10px;
  }
  
  .menu .shop-element {
    display: none;
  }
  @media screen and (max-width: 640px) {
    .menu {
      background: #f8f1e4;
      border-right: 1px solid #f3e8d4;
    }
  }
  @media screen and (max-width: 640px) {
    .menu .item {
      border-bottom: 1px solid #f3e8d4;
    }
  }
  .menu .item > a {
    color: #84628d;
    font-size: 1.25em;
  }
  @media screen and (max-width: 1090px) {
    .menu .item > a {
      font-size: 1.125em;
    }
  }
  @media screen and (min-width: 1091px){
      .menu-desktop {
          width: 800px;
      }
  }
  @media screen and (min-width: 1200px){
      .menu-desktop {
          width: 900px;
      }
  }
  @media screen and (max-width: 1024px) {
    .menu .item > a {
      font-size: 1em;
    }
  }
  @media screen and (max-width: 768px) {
    .menu .item > a {
      font-size: 0.875em;
    }
  }
  .menu .item > a:active, .menu .item > a.active {
    position: relative;
  }
  .menu .item > a:active.color-celeste, .menu .item > a.active.color-celeste {
    color: #62cdf6;
  text-decoration: underline;
  }
  .menu .item > a:active.color-purple, .menu .item > a.active.color-purple {
    color: #c3aab9;
  }
  .menu .item > a:active:after, .menu .item > a.active:after {
    /* content: "";
    height: 0;
    width: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #f9f2e5;
    position: absolute;
    bottom: -18px;
    left: 50%;
    margin-left: -10px; */
  }
  @media screen and (max-width: 1090px) {
    .menu .item > a:active:after, .menu .item > a.active:after {
      /* bottom: -16px; */
    }
  }
  @media screen and (max-width: 1024px) {
    .menu .item > a:active:after, .menu .item > a.active:after {
      /* bottom: -18px; */
    }
  }
  @media screen and (max-width: 640px) {
    .menu .item > a:active, .menu .item > a.active {
      color: #676767;
    }
  }
  @media screen and (max-width: 640px) {
    .menu .item > a {
      color: #676767;
      font-size: 1.5625em;
    }
  
    .menu .item > a:active:before, .menu .item > a.active:before {
      display: none;
    }
  
  /*
    .menu-item-has-children a.js-submenu {
      margin-right: 23%;
    }
  */
  
  }
  @media screen and (max-width: 320px) {
    .menu .item > a {
      font-size: 0.75em;
    }
  }
  
  .menu .item.lets-connect > a:active:after {
    content: none;
    /*    border-left: none !important;
        border-right: none !important;
        border-bottom: none !important;
        */
  }
  
  .type-a menu a:active:after, .type-a menu a.active:after {
    bottom: -15px;
  }
  
  .sub-menu {
    background: #f9f2e5;
    border-bottom: 1px solid #b8b1a6;
  }
  @media screen and (max-width: 640px) {
    .sub-menu {
      border: 0;
    }
  }
  .sub-menu .current-menu-item a {
    font-weight: 600;
  }
  .sub-menu .sub-item > a {
    color: #676767;
    
    font-size: 1.125em;
    cursor: pointer;
  }
  @media screen and (max-width: 1090px) {
    .sub-menu .sub-item > a {
      font-size: 1em;
    }
  }
  @media screen and (max-width: 890px) {
    .sub-menu .sub-item > a {
      font-size: 0.875em;
    }
  }
  @media screen and (max-width: 640px) {
    .sub-menu .sub-item > a {
      font-size: 1.375em;
      background: #f7eddd;
      font-weight: 500;
    }
  }
  @media screen and (max-width: 320px) {
    .sub-menu .sub-item > a {
      font-size: 0.75em;
    }
  }
  .sub-menu .sub-item > a.active {
    font-weight: 600;
  }
  @media screen and (max-width: 640px) {
    .sub-menu .sub-item > a.active {
      background: #f0e0c6;
      font-weight: 400;
    }
  }
  
  .social-icon .icon-facebook {
    font-size: 22px;
  }
  .social-icon .icon-google-plus {
    font-size: 20px;
    padding-left: 3px;
    padding-top: 1px;
  }
  .social-icon .icon-instagram {
    font-size: 18px;
    padding-right: 1px;
  }
  .social-icon .icon-instagram:before {
    display: inline-block;
    width: 14px;
  }
  .social-icon i {
    height: 30px;
    width: 30px;
    display: inline-block;
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
    margin-right: 5px;
  }
  @media screen and (max-width: 640px) {
    .social-icon i {
      margin-right: 0;
      display: block;
    }
  }
  .social-icon i.bg-purple {
    background: #c4aaba;
    color: #644c76;
  }
  .social-icon i.bg-blue {
    background: #84628d;
    color: #1e738c;
  }
  .social-icon i.bg-blue:hover {
    background-color: #7fc261;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#7fc261), to(#a3ce5d));
    background-image: linear-gradient(#7fc261, #a3ce5d);
    color: #1e738c;
  }
  .social-icon i.bg-grey {
    background: #bbbdbf;
    color: #fff;
  }
  @media screen and (max-width: 640px) {
    .social-icon i.bg-blue, .social-icon i.bg-purple {
      background: #646059;
      color: #f7eddd;
    }
    .social-icon i.bg-blue:hover, .social-icon i.bg-purple:hover {
      background: #969490;
      color: #f7eddd;
    }
  }
  
  .menu-mobile .shop-element {
    display: none;
  }
  .menu-mobile a {
    font-size: 35px;
    vertical-align: middle;
  }
  .menu-mobile a.plan-your-trip {
    font-size: 16px;
    width: 80px;
    line-height: 1.1;
    height: 50px;
    padding: 0;
  color: white;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .menu-mobile a.plan-your-trip._visible-plan-trip {
    padding-left: 15px;
  }
  .menu-mobile a.plan-your-trip:before {
    left: 17px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    height: 12px;
  }
  @media screen and (max-width: 450px) {
    .menu-mobile a {
      font-size: 20px;
    }
  }
  .menu-mobile a:active.blue, .menu-mobile a.active.blue, .menu-mobile a:hover.blue {
    color: #84628d;
  }
  
  #menu-main-menu {
    padding-left: 0;
  }
  
  /*@media screen and (max-width: 320px) {
    body {
      padding-top: 41px;
    }
  
    .menu-mobile a.plan-your-trip {
      height: 42px;
    }
  
    #top-bar-menu {
      font-size: 13px;
      line-height: 38px;
    }
  }*/
  /*.footer {
    background-image: url("../images/background_slider.png");
    background-position: center center;
    background-size: cover;
  }
  
  .box-info {
    
  }*/
  .box-info p {
    color: #fff;
    line-height: 26px;
    font-size: 1em;
  }
  @media screen and (max-width: 320px) {
    .box-info p {
      font-size: 0.5em;
    }
  }
  .box-info .ranking i {
    font-size: 2em;
  }
  @media screen and (max-width: 320px) {
    .box-info .ranking i {
      font-size: 1em;
    }
  }
  .box-info .ranking i.rank-star {
    color: #fff;
  }
  .box-info .ranking i.no-rank-star {
    color: #676767;
  }
  
  .footer-medium .mask-blur {
    background-color: rgba(0, 0, 0, 0.5);
    background-image: url("../images/background-slider-blur.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center -130px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 225px;
    z-index: 0;
    overflow: hidden;
  }
  @media screen and (max-width: 1024px) {
    .footer-medium .mask-blur {
      background-position: center -55px;
    }
  }
  @media screen and (max-width: 768px) {
    .footer-medium .mask-blur {
      background-position: center -160px;
      background-size: inherit;
      height: initial;
    }
  }
  @media screen and (max-width: 640px) {
    .footer-medium .mask-blur {
      height: 300px;
      background-position: center bottom;
    }
  }
  @media screen and (max-width: 450px) {
    .footer-medium .mask-blur {
      height: 470px;
      background-image: url("");
    }
  }
  
  .footer-medium .container {
    position: relative;
    z-index: 3;
  }
  
  .title-box-info {
    font-weight: 400;
    color: #62cdf6;
    text-transform: uppercase;
    font-size: 1.125em;
  }
  @media screen and (max-width: 320px) {
    .title-box-info {
      font-size: 0.5625em;
    }
  }
  
  .footer-bottom {
    background: #fff;
  }
  
  .footer-links li {
    display: inline-block;
  }
  
  .footer-links a, .copy {
    color: #231f20;
    font-family: 'Arial';
    font-size: 0.75em;
  }
  @media screen and (max-width: 320px) {
    .footer-links a, .copy {
      font-size: 0.375em;
    }
  }
  
  .footer-links a {
    margin-right: 10px;
  }
  
  .ui-widget-content {
    border: none;
  }
  
  .landing-page .line {
    height: 1px;
    width: 166px;
    margin: 0 auto;
    display: block;
    background: #bec0c1;
  }
  .landing-page p {
    font-size: 1.625em;
    text-align: center;
    color: #231f20;
  }
  @media screen and (max-width: 640px) {
    .landing-page p {
      color: #404041;
      text-align: left;
      font-size: 1.5em;
    }
  }
  .landing-page .form-landing .gform_body ul {
    list-style: none;
  }
  
  .image-background {
    border: 1px solid #bbbdbf;
  }
  .image-background .image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  @media screen and (max-width: 640px) {
    .image-background {
      border: 1px solid #c7c7c6;
      background: #fff;
      padding: 10px;
    }
  }
  
  .form-landing {
    background: #fefcf4;
    border: 2px solid #676767;
  }
  
  .landing-page.type-b p {
    
    margin-bottom: 40px;
    line-height: 36px;
  }
  .landing-page.type-b label {
    
    font-weight: 600;
  }
  .landing-page.type-b .legend {
    
  }
  .landing-page.type-b .image-background {
    padding: 0;
  }
  
  .landing-page.type-a.type-1 .image-background {
    padding: 10px;
    background: #fff;
  }
  
  .landing-page.type-a.type-1 p, .landing-page.type-b.type-1 p {
    font-size: 1.125em;
    text-align: left;
    padding-top: 0;
  }
  .landing-page.type-a.type-1 .form-landing, .landing-page.type-b.type-1 .form-landing {
    background: none;
    border: 0;
    padding-top: 0;
  }
  
  .testimonial-area {
    color: #fff;
  }
  .testimonial-area .text {
    /*font-family: "Capita";*/
    font-size: 1.625em;
    font-weight: 300;
    font-style: italic;
  }
  @media screen and (max-width: 320px) {
    .testimonial-area .text {
      font-size: 1.125em;
    }
  }
  .testimonial-area .author {
    
    font-size: 1.375em;
  }
  @media screen and (max-width: 320px) {
    .testimonial-area .author {
      font-size: 0.6875em;
    }
  }
  
  .banner-info.margin-top {
    margin-top: 30px;
  }
  .banner-info .bg-image {
    background-size: cover;
  }
  .banner-info .box-content.with-background {
    border: 1px solid #fff;
  }
  .banner-info .title-module {
    color: #fff;
    font-size: 2.25em;
    text-align: center;
    text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.6);
  }
  @media screen and (max-width: 640px) {
    .banner-info .title-module {
      font-size: 1.875em;
    }
  }
  @media screen and (max-width: 320px) {
    .banner-info .title-module {
      font-size: 1.125em;
    }
  }
  .banner-info input {
    /*font-family: "Capita";*/
  }
  
  @media screen and (max-width: 640px) {
    .recent-photos {
      overflow: hidden;
    }
  }
  @media screen and (min-width: 1025px) {
    .recent-photos {
      margin-left: 60px;
      margin-right: 60px;
    }
  }
  .recent-photos .slides figure {
    border: 1px solid #b6b8b8;
  }
  .recent-photos .flex-direction-nav a {
    top: 23%;
  }
  .recent-photos .flex-direction-nav .flexslider:hover .flex-next {
    opacity: 1;
  }
  .recent-photos .flex-direction-nav .flex-prev, .recent-photos .flex-direction-nav .flex-next, .recent-photos .flex-direction-nav .flex-next.flex-disabled, .recent-photos .flex-direction-nav .flex-prev.flex-disabled {
    color: #676767;
    opacity: 1;
  }
  .recent-photos .flex-direction-nav .flex-prev, .recent-photos .flex-direction-nav .flex-next {
    margin-top: -22px;
    width: 45px;
    height: 45px;
    background: #fff;
    border-radius: 50%;
  }
  .recent-photos .flex-direction-nav .flex-prev:before, .recent-photos .flex-direction-nav .flex-next:before {
    padding-top: 2px;
    padding-left: 2px;
  }
  @media screen and (max-width: 640px) {
    .recent-photos .flex-direction-nav .flex-prev, .recent-photos .flex-direction-nav .flex-next {
      top: 10% !important;
      height: 140px;
      width: 140px;
      border-radius: 50%;
      background: #84628d;
      text-align: center;
      color: #fff;
      border: 1px solid #fff;
    }
  }
  @media screen and (max-width: 320px) {
    .recent-photos .flex-direction-nav .flex-prev, .recent-photos .flex-direction-nav .flex-next {
      height: 75px;
      width: 75px;
    }
    .recent-photos .flex-direction-nav .flex-prev:before, .recent-photos .flex-direction-nav .flex-next:before {
      font-size: 20px;
    }
  }
  @media screen and (max-width: 640px) {
    .recent-photos .flex-direction-nav .flex-prev {
      left: -17% !important;
    }
  }
  @media screen and (max-width: 450px) {
    .recent-photos .flex-direction-nav .flex-prev {
      left: -25% !important;
    }
  }
  @media screen and (max-width: 320px) {
    .recent-photos .flex-direction-nav .flex-prev {
      left: -20% !important;
    }
  }
  .recent-photos .flex-direction-nav .flex-prev:before {
    font-family: "icomoon";
    content: "\e622";
  }
  @media screen and (max-width: 640px) {
    .recent-photos .flex-direction-nav .flex-prev:before {
      padding-top: 35%;
      padding-left: 50%;
    }
  }
  @media screen and (max-width: 640px) {
    .recent-photos .flex-direction-nav .flex-next {
      right: -17% !important;
    }
  }
  @media screen and (max-width: 640px) {
    .recent-photos .flex-direction-nav .flex-next {
      right: -17% !important;
    }
  }
  @media screen and (max-width: 450px) {
    .recent-photos .flex-direction-nav .flex-next {
      right: -25% !important;
    }
  }
  @media screen and (max-width: 320px) {
    .recent-photos .flex-direction-nav .flex-next {
      right: -20% !important;
    }
  }
  .recent-photos .flex-direction-nav .flex-next:before {
    font-family: "icomoon";
    content: "\e621";
  }
  @media screen and (max-width: 640px) {
    .recent-photos .flex-direction-nav .flex-next:before {
      padding-top: 35%;
      padding-right: 50%;
    }
  }
  
  .title-recent-photos {
    font-size: 1.375em;
    color: #676767;
    line-height: 22px;
    
    font-weight: 400;
    text-transform: uppercase;
  }
  
  .hero-slider:after {
    content: "";
    display: table;
    clear: both;
  }
  .hero-slider .flex-control-paging li a {
    background: transparent;
    border: 1px solid #fff;
    -webkit-box-shadow: none;
            box-shadow: none;
    width: 18px;
    height: 18px;
  }
  .hero-slider .flex-control-paging li a:hover {
    background: #fff;
  }
  .hero-slider .flex-control-paging li a.flex-active {
    background: #fff;
  }
  
  .headline-text {
    font-size: 3.75em;
    color: #fff;
    text-align: right;
  }
  @media screen and (max-width: 640px) {
    .headline-text {
      font-size: 2.25em;
    }
  }
  
  .sub-headline-text {
    font-size: 1.375em;
    color: #fff;
    text-align: right;
  }
  @media screen and (max-width: 640px) {
    .sub-headline-text {
      font-size: 0.9375em;
    }
  }
  
  .event {
    text-align: center;
  }
  @media screen and (max-width: 640px) {
    .event {
      text-align: left;
    }
  }
  .event figure {
    border: 1px solid #bbbdbf;
  }
  .event figure img {
    display: block;
    width: 100%;
  }
  .event .title-event {
    color: #84628d;
    font-size: 1.875em;
    font-weight: 300;
    line-height: 1.25;
    padding-top: .25em;
  }
  @media screen and (max-width: 320px) {
    .event .title-event {
      font-size: 1.125em;
    }
  }
  .event .title-event.brown {
    color: #825622;
  }
  .event .title-event.tea-blue {
    color: #327A8C;
  }
  .event .learn-more, .event .add-event {
    font-weight: lighter;
    height: 30px;
    line-height: 27px;
    font-size: 0.9375em;
  }
  @media screen and (max-width: 320px) {
    .event .learn-more, .event .add-event {
      height: 23px;
      line-height: 23px;
      font-size: 0.6875em;
    }
  }
  .event .excerpt-event {
    font-size: 1em;
    color: #2a2a2a;
  }
  @media screen and (max-width: 320px) {
    .event .excerpt-event {
      font-size: 0.6875em;
    }
  }
  @media screen and (max-width: 640px) {
    .event.type-2 {
      text-align: center;
    }
  }
  .event.type-2 .title-event {
    color: #84628d;
    font-size: 1.5em;
  }
  @media screen and (max-width: 640px) {
    .event.type-2 .title-event {
      font-size: 1.625em;
      margin-bottom: 5px;
    }
  }
  @media screen and (max-width: 320px) {
    .event.type-2 .title-event {
      font-size: 0.8125em;
    }
  }
  .event.type-2 .title-event.brown {
    color: #825622;
  }
  @media screen and (max-width: 640px) {
    .event.type-2 .excerpt-event {
      font-size: 1.375em;
      line-height: 31px;
      margin-bottom: 23px;
    }
  }
  @media screen and (max-width: 320px) {
    .event.type-2 .excerpt-event {
      font-size: 0.6875em;
      line-height: 15px;
    }
  }
  @media screen and (max-width: 640px) {
    .event.type-2 .learn-more, .event.type-2 .add-event {
      height: 45px;
      line-height: 45px;
      font-size: 1.375em;
    }
  }
  @media screen and (max-width: 320px) {
    .event.type-2 .learn-more, .event.type-2 .add-event {
      font-size: 11px;
      line-height: 21px;
      height: 24px;
    }
  }
  .event.type-2 figure {
    overflow: hidden;
    max-height: 160px;
  }
  .event.type-2 figure img {
    height: auto;
    min-height: 100%;
  }
  
  .image-banner {
    width: 100%;
    height: 450px;
  }
  .image-banner figure {
    width: 100%;
    height: 100%;
  }
  .image-banner figure img {
    display: block;
    width: 100%;
    height: 100%;
  }
  
  .default-article {
    border-bottom: 2px solid #ede5d7;
  }
  .default-article:nth-last-child(1) {
    border-bottom: 0;
  }
  .default-article .button-default, .default-article .ee_view_cart {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media screen and (max-width: 320px) {
    .default-article .button-default, .default-article .ee_view_cart {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  
  .title-article {
    font-weight: 400;
    font-size: 1.875em;
    color: #84628e;
  }
  @media screen and (max-width: 640px) {
    .title-article {
      font-size: 2.1875em;
    }
  }
  @media screen and (max-width: 320px) {
    .title-article {
      font-size: 1.0625em;
    }
  }
  
  .text-article {
    line-height: 30px;
    text-align: left;
    font-size: 1.125em;
    line-height: 30px;
  }
  @media screen and (max-width: 640px) {
    .text-article {
      font-size: 1.625em;
      line-height: 37px;
    }
  }
  @media screen and (max-width: 320px) {
    .text-article {
      font-size: 0.8125em;
      line-height: 19px;
      margin-bottom: 0;
    }
  }
  
  .list-article {
    font-size: 1.125em;
  }
  @media screen and (max-width: 640px) {
    .list-article {
      font-size: 1.625em;
    }
  }
  @media screen and (max-width: 320px) {
    .list-article {
      font-size: 0.8125em;
    }
  }
  @media screen and (max-width: 320px) {
    .list-article li {
      margin-bottom: 0;
      line-height: 19px;
    }
  }
  
  @media screen and (max-width: 320px) {
    .content-box-article ul li {
      font-size: 0.8125em;
    }
  }
  
  .page-id-59 .content-box-article ul li {
    font-size: 1.125em;
    
  }
  @media screen and (max-width: 320px) {
    .page-id-59 .content-box-article ul li {
      font-size: 0.8125em;
    }
  }
  
  .default-page img {
    width: auto;
    max-width: 100%;
  }
  .default-page p.text-default, .default-page ul.list-article li {
    
  }
  .default-page .box-text table {
    
    margin-bottom: 15px;
  }
  .default-page .box-text table td {
    padding-top: 5px;
    padding-right: 15px;
    padding-bottom: 5px;
  }
  
  .normal-single-page .icons img:nth-child(n+3) {
    display: none !important;
  }
  
  .spa-home-events .icons img:nth-child(n+4) {
    display: none !important;
  }
  
  @media screen and (max-width: 640px) {
    .menu-slider.type-spa .flex-prev, .menu-slider.type-spa .flex-next {
      background: #84628e;
    }
  }
  @media screen and (max-width: 640px) {
    .menu-slider.type-basic .flex-prev, .menu-slider.type-basic .flex-next {
      background: #84628d;
    }
  }
  .menu-slider .title-coming {
    color: #84628d;
    font-size: 1.5em;
  }
  @media screen and (max-width: 640px) {
    .menu-slider .title-coming {
      font-size: 1.625em;
    }
  }
  @media screen and (max-width: 320px) {
    .menu-slider .title-coming {
      font-size: 0.8125em;
    }
  }
  @media screen and (max-width: 320px) {
    .menu-slider .date {
      font-size: 0.5625em;
    }
  }
  .menu-slider .details-coming {
    color: #2a2a2a;
    font-size: 1em;
  }
  @media screen and (max-width: 640px) {
    .menu-slider .details-coming {
      font-size: 1.375em;
    }
  }
  @media screen and (max-width: 320px) {
    .menu-slider .details-coming {
      font-size: 0.6875em;
    }
  }
  .menu-slider .button-default, .menu-slider .ee_view_cart {
    font-size: 0.9375em;
  }
  @media screen and (max-width: 640px) {
    .menu-slider .button-default, .menu-slider .ee_view_cart {
      font-size: 1.375em;
    }
  }
  @media screen and (max-width: 320px) {
    .menu-slider .button-default, .menu-slider .ee_view_cart {
      font-size: 0.6875em;
    }
  }
  .menu-slider .flex-direction-nav a {
    top: 23%;
  }
  .menu-slider .flex-direction-nav .flexslider:hover .flex-next {
    opacity: 1;
  }
  .menu-slider .flex-direction-nav .flex-prev, .menu-slider .flex-direction-nav .flex-next, .menu-slider .flex-direction-nav .flex-next.flex-disabled, .menu-slider .flex-direction-nav .flex-prev.flex-disabled {
    color: #676767;
    opacity: 1;
  }
  @media screen and (max-width: 640px) {
    .menu-slider .flex-direction-nav .flex-prev, .menu-slider .flex-direction-nav .flex-next {
      top: 10%;
      height: 150px;
      width: 150px;
      border-radius: 50%;
      text-align: center;
      color: #fff;
      border: 1px solid #fff;
    }
  }
  @media screen and (max-width: 320px) {
    .menu-slider .flex-direction-nav .flex-prev, .menu-slider .flex-direction-nav .flex-next {
      height: 75px;
      width: 75px;
    }
    .menu-slider .flex-direction-nav .flex-prev:before, .menu-slider .flex-direction-nav .flex-next:before {
      font-size: 20px;
    }
  }
  @media screen and (max-width: 640px) {
    .menu-slider .flex-direction-nav .flex-prev {
      left: -17% !important;
    }
  }
  @media screen and (max-width: 450px) {
    .menu-slider .flex-direction-nav .flex-prev {
      left: -25% !important;
    }
  }
  @media screen and (max-width: 320px) {
    .menu-slider .flex-direction-nav .flex-prev {
      left: -20% !important;
    }
  }
  .menu-slider .flex-direction-nav .flex-prev:before {
    font-family: "icomoon";
    content: "\e622";
  }
  @media screen and (max-width: 640px) {
    .menu-slider .flex-direction-nav .flex-prev:before {
      padding-top: 35%;
      padding-left: 40%;
    }
  }
  @media screen and (max-width: 640px) {
    .menu-slider .flex-direction-nav .flex-next {
      right: -17% !important;
    }
  }
  @media screen and (max-width: 450px) {
    .menu-slider .flex-direction-nav .flex-next {
      right: -25% !important;
    }
  }
  @media screen and (max-width: 320px) {
    .menu-slider .flex-direction-nav .flex-next {
      right: -20% !important;
    }
  }
  .menu-slider .flex-direction-nav .flex-next:before {
    font-family: "icomoon";
    content: "\e621";
  }
  @media screen and (max-width: 640px) {
    .menu-slider .flex-direction-nav .flex-next:before {
      padding-top: 35%;
      padding-right: 40%;
    }
  }
  
  .box-menu-slider {
    text-align: center;
  }
  .box-menu-slider .title-menus {
    font-size: 1.5em;
    color: #84628e;
  }
  .box-menu-slider .title-menus.blue {
    color: #84628d;
  }
  @media screen and (max-width: 640px) {
    .box-menu-slider .title-menus {
      font-size: 2em;
    }
  }
  @media screen and (max-width: 320px) {
    .box-menu-slider .title-menus {
      font-size: 1em;
    }
  }
  .box-menu-slider .list-recipes {
    list-style: none;
  }
  .box-menu-slider .list-recipes a {
    color: #2a2a29;
  }
  .box-menu-slider .list-recipes li {
    font-size: 1em;
    color: #2a2a29;
  }
  @media screen and (max-width: 640px) {
    .box-menu-slider .list-recipes li {
      font-size: 1.375em;
    }
  }
  @media screen and (max-width: 320px) {
    .box-menu-slider .list-recipes li {
      font-size: 0.75em;
      line-height: 16px;
    }
  }
  
  .quote {
    text-align: center;
    padding: 30px 10px;
    position: relative;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  @media screen and (max-width: 320px) {
    .quote {
      padding: 25px 5px;
    }
  }
  .quote:before {
    top: 0px;
  }
  .quote:after {
    bottom: 0px;
  }
  .quote:before, .quote:after {
    background: #bec0c1;
    content: "";
    height: 1px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 166px;
  }
  .quote .text-quote {
    font-size: 1.625em;
    line-height: 36px;
  }
  @media screen and (max-width: 320px) {
    .quote .text-quote {
      font-size: 0.8125em;
      line-height: 16px;
    }
  }
  .quote .text-quote, .quote .author-quote {
    color: #825622;
  }
  .quote .text-quote p, .quote .author-quote p {
    color: #825622;
  }
  .quote .author-quote {
    font-size: 1.375em;
    font-weight: 300;
    font-style: italic;
    line-height: 36px;
  }
  @media screen and (max-width: 320px) {
    .quote .author-quote {
      font-size: 0.6875em;
      line-height: 13px;
    }
  }
  
  .item-grid figure {
    border: 1px solid #a8a8aa;
  }
  .item-grid .title-box {
    font-size: 1.875em;
    color: #016699;
  }
  @media screen and (max-width: 320px) {
    .item-grid .title-box {
      font-size: 1.25em;
      line-height: 16px;
      margin-bottom: 20px;
    }
  }
  .item-grid .details-box {
    font-size: 1em;
    color: #2a2a29;
    line-height: 26px;
  }
  @media screen and (max-width: 320px) {
    .item-grid .details-box {
      font-size: 1em;
      line-height: 22px;
    }
  }
  
  .box-accordion.bg-blue-diamond {
    margin-bottom: 0;
  }
  .box-accordion.active i:before {
    content: "\e624";
    font-family: 'icomoon';
  }
  
  .title-accordion {
    font-size: 2.25em;
    
    margin-bottom: 0;
  }
  @media screen and (max-width: 320px) {
    .title-accordion {
      font-size: 1.125em;
    }
  }
  .title-accordion:before {
    height: 0;
  }
  .title-accordion i {
    margin-right: 20px;
    font-size: 30px;
  }
  @media screen and (max-width: 320px) {
    .title-accordion i {
      font-size: 16px;
    }
  }
  
  .content-accordion {
    display: none;
  }
  
  .title-accordion.active + .content-accordion {
    display: block;
  }
  
  .map {
    position: relative;
  }
  .map .link-to-pdf {
    color: #000;
    text-align: center;
    display: block;
  }
  .map .link-to-pdf:hover {
    color: #224c74;
  }
  @media screen and (min-width: 641px) {
    .map .map-mobile {
      display: none;
    }
  }
  @media screen and (max-width: 640px) {
    .map .map-desktop {
      display: none;
    }
  }
  .map .item-map {
    width: 86px;
    text-align: center;
    position: absolute;
  }
  @media screen and (max-width: 640px) {
    .map .item-map {
      display: none;
    }
  }
  .map .item-map:active .item-title {
    display: none;
  }
  .map .item-map .item-title {
    color: #fff;
    font-size: 20px;
    line-height: 22px;
    font-weight: 600;
    text-shadow: 1px 1px #676767;
  }
  @media screen and (max-width: 1150px) {
    .map .item-map .item-title {
      font-size: 0.8125em;
    }
  }
  @media screen and (max-width: 768px) {
    .map .item-map .item-title {
      font-size: 0.6875em;
    }
  }
  .map .box-item-map {
    background: #f7f0e3;
    padding: 15px;
    width: 294px;
    position: absolute;
    top: 110px;
    z-index: 1;
    display: none;
  }
  @media screen and (max-width: 1024px) {
    .map .box-item-map {
      width: 214px;
    }
  }
  @media screen and (max-width: 890px) {
    .map .box-item-map {
      width: 200px;
    }
  }
  .map .box-item-map:before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 25px 30px 25px;
    border-color: transparent transparent #f7f0e3 transparent;
    position: absolute;
    top: -25px;
  }
  .map .box-item-map.center {
    left: -105px;
  }
  @media screen and (max-width: 1024px) {
    .map .box-item-map.center {
      left: -65px;
    }
  }
  @media screen and (max-width: 890px) {
    .map .box-item-map.center {
      left: -55px;
    }
  }
  .map .box-item-map.center:before {
    left: 50%;
    margin-left: -25px;
  }
  .map .box-item-map.left:before {
    left: 15px;
  }
  .map .box-item-map.right {
    right: 0;
  }
  .map .box-item-map.right:before {
    right: 15px;
  }
  .map .box-item-map figure {
    width: 262px;
    border: 1px solid #b9babb;
    display: inline-block;
    margin-bottom: 5px;
  }
  @media screen and (max-width: 1024px) {
    .map .box-item-map figure {
      width: 186px;
    }
  }
  @media screen and (max-width: 890px) {
    .map .box-item-map figure {
      width: 170px;
    }
  }
  .map .box-item-map figure img {
    display: block;
    width: 100%;
  }
  .map .box-details-map {
    text-align: left;
    
    font-size: 18px;
    color: #404041;
  }
  .map .box-details-map h3 {
    font-weight: 600;
    margin-bottom: 5px;
  }
  .map .box-details-map p {
    line-height: 24px;
  }
  .map .box-details-map .light {
    font-weight: 300;
    font-style: italic;
  }
  .map .box-details-map .light a {
    color: #676767;
  }
  .map .box-details-map .light a:hover {
    color: #84628d;
  }
  
  .box-text .map .box-item-map {
    top: 75px;
  }
  
  @media screen and (max-width: 1150px) {
    .accordion .map .box-item-map {
      top: 105px;
    }
  }
  @media screen and (max-width: 1024px) {
    .accordion .map .box-item-map {
      top: 95px;
    }
  }
  @media screen and (max-width: 890px) {
    .accordion .map .box-item-map {
      top: 85px;
    }
  }
  @media screen and (max-width: 768px) {
    .accordion .map .box-item-map {
      top: 75px;
    }
  }
  @media screen and (min-width: 890px) {
    .accordion .map .nature-walk .box-item-map {
      left: -19px;
    }
    .accordion .map .nature-walk .box-item-map.center:before {
      left: 29%;
    }
  }
  @media screen and (min-width: 1024px) {
    .accordion .map .nature-walk .box-item-map {
      left: -37px;
    }
    .accordion .map .nature-walk .box-item-map.center:before {
      left: 28%;
    }
  }
  @media screen and (min-width: 890px) {
    .accordion .map .garden .box-item-map {
      left: -102px;
    }
    .accordion .map .garden .box-item-map.center:before {
      left: 68%;
    }
  }
  @media screen and (min-width: 1024px) {
    .accordion .map .garden .box-item-map {
      left: -157px;
    }
    .accordion .map .garden .box-item-map.center:before {
      left: 69%;
    }
  }
  
  .nature-walk {
    top: 40%;
    left: 2%;
  }
  @media screen and (max-width: 768px) {
    .nature-walk {
      left: 0;
    }
  }
  .nature-walk .border {
    background-image: url("../images/pines.svg");
  }
  .nature-walk .item-title {
    position: absolute;
    top: -40px;
    margin: 0 auto;
    left: 0;
    right: 0;
  }
  @media screen and (max-width: 1150px) {
    .nature-walk .item-title {
      top: -15px;
    }
  }
  @media screen and (max-width: 890px) {
    .nature-walk .box-item-map.center {
      left: 0;
    }
    .nature-walk .box-item-map.center:before {
      left: 22%;
    }
  }
  
  .childrens-playground {
    top: 40%;
    left: 10%;
  }
  .childrens-playground .border {
    background-image: url("../images/playground.svg");
  }
  
  .dining-hall {
    top: 44.5%;
    left: 21.9%;
  }
  @media screen and (max-width: 768px) {
    .dining-hall {
      left: 19%;
    }
  }
  .dining-hall .border {
    background-image: url("../images/dinninghall.svg");
  }
  .dining-hall .item-title {
    position: absolute;
    top: -40px;
    margin: 0 auto;
    left: 0;
    right: 0;
  }
  @media screen and (max-width: 1150px) {
    .dining-hall .item-title {
      top: -15px;
    }
  }
  
  .main-hall {
    top: 17%;
    left: 21.9%;
  }
  @media screen and (max-width: 768px) {
    .main-hall {
      left: 20%;
    }
  }
  .main-hall .border {
    background-image: url("../images/mainhall.svg");
  }
  
  .vedas-hall {
    top: 17%;
    left: 30.2%;
  }
  @media screen and (max-width: 768px) {
    .vedas-hall {
      left: 28%;
    }
  }
  .vedas-hall .border {
    background-image: url("../images/vedahall.svg");
  }
  .vedas-hall .item-title {
    position: absolute;
    top: -40px;
    margin: 0 auto;
    left: 0;
    right: 0;
  }
  @media screen and (max-width: 1150px) {
    .vedas-hall .item-title {
      top: -15px;
    }
  }
  
  .labyrinth {
    top: 17%;
    left: 38.6%;
  }
  @media screen and (max-width: 768px) {
    .labyrinth {
      left: 36%;
    }
  }
  .labyrinth .border {
    background-image: url("../images/laberint.svg");
  }
  
  .pottery-studio {
    top: 26%;
    right: 38%;
  }
  @media screen and (max-width: 768px) {
    .pottery-studio {
      right: 36%;
    }
  }
  .pottery-studio .border {
    background-image: url("../images/pottery.svg");
  }
  
  .hillside-hall {
    top: 12%;
    right: 23%;
  }
  .hillside-hall .border {
    background-image: url("../images/hillside.svg");
  }
  
  .the-spa {
    top: 36%;
    right: 8.5%;
  }
  @media screen and (max-width: 768px) {
    .the-spa {
      right: 6.5%;
    }
  }
  .the-spa .border {
    background-image: url("../images/spa.svg");
  }
  @media screen and (max-width: 890px) {
    .the-spa .box-item-map.center {
      left: -74px;
    }
    .the-spa .box-item-map.center:before {
      left: 59%;
    }
  }
  
  .garden {
    top: 21%;
    right: 3%;
  }
  @media screen and (max-width: 768px) {
    .garden {
      right: 0;
    }
  }
  .garden .border {
    background-image: url("../images/thegarden.svg");
  }
  @media screen and (max-width: 890px) {
    .garden .box-item-map.center {
      left: -115px;
    }
    .garden .box-item-map.center:before {
      left: 79%;
    }
  }
  
  .border {
    height: 86px;
    width: 86px;
    display: inline-block;
    border-radius: 50%;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .border.half-size {
    height: 43px;
    width: 43px;
  }
  @media screen and (max-width: 1150px) {
    .border {
      height: 85px;
      width: 85px;
      text-align: center;
    }
  }
  @media screen and (max-width: 768px) {
    .border {
      height: 43px;
      width: 43px;
    }
  }
  .border:hover {
    border: 5px solid #fff;
  }
  @media screen and (max-width: 1150px) {
    .border:hover {
      border: 3px solid #fff;
    }
  }
  @media screen and (max-width: 768px) {
    .border:hover {
      border: 2px solid #fff;
    }
  }
  
  @media screen and (max-width: 1150px) {
    .accordion .border {
      width: 75px;
      height: 75px;
    }
  }
  @media screen and (max-width: 1024px) {
    .accordion .border {
      width: 65px;
      height: 65px;
    }
  }
  @media screen and (max-width: 890px) {
    .accordion .border {
      width: 55px;
      height: 55px;
    }
  }
  @media screen and (max-width: 768px) {
    .accordion .border {
      width: 45px;
      height: 45px;
    }
  }
  
  .article-accomodation {
    border-bottom: 0px;
  }
  .article-accomodation figure {
    border: 1px solid #cfcdd3;
    max-width: 262px;
  }
  .article-accomodation figure img {
    display: block;
    margin: 0;
  }
  @media screen and (max-width: 320px) {
    .article-accomodation ul {
      padding-left: 0px;
    }
  }
  .article-accomodation li {
    font-size: 1.125em;
  }
  @media screen and (max-width: 640px) {
    .article-accomodation li {
      font-size: 1.625em;
    }
  }
  @media screen and (max-width: 320px) {
    .article-accomodation li {
      font-size: 0.8125em;
    }
  }
  .article-accomodation .button-default, .article-accomodation .ee_view_cart {
    font-size: 0.9375em;
    line-height: 28px;
  }
  @media screen and (max-width: 640px) {
    .article-accomodation .button-default, .article-accomodation .ee_view_cart {
      line-height: 40px;
      margin-bottom: 20px;
      font-size: .79em;
    }
  }
  
  .normal-weight {
    font-weight: 300;
  }
  
  .form-contact label, .form-contact .legend-text {
    display: block;
  }
  @media screen and (max-width: 640px) {
    .form-contact label, .form-contact .legend-text {
      font-size: 1.625em;
      margin-bottom: 10px;
    }
  }
  
  .contact-form .gform_confirmation_message {
    text-align: center;
    color: #069;
    font-size: 30px;
    margin-bottom: 30px;
  }
  @media screen and (max-width: 320px) {
    .contact-form .form-contact .gfield_label {
      font-size: 0.8125em;
    }
  }
  @media screen and (max-width: 320px) {
    .contact-form .form-contact .gfield_checkbox label {
      font-size: 0.8125em !important;
    }
  }
  @media screen and (max-width: 320px) {
    .contact-form .form-contact .gform_footer .button-landing {
      font-size: 0.9375em;
      line-height: 26px;
    }
  }
  
  .extra-contact .text-default {
    font-size: 1.125em;
  }
  @media screen and (max-width: 640px) {
    .extra-contact .text-default {
      font-size: 1.5em;
    }
  }
  @media screen and (max-width: 320px) {
    .extra-contact .text-default {
      font-size: 0.75em;
    }
  }
  .extra-contact .text-default i {
    margin-right: 5px;
  }
  .extra-contact .text-default h3 {
    font-weight: 600;
  }
  @media screen and (max-width: 320px) {
    .extra-contact .text-default h3 {
      font-size: 1.25em;
      font-weight: 600;
    }
  }
  .extra-contact .text-default .social-icon {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  @media screen and (max-width: 640px) {
    .extra-contact .sub-head-title {
      font-size: 2.25em;
    }
  }
  
  .return-to a {
    color: #676767;
    text-transform: uppercase;
  }
  @media screen and (max-width: 320px) {
    .return-to a {
      font-size: 0.5em;
    }
  }
  .return-to a:hover {
    opacity: .8;
  }
  
  .box-post .box-button {
    margin-bottom: 65px;
  }
  @media screen and (max-width: 640px) {
    .box-post .box-button {
      margin-top: 20px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .box-post h1.title-medium {
    line-height: 100%;
    margin-bottom: 10px;
  }
  
  .single-post {
    border-bottom: 1px solid #c4c5c7;
  }
  .single-post.no-border {
    border-bottom: 0px;
  }
  .single-post.primary-post .text-default {
    font-size: 1.625em;
    line-height: 50px;
  }
  @media screen and (max-width: 640px) {
    .single-post.normal {
      background: url("../images/bg_blue_diamond.jpg");
      background-repeat: repeat-x;
      background-color: #fff;
    }
  }
  @media screen and (max-width: 890px) {
    .single-post.normal.remove-padding-bottom {
      padding-bottom: 25px;
    }
  }
  .single-post .title-large {
    font-size: 3.8125em;
    font-weight: 400;
  }
  @media screen and (max-width: 640px) {
    .single-post .title-large {
      font-size: 2.25em;
    }
  }
  .single-post .title-medium {
    font-size: 2.25em;
    font-weight: 400;
  }
  @media screen and (max-width: 320px) {
    .single-post .title-medium {
      font-size: 1.125em;
    }
  }
  
  .addthis-smartlayers {
    display: none;
  }
  
  .spa-default-content {
    /*font-family: "Capita";*/
    font-weight: 300;
  }
  
  .box-post-archive .container-single-post {
    padding: 0;
  }
  .box-post-archive figure {
    max-width: 100%;
  }
  
  img.aligncenter {
    text-align: center;
    display: block;
  }
  
  .container-blog a {
    color: #84628d;
  }
  
  ol li p.text-default.type-b {
    font-size: 1em;
  }
  
  .head-post {
    word-wrap: break-word;
  }
  .head-post .title-large {
    font-size: 2.25em;
    font-weight: 400;
    margin-left: 0;
  }
  .head-post .title-medium {
    font-size: 2.25em;
    font-weight: 400;
  }
  @media screen and (max-width: 640px) {
    .head-post .text-default {
      font-size: 1.5em;
    }
  }
  .head-post .head-text {
    font-size: 1.625em;
  }
  
  .nav-post .container-nav-arrows {
    border-top: 1px solid #c4c5c7;
    border-bottom: 1px solid #c4c5c7;
    display: inline-block;
    width: 100%;
  }
  .nav-post .nav-arrow {
    font-size: 1.5em;
    color: #84628d;
  }
  @media screen and (max-width: 320px) {
    .nav-post .nav-arrow {
      font-size: 0.8125em;
    }
  }
  .nav-post .nav-arrow:hover {
    color: #a4ce5d;
  }
  .nav-post .title-post {
    color: #676767;
    font-size: 1.125em;
    font-weight: 600;
  }
  @media screen and (max-width: 320px) {
    .nav-post .title-post {
      font-size: 0.5625em;
    }
  }
  
  .single-comment {
    border-bottom: 1px solid #d0d2d3;
    line-height: 24px;
  }
  .single-comment .user-name {
    font-weight: 600;
    font-size: 1em;
    color: #676767;
  }
  @media screen and (max-width: 640px) {
    .single-comment .user-name {
      font-size: 1.125em;
    }
  }
  .single-comment .date-comment, .single-comment .time-comment {
    color: #bbbdbf;
    font-size: 0.875em;
  }
  @media screen and (max-width: 640px) {
    .single-comment .date-comment, .single-comment .time-comment {
      font-size: 1em;
    }
  }
  .single-comment .date-comment:after {
    content: "|";
    color: #bbbdbf;
    margin-left: 5px;
    margin-right: 5px;
  }
  .single-comment .text-default {
    font-size: 1em;
    color: #676767;
    margin-bottom: 0;
  }
  @media screen and (max-width: 640px) {
    .single-comment .text-default {
      font-size: 1.125em;
    }
  }
  .single-comment .button-reply, .single-comment .comment-reply-link {
    font-size: 0.875em;
    color: #84628d;
  }
  @media screen and (max-width: 640px) {
    .single-comment .button-reply, .single-comment .comment-reply-link {
      font-size: 1.125em;
    }
  }
  .single-comment .button-reply:hover, .single-comment .comment-reply-link:hover {
    color: #a4ce5d;
  }
  
  .share span {
    text-transform: uppercase;
    color: #bbbdbf;
  }
  
  .head-comment .number-comment, .comment-form .number-comment {
    font-size: 1.5em;
    color: #676767;
  }
  @media screen and (max-width: 320px) {
    .head-comment .number-comment, .comment-form .number-comment {
      font-size: 0.8125em;
    }
  }
  
  .head-post .text-default {
    font-size: 1.625em;
  }
  @media screen and (max-width: 640px) {
    .head-post .text-default {
      font-size: 1.875em;
    }
  }
  
  .single-post-extra .author, .single-post-extra .date, .single-post-extra .share span {
    font-size: 1em;
    text-transform: uppercase;
  }
  @media screen and (max-width: 320px) {
    .single-post-extra .author, .single-post-extra .date, .single-post-extra .share span {
      font-size: 0.5625em;
    }
  }
  .single-post-extra .author {
    color: #84628d;
  }
  .single-post-extra .date {
    color: #bbbdbf;
  }
  .single-post-extra .date:before {
    content: "|";
    border-left: #bbbdbf;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
  }
  
  .box-single-post h1, .box-single-post h2, .box-single-post h3, .box-single-post h4, .box-single-post h5, .box-single-post h6 {
    margin-left: 95px;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 890px) {
    .box-single-post h1, .box-single-post h2, .box-single-post h3, .box-single-post h4, .box-single-post h5, .box-single-post h6 {
      margin-left: 25px;
    }
  }
  @media screen and (max-width: 768px) {
    .box-single-post h1, .box-single-post h2, .box-single-post h3, .box-single-post h4, .box-single-post h5, .box-single-post h6 {
      margin-left: 15px;
    }
  }
  .box-single-post ul, .box-single-post ol {
    font-size: 1.25em;
  }
  @media screen and (max-width: 768px) {
    .box-single-post ul, .box-single-post ol {
      font-size: 1.375em;
      line-height: 140%;
    }
  }
  .box-single-post ol {
    line-height: 30px;
    color: #404041;
    /* font-family: Gibson */ /* Removed font */
    padding-left: 125px;
    font-weight: 300;
  }
  @media screen and (max-width: 768px) {
    .box-single-post ol {
      padding-left: 40px;
    }
  }
  .box-single-post ul {
    padding-left: 115px;
    font-size: 1.25em;
  }
  @media screen and (max-width: 768px) {
    .box-single-post ul {
      padding-left: 25px;
    }
  }
  .box-single-post .text-default {
    padding-left: 95px;
  }
  @media screen and (max-width: 890px) {
    .box-single-post .text-default {
      padding-left: 25px;
    }
  }
  
  @media screen and (max-width: 890px) {
    .container-single-post {
      padding-left: 25px;
    }
  }
  
  .box-aside, aside.widget {
    background: #fdf5e9;
    border: 1px solid #f2e4d2;
    padding: 15px 15px 13px 15px;
    margin-bottom: 20px;
    color: #676767;
  }
  .box-aside a, aside.widget a {
    color: #676767;
  }
  .box-aside a:hover, aside.widget a:hover {
    text-decoration: underline;
  }
  
  .box-aside .list-tags .x-large,
  .box-aside .list-tags .large,
  .box-aside .list-tags .medium,
  .box-aside .list-tags .small, aside.widget .list-tags .x-large,
  aside.widget .list-tags .large,
  aside.widget .list-tags .medium,
  aside.widget .list-tags .small {
    display: inline-block;
  }
  .box-aside .list-tags .x-large, aside.widget .list-tags .x-large {
    font-size: 2.875em;
  }
  .box-aside .list-tags .large, aside.widget .list-tags .large {
    font-size: 2.1875em;
  }
  .box-aside .list-tags .medium, aside.widget .list-tags .medium {
    font-size: 1.75em;
  }
  .box-aside .list-tags .small, aside.widget .list-tags .small {
    font-size: 1.3125em;
  }
  
  .widget.gform_widget .gform_confirmation_wrapper.form-signup div {
    color: #a3ce5d;
    text-align: center;
  }
  
  .overview {
    margin-bottom: 60px;
  }
  .overview .button-default, .overview .ee_view_cart {
    text-align: center;
  }
  
  .accordion-event-details {
    margin-bottom: 55px;
  }
  .accordion-event-details .title-event-accordion {
    font-weight: 500;
    line-height: 68px;
  }
  @media screen and (max-width: 640px) {
    .accordion-event-details .title-event-accordion {
      font-size: 2.25em;
    }
  }
  .accordion-event-details .title-event-accordion i {
    margin-left: 10px;
    margin-right: 10px;
    font-size: 20px;
  }
  .accordion-event-details .box-accordion {
    border-bottom: 1px solid #cccecf;
  }
  .accordion-event-details .box-accordion:first-of-type {
    border-top: 1px solid #cccecf;
  }
  .accordion-event-details figure {
    border: 1px solid #cccecf;
  }
  @media screen and (max-width: 640px) {
    .accordion-event-details p {
      font-size: 1.5em;
    }
  }
  
  .event-slider .flex-control-nav {
    bottom: 30px;
  }
  .event-slider .flex-control-nav.flex-control-paging a {
    border: 1px solid #fff;
    background: transparent;
    width: 20px;
    height: 20px;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .event-slider .flex-control-nav.flex-control-paging a.flex-active {
    background: #fff;
  }
  
  @media screen and (max-width: 640px) {
    .aside-event-details .sub-head-title {
      font-size: 1.5em;
    }
  }
  @media screen and (max-width: 640px) {
    .aside-event-details li, .aside-event-details p {
      font-size: 1.125em;
    }
  }
  
  .details-date-time {
    list-style: none;
  }
  .details-date-time li {
    margin-bottom: 20px;
    line-height: 24px;
  }
  .details-date-time span {
    font-weight: 600;
  }
  
  .list-teacher, .aside-list-related-events, .aside-details-contact {
    list-style: none;
  }
  .list-teacher li, .aside-list-related-events li, .aside-details-contact li {
    margin-bottom: 5px;
  }
  
  .aside-box-prerequisites p {
    line-height: 24px;
  }
  
  .box-aside-follow .social-icon a {
    vertical-align: top;
  }
  .box-aside-follow .social-icon i {
    background: #84628d;
    color: #fdf5e9;
  }
  .box-aside-follow .blank-icons i {
    background: none;
    color: #84628d;
  }
  
  
  .list-packages .title-package {
    font-size: 1.5em;
    color: #fff;
  }
  @media screen and (max-width: 640px) {
    .list-packages .title-package {
      font-size: 2em;
    }
  }
  @media screen and (max-width: 320px) {
    .list-packages .title-package {
      font-size: 1em;
    }
  }
  .list-packages .details-package {
    color: #c3aab9;
  }
  @media screen and (max-width: 640px) {
    .list-packages .details-package {
      font-size: 1.375em;
    }
  }
  @media screen and (max-width: 320px) {
    .list-packages .details-package {
      font-size: 0.6875em;
    }
  }
  
  .explorer {
    text-align: center;
    padding-bottom: 25px;
  }
  .explorer .title-page {
    color: #fff;
  }
  .explorer .title-page:before {
    height: 0;
  }
  .explorer .button-default, .explorer .ee_view_cart {
    font-size: 0.9375em;
  }
  @media screen and (max-width: 640px) {
    .explorer .button-default, .explorer .ee_view_cart {
      font-size: 1.375em;
    }
  }
  @media screen and (max-width: 320px) {
    .explorer .button-default, .explorer .ee_view_cart {
      font-size: 0.6875em;
    }
  }
  
  .list-explorer .title-explorer {
    font-size: 1.5em;
    color: #fff;
  }
  @media screen and (max-width: 640px) {
    .list-explorer .title-explorer {
      font-size: 2em;
    }
  }
  @media screen and (max-width: 320px) {
    .list-explorer .title-explorer {
      font-size: 1em;
    }
  }
  .list-explorer .details-explorer {
    color: #fff;
  }
  @media screen and (max-width: 640px) {
    .list-explorer .details-explorer {
      font-size: 1.375em;
    }
  }
  @media screen and (max-width: 320px) {
    .list-explorer .details-explorer {
      font-size: 0.6875em;
    }
  }
  
  .coming-up {
    text-align: center;
  }
  
  .list-coming-up .title-coming {
    color: #84628d;
    font-size: 1.5em;
  }
  @media screen and (max-width: 640px) {
    .list-coming-up .title-coming {
      font-size: 1.625em;
    }
  }
  @media screen and (max-width: 320px) {
    .list-coming-up .title-coming {
      font-size: 0.8125em;
    }
  }
  @media screen and (max-width: 320px) {
    .list-coming-up .date {
      font-size: 0.5625em;
    }
  }
  .list-coming-up .details-coming {
    color: #2a2a2a;
    font-size: 1em;
  }
  @media screen and (max-width: 640px) {
    .list-coming-up .details-coming {
      font-size: 1.375em;
    }
  }
  @media screen and (max-width: 320px) {
    .list-coming-up .details-coming {
      font-size: 0.6875em;
    }
  }
  .list-coming-up .button-default, .list-coming-up .ee_view_cart {
    font-size: 0.9375em;
  }
  @media screen and (max-width: 640px) {
    .list-coming-up .button-default, .list-coming-up .ee_view_cart {
      font-size: 1.375em;
    }
  }
  @media screen and (max-width: 320px) {
    .list-coming-up .button-default, .list-coming-up .ee_view_cart {
      font-size: 0.6875em;
    }
  }
  
  .page-id-2024 .title-coming {
    color: #84628d;
    /*font-family: "Capita";*/
  }
  
  .page-id-2024 .details-coming {
    
    line-height: 1.5;
  }
  
  .page-id-2024 .button-light-purple {
    background-color: #175479;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#175479), to(#0f4a71));
    background-image: linear-gradient(#175479, #0f4a71);
    border: 1px solid #175479;
  }
  .page-id-2024 .button-light-purple:hover {
    background-color: #7fc261;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#7fc261), to(#a3ce5d));
    background-image: linear-gradient(#7fc261, #a3ce5d);
    border: 1px solid #158152;
  }
  .page-id-2024 .button-light-purple:active {
    background-color: #0082b1;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#0082b1), to(#036a95));
    background-image: linear-gradient(#0082b1, #036a95);
    border: 1px solid #17567c;
  }
  
  .box-pagination i {
    border: 1px solid #676767;
    color: #676767;
  }
  .box-pagination li a {
    color: #84628d;
  }
  .box-pagination li a.active {
    color: #676767;
  }
  
  .home-contact .title {
    font-size: 1.875em;
    color: #84628d;
  }
  @media screen and (max-width: 320px) {
    .home-contact .title {
      font-size: 1em;
    }
  }
  .home-contact .title-location, .home-contact .info-location, .home-contact .day {
    font-size: 1.125em;
    color: #676767;
    line-height: 30px;
  }
  @media screen and (max-width: 320px) {
    .home-contact .title-location, .home-contact .info-location, .home-contact .day {
      font-size: 0.625em;
      line-height: 15px;
    }
  }
  .home-contact .title-location {
    font-weight: 500;
  }
  
  .spa-hero-unit .testimonial, .spa-hero-unit .author {
    font-size: 1.3125em;
    line-height: 1.5;
    font-weight: 300;
    color: #fff;
  }
  @media screen and (max-width: 320px) {
    .spa-hero-unit .testimonial, .spa-hero-unit .author {
      font-size: 0.625em;
    }
  }
  .spa-hero-unit .author {
    font-style: italic;
  }
  .spa-hero-unit .content-banner-intro .title-page {
    font-style: normal;
    font-weight: 300;
    color: #84628d;
    font-size: 4.375em;
  }
  @media screen and (max-width: 320px) {
    .spa-hero-unit .content-banner-intro .title-page {
      font-size: 2.1875em;
    }
  }
  .spa-hero-unit .content-banner-intro .title-page:before {
    height: 0px;
  }
  
  @media screen and (max-width: 640px) {
    .whats-happening .excerpt-event {
      font-size: 1.375em;
    }
  }
  
  .hero-unit figcaption {
    text-align: center;
  }
  .hero-unit .title {
    font-size: 4.6875em;
    line-height: 5rem;
    color: #fff;
    font-family: 'Playfair Display',serif;
    /*text-shadow: 0 3px 14px rgba(51, 133, 170, 0.75);*/
  }
  @media screen and (max-width: 640px) {
    .hero-unit .title {
      font-size: 2.875em;
      line-height: 57px;
    }
  }
  @media screen and (max-width: 320px) {
    .hero-unit .title {
      font-size: 1.4375em;
      line-height: 28px;
    }
  }
  .hero-unit .details {
    font-size: 1.125em;
    color: #fff;
    line-height: 30px;
  }
  @media screen and (max-width: 320px) {
    .hero-unit .details {
      font-size: 0.5625em;
      line-height: 15px;
    }
  }
  
  .hero-unit .bulletslide .title {
    line-height: 40px;
  }
  
  .box-filter-type .row-form, .box-filter-type .form-additional-spa .gfield, .form-additional-spa .box-filter-type .gfield, .box-filter-type .form-additional-spa .gform_footer, .form-additional-spa .box-filter-type .gform_footer {
    margin-bottom: 10px;
  }
  .box-filter-type label.type-b {
    font-weight: 400;
  }
  .box-filter-type label.type-b span {
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
    width: 23px;
  }
  .box-filter-type label.type-b img {
    margin: 0 auto;
    display: block;
  }
  .box-filter-type input[type=checkbox].css-checkbox + label.css-label-checkbox, .box-filter-type .form-additional-spa .gfield_checkbox input[type=checkbox] + label, .form-additional-spa .gfield_checkbox .box-filter-type input[type=checkbox] + label {
    font-size: 1em;
    padding-left: 35px;
  }
  
  .weather-plugin {
    margin: 0px auto;
    color: #fff;
  }
  .weather-plugin i {
    color: #fff;
    font-family: weather;
    font-size: 100px;
    font-weight: normal;
    font-style: normal;
    line-height: 1.0;
  }
  
  #weather h2 {
    margin: 0 0 8px;
    color: #fff;
    font-size: 100px;
    font-weight: 300;
    text-align: center;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
  }
  
  .box-temp {
    text-align: left;
    font-size: 36px;
    
    border-left: 1px solid #3b8ca8;
    padding-left: 20px;
  }
  @media screen and (max-width: 320px) {
    .box-temp {
      font-size: 18px;
      padding-left: 20px;
    }
  }
  .box-temp .currently {
    font-size: 28px;
    padding-top: 5px;
  }
  @media screen and (max-width: 320px) {
    .box-temp .currently {
      font-size: 14px;
    }
  }
  
  .footer-slider .flexslider {
    color: #fff;
    text-align: center;
    max-width: 700px;
    margin: 0 auto;
    padding-top: 50px;
  }
  @media screen and (max-width: 1090px) {
    .footer-slider .flexslider {
      max-width: 70%;
      padding-top: 40px;
    }
  }
  @media screen and (max-width: 768px) {
    .footer-slider .flexslider {
      padding-top: 20px;
    }
  }
  @media screen and (max-width: 450px) {
    .footer-slider .flexslider {
      padding-top: 20px;
    }
  }
  .footer-slider .flexslider .flex-control-nav.flex-control-paging {
    display: none;
  }
  .footer-slider .flexslider .flex-direction-nav .flex-prev, .footer-slider .flexslider .flex-direction-nav .flex-next {
    color: #fff;
    opacity: 1;
  }
  .footer-slider .flexslider .flex-direction-nav .flex-prev {
    left: -25%;
  }
  @media screen and (max-width: 1090px) {
    .footer-slider .flexslider .flex-direction-nav .flex-prev {
      left: -15%;
    }
  }
  .footer-slider .flexslider .flex-direction-nav .flex-prev:before {
    font-family: "icomoon";
    content: "\e622";
  }
  .footer-slider .flexslider .flex-direction-nav .flex-next {
    right: -25%;
  }
  @media screen and (max-width: 1090px) {
    .footer-slider .flexslider .flex-direction-nav .flex-next {
      right: -15%;
    }
  }
  .footer-slider .flexslider .flex-direction-nav .flex-next:before {
    font-family: "icomoon";
    content: "\e621";
  }
  .footer-slider .testimonial {
    font-size: 1.5em;
    font-style: italic;
    font-weight: 300;
    line-height: 36px;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 768px) {
    .footer-slider .testimonial {
      font-size: 1.375em;
      line-height: 34px;
    }
  }
  @media screen and (max-width: 640px) {
    .footer-slider .testimonial {
      font-size: 1.25em;
      line-height: 32px;
    }
  }
  .footer-slider .author {
    font-size: 1.625em;
    font-weight: 300;
  }
  @media screen and (max-width: 640px) {
    .footer-slider .author {
      font-size: 1.5em;
    }
  }
  
  .gform_wrapper .validation_error {
    color: red;
    display: none;
  }
  .gform_wrapper .validation_message {
    color: red;
    text-align: center;
  }
  
  .gform_confirmation_message_5 .gform_confirmation_message {
    text-align: center;
    color: green;
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  
  .gform_confirmation_message_4 .gform_confirmation_message {
    text-align: center;
    color: green;
    font-size: 20px;
    padding: 5px 0;
    background-color: #fff;
  }
  
  .gform_fields li {
    list-style: none;
  }
  
  .gfield_select {
    margin-bottom: 10px;
  }
  
  .form-signup {
    position: relative;
  }
  .form-signup .validation_error {
    display: none;
  }
  .form-signup .gfield_description.validation_message {
    margin-top: 5px;
  }
  .form-signup .gform_body {
    width: 100%;
    padding-left: 10px;
    height: 32px;
  }
  .form-signup .gform_body::-webkit-input-placeholder {
    font-weight: 200;
  }
  .form-signup .gform_body::-moz-placeholder {
    font-weight: 200;
  }
  .form-signup .gform_body:-moz-placeholder {
    font-weight: 200;
  }
  .form-signup .gform_body:-ms-input-placeholder {
    font-weight: 200;
  }
  .form-signup .gform_footer, .form-signup button {
    position: absolute;
    right: 0;
    top: 0;
    height: 32px;
    width: 32px;
    outline: none;
  }
  .form-signup .gform_footer .legend, .form-signup button .legend {
    display: none;
  }
  .form-signup.form-newsletter .gform_body, .form-signup#gform_8 .gform_body {
    background: transparent;
    color: #fff;
  }
  .form-signup.form-newsletter .gform_body::-webkit-input-placeholder, .form-signup#gform_8 .gform_body::-webkit-input-placeholder {
    color: #fff;
  }
  .form-signup.form-newsletter .gform_body::-moz-placeholder, .form-signup#gform_8 .gform_body::-moz-placeholder {
    color: #fff;
  }
  .form-signup.form-newsletter .gform_body:-moz-placeholder, .form-signup#gform_8 .gform_body:-moz-placeholder {
    color: #fff;
  }
  .form-signup.form-newsletter .gform_body:-ms-input-placeholder, .form-signup#gform_8 .gform_body:-ms-input-placeholder {
    color: #fff;
  }
  .form-signup.form-newsletter .gform_footer, .form-signup.form-newsletter input[type="submit"], .form-signup#gform_8 .gform_footer, .form-signup#gform_8 input[type="submit"] {
    background: #62cdf6;
  }
  .form-signup.form-newsletter .gform_footer .button-landing, .form-signup.form-newsletter input[type="submit"] .button-landing, .form-signup#gform_8 .gform_footer .button-landing, .form-signup#gform_8 input[type="submit"] .button-landing {
    font-family: icomoon;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-size: 16px;
  }
  .form-signup.form-newsletter .gform_footer .button-landing:before, .form-signup.form-newsletter input[type="submit"] .button-landing:before, .form-signup#gform_8 .gform_footer .button-landing:before, .form-signup#gform_8 input[type="submit"] .button-landing:before {
    content: "\e621";
    color: #000;
  }
  .form-signup#gform_8 ul {
    padding: 0;
  }
  .form-signup#gform_8 .gform_body {
    padding: 0;
  }
  .form-signup#gform_8 input {
    border-radius: 0;
  }
  
  .form-landing .gfield_description.validation_message {
    margin-top: -18px;
    margin-bottom: 6px;
  }
  
  .form-contact ul {
    list-style-type: none;
  }
  .form-contact #choice_6_1 {
    position: absolute;
    z-index: -1000;
    left: -1000px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
  }
  .form-contact #choice_6_1 + #label_6_1,
  .form-contact #choice_6_1 + #label_6_1.clr {
    padding-left: 25px;
    height: 21px;
    display: inline-block;
    line-height: 24px;
    background-repeat: no-repeat;
    background-position: 0 0;
    font-size: 20px;
    vertical-align: middle;
    cursor: pointer;
    margin-top: 30px;
  }
  .form-contact #choice_6_1:checked + #label_6_1,
  .form-contact #choice_6_1 + #label_6_1.chk {
    background-position: 0 -21px;
  }
  .form-contact #label_6_1 {
    background-image: url("../images/input_checkbox.png");
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    font-weight: 600;
  }
  .form-contact li.gchoice_6_1 {
    width: 70%;
  }
  @media screen and (max-width: 640px) {
    .form-contact li.gchoice_6_1 {
      width: 100%;
    }
  }
  .form-contact label, .form-contact .legend-text {
    display: block;
  }
  @media screen and (max-width: 640px) {
    .form-contact label, .form-contact .legend-text {
      font-size: 1.625em;
      margin-bottom: 10px;
    }
  }
  .form-contact .button-landing {
    font-weight: lighter;
    float: right;
    font-size: 1.5em;
    color: #fff;
    border-radius: 5px;
    height: 50px;
    line-height: 46px;
    cursor: pointer;
    /*font-family: "Capita";*/
    display: inline-block;
    padding-left: 13px;
    padding-right: 13px;
    outline: none;
    width: 106px;
    background-color: #016789;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#016789), to(#0f4a71));
    background-image: linear-gradient(#016789, #0f4a71);
    border: 1px solid #175479;
  }
  .form-contact .button-landing:hover {
    background-color: #7fc261;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#7fc261), to(#a3ce5d));
    background-image: linear-gradient(#7fc261, #a3ce5d);
    border: 1px solid #158152;
  }
  .form-contact .button-landing:active {
    background-color: #0082b1;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#0082b1), to(#036a95));
    background-image: linear-gradient(#0082b1, #036a95);
    border: 1px solid #17567c;
  }
  @media screen and (max-width: 640px) {
    .form-contact .button-landing {
      width: 100%;
      float: none;
      margin-top: 20px;
    }
  }
  .form-contact .legend {
    margin-top: -60px;
    
    color: red;
    font-size: 16px;
  }
  @media screen and (max-width: 320px) {
    .form-contact .legend {
      font-size: 13px;
    }
  }
  .form-contact .gfield_description.validation_message {
    margin-bottom: -22px;
  }
  
  .form-stay-connected ul {
    list-style-type: none;
  }
  .form-stay-connected li,
  .form-stay-connected .gform_body,
  .form-stay-connected .gform_footer {
    display: inline-block;
    vertical-align: top;
  }
  .form-stay-connected .button-landing {
    font-weight: lighter;
    float: right;
    font-size: 1.5em;
    color: #fff;
    border-radius: 5px;
    height: 40px;
    line-height: 35px;
    cursor: pointer;
    /*font-family: "Capita";*/
    display: inline-block;
    padding-left: 13px;
    padding-right: 13px;
    outline: none;
    width: 106px;
    vertical-align: bottom;
    background-color: #016789;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#016789), to(#0f4a71));
    background-image: linear-gradient(#016789, #0f4a71);
    border: 1px solid #175479;
  }
  .form-stay-connected .button-landing:hover {
    background-color: #7fc261;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#7fc261), to(#a3ce5d));
    background-image: linear-gradient(#7fc261, #a3ce5d);
    border: 1px solid #158152;
  }
  .form-stay-connected .button-landing:active {
    background-color: #0082b1;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#0082b1), to(#036a95));
    background-image: linear-gradient(#0082b1, #036a95);
    border: 1px solid #17567c;
  }
  @media screen and (max-width: 640px) {
    .form-stay-connected .button-landing {
      width: 100%;
      float: none;
      margin-top: 20px;
    }
  }
  .form-stay-connected .legend {
    display: none;
  }
  .form-stay-connected .gfield_description.validation_message {
    margin-bottom: -22px;
    
    font-size: 16px;
  }
  
  @media screen and (max-width: 768px) {
    .form-stay-connected_wrapper {
      text-align: center;
    }
    .form-stay-connected_wrapper .gfield {
      width: 93%;
    }
  }
  
  @media screen and (max-width: 768px) {
    .banner-info input {
      width: 100%;
      margin-bottom: 9px;
      height: 60px;
    }
  }
  .form-group-inquiries {
    position: relative;
  }
  @media screen and (max-width: 640px) {
    .form-group-inquiries {
      position: initial;
    }
  }
  .form-group-inquiries ul {
    list-style-type: none;
  }
  .form-group-inquiries label {
    
    font-weight: 600;
  }
  .form-group-inquiries .box-left,
  .form-group-inquiries .box-right {
    margin-bottom: 30px;
  }
  @media screen and (max-width: 320px) {
    .form-group-inquiries .box-left,
    .form-group-inquiries .box-right {
      margin-bottom: 10px;
    }
  }
  .form-group-inquiries .box-right {
    position: absolute;
    top: 0;
    right: 0;
  }
  @media screen and (max-width: 640px) {
    .form-group-inquiries .box-right {
      position: initial;
    }
  }
  .form-group-inquiries .button-landing {
    font-weight: lighter;
    float: left;
    font-size: 1.5em;
    color: #fff;
    border-radius: 5px;
    height: 50px;
    line-height: 46px;
    cursor: pointer;
    /*font-family: "Capita";*/
    display: inline-block;
    padding-left: 13px;
    padding-right: 13px;
    outline: none;
    width: 106px;
    vertical-align: bottom;
    background-color: #016789;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#016789), to(#0f4a71));
    background-image: linear-gradient(#016789, #0f4a71);
    border: 1px solid #175479;
    margin-top: 21px;
    margin-bottom: 5em;
  }
  @media screen and (max-width: 320px) {
    .form-group-inquiries .button-landing {
      font-size: 0.9375em;
      height: 30px;
      line-height: 28px;
    }
  }
  .form-group-inquiries .button-landing:hover {
    background-color: #7fc261;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#7fc261), to(#a3ce5d));
    background-image: linear-gradient(#7fc261, #a3ce5d);
    border: 1px solid #158152;
  }
  .form-group-inquiries .button-landing:active {
    background-color: #0082b1;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#0082b1), to(#036a95));
    background-image: linear-gradient(#0082b1, #036a95);
    border: 1px solid #17567c;
  }
  @media screen and (max-width: 640px) {
    .form-group-inquiries .button-landing {
      width: 100%;
      float: none;
      margin-top: 20px;
    }
  }
  .form-group-inquiries .legend {
    display: none;
  }
  .form-group-inquiries .gfield_description.validation_message {
    margin-bottom: -22px;
    
    font-size: 16px;
  }
  .form-group-inquiries .gform_confirmation_message {
    text-align: center;
    color: #069;
    font-size: 30px;
    margin-bottom: 5em;
  }
  
  /**
   * Additional Gravity Form on Spa Basic Template
   */
  .form-additional-spa {
    /**
     * Fix for Contact Form within this section
     */
  }
  .form-additional-spa .form-contact .legend {
    margin-top: -85px;
  }
  .form-additional-spa .gfield {
    list-style: none;
  }
  .form-additional-spa .gfield li {
    list-style: none;
  }
  .form-additional-spa .gfield_description {
    font-weight: 400;
    display: inline;
    margin-left: 5px;
  }
  .form-additional-spa .gfield_radio li {
    display: inline;
  }
  
  .form-newsletter .gform_confirmation_message {
    color: #FFF;
  }
  
  /**
   * Stream Infinite Load styles
   */
  section.blog-single {
    /**
     * Stream loading at the bottom
     */
  }
  section.blog-single .posts-loading {
    background-image: url("../images/loading.gif");
    background-position: center 10px;
    background-repeat: no-repeat;
    text-align: center;
    text-transform: uppercase;
    color: #999;
    padding: 30px 0px;
  }
  section.blog-single .posts-loading.is-inactive {
    text-align: center;
    background: none;
  }
  
  .responsive-image {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    content: '&nbsp;';
  }
  
  .page-template-teacher-detail img {
    max-width: 100%;
  }
  
  @media screen and (max-width:640px) {
  
    .page-template-teacher-detail .container {
      padding-left: 40px;
      padding-right: 40px;
    }
  
  }
  
  .page-template-teacher-detail .banner-event-details {
    min-height: 0 !important;
  }
  
  .teacher-image {
    margin-bottom: 15px;
  }
  
  .teacher_position {
    color: #777;
    font-style: italic;
    margin-bottom: 10px !important;
  }
  
  .single_teacher_title {
    margin-bottom: 0 !important;
  }
  
  @media screen and (min-width:993px) {
  
  #left_single_teacher {
    width: 47%;
    float: left;
  }
  
  #right_single_teacher {
    width: 47%;
    float: right;
  }
  
  }
  
  @media screen and (max-width:992px) {
    #right_single_teacher {
      margin-top: 30px;
    }
  }
  
  .single_teacher_wrap {
    margin-bottom: 80px;
  }
  
  .location-map iframe {
    top: 0;
    left: 0;
    width: 100%;
    border: none;
    margin-top: 30px;
  }
  
  
  #event_espresso_shopping_cart {
    font-family: "Gibson" !important;
  }
  @media screen and (max-width: 640px) {
    #event_espresso_shopping_cart .event-data-display {
      padding: 0;
    }
  }
  @media screen and (max-width: 640px) {
    #event_espresso_shopping_cart .event-data-display td {
      padding: 0;
    }
  }
  @media screen and (max-width: 640px) {
    #event_espresso_shopping_cart .event-data-display table.price_list td.price_type,
    #event_espresso_shopping_cart .event-data-display table.price_list td.price {
      width: 40%;
    }
  }
  @media screen and (max-width: 640px) {
    #event_espresso_shopping_cart .event-data-display table.price_list td.selection {
      width: 20%;
    }
  }
  #event_espresso_shopping_cart .ui-widget, #event_espresso_shopping_cart .ui-widget input {
    font-family: "Gibson" !important;
  }
  #event_espresso_shopping_cart .ui-corner-all, #event_espresso_shopping_cart .ui-corner-bottom {
    border-radius: 0;
  }
  #event_espresso_shopping_cart .event_title.ui-widget-header.ui-corner-top {
    font-family: "Gibson" !important;
    font-weight: 600;
    line-height: 30px;
    height: 30px;
    border-radius: 0;
    color: #84628d;
  }
  #event_espresso_shopping_cart #event_espresso_total_wrapper {
    padding-left: 6px;
    padding-right: 6px;
  }
  #event_espresso_shopping_cart #event_espresso_refresh_total a {
    background-color: #016789;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#016789), to(#174b72));
    background-image: linear-gradient(#016789, #174b72);
    border-radius: 3px;
    border: 2px solid #224c74;
    color: #fff;
    display: inline-block;
    
    text-align: center;
    text-transform: uppercase;
    height: 37px;
    line-height: 35px;
    padding-left: 5px;
    padding-right: 5px;
  }
  @media screen and (max-width: 640px) {
    #event_espresso_shopping_cart #event_espresso_refresh_total a {
      font-size: 12px;
      height: 30px;
      line-height: 28px;
    }
  }
  #event_espresso_shopping_cart #event_espresso_refresh_total a:hover {
    background-color: #7fc261;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#7fc261), to(#a3ce5d));
    background-image: linear-gradient(#7fc261, #a3ce5d);
    border: 2px solid #158152;
  }
  #event_espresso_shopping_cart .ui-widget input {
    background-color: #016789;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#016789), to(#174b72));
    background-image: linear-gradient(#016789, #174b72);
    border-radius: 3px;
    border: 2px solid #224c74;
    color: #fff;
    display: block;
    
    text-align: center;
    text-transform: uppercase;
  }
  @media screen and (max-width: 640px) {
    #event_espresso_shopping_cart .ui-widget input {
      font-size: 12px;
      height: 40px;
    }
  }
  #event_espresso_shopping_cart .ui-widget input:hover {
    background-color: #7fc261;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#7fc261), to(#a3ce5d));
    background-image: linear-gradient(#7fc261, #a3ce5d);
    border: 2px solid #158152;
  }
  
  #aim-payment-option-lnk {
    max-width: none;
  }
  
  /*
   * jQuery FlexSlider v2.2.0
   * http://www.woothemes.com/flexslider/
   *
   * Copyright 2012 WooThemes
   * Free to use under the GPLv2 license.
   * http://www.gnu.org/licenses/gpl-2.0.html
   *
   * Contributing author: Tyler Smith (@mbmufffin)
   */
  /* Browser Resets
  *********************************/
  .flex-container a:active,
  .flexslider a:active,
  .flex-container a:focus,
  .flexslider a:focus {
    outline: none;
  }
  
  .slides,
  .flex-control-nav,
  .flex-direction-nav {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  /* Icon Fonts
  *********************************/
  /* Font-face Icons */
  @font-face {
    font-family: 'flexslider-icon';
    src: url("../fonts/flexslider/flexslider-icon.eot");
    src: url("../fonts/flexslider-icon.eot?#iefix") format("embedded-opentype"), url("../fonts/flexslider/flexslider-icon.woff") format("woff"), url("../fonts/flexslider/flexslider-icon.ttf") format("truetype"), url("../fonts/flexslider/flexslider-icon.svg#flexslider-icon") format("svg");
    font-weight: normal;
    font-style: normal;
  }
  /* FlexSlider Necessary Styles
  *********************************/
  .flexslider {
    margin: 0;
    padding: 0;
  }
  
  .flexslider .slides > li {
    /*display: none;*/ /* To fix slide not showing issues */
    -webkit-backface-visibility: hidden;
  }
  
  /* Hide the slides before the JS is loaded. Avoids image jumping */
  .flexslider .slides img {
    width: 100%;
    display: block;
  }
  
  .flex-pauseplay span {
    text-transform: capitalize;
  }
  
  /* Clearfix for the .slides element */
  .slides:after {
    content: "\0020";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
  }
  
  html[xmlns] .slides {
    display: block;
  }
  
  * html .slides {
    height: 1%;
  }
  
  /* No JavaScript Fallback */
  /* If you are not using another script, such as Modernizr, make sure you
   * include js that eliminates this class on page load */
  .no-js .slides > li:first-child {
    display: block;
  }
  
  /* FlexSlider Default Theme
  *********************************/
  .flexslider {
    /*margin: 0 0 60px;
    background: #fff;
    border: 4px solid #fff;*/
    position: relative;
    /*	-webkit-border-radius:4px;
        -moz-border-radius: 4px;
        -o-border-radius: 4px;
        border-radius: 4px;
        -webkit-box-shadow: 0 1px 4px rgba(0,0,0,.2);
        -moz-box-shadow: 0 1px 4px rgba(0,0,0,.2);
        -o-box-shadow: 0 1px 4px rgba(0,0,0,.2);
        box-shadow: 0 1px 4px rgba(0,0,0,.2);*/
    zoom: 1;
  }
  
  .flex-viewport {
    max-height: 2000px;
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
  }
  
  .loading .flex-viewport {
    max-height: 300px;
  }
  
  .flexslider .slides {
    zoom: 1;
  }
  
  .carousel li {
    margin-right: 5px;
  }
  
  /* Direction Nav */
  .flex-direction-nav {
    *height: 0;
  }
  
  .flex-direction-nav a {
    text-decoration: none;
    display: block;
    width: 40px;
    height: 40px;
    margin: -20px 0 0;
    position: absolute;
    top: 50%;
    z-index: 10;
    overflow: hidden;
    opacity: 0;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.8);
    text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
  }
  
  .flex-direction-nav .flex-prev {
    left: -50px;
  }
  
  .flex-direction-nav .flex-next {
    right: -50px;
    text-align: right;
  }
  
  .flexslider:hover .flex-prev {
    opacity: 0.7;
    /*left: 10px;*/
  }
  
  .flexslider:hover .flex-next {
    opacity: 0.7;
    /*right: 10px;*/
  }
  
  .flexslider:hover .flex-next:hover, .flexslider:hover .flex-prev:hover {
    opacity: 1;
  }
  
  .flex-direction-nav .flex-disabled {
    opacity: 0 !important;
    filter: alpha(opacity=0);
    cursor: default;
  }
  
  .flex-direction-nav a:before {
    font-family: "flexslider-icon";
    font-size: 40px;
    display: inline-block;
    content: '\f001';
  }
  
  .flex-direction-nav a.flex-next:before {
    content: '\f002';
  }
  
  /* Pause/Play */
  .flex-pauseplay a {
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: 5px;
    left: 10px;
    opacity: 0.8;
    z-index: 10;
    overflow: hidden;
    cursor: pointer;
    color: #000;
  }
  
  .flex-pauseplay a:before {
    font-family: "flexslider-icon";
    font-size: 20px;
    display: inline-block;
    content: '\f004';
  }
  
  .flex-pauseplay a:hover {
    opacity: 1;
  }
  
  .flex-pauseplay a.flex-play:before {
    content: '\f003';
  }
  
  /* Control Nav */
  .flex-control-nav {
    width: 100%;
    position: absolute;
    bottom: -40px;
    text-align: center;
  }
  
  .flex-control-nav li {
    margin: 0 6px;
    display: inline-block;
    zoom: 1;
    *display: inline;
  }
  
  .flex-control-paging li a {
    width: 11px;
    height: 11px;
    display: block;
    background: #666;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    text-indent: -9999px;
    border-radius: 20px;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    -o-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  }
  
  .flex-control-paging li a:hover {
    background: #333;
    background: rgba(0, 0, 0, 0.7);
  }
  
  .flex-control-paging li a.flex-active {
    background: #000;
    background: rgba(0, 0, 0, 0.9);
    cursor: default;
  }
  
  .flex-control-thumbs {
    margin: 5px 0 0;
    position: static;
    overflow: hidden;
  }
  
  .flex-control-thumbs li {
    width: 25%;
    float: left;
    margin: 0;
  }
  
  .flex-control-thumbs img {
    width: 100%;
    display: block;
    opacity: .7;
    cursor: pointer;
  }
  
  .flex-control-thumbs img:hover {
    opacity: 1;
  }
  
  .flex-control-thumbs .flex-active {
    opacity: 1;
    cursor: default;
  }
  
  .container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
  .container:before, .container:after {
    content: " ";
    display: table;
  }
  .container:after {
    clear: both;
  }
  @media (min-width: 768px) {
    .container {
      width: 750px;
    }
      .header>.container{
          width: 100%;
      }
  }
  @media (min-width: 992px) {
    .container {
      width: 970px;
    }
      .header>.container{
          width: 100%;
      }
  }
  @media (min-width: 1200px) {
    .container {
      width: 1170px;
    }
      .header>.container{
          width: 100%;
          max-width: 1800px;
      }
  }
  
  .container-fluid {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
  .container-fluid:before, .container-fluid:after {
    content: " ";
    display: table;
  }
  .container-fluid:after {
    clear: both;
  }
  
  .row {
    margin-left: -15px;
    margin-right: -15px;
  }
  .row:before, .row:after {
    content: " ";
    display: table;
  }
  .row:after {
    clear: both;
  }
  
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
  }
  
  .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
    float: left;
  }
  
  .col-xs-1 {
    width: 8.33333%;
  }
  
  .col-xs-2 {
    width: 16.66667%;
  }
  
  .col-xs-3 {
    width: 25%;
  }
  
  .col-xs-4 {
    width: 33.33333%;
  }
  
  .col-xs-5 {
    width: 41.66667%;
  }
  
  .col-xs-6 {
    width: 50%;
  }
  
  .col-xs-7 {
    width: 58.33333%;
  }
  
  .col-xs-8 {
    width: 66.66667%;
  }
  
  .col-xs-9 {
    width: 75%;
  }
  
  .col-xs-10 {
    width: 83.33333%;
  }
  
  .col-xs-11 {
    width: 91.66667%;
  }
  
  .col-xs-12 {
    width: 100%;
  }
  
  .col-xs-pull-0 {
    right: auto;
  }
  
  .col-xs-pull-1 {
    right: 8.33333%;
  }
  
  .col-xs-pull-2 {
    right: 16.66667%;
  }
  
  .col-xs-pull-3 {
    right: 25%;
  }
  
  .col-xs-pull-4 {
    right: 33.33333%;
  }
  
  .col-xs-pull-5 {
    right: 41.66667%;
  }
  
  .col-xs-pull-6 {
    right: 50%;
  }
  
  .col-xs-pull-7 {
    right: 58.33333%;
  }
  
  .col-xs-pull-8 {
    right: 66.66667%;
  }
  
  .col-xs-pull-9 {
    right: 75%;
  }
  
  .col-xs-pull-10 {
    right: 83.33333%;
  }
  
  .col-xs-pull-11 {
    right: 91.66667%;
  }
  
  .col-xs-pull-12 {
    right: 100%;
  }
  
  .col-xs-push-0 {
    left: auto;
  }
  
  .col-xs-push-1 {
    left: 8.33333%;
  }
  
  .col-xs-push-2 {
    left: 16.66667%;
  }
  
  .col-xs-push-3 {
    left: 25%;
  }
  
  .col-xs-push-4 {
    left: 33.33333%;
  }
  
  .col-xs-push-5 {
    left: 41.66667%;
  }
  
  .col-xs-push-6 {
    left: 50%;
  }
  
  .col-xs-push-7 {
    left: 58.33333%;
  }
  
  .col-xs-push-8 {
    left: 66.66667%;
  }
  
  .col-xs-push-9 {
    left: 75%;
  }
  
  .col-xs-push-10 {
    left: 83.33333%;
  }
  
  .col-xs-push-11 {
    left: 91.66667%;
  }
  
  .col-xs-push-12 {
    left: 100%;
  }
  
  .col-xs-offset-0 {
    margin-left: 0%;
  }
  
  .col-xs-offset-1 {
    margin-left: 8.33333%;
  }
  
  .col-xs-offset-2 {
    margin-left: 16.66667%;
  }
  
  .col-xs-offset-3 {
    margin-left: 25%;
  }
  
  .col-xs-offset-4 {
    margin-left: 33.33333%;
  }
  
  .col-xs-offset-5 {
    margin-left: 41.66667%;
  }
  
  .col-xs-offset-6 {
    margin-left: 50%;
  }
  
  .col-xs-offset-7 {
    margin-left: 58.33333%;
  }
  
  .col-xs-offset-8 {
    margin-left: 66.66667%;
  }
  
  .col-xs-offset-9 {
    margin-left: 75%;
  }
  
  .col-xs-offset-10 {
    margin-left: 83.33333%;
  }
  
  .col-xs-offset-11 {
    margin-left: 91.66667%;
  }
  
  .col-xs-offset-12 {
    margin-left: 100%;
  }
  
  @media (min-width: 768px) {
    .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
      float: left;
    }
  
    .col-sm-1 {
      width: 8.33333%;
    }
  
    .col-sm-2 {
      width: 16.66667%;
    }
  
    .col-sm-3 {
      width: 25%;
    }
  
    .col-sm-4 {
      width: 33.33333%;
    }
  
    .col-sm-5 {
      width: 41.66667%;
    }
  
    .col-sm-6 {
      width: 50%;
    }
  
    .col-sm-7 {
      width: 58.33333%;
    }
  
    .col-sm-8 {
      width: 66.66667%;
    }
  
    .col-sm-9 {
      width: 75%;
    }
  
    .col-sm-10 {
      width: 83.33333%;
    }
  
    .col-sm-11 {
      width: 91.66667%;
    }
  
    .col-sm-12 {
      width: 100%;
    }
  
    .col-sm-pull-0 {
      right: auto;
    }
  
    .col-sm-pull-1 {
      right: 8.33333%;
    }
  
    .col-sm-pull-2 {
      right: 16.66667%;
    }
  
    .col-sm-pull-3 {
      right: 25%;
    }
  
    .col-sm-pull-4 {
      right: 33.33333%;
    }
  
    .col-sm-pull-5 {
      right: 41.66667%;
    }
  
    .col-sm-pull-6 {
      right: 50%;
    }
  
    .col-sm-pull-7 {
      right: 58.33333%;
    }
  
    .col-sm-pull-8 {
      right: 66.66667%;
    }
  
    .col-sm-pull-9 {
      right: 75%;
    }
  
    .col-sm-pull-10 {
      right: 83.33333%;
    }
  
    .col-sm-pull-11 {
      right: 91.66667%;
    }
  
    .col-sm-pull-12 {
      right: 100%;
    }
  
    .col-sm-push-0 {
      left: auto;
    }
  
    .col-sm-push-1 {
      left: 8.33333%;
    }
  
    .col-sm-push-2 {
      left: 16.66667%;
    }
  
    .col-sm-push-3 {
      left: 25%;
    }
  
    .col-sm-push-4 {
      left: 33.33333%;
    }
  
    .col-sm-push-5 {
      left: 41.66667%;
    }
  
    .col-sm-push-6 {
      left: 50%;
    }
  
    .col-sm-push-7 {
      left: 58.33333%;
    }
  
    .col-sm-push-8 {
      left: 66.66667%;
    }
  
    .col-sm-push-9 {
      left: 75%;
    }
  
    .col-sm-push-10 {
      left: 83.33333%;
    }
  
    .col-sm-push-11 {
      left: 91.66667%;
    }
  
    .col-sm-push-12 {
      left: 100%;
    }
  
    .col-sm-offset-0 {
      margin-left: 0%;
    }
  
    .col-sm-offset-1 {
      margin-left: 8.33333%;
    }
  
    .col-sm-offset-2 {
      margin-left: 16.66667%;
    }
  
    .col-sm-offset-3 {
      margin-left: 25%;
    }
  
    .col-sm-offset-4 {
      margin-left: 33.33333%;
    }
  
    .col-sm-offset-5 {
      margin-left: 41.66667%;
    }
  
    .col-sm-offset-6 {
      margin-left: 50%;
    }
  
    .col-sm-offset-7 {
      margin-left: 58.33333%;
    }
  
    .col-sm-offset-8 {
      margin-left: 66.66667%;
    }
  
    .col-sm-offset-9 {
      margin-left: 75%;
    }
  
    .col-sm-offset-10 {
      margin-left: 83.33333%;
    }
  
    .col-sm-offset-11 {
      margin-left: 91.66667%;
    }
  
    .col-sm-offset-12 {
      margin-left: 100%;
    }
  }
  @media (min-width: 992px) {
    .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
      float: left;
    }
  
    .col-md-1 {
      width: 8.33333%;
    }
  
    .col-md-2 {
      width: 16.66667%;
    }
  
    .col-md-3 {
      width: 25%;
    }
  
    .col-md-4 {
      width: 33.33333%;
    }
  
    .col-md-5 {
      width: 41.66667%;
    }
  
    .col-md-6 {
      width: 50%;
    }
  
    .col-md-7 {
      width: 58.33333%;
    }
  
    .col-md-8 {
      width: 66.66667%;
    }
  
    .col-md-9 {
      width: 75%;
    }
  
    .col-md-10 {
      width: 83.33333%;
    }
  
    .col-md-11 {
      width: 91.66667%;
    }
  
    .col-md-12 {
      width: 100%;
    }
  
    .col-md-pull-0 {
      right: auto;
    }
  
    .col-md-pull-1 {
      right: 8.33333%;
    }
  
    .col-md-pull-2 {
      right: 16.66667%;
    }
  
    .col-md-pull-3 {
      right: 25%;
    }
  
    .col-md-pull-4 {
      right: 33.33333%;
    }
  
    .col-md-pull-5 {
      right: 41.66667%;
    }
  
    .col-md-pull-6 {
      right: 50%;
    }
  
    .col-md-pull-7 {
      right: 58.33333%;
    }
  
    .col-md-pull-8 {
      right: 66.66667%;
    }
  
    .col-md-pull-9 {
      right: 75%;
    }
  
    .col-md-pull-10 {
      right: 83.33333%;
    }
  
    .col-md-pull-11 {
      right: 91.66667%;
    }
  
    .col-md-pull-12 {
      right: 100%;
    }
  
    .col-md-push-0 {
      left: auto;
    }
  
    .col-md-push-1 {
      left: 8.33333%;
    }
  
    .col-md-push-2 {
      left: 16.66667%;
    }
  
    .col-md-push-3 {
      left: 25%;
    }
  
    .col-md-push-4 {
      left: 33.33333%;
    }
  
    .col-md-push-5 {
      left: 41.66667%;
    }
  
    .col-md-push-6 {
      left: 50%;
    }
  
    .col-md-push-7 {
      left: 58.33333%;
    }
  
    .col-md-push-8 {
      left: 66.66667%;
    }
  
    .col-md-push-9 {
      left: 75%;
    }
  
    .col-md-push-10 {
      left: 83.33333%;
    }
  
    .col-md-push-11 {
      left: 91.66667%;
    }
  
    .col-md-push-12 {
      left: 100%;
    }
  
    .col-md-offset-0 {
      margin-left: 0%;
    }
  
    .col-md-offset-1 {
      margin-left: 8.33333%;
    }
  
    .col-md-offset-2 {
      margin-left: 16.66667%;
    }
  
    .col-md-offset-3 {
      margin-left: 25%;
    }
  
    .col-md-offset-4 {
      margin-left: 33.33333%;
    }
  
    .col-md-offset-5 {
      margin-left: 41.66667%;
    }
  
    .col-md-offset-6 {
      margin-left: 50%;
    }
  
    .col-md-offset-7 {
      margin-left: 58.33333%;
    }
  
    .col-md-offset-8 {
      margin-left: 66.66667%;
    }
  
    .col-md-offset-9 {
      margin-left: 75%;
    }
  
    .col-md-offset-10 {
      margin-left: 83.33333%;
    }
  
    .col-md-offset-11 {
      margin-left: 91.66667%;
    }
  
    .col-md-offset-12 {
      margin-left: 100%;
    }
  }
  @media (min-width: 1200px) {
    .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
      float: left;
    }
  
    .col-lg-1 {
      width: 8.33333%;
    }
  
    .col-lg-2 {
      width: 16.66667%;
    }
  
    .col-lg-3 {
      width: 25%;
    }
  
    .col-lg-4 {
      width: 33.33333%;
    }
  
    .col-lg-5 {
      width: 41.66667%;
    }
  
    .col-lg-6 {
      width: 50%;
    }
  
    .col-lg-7 {
      width: 58.33333%;
    }
  
    .col-lg-8 {
      width: 66.66667%;
    }
  
    .col-lg-9 {
      width: 75%;
    }
  
    .col-lg-10 {
      width: 83.33333%;
    }
  
    .col-lg-11 {
      width: 91.66667%;
    }
  
    .col-lg-12 {
      width: 100%;
    }
  
    .col-lg-pull-0 {
      right: auto;
    }
  
    .col-lg-pull-1 {
      right: 8.33333%;
    }
  
    .col-lg-pull-2 {
      right: 16.66667%;
    }
  
    .col-lg-pull-3 {
      right: 25%;
    }
  
    .col-lg-pull-4 {
      right: 33.33333%;
    }
  
    .col-lg-pull-5 {
      right: 41.66667%;
    }
  
    .col-lg-pull-6 {
      right: 50%;
    }
  
    .col-lg-pull-7 {
      right: 58.33333%;
    }
  
    .col-lg-pull-8 {
      right: 66.66667%;
    }
  
    .col-lg-pull-9 {
      right: 75%;
    }
  
    .col-lg-pull-10 {
      right: 83.33333%;
    }
  
    .col-lg-pull-11 {
      right: 91.66667%;
    }
  
    .col-lg-pull-12 {
      right: 100%;
    }
  
    .col-lg-push-0 {
      left: auto;
    }
  
    .col-lg-push-1 {
      left: 8.33333%;
    }
  
    .col-lg-push-2 {
      left: 16.66667%;
    }
  
    .col-lg-push-3 {
      left: 25%;
    }
  
    .col-lg-push-4 {
      left: 33.33333%;
    }
  
    .col-lg-push-5 {
      left: 41.66667%;
    }
  
    .col-lg-push-6 {
      left: 50%;
    }
  
    .col-lg-push-7 {
      left: 58.33333%;
    }
  
    .col-lg-push-8 {
      left: 66.66667%;
    }
  
    .col-lg-push-9 {
      left: 75%;
    }
  
    .col-lg-push-10 {
      left: 83.33333%;
    }
  
    .col-lg-push-11 {
      left: 91.66667%;
    }
  
    .col-lg-push-12 {
      left: 100%;
    }
  
    .col-lg-offset-0 {
      margin-left: 0%;
    }
  
    .col-lg-offset-1 {
      margin-left: 8.33333%;
    }
  
    .col-lg-offset-2 {
      margin-left: 16.66667%;
    }
  
    .col-lg-offset-3 {
      margin-left: 25%;
    }
  
    .col-lg-offset-4 {
      margin-left: 33.33333%;
    }
  
    .col-lg-offset-5 {
      margin-left: 41.66667%;
    }
  
    .col-lg-offset-6 {
      margin-left: 50%;
    }
  
    .col-lg-offset-7 {
      margin-left: 58.33333%;
    }
  
    .col-lg-offset-8 {
      margin-left: 66.66667%;
    }
  
    .col-lg-offset-9 {
      margin-left: 75%;
    }
  
    .col-lg-offset-10 {
      margin-left: 83.33333%;
    }
  
    .col-lg-offset-11 {
      margin-left: 91.66667%;
    }
  
    .col-lg-offset-12 {
      margin-left: 100%;
    }
  }
  .wcp-event-container {
    padding-top: 40px;
  }
  .wcp-event-container img {
    max-width: 100%;
  }
  .wcp-event-container .col-sm-8 h1, .wcp-event-container .col-sm-8 h2 {
    color: #84628d;
  }
  .wcp-event-container .col-sm-3 {
    /* font-family: Gibson; */ /* Removed font */
  }
  .wcp-event-container .col-sm-3 .wcp-event-date-container, .wcp-event-container .col-sm-3 .wcp-event-text-container {
    border: 1px solid #f2e4d2;
    background-color: #fdf5e9;
    margin-top: 20px;
    padding: 15px;
    color: #676767;
    font-size: 14px;
  }
  .wcp-event-container .col-sm-3 .wcp-event-date-container .wcp-event-section-title, .wcp-event-container .col-sm-3 .wcp-event-text-container .wcp-event-section-title {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
  }
  .wcp-event-container .col-sm-3 .wcp-event-date-container p, .wcp-event-container .col-sm-3 .wcp-event-text-container p {
    margin-bottom: 15px;
  }
  .wcp-event-container .col-sm-3 .wcp-event-date-container p:last-child, .wcp-event-container .col-sm-3 .wcp-event-text-container p:last-child {
    margin: 0;
  }
  .wcp-event-container .col-sm-3 .wcp-event-date-container p span, .wcp-event-container .col-sm-3 .wcp-event-text-container p span {
    font-weight: 600;
  }
  .wcp-event-container .col-sm-3 .wcp-event-date-container .wcp-box-follow, .wcp-event-container .col-sm-3 .wcp-event-text-container .wcp-box-follow {
    display: inline-block;
    margin: 0;
    padding: 5px 0;
    font-size: 18px;
  }
  .wcp-event-container .col-sm-3 .wcp-event-date-container .social-icon, .wcp-event-container .col-sm-3 .wcp-event-text-container .social-icon {
    float: right;
  }
  .wcp-event-container .col-sm-3 .wcp-event-date-container .social-icon a i, .wcp-event-container .col-sm-3 .wcp-event-text-container .social-icon a i {
    color: white;
  }
  .wcp-event-container .col-sm-3 .text-repeater p.text-repeater-p {
    margin-bottom: 10px;
  }
  
  .wcp-event-register {
    background-color: #016789;
    padding: 10px;
    width: 100%;
    display: block;
    text-align: center;
    color: white;
    border-radius: 5px;
    font-size: 22px;
    border: 1px solid transparent;
    /* font-family: Gibson; */ /* Removed font */
  }
  .wcp-event-register:hover {
    background-color: #7fc261;
    border: 1px solid #016789;
  }
  .wcp-event-register._fixed {
    position: fixed;
    left: 50%;
    width: 100%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    z-index: 10;
  }
  
  .wcp-event-register-for-mobile {
    display: none;
    background: #fff;
  }
  .wcp-event-register-for-mobile .wcp-event-register {
    max-width: 350px;
    margin: 0 auto;
  }
  
  @media (max-width: 767px) {
    .wcp-event-register-for-desktop {
      display: none;
    }
  
    .wcp-event-register-for-mobile {
      display: block;
    }
  }
  
  
  
  
  .under_img_teacher_inner {
    border-top: none !important;
    padding: 10px 5px 20px 5px;
  }
  
  
  
  ._wcp-prev-next-posts {
    text-align: center;
    padding: 0 0 40px 0;
  }
  ._wcp-prev-next-posts > div {
    display: inline-block;
    padding: 0 20px;
  }
  ._wcp-prev-next-posts a {
    color: #888;
  }
  
  .wcp-events-loading #infscr-loading {
    text-align: center;
  }
  
  .wcp-events-flexslider {
    margin-bottom: 30px;
  }
  .wcp-events-flexslider .flex-control-nav {
    display: block !important;
  }
  .wcp-events-flexslider .wcp-event-col {
    clear: none !important;
  }
  .plan-your-trip {
    /* background: url(../images/bg_yellow.jpg) repeat-x; */
    background: #e9a52e;
    padding: 10px;
    text-align: center;
    width: 100px;
      color: white;
  }
  .plan-your-trip._visible-plan-trip { background-color: #84628d; }
  .plan-your-trip>a{
      color: white;
  }
  .plan-your-trip a:after {
    content: none !important;
  }
  .plan-your-trip:hover {
    -webkit-filter: brightness(110%);
    filter: brightness(110%);
  }
  .plan-your-trip:hover a {
    color: #fff !important;
    -webkit-filter: brightness(110%);
    filter: brightness(110%);
  }
  .plan-your-trip:hover a:after {
    content: none;
  }
  @media (max-width: 767px) {
    .plan-your-trip {
      display: inline-block;
        width: auto;
    }
  }
  
  .plan-your-trip:hover a.js-submenu.active,
  ._visible-plan-trip a.js-submenu.active {
    color: #d09e50 !important;
  }
  
  .lets-connect:hover {
    -webkit-filter: brightness(160%);
    filter: brightness(160%);
  }
  
  .lets-connect:hover a {
    color: #fff !important;
    -webkit-filter: brightness(110%);
    filter: brightness(110%);
  }
  
  .color-blue {
    color: #23738b;
  }
  
  body {
    text-rendering: optimizeLegibility;
  }
  
  .desktop-sub-menu.box-sub-menu:after {
    content: "";
    display: table;
    clear: both;
  }
  
  
  .desktop-sub-menu.box-sub-menu {
    z-index:100;
    left: auto;
    right: 0;
    width: 100vw;
    top: 42px;
    background-color:white;
    display: none;
  }
  
  @media screen and (min-width: 880px) {
    .desktop-sub-menu.box-sub-menu {
      left: -45%;
      right: -5%;
      top: 50px;
    }
  }
  
  @media screen and (max-width: 879px){
      .box-logo{
          height: 50px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
      }
  }
  
  @media screen and (min-width: 990px) {
    .desktop-sub-menu.box-sub-menu {
      left: -150px;
      right: 80px;
      width: 100%;
    }
  }
  
  @media screen and (min-width: 1200px) {
    .desktop-sub-menu.box-sub-menu {
      top: 56px;
    }
  }
  
  .desktop-sub-menu.box-sub-menu.active {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  
  .desktop-sub-menu_column-title {
    font-size: 20px;
    color: #40a2ce;
    padding: 4px 0;
    margin: 0;
    font-weight: 500;
  }
  
  .desktop-sub-menu_column-body {
    line-height: 1.5;
    /* font-family: Gibson */ /* Removed font */;
    margin-bottom: 10px;
  }
  
  .desktop-sub-menu_item {
    color: #646464;
    position: relative;
    width: 25%;
    padding: 20px 30px 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 25%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  
  @media screen and (min-width: 1200px) {
    .desktop-sub-menu_item {
      padding: 30px 40px 35px;
    }
  }
  
  .desktop-sub-menu_item:not(:first-of-type):before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 15%;
    width: 1px;
    height: 73%;
    background-color:#e0d9cf;
  }
  
  #menu-main-menu .desktop-sub-menu_column-quicklink {
    padding: 0;
  }
  
  .desktop-sub-menu_column-quicklink a {
    line-height: 1.2;
    /* font-family: Gibson; */ /* Removed font */
    color: #646464;
  }
  
  .desktop-submenu_quick_links ul {
    list-style-type: disc;
    padding-left: 20px;
    color: #646464;
    text-align: left;
  }
  
  .desktop-sub-menu_column-image {
    margin: 4px auto;
    display: block;
  }
  
  .desktop-sub-menu_item.desktop-submenu_quick_links {
    padding: 20px 30px;
  }
  
  @media screen and (min-width: 1200px) {
    .desktop-sub-menu_item.desktop-submenu_quick_links {
      padding: 30px 45px;
    }
  }
  
  .desktop-sub-menu_item.desktop-submenu_column-4 {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  
  .text-center {
    text-align: center;
  }
  
  .desktop-submenu_column-4 .desktop-sub-menu_column-body {
    /* margin-bottom: 0; */
  }
  
  .js-menu-mobile{
      color: #7b5c80;
  }
  
      @media (max-width: 320px){
          .box-logo{
              height: 42px;
          }
      }
  
  .desktop-sub-menu_column-button {
    /* font-family: Gibson; */ /* Removed font */
    font-size: 12px;
    text-transform: uppercase;
    color: #FFF;
    background-color: #016789;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#016789), to(#0f4a71));
    background-image: linear-gradient(#016789, #0f4a71);
    border: 1px solid #175479;
    display: inline-block;
    border-radius: 3px;
    padding: 10px 15px;
    font-weight: 400;
    letter-spacing: 0.75px;
    min-width: 134px;
    text-align: center;
    margin-top: auto;
  }
  
  .menu li .desktop-sub-menu_column-button:hover {
    color: #FFF;
    background-color: #7fc261;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#7fc261), to(#a3ce5d));
    background-image: linear-gradient(#7fc261, #a3ce5d);
    border: 1px solid #158152;
  }
  
  .menu .item > a.active {
    color: #23738b;
    position: relative;
    z-index: 1;
  }
  
  .menu .item > a.active:before, .menu .item > a.active:before {
    content: "";
    height: 70px;
    width: 100%;
    position: absolute;
    background-color: #f9f2e5;
    bottom: -18px;
    left: 0%;
    z-index: -1;
  }
  
  @media screen and (max-width: 1090px) {
    .menu .item > a:active:before, .menu .item > a.active:before {
      bottom: -16px;
    }
  }
  @media screen and (max-width: 1024px) {
    .menu .item > a:active:before, .menu .item > a.active:before {
      bottom: -18px;
    }
  }
  
  #menu-main-menu a.js-submenu:focus {
    outline: 0;
  }
  
  
  
  #close_events_bar {
    display: none;
  }
  
  @media screen and (max-width: 640px) {
  
  
  #menu-main-menu .menu-item-has-children > a:after {
    content: "\f078";
    font: normal normal normal 14px/1 FontAwesome;
    color: #fff;
    position: relative;
  }
  
  
    #menu-main-menu .menu-item-has-children {
      position: relative;
    }
  
  
  #menu-main-menu .menu-item-has-children > a:after {
    color: #555;
    float: right;
  }
  
   
  
  
    .page-template-template-wp-events .blue.plan-your-trip {
      position: relative;
    }
  
    .page-template-template-wp-events .blue.plan-your-trip:after {
      content: "\f002";
      font: normal normal normal 22px/1 FontAwesome;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #e9a52e;
      line-height: 98px;
      color: #fff !important;
    }
  
  
  
  }
  
  
  
  @media screen and (min-width: 641px) {
    .menu-desktop {
      display: block !important;
    }
  }
  
  .events-search-bar button.button-default._go.wcp-event-register span {
    display: none !important;
  }
  
  .menu .menu-phone>a{
      font-size: 32px;
      color: #82cfe1;
      line-height: 1;
  }
  
  @media screen and (max-width: 879px){
      .menu .menu-phone>a{
          font-size: 28px;
      }
  }
  
  .menu li.menu-phone>a:hover{
      border-bottom: 0;
  }
  
  #menu-main-menu.menu .menu-phone{
      padding-bottom: 10px;
      padding-top: 10px;
      position: absolute;
  }
  
  .header-phone-number{
      display: none;
      opacity: 0;
      -webkit-transition: 0.3s;
      transition: 0.3s;
  }
  
  .header-phone-number.show-phone-number{
      background-color: white;
      padding: 20px 40px;
      color: #84628d;
      position: absolute;
      z-index: 999;
      font-size: 18px;
      right: 0;
      display: block;
      opacity: 1;
      -webkit-transition: 0.3s;
      transition: 0.3s;
  }
  
  .header-phone-number>a{
      color: #84628d;
  }
  
  