.elementor-posts--skin-teacher-skin-new-event-template-id {

  /* Style the button */
  .elementor-post__excerpt .button {
    background-color: #FFF;
    font-size: 16px;
    letter-spacing: 3.44px;
    color: #3a3346;
    text-transform: uppercase;
    margin-top: 20px;
    font-weight: bold;
    padding-top: 15px;
    padding-bottom: 15px;
    display: inline-block;
    text-align: center;
    margin-bottom: 50px;
  }

  /*.events-list {
    li {
      margin-bottom: 0 !important;

      a {
        max-width: 75%;
        text-decoration: underline;
      }
    }
  }*/
}