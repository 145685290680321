.events-recently-viewed-wrapper {

  // Spacing
  // Mobile
  @media screen and (max-width: 767px) {
    padding-top: 30px;
    padding-bottom: 10px;
  }

  // Above mobile
  @media screen and (min-width: 768px) {
    padding-top: 40px;
    padding-bottom: 30px;
  }

  // Style the title
  h2.section-title {
    text-align: center;
    margin-bottom: 10px;
  }

}


/* Swiper Slider Carousel */
.events-recently-viewed-wrapper{
  overflow: hidden;
  margin-left:0;
  margin-right: 0;
  max-width: 1098px; /* 970px */
  position: relative;

  @media screen and (max-width: 767px) {
    padding-top: 30px;
    padding-bottom: 10px;
  }

  // Above mobile
  @media screen and (min-width: 768px) {
    padding-top: 40px;
    padding-bottom: 30px;
  }

  // Style the title
  h2.section-title {
    text-align: center;
    margin-bottom: 10px;
  }

  .title-page{
    margin-bottom: 25px;
  }

  .related-art{
    max-width: 970px;
  }

  .recent-articles{
    overflow: hidden;
    margin:auto;
    max-width: 970px;
    &.category{
      .swiper-slide .slide-inner a .content{        
        .event-title{
          font-family: $font-primary-title;
          font-size: 1.23rem;
          line-height: 1.76rem;
          letter-spacing: .20px;
          color:$brand-primary;
          min-height: 65px; 
          font-weight: 500;         
        }
        .excerpt{
          font-size: 15px;
          min-height: 49px;
        }
        ul.auth-meta{
          justify-content: flex-end;
        }
      }
    }
  }

  .swiper-button-prev, .swiper-button-next, .catswiper-button-prev, .catswiper-button-next, .releventswiper-button-prev, .releventswiper-button-next{
      background-color: #84628d;
      width: 50px;
      height: 50px;
      border-radius: 100%;
      position: absolute;
      font-size: 27px;
      color: #ffffff;
      background-image: none;
      text-align: center;
      
      i{
        border-bottom: 2px solid #ffffff;
        border-right: 2px solid #ffffff;
        height: 15px;
        position: absolute;
        top: 17px;
        width: 15px;
        &:before{
          background: #84628d;
          border-radius: 50%;
          content: "";
          display: block;
          height: 100%;
          left: 0;
          opacity: 1;
          width: 100%;
        }

        &.right-arrow{
          right: calc(50% - 5px);
          transform: rotate(-45deg);
          transition: right .5s;
        }
        &.left-arrow{
          left: calc(50% - 5px);
          transform: rotate(135deg);
          transition: left .5s;
        }
      }
  }

  .catswiper-button-prev, .releventswiper-button-prev{
    left: 10px;
    right: auto;
  }

  .catswiper-button-next, .releventswiper-button-next{
    right: 10px;
    left: auto;
  }

  .catswiper-button-next, .catswiper-button-prev, .releventswiper-button-prev, .releventswiper-button-next{
    top: 50%;
    margin-top: -22px;
    z-index: 10;
    cursor: pointer;
    i{
      border-bottom: 2px solid #fff;
      border-right: 2px solid #fff;
      height: 15px;
      position: absolute;
      top: 17px;
      width: 15px;
    }
    &.swiper-button-disabled{
      opacity: .35;
      cursor: auto;
      pointer-events: none;
    }
  }

  .swiper-slide{

    .slide-inner{
      padding-left: 11px;
      padding-right: 11px;
      padding-bottom: 30px;

      a, .image, .content{
        max-width: 294px;
        margin:auto;
      }
      a{
        display: block;
        background: #ffffff;    
        margin: auto;
        border-radius: 10px;
        border:1px solid #dbdbdb;

        &:hover, &:focus{
          box-shadow: 0 2px 3px 0 rgba(99, 90, 98, 0.15), 0 6px 10px 4px rgba(66, 60, 67, 0.15);
          /* box-shadow: 0 2px 3px 0 rgba(60,64,67,.15), 0 6px 10px 4px rgba(60,64,67,.15); */
        }

        .image{        
          width: 100%;        
          min-height: 166px;
          background-size: cover;
          border-radius: 10px 10px 0 0;
        }
        .content{
          padding: 20px 25px;
          font-size: $txt-size;
          line-height: $text-size-lheight;

          .event-title{
            font-family: $font-primary-title; /* $font-primary */
            font-size: $txt-size-xlg;
            line-height: 1.56rem;
            letter-spacing: .5px;
            margin: 0 0 10px 0;
            padding: 0;
            font-weight: 700; /* 500 */
            color: $brand-primary;
            min-height: 100px;
          }
          ul{
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            align-items: center;
            justify-content: space-between;

            li{
              margin: 0;
              padding:0;
              font-size: 14px;
              font-weight: 500;
              span{
                color:#686868;
                &.auth{
                  color:$primary-color;                  
                }
              }             
                .go{
                  background: url(/wp-content/themes/astra-child/dist/images/spriteicons.svg) no-repeat 0 -195px;
                  justify-content: flex-end;
                  display: flex;
                  width: 18px;
                  height: 16px;
                }              
            }
          }
          .post-date{
            display: block;
            width: 100%;
            margin-bottom: 8px;
            font-size: $txt-size-s;
            color: $secondary-color;
          }
          .events_teacher {
            p{
              margin:0;
            }
            p, h6{
              font-size: 13px;
            }
          }
          .excerpt{
            display: block;
            margin-bottom: 15px;
          }
          .taxonomies{
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 10px;
            bottom: 35px;
            max-width: 218px;
            overflow: hidden;
            position:absolute;
          }
        }
      }
    }
  }
  .swiper-pagination-bullets{
    max-width: 750px;
    margin: auto;
    width: 100%;
    position: relative;
    margin-top:4px;
    text-align: center;
    transition: .3s;
    transform: translateZ(0);
    z-index: 10;

    .swiper-pagination-bullet{
      width:12px;
      height:12px;
      margin:0 11px;
    }
  }
}

@media(max-width:767px){
  .events-recently-viewed-wrapper .swiper-slide .slide-inner a .content .event-title{
    min-height: auto;
  }
}

@media(min-width:767px) and (max-width:1024px){
  .events-recently-viewed-wrapper{
    padding-left:60px;
    padding-right: 60px;
  }
}

/* End */