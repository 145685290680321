.calendar-search-bar {

    display: block;
    width:100%;
    padding:10px 25px 35px 25px;
    background: #ffffff;
    border-radius: 3px;
    max-width: 900px;
    margin:auto;
    box-shadow: 4px 7px 38px 6px rgba(0, 0, 0, 0.1);
  

  form{
    label{
        display: block;
        width:100%;
        text-transform: uppercase;
        font-size: 13px;
        letter-spacing: 1px;
        text-align:left;

            span{
                font-weight: bold;
                color:#4d4d4d;
            }
    }

    .field{
        display: inline-block;
        padding: 0 5px;
        margin: 0;
        position: relative;
        cursor: pointer!important;
        font-size: 16px;

        &.month-select{
            width: calc(26% - 5px);
            margin-left:0;
            padding-left:0;

            .mselector{
                margin-left: 0;
                padding-left:0; 
                width:100%; 
                position: relative;
                display: inline-block;                   
                
                &::before{
                    right: 20px;
                    top: calc(50% - 2px);
                    content: "";
                    display: block;
                    position: absolute;
                    cursor: pointer!important;
                    width: 0;
                    height: 0;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-top: 6px solid #84628d;
                    pointer-events: none;
                }

                
            }
        }

        &.keyword-input{
            max-width: 385px;
            width:100%;
        }

        &.submit-wrapper{
            width: 100%;
            height:50px;
            line-height: 50px;
            max-width: 80px;
        }
        
    }
    

    ._text-field {
        color: #a8a29f;
        display: inline-block;
        background: #fbfbfb;
        height: 24px;
        font-size: 16px;
        font-weight: 500;
        border: 1px solid #e2e2e2;
        border-radius: 4px;
        position: relative;
        padding: .75em;
        max-width: 350px;
        width:100%;
        box-sizing: content-box;
    }

    select {
        height: 50px;
        border: 1px solid #e2e2e2;
        font-size: 16px;
        font-weight: 500;
        color: #a8a29f;
        padding: .75em;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: 4px;
        background:#fbfbfb;
        cursor: pointer!important;
        outline:0;
        width: 100%;
      }

    .submit-wrapper{
        display: inline-block;
        padding: 0 5px 0 5px;
        position: relative;
        bottom: 0;
        ._go{
            display: inline-block;
            background: #84628d;
            cursor: pointer!important;
            height: 50px;
            line-height: 50px;
            padding: 0;
            width: 65px;
            font-size: 14px;
            border: 1px solid transparent;
            text-transform: uppercase;
            border-radius: 0;
            letter-spacing: 2.29px;
            font-weight: 700;
            border-radius: 4px;
            outline:none;
            color: #ffffff;
        }
    }
    .calendar-wrapper{
        display: inline-block;
        position: relative;
        height: 50px;
        line-height: 20px;
        padding: 0;
        border: 1px solid transparent;
        max-width: 157px;
        width:100%;
        bottom:-38px;

        .button-default {
            background-color: #84628d;
            border-radius: 4px;
            color: #fff;
            letter-spacing: 2.29px;
            font-weight: 700;
            font-size: 14px;
            line-height: 50px;
            height: 50px;
            text-transform: uppercase;
            position: relative;
            text-align:center;
            width: 100%;
            cursor: pointer;
            display: inline-block;
            padding-left: 13px;
            padding-right: 13px;
            outline: none;
            
        }
        span{
            font-size: 13px;
            text-align: center;
            width: 100%;
            display: inline-block;
            line-height: 30px;
        }
    }



    
  }



  @media screen and (max-width: 767px) and (orientation:portrait){
   form ._text-field{
        width:91%;
        max-width: 100%;
        margin-bottom:15px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
   
    form .submit-wrapper ._go{
        margin-bottom: 5px;
    }

    form .field {
        padding: 0;
    }

    form .field.month-select{
        width:100%;
        margin-bottom: 10px;
    }
    form .calendar-wrapper{
        bottom:0;
            span{
                font-size: 12px;
            }
    }
}



@media screen and (max-width: 767px) and (orientation:landscape){
    form ._text-field{
        width:96%;
        max-width: 100%;
        margin-bottom:5px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
    form .submit-wrapper ._go{
        margin-bottom: 5px;
    }
    
    form .field {
        padding: 0;
    }

    
    form .field.month-select{
        width:auto;
    }
}
}

.elementor-widget-slides  + .elementor-section-height-default .alert-msg{
    display: block;
    width: 100%;
    padding: 15px 20px;
    background: #f6efe2;
    border-radius: 3px;
    max-width: 900px;
    margin: 2px auto auto auto;

        p{
            color:#936c1f;
            font-size: 16px;
            font-size: 1rem;
            line-height: 20px;
            margin:0;
                a{
                    color: #614205;
                        &:hover{
                            text-decoration: underline;
                        }
                }
        }
}