body.page-template-new-calendar-page {
  .calendar-new-pagination {
    text-align: center;
  }

  .no-events-found {
    padding-left: 15px;
    padding-right: 15px;
    max-width: $container-max-width;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}

