/*-------------------------------------------------------------
  1.    Global Styles
-------------------------------------------------------------*/ 
$gro-primary-font:  $font-secondary;
$gro-general-font: $font-primary;
$border-radius: 20px;

#page{
  .event-floaction{
    .eftitle.elementor-widget-heading{
      .elementor-heading-title{
        font-family: $font-primary-title;
        font-size: $h3-size;
        letter-spacing: .31px;
        line-height: 1.4em;
        font-weight: normal;
      }
    }
    .efaddress.elementor-widget-heading{
      .elementor-heading-title{
        font-size: $txt-size-lg;
        text-transform: uppercase;
        font-weight: bold;
        line-height: 28px;
        letter-spacing: 0.92px;
        color: $brand-primary;
      }
    }
  }
  #description{
    .elementor-column .elementor-widget-wrap{
      padding:0;
    }
    .elementor-column:last-child .elementor-widget-wrap{
      padding:0 28px;
    }
    &.fixed{
      position: fixed;
      top: 57px;
      width:100%;
    }
  }
}

.admin-bar #page{
  #description{
    &.fixed{
      position: fixed;
      top: 100px;
      width:100%;
    }
  }
  #singleContentAreaNewDesign{
    #stickyEventSidebar4{      
      &.fixed{
        top:150px !important;
      }
    }
  }
}

#singleContentAreaNewDesign {

  @media screen and (max-width: 767px) {
    p, ul li {
      font-size: $txt-size; /* 16px */
    }
  }

  @media screen and (min-width: 768px) {
    p, ul li {
      font-size: $txt-size; /* 16px */
    }
    .elementor-text-editor{
      font-size: $txt-size;
      line-height: 25px;
      color:  $primary-color;
    }



     #stickyEventSidebar4{
       .elementor-widget-container{
         .elementor-text-editor{
            font-size: $txt-size;
            color:#ffffff;
            line-height: 24px;
            margin-bottom: 5px;
         }
       }
        .elementor-widget-text-editor.event-summary{
          line-height:$text-size-lheight;
          
            .elementor-widget-container{
              margin-bottom: 0;
              
              .elementor-text-editor{                
                p{
                  color: #ffffff !important;
                  padding-top: 5px;
                  margin-bottom: 0;
    
                  span.yellow{
                    color:#fead51;
                    font-weight: bold;
                  }                 
    
                }
              }
            }
          
        }
        .elementor-column .elementor-widget-wrap{
          padding:0;
        }
        &.fixed{
          position: fixed;
          top: 120px !important;
          z-index: 1;
        }
      }
    
  }

  @media (min-width:1025px) and (max-width: 1279px){
    #stickyEventSidebar4.fixed{
      top:108px;
    }
    #page #description.fixed{
      top:56px;
    }
  }
  

  ul {
    margin-left: 20px;
  }

  p, div p, ul li {
    margin-bottom: 15px;
    color: $primary-color;
    line-height: $text-size-lheight;
  }

  .elementor-text-editor ul li{
    margin-bottom: 5px;
  }  

  h2 {
    font-family: $font-primary-title;
    font-size: $h3-size;
    letter-spacing: 0.31px;
    color: $hdr-primary;
    line-height: $h1-msize-lheight;
  }

  // Style the h3 heading inside the text element
  .elementor-text-editor {
    h1, h2, h3, h4{
      font-family: $font-primary-title;      
      margin-bottom: 15px;
      color: $hdr-primary;
      line-height: $text-size-lheight;
    }

    h1{      
      font-size: 33px;
      letter-spacing: .31px;
    }

    h2{
      font-size: $h3-size;
      letter-spacing: .31px;
      line-height: 38px;
    }

    h3{
      letter-spacing: 0.19px;
      font-size: 28px;
      line-height: 32px;
    }

    h4{
      letter-spacing: 0.19px;
      font-size: 24px;
    }

    p+h1, p+h2, p+h3, p+h4{
      margin-top:25px;
    }

    p + ul, ul + p{
      margin-top:15px;
    }

    img{
      margin:25px auto;
    }

  }

  // Rooms & pricing  
    .rooms .elementor-col-100 .elementor-col-50 .elementor-widget-text-editor{
      p{
        margin-bottom:0;
      }
      ul li{
        margin-bottom:5px;
      }
    }

       
    article.teacher.type-teacher, article.staff.type-staff{

      .elementor-post__excerpt {  
          /*-webkit-columns: 3 auto;
          -moz-columns: 3 auto;
          columns: 3 auto;
          -moz-column-fill: auto;
          column-fill: auto;
          -webkit-column-gap: 5em;
          -moz-column-gap: 5em;
          column-gap: 5em;*/
          margin-bottom: 0;

          h1, h2, h3, h4, h5, h6{           
              -webkit-column-span: all; 
              column-span: all;            
          }          
          
      } 
      .elementor-text-editor, .elementor-text-editor p, .elementor-post__excerpt, .elementor-post__excerpt p, .elementor-post__excerpt a{
        color:#ffffff;
      }
      .elementor-text-editor a, .elementor-text-editor p a{
        color:#ffffff;
        text-decoration: underline;
      }
      .elementor-post__text a{
        &.elementor-post__read-more{
          font-size: 0.81rem;
          color: #ffffff;
          text-decoration: underline;
          letter-spacing: 1.24px;
          padding: 10px;
          line-height: 0.85rem;
        }
      }
    }


    .no-coln{
      article.teacher.type-teacher, article.staff.type-staff{
        .elementor-post__excerpt {  
          -webkit-columns: unset;
          -moz-columns: unset;
          columns: unset;
          -moz-column-fill: unset;
          column-fill: unset;
          -webkit-column-gap: unset;
          -moz-column-gap: unset;
          column-gap: unset;

          p + p, .elementor-section + .elementor-section, .elementor-section + .elementor-section + .elementor-section{
            display: none;
          }
          p.ast-the-content-more-link{
            display: block;
          }
          a{
            color:#ffffff;
          }
        }
      }
    }


    .tcoln{
      article.teacher.type-teacher, article.staff.type-staff{ 
    
        .elementor-post__excerpt{
          -webkit-columns: 2 auto;
          -moz-columns: 2 auto;
          columns: 2 auto;
          -moz-column-fill: auto;
          column-fill: auto;
          -webkit-column-gap: 5em;
          -moz-column-gap: 5em;
          column-gap: 5em;

          h1, h2, h3, h4, h5, h6{           
              -webkit-column-span: all; 
              column-span: all;            
          }

          a{
            color:#ffffff;
          }
      }
    }

    

    }

    .elementor-col-100 .elementor-col-50.sidebar, .elementor-col-50 .elementor-col-50.sidebar{

          .elementor-text-editor{
                margin-top:-10px;

                h1, h2, h3, h4, h5, h6{
                  font-family: $font-primary;
                  color: #544741;
                  font-size: $txt-size;
                  font-weight: 700;
                  text-transform: uppercase;
                  line-height: 23px;
                  display: block;
                  margin-bottom: 15px;
                  padding-top:10px;
                }

                p{
                  margin:0;
                  padding:0;
                  display: block;
                }

                ul{
                  margin:0 0 30px 0;
                  padding:0;
                  display: block;

                    li{
                      margin: 0 0 15px 0;
                      color:#676767;
                      font-weight: normal;
                      list-style:none;
                    }

                }
          }
    }   

    /* Happiness Retreat limit text for mobile only */
    .show-read-more .elementor-image-box-description .more-text, .show-read-more .elementor-text-editor .more-text{
        display: none;
    }
    .show-read-more .elementor-image-box-description .morecontent span, .show-read-more .elementor-text-editor .morecontent span {
        display: none;
    }
    .show-read-more .elementor-image-box-description .morelink, .show-read-more .elementor-text-editor .morelink , .show-read-more .elementor-text-editor .showmore{
        display: block;
        font-size: 20px;
        letter-spacing: .92px;
        font-weight: 700;
        padding: 10px 0 0 0;
        text-align:left;
        cursor: pointer;
        color:#84628d;
    }
    .show-read-more .elementor-text-editor .showmore{
      padding:0;
    }

    .highlight-container{
      margin-bottom: 40px;
      padding:15px;
      background:#ffffff;
      border-radius: 20px;
      box-shadow: 4px 7px 38px 7px rgba(132,98,140,.1);
        &.yellow{
          background-color: #fefcf6;
          border:1px solid #fbefcd;
        }
      .elementor-image-box-description{
        margin-bottom: 0;       
      }
      .show-read-more .elementor-image-box-description .morelink{
        text-align:center;
      }
    }

    /* Accordion / toggle style */
    .elementor-widget-toggle .elementor-widget-container .elementor-toggle{
        margin-top: 12px;
        margin-bottom: 20px;
        padding: 10px 0 0;
        background: #fff;
        border-radius: 20px;
        box-shadow: 4px 7px 38px 7px rgba(132,98,140,.1);

        .elementor-toggle-item{
          border-color: #d9d9d9;
          border-width: 0 0 1px;
          border-radius: 20px 20px 0 0;

            .elementor-tab-title{
              padding: 25px 35px;
              a{                
                color: $primary-color;
                font-family: $font-primary;
                font-weight: bold;
                font-size: $txt-size-lg;
                line-height: 1.40rem;   
              }
              .elementor-toggle-icon{
                margin:5px 10px 25px 0;
                .elementor-toggle-icon-closed .fa-minus:before, 
                .elementor-toggle-icon-closed .fa-plus:before, 
                .elementor-toggle-icon-closed .fa-caret-right:before, 
                .elementor-toggle-icon-opened .fa-minus:before,
                .elementor-toggle-icon-opened .fa-plus:before,
                .elementor-toggle-icon-opened .fa-caret-right:before{
                  color: #84628c;
                }                
              }
            }
            .elementor-tab-content {
              border: 0;
              padding: 8px 60px 35px;              
            }
          
          &:last-child .elementor-tab-title{
            border:0;
          }

        }
    }
    
    /* Discount Badge styles */
    .discountBadge{
        position: fixed;
        top: 98px;
        right: -2px;
        width: 100px;
        z-index: 100;
    }

    .white-shadow-container{
      .elementor-widget-call-to-action, .elementor-widget-media-carousel, &.shadow-coln .elementor-container, .white-shadow{
        display: block;
        margin-top:0;
        margin-bottom:0;
        padding:28px 28px 28px 28px;
        background:#ffffff;
        border-radius: $border-radius;
        box-shadow: 4px 7px 38px 7px rgba(132, 98, 140, 0.1);
        &.no-padding{
          padding:0 28px;
        }
      }
      .elementor-cta--skin-classic .elementor-cta__content{
        background:#ffffff;
      }
      .elementor-widget-call-to-action .elementor-cta__title, &.shadow-coln .elementor-container .elementor-heading-title{
        font-family: $font-secondary;        
        font-weight: 500;
        color: $brand-primary;        
      }
      
      &.type-two .elementor-widget-call-to-action{
        padding:0;
      }
      
      .shadow-inner-coln{
        margin:0 5px;
        padding:30px;
        background:#ffffff;
        border-radius: $border-radius;
        box-shadow: 4px 7px 38px 7px rgba(132, 98, 140, 0.1);

        .elementor-heading-title{
          font-family: $font-secondary;        
          font-weight: 500;
          color: $brand-primary;
        }

        ul{
          margin:0 0 40px 40px;
          padding:0;

          li{
              margin-bottom: 10px;
          }

        }
        &.no-mpadding{
          margin:0;
          padding:0;
        }
        &.no-padding{
          padding:0;
        }
        &.no-margin{
          margin:0;
        }

        &.no-mpadding, &.no-padding, &.no-margin{
          ul{
            margin:0;
            padding:0;

            li{
                margin-bottom: 0;
            }

          }
        }
        
        .elementor-widget-price-list{
          margin:0;
          padding:0 30px 30px 30px;

          ul.elementor-price-list{
            li{
              .elementor-price-list-text{
                margin-bottom:10px;

                .elementor-price-list-header{
                  margin-top:10px;
                }
                ul{
                  margin:-20px 25px 25px 0;
                  padding:0;
                  list-style: none;
                  li{
                    i.fa-check{
                      color: #44A453;
                      margin-right:6px;
                    }
                    i.fa-times{
                      margin-right:6px;
                    }
                    h6{
                      font-family: $font-primary;
                      font-weight: 500;
                      font-size: 17px;
                      margin-top:10px;
                    }
                    p:last-child{
                      margin:0;
                      padding:0;
                    }                                     
                  }
                }
                ul:last-of-type{
                  margin-bottom: 10px;
                }
                p.elementor-price-list-description+ul{
                  margin-top:-20px;
                }
                p.elementor-price-list-description+ul li:first-child{
                  margin-top:10px;
                }
                .elementor-price-list-title{
                  color:$brand-primary !important;
                }
                p:last-child{
                  margin:0;
                  padding:0;
                } 
              }
              //
            }
          }
        }

        .repeatable_fields_shortcode{
          margin-top: -15px;
          padding: 0 30px 30px 30px;

          #mySelect, .mySelectDropdown{
            margin-top: 5px;
            margin-bottom: 20px;
            font-family: $font-primary!important;
            color: $primary-color!important;
            background: rgba(255, 162, 8, 0.09) !important;
            min-height: 46px!important;                        
            font-size: 17px!important;
            font-weight: 500;
            border: 1px solid #84628d!important;
            border-radius: 4px!important;
            width: 100%;
            padding: 2px 10px !important;
            /*box-shadow: 0 0 12px #f4c208;*/             

            /* FF fix */
            @-moz-document url-prefix() {
              padding: 8px 10px !important;
            }
            
          }

          #mySubmit, .register-button{
            background-color: $brand-primary;
          }

        }

      }
      
    }
    #schedule .elementor-text-editor{
      h2, h3, h4, h5, h6{
        color:#84628D;
      }
    }
    #faqs .faq-slide{
      .elementor-tab-title, .elementor-tab-title a{
        color: $primary-color;
        font-family: $font-primary;
        font-weight: 700;
        font-size: $txt-size-lg;
      }
    }
    .room-details{
      .elementor-heading-title{
        color:#84628d;
      }
      .room-price{
        .elementor-text-editor{
          font-size: $txt-size-lg;
        }
      }
    }
}
//

#singleContentAreaNewDesign{
  .elementor-widget-reviews, .elementor-widget-testimonial-carousel{
    .swiper-slide{
      .elementor-testimonial{
          .elementor-testimonial__header{
              .elementor-testimonial__cite{
                .elementor-testimonial__name{
                  font-size: 20px !important;
                }
                .elementor-testimonial__title{
                  font-size: 16px !important;
                }
              }
          }
          .elementor-testimonial__content{
            .elementor-testimonial__text, .elementor-testimonial__text p{
              font-size: $txt-size !important;
              font-style: normal;
            }
            .elementor-testimonial__cite{
              .elementor-testimonial__name{
                font-size: $txt-size-lg;
                font-style: normal;
                line-height: 22px;
              }
              .elementor-testimonial__title{
                font-size: $txt-size;
                line-height: 22px;
                margin-top: 5px;
              }
            }
          }
      }
    }
    
  } 
}

.pg-faq .elementor-widget-toggle .elementor-widget-container .elementor-toggle .elementor-toggle-item .elementor-tab-content{
    margin-bottom: 15px;
    color: $primary-color;
    line-height: $text-size-lheight;
    font-size: $txt-size;
  ul{
    li{
      margin-bottom: 20px;
    }
  }
}

/*===============
  Video List
===============*/
#singleContentAreaNewDesign{
  .video-list-container{
    .elementor-widget-divider, .elementor-divider-separator{
      border-color:#d7d7d7;
      margin-bottom: 0;
    }
    .video-list{
      background-color: #fff;
      transition: all .2s ease-in-out;
      box-shadow: 8px 15px 45px 0 rgba(0,0,0,.09);
      border-radius: 20px;
      margin:20px 0;

      .elementor-video-iframe{
        border-radius: 3px;
      }
      .elementor-widget-heading{
        margin-bottom: 15px;
      }    
      .elementor-widget:not(:last-child){
        margin-bottom: 10px;
          &.elementor-widget-heading{
            margin-bottom: 15px;
          }
          &.elementor-widget-divider{
            margin-bottom: 0;
          }
      }
      h3, h4, h5, h6{
        font-family: $font-primary-title;
        font-size: 24px;
        letter-spacing: .31px;
        color: #484848;
        line-height: 28px;
      }
      .elementor-icon-list-items{
        margin-left:0;
          li{
            margin-bottom: 0;

            a{
              color: #676767;
            }
          }
      }
      .elementor-text-editor{
        p{
          margin-bottom: 5px;
        }
      }
    }
  }
}


/* Discount Popup styles */
.discount-popup .elementor-text-editor p{
  font-size: 22px;
  text-align: center;
    .yellow{
      color:#fc6e51;
    }
}

.discount-popup .elementor-text-editor p .discount-code{
    display:block;
    background: url(/wp-content/uploads/2019/10/scissor-2x.png) no-repeat 42% 0;
    background-size: auto 25%;
    padding-top: 7px;
    margin-top: 10px;
    margin-bottom: -25px;

    #couponCopyBtn{
      padding:11px 30px 13px 30px;
    }
}
.discount-popup .elementor-text-editor p .promo{
    display:inline-block;
    border:2px dashed #d7d7d7;
    padding:10px 25px 12px 25px;
    font-weight: bold;
  }
.discount-popup .elementor-text-editor p .copied{
    display:inline-block;
    padding-left:10px;
    font-size: 18px;
}


#hero-title{

  #reg-event-details{
    padding-top:10px;
    padding-bottom:15px;
    background-color:rgba(28,39,40,0.5);

    .event-reg-title{
      display: block;
      margin-bottom:8px;
    }

    .conf-event-date{
      ul{
        display:inline-block;
        margin:0 45px 0 0;
        padding:0;

        li{
          display: block;
          list-style:none;

          &:first-child{
            color:#fda32b;
            text-transform: uppercase;
            font-size:24px;
            font-weight: bold;
            line-height:24px;
            margin-bottom: 5px;
          }
          &:nth-child(2){
            font-size:14px;
            font-weight: bold;
            letter-spacing: 3px;
            margin-bottom:12px;
          }

          &:nth-child(3), &:nth-child(4), &:nth-child(5){
            font-size:12px;
            letter-spacing: 3px;
            text-transform: uppercase;
            font-weight: bold;
            line-height: 22px;
            text-align: right;

            strong{
              letter-spacing: 0;
              font-size:20px;
            }

          }
        }

      }
      ul:last-child{
        margin-right:0;
      }
    }
    //

    .acuity-embed-button{
      display: inline-block;
      background: #84628c !important;
      color: #fff;
      font-weight: bold;
      text-transform: uppercase !important;
      border: 0px;
      padding: 10px 25px;
      letter-spacing: 1.5px;
      font-size: 16px;
    }

  }
}

.wcp_event #hero-title{
  .elementor-column{
    .desc{
      .elementor-text-editor{
        font-size: 22px;
        color:#ffffff;
        line-height: 28px;
      }
    }
    .date, .author{
      .elementor-text-editor, .elementor-text-editor p, .elementor-text-editor p span{        
        color:#ffffff;
        font-size: $txt-size-xlg;
      }
      .elementor-text-editor p{
        margin-bottom:0;
      }
    }
  }  
}

.conf-presenter{
  h3{
    display: block;
    font-size:24px;
    color:#484848;
  }
  h5{
    display: block;
    font-size:16px;
    color:#7f658a;
    margin-bottom: 10px;
    font-weight: bold;
    font-family: $font-primary;
  } 
  figure{
    figcaption{
      color:#000000;
      font-weight: bold;
      
      span{
        display:inline-block;
        font-weight: bold;
        font-size:12px;
        color:#494949;
        line-height: 22px;
      }
    }
  }
}

  #img-vdo-slider{
    position: relative;
    margin-bottom:0;
  }

    @media (max-width:767px){
      #img-vdo-slider{    
        margin-top: 0;  

        .elementor-main-swiper{
            height: calc(230px - 4.5rem);
        }

      }
    }

    @media (min-width:768px) and (max-width:1024px){
      #img-vdo-slider{    
        margin-top: 0;  

        .elementor-main-swiper{
            height: calc(230px - 1.2rem);
        }

      }
    }

    @media (min-width:1025px) and (max-width:1199px){
      #img-vdo-slider{    
        margin-top: -60px;

        .elementor-main-swiper{
            height: calc(230px - 6.5rem);
        }

      }
    }

    @media (min-width:1200px) and (max-width:1599px){
      #img-vdo-slider{    
        margin-top: -80px;

        .elementor-main-swiper{
          height: calc(230px - 5rem);
        }

      }
    }

    @media (min-width:1600px){
      #img-vdo-slider{    
        margin-top: -100px;
        
        .elementor-main-swiper{
          height: calc(230px - 2rem);
        }

      }
    }

// Schedule an appointment - Popup

#stickyEventSidebar3{
  position: fixed; 
  width: 20%;
  top: 70px;
  z-index: 9900;
  right:0;
  border-radius: 0;
  max-width:410px;
  min-width: 335px;
  padding:15px 38px 28px 38px;

  .elementor-widget-icon .elementor-icon svg{
    width:15px;
    height:15px;
    margin-top:0;
  }

  // expand collapse icon
  .elementor-widget-icon .elementor-icon.collapse{
    transform: rotate(-90deg);
  }

  .elementor-widget-icon .elementor-icon.expand{
    transform: rotate(-45deg);
  }

  .elementor-widget-icon + .elementor-inner-section .elementor-inner-column{
      .elementor-column-wrap{
        padding-bottom:0;
      }
  }

  .elementor-widget-text-editor{
    line-height:$text-size-lheight;
  }

  .elementor-widget-text-editor.event-desc{
    padding-top:5px;
  }

  .elementor-widget-button.register-button{
    height:0;
  }

  .elementor-widget-star-rating{
    margin-top:10px;
    margin-bottom:5px;
  }

  .elementor-widget-star-rating .elementor-star-rating__title{
    order:2;
    padding-left:10px;
    color:#ffffff;
  }

  .elementor-widget-star-rating .elementor-widget-container{
    padding-left:0;
  }

  .elementor-inner-section .elementor-inner-column .elementor-column-wrap{
    padding:0;

        .elementor-widget-reviews {
              .swiper-slide{
                background-color:transparent;
                border:0;
                border-radius: 0;

                    .elementor-testimonial{
                      display:flex;
                      flex-flow: row wrap;

                          .elementor-testimonial__header{
                            border-bottom:0;
                            order:2; 
                            width:100%;  
                            padding:0; 
                            
                                cite{
                                  width:100%;
                                }

                                cite .elementor-testimonial__name{
                                  color:#ffffff;
                                  font-size:18px;
                                  font-weight: normal;
                                  margin:auto;
                                  text-align: left;
                                  display: block;
                                  width:73%;                              
                                }
                          }

                          .elementor-testimonial__content{
                            color:#ffffff;
                            padding-bottom:4px;

                              .elementor-testimonial__text{
                                margin:auto;
                                width:80%;
                                font-size:13px;
                                letter-spacing: 0.065px;
                                line-height:1.7;
                                overflow-wrap: break-word;
                                color:#ffffff;
                              }
                          }            
                    
                    }            

              }
                        
        }      
        
  }  
        // Dropdown
        .repeatable_fields_shortcode.mobile{
            padding:0; 
            margin-bottom:20px;  
            
            #mySelect{
              margin-top:0;
            }
        }
        // Buttons
        .elementor-button-wrapper{
            a, .elementor-button-text{
              display:block;
              font-size:12px;
              line-height: 18px;
              letter-spacing: 0;
              text-transform: uppercase;              
            }
        }
}


#stickyEventSidebar4{  
  border-radius: 0;
  max-width:390px;
  min-width: 335px;  

  .elementor-widget-icon .elementor-icon svg{
    width:15px;
    height:15px;
    margin-top:0;
  }

  .elementor-widget-icon + .elementor-inner-section .elementor-inner-column{
      .elementor-column-wrap{
        padding-bottom:0;
      }
  }

  .elementor-widget-text-editor.event-desc{
    padding-top:5px;
  }

  .elementor-widget-button.register-button .elementor-widget-container{
    margin:0!important;
  }

  .elementor-widget-star-rating{
    margin-top:10px;
    margin-bottom:5px;
  }

  .elementor-widget-star-rating .elementor-star-rating__title{
    order:2;
    padding-left:10px;
    color:#ffffff;
  }

  .elementor-widget-star-rating .elementor-widget-container{
    padding-left:0;
  }

  .elementor-inner-section .elementor-inner-column .elementor-column-wrap{
    padding:0;

        .elementor-widget-reviews {
              .swiper-slide{
                background-color:transparent;
                border:0;
                border-radius: 0;

                    .elementor-testimonial{
                      display:flex;
                      flex-flow: row wrap;

                          .elementor-testimonial__header{
                            border-bottom:0;
                            order:2; 
                            width:100%;  
                            padding:0; 
                            
                                cite{
                                  width:100%;
                                }

                                cite .elementor-testimonial__name{
                                  color:#ffffff;
                                  font-size:$txt-size !important;
                                  letter-spacing: 0.065px;
                                  font-weight: normal;
                                  margin:auto;
                                  text-align: left;
                                  display: block;
                                  width:73%;                            
                                }
                          }

                          .elementor-testimonial__content{
                            color:#ffffff;
                            padding-bottom:4px;

                              .elementor-testimonial__text{
                                margin:auto;
                                width:80%;
                                font-size:13px;
                                color:#ffffff;
                                line-height:$text-size-lheight;
                                letter-spacing: .065px;
                                overflow-wrap: break-word;
                              }
                          }            
                    
                    }            

              }
                        
        }      
        
  }  
        // Dropdown
        .repeatable_fields_shortcode.mobile{
            padding:0; 
            margin-bottom:20px;  
            
            #mySelect{
              margin-top:0;
            }
        }
        // Buttons
        .call-button{
          .elementor-button-wrapper{
            a.elementor-button.elementor-size-sm{
              padding:12px;
              max-width:125px;
            }
          }
        }

        .more-information-button{
          .elementor-button-wrapper{
            a.elementor-button.elementor-size-sm{
              padding:15px 10px 8px 18px;
            }
          }
        }


        .elementor-button-wrapper{
            a#singleEventDesktopRegister .elementor-button-text{
              display:block;
              font-size:16px;
              line-height: 18px;
              letter-spacing: 0;
              text-transform: uppercase;              
            }
            a {
              width:100%;

              &.elementor-button .elementor-align-icon-left{
                margin-right:2px;
                .fas.fa-phone{
                  font-size: 12px;
                }
              }
              .elementor-button-text{
                display:block;
                font-size: 11px;
                line-height: 16px;
                letter-spacing: 0;
                text-transform: uppercase; 
              }
            }            
        }
        .register-button{
          .elementor-button-wrapper{
            a{
              .elementor-button-text{
                font-size: 16px;
                letter-spacing: 2px;
                line-height: 23px;
              }
            }
          }
        }
  /* Discount code copy */
  button#couponCopyBtn{
    display:none;
  }   
}


#singleContentAreaNewDesign #stickyEventSidebar4{
    .elementor-widget-text-editor.event-summary{
      line-height:$text-size-lheight;
      
        .elementor-widget-container{
          margin-bottom: 0;
          
          .elementor-text-editor{
            p{
              color: #ffffff !important;
              padding-top: 5px;
              margin-bottom: 0;

              span.yellow{
                color:#fead51;
                font-weight: bold;
              }

              .promo{
                background: #fead51;
                color: #3c3247;
                padding: 0 5px;
                font-weight: 500;
                border-radius: 3px;
              }

            }
          }
        }
      
    }

    // Silent Two dropdow Sidebar
    .mySelectDropdown{
      max-width: 292px;
      padding: 0.5em .75em 0.5em 0.5em;
      margin-bottom: 10px;
    }
}

// Mobile
@media (max-width: 767px){
  #primary{
    .flocation.elementor-widget-heading{
      .elementor-heading-title{
        font-size: $txt-size-lg;
        text-transform: uppercase;
        font-weight: bold;
        line-height: 30px;
      }
    }
    .event-floaction{
      .eftitle.elementor-widget-heading{
        .elementor-heading-title{
          font-family: $font-primary-title;
          font-size: $h3-size;
          letter-spacing: .31px;
          line-height: 1.4em;
          font-weight: normal;
        }
      }
      .efaddress.elementor-widget-heading{
        .elementor-heading-title{
          font-size: $txt-size-lg;
          text-transform: uppercase;
          font-weight: bold;
          line-height: 28px;
          letter-spacing: 0.92px;
          color: $brand-primary;
        }
      }
    }
  }

  #singleContentAreaNewDesign article.teacher.type-teacher{
    display: flex;
    flex-direction: column;
      .elementor-post__excerpt, .elementor-post__excerpt p, .elementor-text-editor, .elementor-text-editor p{
        color: #ffffff;
        padding-top:0;
      }
      .elementor-post__title, .elementor-post__title a{
        color:#ffffff;
        font-family: $font-secondary;
        font-size: $h2-msize;
        line-height: $h2-msize-lheight;
        letter-spacing: 0.31px;
        margin-bottom: 25px;
      }
      .elementor-post__excerpt .elementor-column-wrap{
        padding:0;
      }
      .elementor-post__thumbnail__link{
        margin-bottom: 25px;
      }
  }

  /*#singleContentAreaNewDesign article.teacher.type-teacher .elementor-post__excerpt, #singleContentAreaNewDesign article.staff.type-staff .elementor-post__excerpt,
  #singleContentAreaNewDesign .tcoln article.staff.type-staff .elementor-post__excerpt, #singleContentAreaNewDesign .tcoln article.teacher.type-teacher .elementor-post__excerpt{
      columns: 1 auto;
  }*/
 
  #singleContentAreaNewDesign .elementor-element.elementor-element-0722778>.elementor-widget-container{
    padding-left:0;
  }
  #singleContentAreaNewDesign .elementor-widget-toggle .elementor-widget-container .elementor-toggle{
    .elementor-toggle-item .elementor-tab-title .elementor-toggle-icon{
      margin-bottom: 45px;
    }
    .elementor-toggle-item{
      .elementor-tab-content{
        padding:8px 20px 15px 20px;
        font-size: $txt-size;
        line-height: $text-size-lheight;
      }
    }
  }

  #singleContentAreaNewDesign .highlight-container{
    margin: 0 25px;
    .elementor-image-box-content .elementor-image-box-title{
      margin-bottom:5px;
    }    
  }

  .discount-popup{
      .elementor-widget-heading{
        margin-bottom: 10px;
      }
      
      .elementor-text-editor p .discount-code{
        background-position: 50% 0;
        background-size: auto 12%;
        #couponCopyBtn{
          margin-top:10px;
        }
      }
  }

  #singleContentAreaNewDesign{ 

    ul{
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .elementor-widget-toggle .elementor-widget-container .elementor-toggle{
      padding-top:0;
        .elementor-toggle-item .elementor-tab-title{
          padding:25px 20px;

          a{
            font-size:$txt-size-lg;
          }
        }
    }
    // for Mobile only teaser & complete
    .show-read-more{
      .complete{
        display: none;
        margin-bottom: 10px;
      }
      .teaser{
        p{
          margin-bottom: 10px;
        }
      }
    }    
  }

  /* Happiness Retreat limit text for mobile only */

  .show-read-more{

    .elementor-image-box-description .more-text, .elementor-text-editor .more-text, .elementor-cta__description .more-text{
      display: none;
    }
    .elementor-image-box-description .morecontent span, .elementor-text-editor .morecontent span, .elementor-cta__description .morecontent span {
      display: none;
    }
    .elementor-image-box-description .morelink, .elementor-text-editor .morelink , .elementor-text-editor .showmore, .elementor-cta__description .showmore{
      display: block;
      font-size: 20px;
      letter-spacing: .92px;
      font-weight: 700;
      padding: 10px 0 0 0 !important;
      text-align:left;
      cursor: pointer;
      color:#84628d;
    }
    .elementor-text-editor .showmore, .elementor-cta__description .showmore{
      padding:0;
    } 
    .complete{
      display: none;
      margin-bottom: 10px;
    }
    .teaser{
      margin-bottom: 10px;
      p{
        margin-bottom: 0;
      }
    }
    .elementor-cta__title, .elementor-cta__description, .elementor-cta__description .teaser, .elementor-cta__description .complete{
      text-align:left;
    }

  }  

}


// Tablet
@media (min-width: 768px) and (max-width: 1024px) {
  #stickyEventSidebar3{
    transform: translateY(48px);

    .call-button{
      .elementor-widget-container{
        margin-top:5px;
      }
    }
  }
  #stickyEventSidebar4{
    transform: translateY(48px);

    .call-button{
      .elementor-widget-container{
        margin-top:5px;
      }
    }
  }
  /*#singleContentAreaNewDesign article.teacher.type-teacher .elementor-post__excerpt, #singleContentAreaNewDesign article.staff.type-staff .elementor-post__excerpt{
    columns: 2 auto;
  }*/

  #singleContentAreaNewDesign article.teacher.type-teacher, #singleContentAreaNewDesign article.staff.type-staff{    
      .elementor-post__excerpt, .elementor-post__excerpt p, .elementor-text-editor, .elementor-text-editor p{
        color: #ffffff;        
      }
      .elementor-post__title, .elementor-post__title a{
        color:#ffffff;
        font-family: "Playfair Display", Sans-serif;
        font-size: 36px;
        line-height: 26px;
        letter-spacing: 0.31px;
      }
  }
  

}

@media (min-width: 1025px) and (max-width: 1199px) {
  #stickyEventSidebar3{
    .call-button .elementor-widget-container{
        margin-top:0;
    }
  }
  #stickyEventSidebar4{
    .call-button .elementor-widget-container{
        margin-top:0;
    }
  }  
}

@media (max-width:767px){
  .show-read-more{  
    .elementor-cta .elementor-cta__content{
        min-height:260px !important;
    }
    .show-read-more .elementor-cta__description .showmore{
        line-height: 1.85714285714286;
    }
    
  }
}