// Regular post header
.post-author-header {
  margin-bottom: 20px;

  img {
    border: 2px solid #3C3247;
    width: 50px;
    height: auto;
    margin-right: 10px;
    border-radius: 100%;

    @media screen and (max-width: 1023px) {
      width: 40px;
    }
  }

  a {
    color: $brand-primary;
  }
}

// Custom Post Header
.custom-post-authors-header {
  display: block;
  margin-top:24px;
  margin-bottom: 25px;
  color: $secondary-color;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1023px) {
    padding: 0 15px;
  }

  ul {
    margin-left: 0;
    display: inline-block;
    margin-bottom: 0;
    padding-bottom: 0;
    

    li {
      display: inline-block;
      font-weight: 500;
      padding-bottom: 0;
      margin-bottom: 0;

      a {
        color: #000000;
        line-height: 20px;
        
        @media screen and (max-width: 1023px) {
          font-size: $txt-size-lg;
        }
      }
  
      .author_avatar { 
        border-radius: 100%;
        border: 2px solid #FFF;
        width: 45px;
        height: 45px;
        margin-right: 20px;

        @media screen and (max-width: 1023px) {
          width: 40px;
          height:auto;
          max-height: 40px;
        }
      }

    }

    &.author-avatar-list {
      li {
        position: relative;
        top: 0;
      }

      li:not(:first-child) {
        margin-left: 0;
      }

      @for $i from 1 through 10 {
        li:nth-last-child(#{$i}) {
          z-index: #{$i};
        }
      } 
    }

    &.author-name-list {
      li:not(:first-child):before {
        position: relative;
        content: "&";
        margin-left: 5px;
        margin-right: 7px;
      }
      li a:hover{
        color:$hover-brand-primary;
      }
    }
    
  }

  .post-meta-right {
    display: inline-flex;
    align-items: center;
    justify-content: left;

    .author-details{
      display: inline-block;
    }

    .author-name-list, .post-meta-quick-info{
      display: block;
      padding-bottom: 0;
    }

    .post-meta-quick-info {
      top: 0;
      position: relative;

      li {
        font-size: 15px;
        line-height: 16px;
        color:$secondary-color;

        @media screen and (max-width: 1023px) {
          font-size: $txt-size;
          margin-bottom: 0;
        }
      }

      li:not(:first-child):before {
        font-size: 16px;
        position: relative;
        content: "";
        margin-left: 7px;
        margin-right: 10px;
        width: 4px;
        height: 4px;
        background-color: #FFF;
        display: inline-block;
        border-radius: 100%;
        line-height: 30px;
        top: -3px;

        @media screen and (max-width: 1023px) {
          margin-left: 3px;
          margin-right: 6px;
        }
      }
    }
  }
  
  .blog-share{
    display: inline-block;
    position: relative;
    a{
      display: inline-block;
      background-image:url("/wp-content/themes/astra-child/dist/images/spriteicons.svg");
      background-repeat:no-repeat;
      background-position: 0 -15px;
      color:#000000;
      font-size: $txt-size;
      padding-left: 28px;

      &:hover{
        color:$hover-brand-primary;
        filter:invert(31%) sepia(37%) saturate(316%) hue-rotate(247deg) brightness(93%) contrast(89%);
      }

    }
    .list-share{
      display: none;
      position: absolute;
      padding:17px 24px;
      background-color: #ffffff;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
      z-index: 1;
      border:0;
      border-radius: 4px;
      right:0;
      top:35px;
      width:155px;
      
      .elementor-column-gap-default .elementor-widget-wrap{
        padding:0;
      }
      .elementor-share-buttons--view-icon .elementor-share-btn__icon, .elementor-share-buttons--view-icon .elementor-share-btn__text, .elementor-share-buttons--view-text .elementor-share-btn__icon, .elementor-share-buttons--view-text .elementor-share-btn__text{
        justify-content: left;
        padding:0;
      }
      .elementor-share-btn__title{
        padding-left: 25px;
        &:before{
          background-image:url("/wp-content/themes/astra-child/dist/images/spriteicons.svg");
          background-repeat: no-repeat;
          width:17px;
          height:17px;
          content: "";
          margin-left: -25px;
          padding-left: 25px;
          top: 2px;
          position: relative;
        }
        &:hover{
          color:$hover-brand-primary;
          filter:invert(31%) sepia(37%) saturate(316%) hue-rotate(247deg) brightness(93%) contrast(89%);
        }
      }
      .elementor-share-btn_twitter{        
        .elementor-share-btn__title:before{
          background-position: 0 -49px;          
        }
      }

      .elementor-share-btn_facebook{        
        .elementor-share-btn__title:before{
          background-position: 0 -74px;          
        }
      }

      .elementor-share-btn_linkedin{        
        .elementor-share-btn__title:before{
          background-position: 0 -100px;          
        }
      }

      .elementor-share-btn_whatsapp{        
        .elementor-share-btn__title:before{
          background-position: 0 -125px;          
        }
      }

      .elementor-share-btn_telegram{        
        .elementor-share-btn__title:before{
          background-position: 0 -149px;          
        }
      }

      .elementor-share-btn_email{        
        .elementor-share-btn__title:before{
          background-position: 0 -172px;          
        }
      }

      .elementor-share-btn_pinterest{        
        .elementor-share-btn__title:before{
          background-position: 0 -219px;          
        }
      }

    }

  }
}

// Make the no-gutenberg variant have a different color
#primary {
  .author-avatar-list img {
    border: 2px solid #3C3247;
  }

  .post-meta-right,
  .post-meta-right a {
    color: #3C3247;
  }

  .post-meta-right {
    .post-meta-quick-info li:not(:first-child):before {
      background-color: #3C3247;
    }
  }
}

@media(max-width:767px){
  .custom-post-authors-header{
    padding-left:0;
    
    ul{
      &.author-avatar-list {
        margin-top: 15px;
        margin-bottom: 0 !important;

        li {
          position: relative; 
          top:-5px;
          .author_avatar{
            margin-right: 10px;
          }
        }
           
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 480px) { // Portrait
  .single-post .single-post-container #primary .custom-post-authors-header{
    ul{
      &.author-avatar-list {     
        li {
          position: relative;
          top:auto;
          margin-bottom: 10px;
        }           
      }
      &.author-name-list{
        li{
          margin-bottom: 10px;
        }
      }
      &.post-meta-quick-info{
        margin-bottom: 5px;
      }
    }
  }
  .single-post .single-post-container{
    #primary{
      &.no-banner{
        .custom-post-authors-header{
          ul{
            &.author-avatar-list{
              display: block;
              margin:0;
              li{
                top:0;
              }
            }
          }
          .post-meta-right{
            ul.author-name-list{
              margin-bottom: 0;
            }          
          }
        } 
      }         
    }
  }
}