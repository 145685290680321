/*.title-page {
  margin-bottom: 20px;
}
*/
/*.title-page:before {
  bottom: -21px;
}
@media screen and (max-width: 320px) {
  .title-page:before {
    bottom: -11px;
  }
}*/

.banner-intro {
  margin-top: 50px;
}
@media screen and (max-width: 320px) {
  .banner-intro {
    padding-bottom: 20px;
    min-height: initial;
  }
}
.banner-intro.no-height {
  min-height: 0;
}

/*
.content-banner-intro .title-page {
  margin-bottom: 38px;
}
*/

@media screen and (max-width: 320px) {
  .content-banner-intro .title-page {
    margin-bottom: 10px;
  }
}
/*.content-banner-intro .title-page:before {
  bottom: -21px;
}
@media screen and (max-width: 320px) {
  .content-banner-intro .title-page:before {
    bottom: -11px;
  }
}*/
@media screen and (max-width: 320px) {
  .content-banner-intro .container-medium {
    padding-top: 10px;
  }
}

.text-banner-intro {
  line-height: 1.5;
}
@media screen and (max-width: 640px) {
  .text-banner-intro {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media screen and (max-width: 320px) {
  .text-banner-intro {
    padding-left: 25px;
    padding-right: 25px;
  }
}


/*.banner-intro .title-page {
  color: #fff;
}*/
/*.banner-intro .title-page.type-b {
  color: #84628d;
}*/

.text-banner-intro {
  /*color: #c4aaba;*/
  font-size: 1.3em;
  text-align: center;
  font-weight: 300;
  margin-bottom: 20px;

  iframe {
    margin-top: 20px;
  }
}
@media screen and (max-width: 640px) {
  .text-banner-intro {
    font-size: 1.2em;
  }
}
@media screen and (max-width: 320px) {
  .text-banner-intro {
    font-size: 1em;
  }
}
/*.text-banner-intro.color-blue {
  color: #84628d;
}
.text-banner-intro.type-b {
  color: #84628d;
}*/
.text-banner-intro.type-b .bold {
  font-weight: 600;
}