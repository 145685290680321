body.single-post #primary .promoted-event-cta {
  background-position: center center;
  background-size: cover;
  color: #FFF;
  min-height: 350px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  margin-top: 30px;

  h3 {
    color: #FFF;
    margin-bottom: 15px;
    padding-left: 0;

    @media screen and (max-width: 1023px) {
      font-size: 25px;
    }
  }

  .short-date {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
    margin-bottom: 20px;
  }

  .button {
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    font-size: 12px;
    letter-spacing: 0.04;
    max-width: 240px;
    padding: 8px;
  }

  .header_review_tag {
    margin-top: 10px;
    font-size: 25px;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 20px;
  }

  .header_review_text {
    font-size: 16px;
    line-height: 21px;
  }

  .inner-container {
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    width: 100%;
    background-image: linear-gradient(180deg,rgba(0,0,0,0.00) 0, rgba(0,0,0,.56),rgba(0,0,0,.86));

    // Disable the flexbox on mobile
    @media screen and (max-width: 1023px) {
      display: block;
    }

    .left {
      display: flex;
      flex-direction: column;
      flex: 1.5;
      align-self: flex-end;  
      padding: 15px;
    }

    .right {
      flex: 1;
      align-self: flex-end;
      padding: 15px;
    }
  }

  

  
}