
.events_teacher,
.events_teacher a {
  /* font-family: Gibson;  */
  margin-bottom: 5px;
  color: #777;
  font-size: 15px;
}



.page-template-teachers .sf-field-search label {
  display: block;
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 400px;
}

.page-template-teachers .sf-field-search label:before {
  content: "\f002";
  font-family: FontAwesome;
  font-size: 20px;
  position: absolute;
  left: 15px;
  top: 14px;
  color: #aaa;
}

.page-template-teachers .sf-input-text {
  width: 100%;
  border: 1px solid #aaa;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background: none !important;
  color: #999 !important;
  display: block;
  padding-left: 50px;
}


#teachers_wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.teacher_wrap img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.teacher_wrap {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 23%;
  flex: 0 0 23%;
  margin: 1%;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border: 1px solid #84628d;
}

@media screen and (max-width: 992px) {

  .teacher_wrap {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 48%;
    flex: 0 0 48%;
    margin: 10px 1%;
  }

}


@media screen and (max-width: 480px) {

  .teacher_wrap {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    margin: 10px 0;
  }

}


// Description

// Remove bottom spacing on image
.teacher_wrap.item-grid p {
  margin-bottom: 0px;
}

.teacher_wrap .details-box {
  margin-bottom: 15px;
  line-height: 1.3;
  flex-grow: 1;
}

.under_img_teacher_inner {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 20px;

  .button-default {
    
  }
}

.teacher_wrap .details-box * {
  font-weight: 300 !important;
  font-size: 16px !important;
  font-style: normal !important;
}

.teacher_wrap .details-box li {
  list-style: none !important;
}

.teacher_wrap .title-box {
  margin-bottom: 15px;
  margin-top: 0;
  color: #84628d;
  line-height: 1;
  font-size: 30px;
}


// Fix the search box
.searchandfilter ul {
  margin-left: 0;
}

/* Fix the issue with the teachers/staff listing when the image is too small */
.teacher_wrap.item-grid p {
	width: 100%;
}
