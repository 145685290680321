/*************************************************************
                        CUSTOM CODE
      Astra Child theme for Art Of Living Retreat Center 
--------------------------------------------------------------
NOTES FOR DEVELOPERS:
- Use tabs of two spaces for best reading experience
--------------------------------------------------------------
  INDEX
  1.    General Styles
  1.1   Retreat Type
  1.1.1 Expanded retreat types
  1.5   Submit "Go" Button
  2.    Menu Search Bar
  3.    Calendar Button variants (2019)
--------------------------------------------------------------
  CODE STARTS HERE
--------------------------------------------------------------
  1.    General Styles	
-------------------------------------------------------------*/
.events-search-bar._visible, .events-search-bar.ever_visible {
  display: block;
}

.events-search-bar.ever_visible{
  font-weight: 400;
  position: relative;
  top: auto;
}

.events-search-bar.ever_visible ._date-fancy-field ._label{
  line-height: 1;
}
.events-search-bar.ever_visible ._date-fancy-field ._text{
  line-height: 26px;
}
.events-search-bar ._date-fancy-field {
  position: relative;
  //vertical-align: middle;
  display: inline-block;
  margin: 12px 0;
  width: 100px;
  cursor: pointer !important;
}
.events-search-bar ._date-fancy-field:hover {
  background: rgba(255, 255, 255, 0.5);
}
.events-search-bar ._date-fancy-field ._label {
  color: $brand-primary;
  text-transform: uppercase;
  font-size: 10px;
  cursor: pointer !important;
}
.events-search-bar ._date-fancy-field ._text {
  color: $brand-primary;
  /* font-family: Capita; */ /* Removed font */
  font-size: 18px;
  line-height: 18px;
  cursor: pointer !important;
}
.events-search-bar ._date-fancy-field input {
  opacity: 0;
  border: 0;
  height: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -100;
}
.events-search-bar ._date-fancy-field:after {
  content: "\f107";
  display: block;
  width: 18px;
  height: 8px;
  line-height: 3px;
  font-size: 22px;
  position: absolute;
  left: calc(50% - 10px);
  bottom: -16px;
  cursor: pointer !important;
  font-family: 'FontAwesome';
  color: $brand-primary;
}
.events-search-bar.ever_visible img{
    border: none;
    padding: 0;
    background: transparent;
    opacity: 1;
}

.events-search-bar.ever_visible ._programs-modal ul {
  padding: 10px 0 0 0;
}

.events-search-bar.ever_visible ._programs-modal ul li ._cell{
  line-height: 1;
  padding: 10px 15px;
  display: inline-block;
  width: 100%;

  div {
    display: flex;
    flex-direction: row;
  }

  span.square {
    width: 16px;
    min-width: 16px;
    height: 16px;
    border: 1px solid #241A12;
    border-radius: 2px;
    position: relative;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  img {
    width:16px;
    height: 16px;
    margin-left: 5px;
    fill: $brand-primary;
    color: $brand-primary;
  }

}

.events-search-bar.ever_visible ._programs-modal ul li ._wishes{
    display:block;
    padding: 0 15px 15px 15px;
    text-align: center;
    line-height: 1;

    span{
      display:inline-block;
      padding:0;
      background-color:#ffffff;
      margin:auto;
      position:relative;
      font-size:.5rem;
      font-style:italic;
      text-transform: uppercase;
      color:#9f9f9f;
      font-weight:700;
    }
    span:before,
    span:after {
      content: "";
      position: absolute;
      height: 1px;
      border-top: 1px solid #676767;
      top: 3px;
      width: 42px;
    }
    span:before {
      right: 100%;
      margin-right: 15px;
    }
    span:after {
      left: 100%;
      margin-left: 15px;
    }
}

.events-search-bar.ever_visible ._date-fancy-field{
    width: 80px;
}

.header-events-search-bar .events-search-bar._visible{
  animation: headerSlideDown .5s ease-in-out;
}

.site-simple-header.fixed-header + .header-events-search-bar .events-search-bar._visible{
  top:70px;
  z-index: 100;
  animation: headerSlideDown .5s ease-in-out;
}
.admin-bar .site-simple-header.fixed-header + .header-events-search-bar .events-search-bar._visible{
  top:102px;
  z-index: 100;
  animation: headerSlideDown .5s ease-in-out;
}


/*------------------------------------------------------------
  1.1    Retreat Type
-------------------------------------------------------------*/

.events-search-bar {
  padding-left: 4px;
  padding-right: 4px;

  text-align: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 0;
  position: fixed;
  display: none;
  z-index: 1;
  width: 100%;
  max-width: $container-max-width;
  margin: 0 auto;

  &:before {
    border-radius: 6px 0 0 4px;
    left: 0;
  }

  &:after {
    border-radius: 0 4px 4px 0;
    right: 0;
    top: 0;
  }


  &.ever_visible {
    z-index: 2; // To prevent it from going under other elements

    ._programs-fancy-field,
    ._text-field-container {
      
      text-align: left;

      label {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 13px;
        letter-spacing: .72px;
        color: #4D4D4D;
      }
    }
  
    ._text-field{
      width: 100%
    }

  }

  ._programs-field {
    display: none;
  }

  ._programs-fancy-field {
    //vertical-align: middle;
    display: inline-block;
    padding: 0px 5px;
    margin: 0 0 0 25px;
    position: relative;
    cursor: pointer !important;
    font-size: 16px;

    ._label {
      display: inline-block;
      vertical-align: middle;
      color: #d09e50;
      text-transform: uppercase;
      width: 100px;
      text-align: left;
      cursor: pointer !important;
      font-size: 14px;
    }

    ._text {
      display: inline-block;
      vertical-align: middle;
      background: #FBFBFB;
      padding: 13px 34px 14px 14px;
      position: relative;
      //width: 300px;
      text-align: left;
      line-height: 23px;
      overflow: hidden;
      cursor: pointer !important;
      color: #A8A29F;
      font-size: 16px;
      border: 1px solid #E2E2E2;
      border-radius: 4px;
      font-weight: 500;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      // don’t forget that .block-with-text can’t be an inline element
    }

    ._text:after {
      right: 20px;
      top: calc(50% - 2px);
      content: "";
      display: block;  
      position: absolute;
      cursor: pointer !important;
      width: 0; 
      height: 0; 
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 6px solid $brand-primary;
    }

  } // Closes ._programs-fancy-field

  /*------------------------------------------------------------
  1.1.1 Expanded retreat types
  -------------------------------------------------------------*/
  ._programs-modal {
    box-shadow: 0 35px 35px 0 rgba(0,0,0,0.15);
    background: #FBFBFB;
    display: none;
    position: absolute;
    top: 100%;
    z-index: 1000000;

    ul {
      background: #FBFBFB;
      list-style: none;
      padding: 0;
     
      li {

         // Style the checkboxes
        ._table._selected {
          //color: #fff;
          //background: #93ccdc;
        
          span.square{
            background-color: lighten($brand-primary,10);
            border-color: $brand-primary;
          }
        }

        ._cell {
          display: table-cell;
          vertical-align: middle;
          padding: 5px 0;
          cursor: pointer !important;
          font-size: 13px;
          font-weight: bold;

          div {
            cursor: pointer !important;
          }

        }

      }

    }

    &._visible {
      display: block;
      border-left: 1px solid #E2E2E2;
      border-bottom: 1px solid #E2E2E2;
      border-right: 1px solid #E2E2E2;
      margin-top: -4px;
    }
  }

  ._header {
    display: none;

    ul li {
      padding: 5px;

      ._table {
        display: table;
        width: 100%;
        cursor: pointer !important;
      }
    }

  }

}

// End of Retreat type

.events-search-bar ._programs-modal ._buttons {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  padding: 15px 0 5px 0;
  color: 000;
}
.events-search-bar ._programs-modal ._buttons a {
  color: #000;
}
.events-search-bar ._programs-modal ._buttons a:first-child {
  padding: 0 8px 0 0;
  margin: 0 5px 0 8px;
  border-right: 1px solid rgba(255, 255, 255, 0.5);
}
.events-search-bar ._text-field-container {
  display: inline-block;
  position: relative;
  margin-left: 20px;
    margin-right: 10px;
}
@media (max-width: 767px) {
  .events-search-bar ._text-field-container,  .events-search-bar.ever_visible ._text-field-container{
    width: 100%;
      margin-left: 0;
  }
}

// Search text Field
.events-search-bar ._text-field {
  color: #a8a29f;
  //vertical-align: top;
  position: relative;
  display: inline-block;
  background: #fbfbfb;
  height: 52px;
  font-size: 16px;
  //width: 300px;
  font-weight: 500;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  position: relative;

  &:focus {
    border: 1px solid #e2e2e2;
  }
}


/*------------------------------------------------------------
  1.5   Submit "Go" Button
-------------------------------------------------------------*/
.events-search-bar ._go {
  //vical-align: middle;
  display: inline-block;
  background: $brand-primary;
  cursor: pointer !important;
  height: 52px;
  line-height: 52px;
  padding: 0;
  width: 65px;
  font-size: 14px;
  border: 1px solid transparent;
  text-transform: uppercase;
  border-radius: 0;
  letter-spacing: 2.29px;
  font-weight: bold;
  border-radius: 4px;
}
.events-search-bar ._go:hover {
  background: $brand-primary;
}
.events-search-bar .overlay {
  display: none;
}

.ui-datepicker {
  background: white;
  border: 10px solid $brand-primary;
    position: absolute;
    display: none;
  border-top: none;
  z-index: 100000 !important;
  /* font-family: Gibson */ /* Removed font */;
  border-radius: 0 !important;
}
.ui-datepicker .ui-icon {
  display: none;
}
.ui-datepicker .ui-state-default,
.ui-datepicker .ui-widget-content .ui-state-default,
.ui-datepicker .ui-widget-header .ui-state-default {
  background: none;
  border: 0;
  font-weight: 600 !important;
}
.ui-datepicker .ui-widget-header {
  border: 0;
  border-radius: 0;
}
.ui-datepicker .ui-datepicker-header {
  background: $brand-primary;
}
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev, .ui-datepicker .ui-datepicker-header .ui-datepicker-next, .ui-datepicker .ui-datepicker-header .ui-datepicker-title {
  color: white;
}
.ui-datepicker .ui-datepicker-header .ui-datepicker-title {
  padding: 20px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
}
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev {
  padding: 20px 20px 20px 0;
  float: left;
  background: url("../images/calendar-arrow-left.png");
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: 100%;
  font-size: 0;
}
.ui-datepicker .ui-datepicker-header .ui-datepicker-next {
  padding: 20px 0 20px 20px;
  float: right;
  background: url("../images/calendar-arrow-right.png");
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: 100%;
  font-size: 0;
}
.ui-datepicker .ui-datepicker-calendar {
  /*margin: 10px;*/
  background: white;
}
.ui-datepicker .ui-datepicker-calendar .ui-datepicker-unselectable {
  color: #d4d3d3 !important;
  font-weight: 600 !important;
  cursor: auto !important;
}
.ui-datepicker .ui-datepicker-calendar thead tr th,
.ui-datepicker .ui-datepicker-calendar tbody tr td {
  width: 32px;
  height: 32px;
  font-size: 12px;
  text-align: center;
}
.ui-datepicker .ui-datepicker-calendar thead tr th a,
.ui-datepicker .ui-datepicker-calendar tbody tr td a {
  border-radius: 50%;
  padding: 3px 2px;
  min-width: 28px;
  display: inline-block;
}
.ui-datepicker .ui-datepicker-calendar thead tr th {
  color: #d09e50;
}
.ui-datepicker .ui-datepicker-calendar tbody tr td a {
  color: $brand-primary;
  font-weight: 600;
  
}
.ui-datepicker .ui-datepicker-calendar tbody tr td.ui-datepicker-other-month a {
  color: #d4d3d3;
}
.ui-datepicker .ui-datepicker-calendar td {
  cursor: pointer !important;
}
.ui-datepicker .ui-datepicker-calendar td.ui-datepicker-current-day a {
  color: #fff;
  background: $brand-primary;
}
.ui-datepicker .ui-datepicker-calendar td:hover a {
  background: $brand-primary;
}
@media (max-width: 767px) {
  .ui-datepicker {
    top: 180px !important;
    left: 10px !important;
    width: calc(100% - 20px) !important;
  }
  .ui-datepicker .ui-datepicker-calendar {
    width: calc(100% - 20px);
  }
}


@media screen and (min-width: 641px) {
  #menu-main-menu .js-submenu + .box-sub-menu {
    display: none !important;
  }
}


/*------------------------------------------------------------
  2.    Desktop grid
-------------------------------------------------------------*/
@media screen and (max-width: 899px) {

  .events-search-bar {
    label,
    br {
      display: none;
    }

    form {
      
      padding-bottom: 18px;

      // Goals, Type and Keyword
      & > div.goal-selector,
      & > div.type-selector {
        ._programs-modal._visible {
          width: calc(100% - 10px);
        }
      }

      & > div.goal-selector,
      & > div.type-selector,
      & > div.keyword {
        //border: 1px solid red;
        width: 100%;
        display: inline-block;
        margin: 0;
        padding: 10px 5px;
      }

      // Z-index to prevent clipping on mobile

      & > .goal-selector ._programs-modal {
        z-index: 1000003;
      }

      & > .type-selector ._programs-modal {
        z-index: 1000002;
      }

      // Month
      & > div.month-selector {
        width: 100%;
        margin-left: 0;
        padding: 10px 5px;
      }

      & > .month-selector ._programs-modal {
        z-index: 1000001;
      }

      // Keyword
      & > div.keyword {
        position: relative;
        bottom: -2px;
      }

      // Go button
      & > div.submit-wrapper {
        //width: 9px;
        display: inline-block;
        padding: 10px 5px 0 5px;
        position: relative;
        bottom: -2px;
        width: 100%;

        button {
          width: 100%;
        }
      }

      ._programs-modal._visible {
        margin-top: -16px;
        width: calc(100% - 10px);
      }

      ._text-field {
        padding-top: 13px;
      }

      ._text-field::placeholder {
        line-height: 25px;
      }

    }
  }

}

@media screen and (min-width: 900px) {

  .events-search-bar {

    // Add rounder borders
    &:before,
    &:after {
      content: "";
      width: 4px;
      height: 100%;
      display: block;
      background-color: #FFFFFF;
      position: absolute;
    }

    form {
      background: #FFF;
      padding-top: 9px;
      padding-bottom: 18px;

      // Goals, Type and Keyword
      & > div.goal-selector,
      & > div.type-selector {
        ._programs-modal._visible {
          width: calc(100% - 20px);
        }
      }

      & > div.goal-selector,
      & > div.type-selector,
      & > div.keyword {
        //border: 1px solid red;
        width: 25%;
        display: inline-block;
        margin: 0;
        padding: 0 10px;
      }

      // Month
      & > div.month-selector {
        width: calc(24% - 75px);
        margin-left: 0;

        ._programs-modal._visible {
          width: calc(100% - 10px);
        }
      }

      // Keyword
      & > div.keyword {
        position: relative;
        bottom: -2px;
      }

      // Go button
      & > div.submit-wrapper {
        //width: 9px;
        display: inline-block;
        padding: 0 10px 0 5px;
        position: relative;
        bottom: -2px;
      }

      ._text-field::placeholder {
        line-height: 25px;
      }
    }
  }

}

//Disable Retreat Goal and Type for AB test // Nov 2019
.page-id-43445 .events-search-bar.ever_visible ._programs-fancy-field.goal-selector, .page-id-43445 .events-search-bar.ever_visible ._programs-fancy-field.type-selector{
  display:none !important;
}

@media screen and (min-width: 900px){
.page-id-43445 .events-search-bar{
    max-width:960px;
  }
.page-id-43445 .events-search-bar form.text > div.keyword{
    width:38%;
  }
.page-id-43445 .events-search-bar form.text>div.calendar-wrapper {
      width: 24%;
  }
.page-id-43445 .events-search-bar form>div.month-selector {
    width: calc(34% - 75px);
    margin-left: 0;
  }
}

/*------------------------------------------------------------
  3.    Calendar Button variants (2019)
-------------------------------------------------------------*/
.events-search-bar {

  // Text Variant
  form.text {

    // Calendar button
    & > div.calendar-wrapper {
      display: inline-block;
      padding: 10px 5px 0 5px;
      position: relative;  
      height: 52px;
      line-height: 52px;
      /*padding: 0;*/
      border: 1px solid transparent;

      .button-default {
        background-color: $brand-primary;
        border-radius: 4px;
        color: #fff;
        letter-spacing: 2.29px;
        font-weight: 700;
        font-size: 14px;
        line-height: 52px;
        height: 52px;
        text-transform: uppercase;
        position: relative;
        bottom: -1px;
        width: 100%;
      }
      
    }
  

    @media screen and (max-width: 899px) {
      // Go button
      & > div.submit-wrapper {
        width: 50%;
        padding-left: 5px;
        padding-right: 5px;

        button {
          width: 100%;
        }
      }

      // Calendar button
      & > div.calendar-wrapper {
        width: 50%;
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    @media screen and (min-width: 900px) {
      & > div.goal-selector,
      & > div.type-selector,
      & > div.keyword {
        width: 21%;
        display: inline-block;
        margin: 0;
        padding: 0 10px;
      }

      // Calendar button
      & > div.calendar-wrapper {
        width: 11%;
      }
    }
    
  }

  // Icon Variant
  form.icon {

    // Calendar button
    & > div.calendar-wrapper {
      display: inline-block;
      padding: 10px 5px 0 5px;
      position: relative;  
      height: 52px;
      line-height: 52px;
      padding: 0;
      border: 1px solid transparent;

      .button-default {
        background-color: $brand-primary;
        border-radius: 4px;
        font-size: 14px;
        line-height: 52px;
        height: 52px;
        text-transform: uppercase;
        position: relative;
        bottom: -1px;
        width: 100%;

        svg {
          position: relative;
          top: 6px;
        }
      }
      
    }
  

    @media screen and (max-width: 899px) {
      // Go button
      & > div.submit-wrapper {
        width: 50%;
        padding-left: 5px;
        padding-right: 5px;

        button {
          width: 100%;
        }
      }

      // Calendar button
      & > div.calendar-wrapper {
        width: 50%;
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    @media screen and (min-width: 900px) {
      & > div.goal-selector,
      & > div.type-selector,
      & > div.keyword {
        width: 22.5%;
        display: inline-block;
        margin: 0;
        padding: 0 10px;
      }

      // Calendar button
      & > div.calendar-wrapper {
        width: 6%;
        padding-right: 5px;
      }
    }
    
  }

}