input[type="submit"] {
  width: auto;
  margin-bottom: 20px;
  display: block;
}


.gform_body ul {
  margin-left: 0;
}

// Group enquiries forms
// First form
.hbspt-form {
  margin-top:30px;

  .form-columns-0, .form-columns-1, .form-columns-2 {
    margin-left: auto;
    margin-right: auto;
  }

  form{
      fieldset, &.hs-form{
          .hs-form-field{
            margin-bottom: 18px;

              ul{
                margin:0!important;
                  &.hs-error-msgs{
                    margin-bottom: -18px!important;
                  }

                li{
                  list-style:none;
                }
              }
          }
          label{
              font-family: $font-primary !important;
              font-size: 16px !important;
              font-weight: 400 !important;

              span{
                color:#4d4d4d !important;
                text-transform: uppercase;
                font-weight: 700 !important;
                font-size: 13px !important;
                letter-spacing: .72px;
              }
              span.hs-form-required{
                color:#ff0000 !important;
                font-size: 16px!important;
                padding-left: 3px;
              }
          }
          ul.no-list.hs-error-msgs.inputs-list{
              padding-left:0;
              
              li{
                  label{
                      margin-top: 5px;
                      color: rgba(255, 0, 0, 0.7);
                      font-style: italic;
                  }
              }
          }
          legend{
              font-family: $font-primary !important;
              font-size: 16px !important;
              font-weight: 400 !important;
              padding: 2px 0 5px 0;
          }
          .input {
              box-sizing: content-box;

            input.hs-input, textarea.hs-input{
              font-family: $font-primary !important;
              color: #a8a29f !important;
              background: #fbfbfb !important;
              min-height: 26px !important;
              padding:0.75em !important;
              font-size: 16px !important;
              font-weight: 500;
              border: 1px solid #e2e2e2 !important;
              border-radius: 4px !important;
              width:100%;
          }
          textarea.hs-input{
              width:100% !important;
              height:125px;
          }

        }
          
      }

      .actions{
          display:block;
          padding:17px 0 18px 0px;
          margin-top: 10px;
          margin-bottom: 18px;

          input.hs-button.primary.large{
              font-family: $font-primary !important;
              background: #84628d !important;
              cursor: pointer!important;
              display: block;
              margin-left: auto;
              margin-right: auto;
              width: auto;
              height: 52px;
              line-height: 52px;
              padding: 0 20px;
              font-size: 16px !important;
              border: 1px solid transparent !important;
              text-transform: uppercase;
              border-radius: 0;
              letter-spacing: 2.29px;
              font-weight: 500;
              border-radius: 4px;
              box-shadow: 0 0 0!important;
              text-shadow: 0 0 0 !important;

              :hover:not(.inactive), :focus:not(.inactive), .hovered:not(.inactive){
                  box-shadow: 0 0 0 !important;
              }
          }
      }
  }
}

//Popup form
.pk-phone-popup form.hs-form {
  margin-top:30px;
  padding: 0 30px;

  .form-columns-0, .form-columns-1, .form-columns-2 {
    margin-left: auto;
    margin-right: auto;
  }

  fieldset{
      .hs-richtext{
        p{
          font-size: 16px;
          margin-bottom: 15px;
        }
      }
      .hs-form-field{
        margin-bottom: 18px;

          ul{
            margin:0!important;
              &.hs-error-msgs{
                margin-bottom: -18px!important;
              }

            li{
              list-style:none;
            }
          }
      }
      label{
          font-family: $font-primary !important;
          font-size: 16px !important;
          font-weight: 400 !important;

          span{
            color:#4d4d4d !important;
            text-transform: uppercase;
            font-weight: 700 !important;
            font-size: 13px !important;
            letter-spacing: .72px;
          }
          span.hs-form-required{
            color:#ff0000 !important;
            font-size: 16px!important;
            padding-left: 3px;
          }
      }
      ul.no-list.hs-error-msgs.inputs-list{
          padding-left:0;
          
          li{
              label{
                  margin-top: 5px;
                  color: rgba(255, 0, 0, 0.7);
                  font-style: italic;
              }
          }
      }
      legend{
          font-family: $font-primary !important;
          font-size: 16px !important;
          font-weight: 400 !important;
          padding: 2px 0 5px 0;
      }
      .input {
          box-sizing: content-box;

        input.hs-input, textarea.hs-input{
          font-family: $font-primary !important;
          color: #a8a29f !important;
          background: #fbfbfb !important;
          min-height: 26px !important;
          padding:0.75em !important;
          font-size: 16px !important;
          font-weight: 500;
          border: 1px solid #e2e2e2 !important;
          border-radius: 4px !important;
          width:100%;
      }
      textarea.hs-input{
          width:100% !important;
          height:125px;
      }

    }
      
  }

  .actions{
      display:block;
      padding:17px 0 18px 0px;
      margin-top: -15px;
      margin-bottom: 0;

      input.hs-button.primary.large{
          font-family: $font-primary !important;
          background: #84628d !important;
          cursor: pointer!important;
          display: block;
          margin-left: auto;
          margin-right: auto;
          width: auto;
          height: 52px;
          line-height: 52px;
          padding: 0 20px;
          font-size: 16px !important;
          border: 1px solid transparent !important;
          text-transform: uppercase;
          border-radius: 0;
          letter-spacing: 2.29px;
          font-weight: 500;
          border-radius: 4px;
          box-shadow: 0 0 0!important;
          text-shadow: 0 0 0 !important;

          :hover:not(.inactive), :focus:not(.inactive), .hovered:not(.inactive){
              box-shadow: 0 0 0 !important;
          }
      }
  }
  
}


// Second form
.form-group-inquiries {
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;

  .box-left,
  .box-right {
    width: 100%;
  }

  input[type="submit"] {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

// Donation Form - Give Lively
.give-lively{
    max-width: 500px;
    padding: 30px;
    background: #fff;
    border-radius: 20px;
    box-shadow: 4px 7px 38px 7px rgba(132,98,140,.1);
    font-family: $font-primary !important;
    font-weight: 400;

    button.gl-widget__btn--primary{
      background: $brand-primary !important;
      color:#ffffff !important;

      &:hover{
        /* background: #3c3247 !important; */
        color:#ffffff !important;
      }
    }

    .gl-widget__frequency-selection-container{

      button.gl-widget__frequency-buttons{        
        max-width: 200px;
        margin-bottom: 15px !important;
        
        &.gl-widget--selected-btn.gl-widget__btn--outline-primary{
          border-color: $brand-primary !important;
          background: rgba(132, 98, 141, 0.1) !important;
          color: $brand-primary !important;

          &:hover{           
            color: $brand-primary !important;            
          }

        }

        &.gl-widget__btn--outline-primary{
          border: 1px solid #ccc !important;
          color: #7f7f7f !important;

          &:hover{
            background: #ceb5d4 !important;
            border-color: $brand-primary !important;
            background: linear-gradient(rgba(206, 181, 212, 0.1), rgba(206, 181, 212, 0.1)) !important;
            color: $brand-primary !important;            
          }

        }

      }
    }

    .gl-widget__suggested-amounts-container{
      button.gl-widget__suggested-amount-button{
        max-width: 100px;
        background: #ededed !important;
        border: 1px solid #ccc !important;
        color: #333 !important;
        padding: 8px !important;
        cursor: pointer !important;
        line-height: 1.2em !important;
        font-size: 0.9em !important;
        margin-bottom: 15px !important;

        &.gl-widget--selected-suggested-amount{
          border-color: #84628d!important;
          background: rgba(132, 98, 141, 0.1) !important;
          color: #84628d!important;
        }
      }
    }

    .gl-widget__input-group{
        margin-bottom: 15px !important;
    }

    .gl-widget__input-group{
      max-width: 410px !important;
      justify-content: center !important;
      margin: auto !important;
    }

    .gl-widget--row, .gl-widget__frequency-selection-container{
      justify-content: center !important;
    }

    .gl-widget__payment{
      margin-top: 15px !important;
      text-align: center;

      button.gl-widget__btn--primary{
        max-width: 408px;
      }
    }

    .gl-widget__frequency-selection-container{
      button{
        margin-right: 12px !important;
      }
      button + button{
        margin-right:0 !important;
      }
    }

    .gl-widget__input-group{
      input{
        /* background: linear-gradient(rgba(206,181,212,.1),rgba(206,181,212,.1))!important; */
        border-color: #ccc !important;
        outline: 0;

        &.gl-widget__donation-input-field{
          padding-left: 65px !important;
        }
      }
      .gl-widget__currency {
        /*background-color: #ededed !important;
        color: #3b3b3b !important;
        font-weight: normal !important;
        height: 50px !important;
        line-height: 49px !important;
        margin: 0 !important;
        position: absolute !important;
        top: 0 !important;
        left: 0 !important;        
        padding: 0 20px;
        border-radius: 3px 0 0 3px !important;
        border-right: 1px solid #ccc;*/
        z-index: 1 !important;
      }
    }

    .gl-widget #give-in-honor-container{
      max-width: 408px;
      margin:auto !important;
      button.popup-card__link{
        color: #84628d!important;
        width:auto !important;
        border-radius: 0 !important;
        border:0 !important;
        padding:0 !important;

        svg{
          margin-right:5px !important;
        }

        &:hover{
          background-color: transparent !important;
          color: #84628d!important;
        }

      }
    }

}


// Donation Form - Give Lively Branded

.give-lively-branded{
  max-width: 100%;
  padding: 50px 30px 50px 30px;
  background: #fff;
  border-radius: 20px;
  box-shadow: 4px 7px 38px 7px rgba(132,98,140,.1);
  font-family: $font-primary !important;
  font-weight: 400;
}


@media (max-width: 767px){
  .give-lively{
    padding: 20px 15px !important;

    .gl-widget{
      min-width: 200px !important;
    }
    .gl-widget__frequency-selection-container button.gl-widget__frequency-buttons{
      max-width: 125px !important;
    }
    .gl-widget__frequency-selection-container button{
      margin-right: 6px!important;
    }
    .gl-widget__payment button.gl-widget__btn--primary{
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
  }

  .pk-phone-popup form.hs-form fieldset .hs-form-field{
    margin-bottom: 10px;
  }
  .pk-phone-popup form.hs-form fieldset .hs-richtext p{
    font-size: 15px;
    margin-bottom: 10px;
    line-height: 17px;
  }
  .pk-phone-popup form.hs-form fieldset .input input.hs-input, .pk-phone-popup form.hs-form fieldset .input textarea.hs-input{
    min-height: 24px!important;
    padding: .60em!important;
  } 
  .pk-phone-popup form.hs-form .actions input.hs-button.primary.large{
    height: 40px;
    line-height: 40px;
    font-size: 14px !important;
    margin-bottom: 5px;
  }
}