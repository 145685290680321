/*-------------------------------------
    Weekly Class Schedule Plugin 
--------------------------------------*/
body.wcs_modal--opened > :not(#wcs-vue-modal):not(.wcs-vue-modal), body.wcs_modal--opened::before, body.wcs_modal--opened::after{
    filter: none !important;
    -webkit-filter: none !important;
}

body.wcs_modal--opened > :not(#wcs-vue-modal):not(.wcs-vue-modal), body.wcs_modal--opened::before, body.wcs_modal--opened::after{
    /*filter: blur(7px) grayscale(1);
    -webkit-filter: blur(7px) grayscale(1);*/
}