/*------------------------------------------------------------
  1.1.  Astra Desktop Menu
-------------------------------------------------------------*/
@media screen and (min-width: $desktop-min) {
  /* Limit the logo width */
  .ast-logo-title-inline {
     .site-logo-img {
        max-width: 117px;
        width:100%;
     }

     .ast-site-identity{
       padding-bottom: 0;
       min-width:330px;

        .location{
          display:flex;
          margin-left:15px;
          padding-left:13px;
          border-left:1px solid rgba(187, 189, 191, 0.35);

          a{
            display:flex;
            align-items: center;

              span.map, .icon{
                margin:auto 8px auto 0;
                width:16px;
                height:20px;
                /*background:url(../../assets/images/map-ico.svg) no-repeat;*/
              }

              span.loc{
                text-transform: uppercase;
                color:#676767;
                font-size: .6rem;
                font-weight: 700;
                letter-spacing: .38px;
                font-family: $font-primary;
              }

          }
        }

     }
  }

  /* Covid 19 message */
  .header-hightlight.infobar{
    a{
      p{
        margin:0;
        padding:0;
        
        &.desktop-msg{
          display: block;
        }
        &.mobile-msg{
          display: none;
        }
      }
    }
  }


  /* Add drop shadow */
  .main-header-bar,
  .main-header-bar.ast-sticky-active {
    border-bottom-width: 0;
    box-shadow: 0 2px 35px 0 rgba(0,0,0,0.15);
    z-index: 9999 !important;
  }

  /*------------------------------------------------------------
  1.1.1	Make the desktop child menu open on click instead of
        hover
  -------------------------------------------------------------*/
  /* Add a bottom border to the active menu item */
  .ast-desktop .main-header-menu > .menu-item-has-children.active:before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: $brand-primary;
  }

  /* Remove subnav opeing on hover so we can attribute it to click */
  .ast-desktop .main-header-menu > .menu-item-has-children:not(.active) .sub-menu,
  .ast-desktop .main-header-menu > .menu-item-has-children:not(.active):hover .sub-menu {
    opacity: 0;
    visibility: hidden;
  }

  /* Make the subnav visible if the top level menu class is toggled or toggled and hover */
  .ast-desktop .main-header-menu > .menu-item-has-children.active .sub-menu,
  .ast-desktop .main-header-menu > .menu-item-has-children.active:hover .sub-menu {
    opacity: 1 !important;
    visibility: visible !important;
    left: 0;
  }

  /* Offset the mega menu a bit from right like the original menu */
  .ast-desktop .main-header-menu > .menu-item-has-children.active .sub-menu.astra-megamenu,
  .ast-desktop .main-header-menu > .menu-item-has-children.active:hover .sub-menu.astra-megamenu {
    left: calc(50% - 475px) !important;
    position: fixed;
    top: 70px; /* 100 */
    padding:0;
  }

  /* Offset top if the admin bar is showing */
  body.admin-bar.ast-desktop .main-header-menu > .menu-item-has-children.active .sub-menu.astra-megamenu,
  body.admin-bar.ast-desktop .main-header-menu > .menu-item-has-children.active:hover .sub-menu.astra-megamenu {
    top: 102px; /* 132 */
  }

  /* Replicate the default padding for the megamenu sub menu */
  .ast-desktop .main-header-menu > .menu-item-has-children .sub-menu.astra-megamenu,
  .ast-desktop .main-header-menu > .menu-item-has-children:hover .sub-menu.astra-megamenu {
    border-radius: 0 0 4px 4px;
    box-shadow: 0 35px 35px 0 rgba(0,0,0,0.15);
    padding: 0;
  }

  /* Make the sub menu list items visible when the class is toggled */
  .ast-desktop .main-header-menu > .menu-item-has-children.active:not(:hover) .sub-menu > li {
    opacity: 1 !important;
    visibility: visible !important;
    display: flex;
  }

  /* Remove the submenu template padding */
  .ast-desktop .main-header-menu > .menu-item-has-children .sub-menu .ast-mm-template-content {
    padding: 0;
   }

  .ast-desktop .main-header-menu > .menu-item-has-children .sub-menu{ // Ayurveda Wellness only
    #menu-item-57167 .ast-mm-template-content{
      .elementor-icon-list-items{
        margin-bottom: 40px;
      }
    }
  }

  /* Add the right border to the columns on the submenu */
  .ast-desktop .main-header-menu > .menu-item-has-children .sub-menu  .elementor-row>.elementor-column>.elementor-column-wrap:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 100%;
    background: #EBE7E2;
  }

  .ast-desktop .main-header-menu > .menu-item-has-children .sub-menu  .elementor-row>.elementor-column:last-child>.elementor-column-wrap:after {
    opacity: 0;
  }

  /* Make the mega menu button hover color white */
  .ast-desktop .main-header-menu .astra-megamenu-li .sub-menu .elementor-button-wrapper a:hover {
    color: #FFFFFF;
  }

  /* Make the join a retreat link always white */
  .ast-desktop .main-header-menu .join-a-retreat > a {
    color: #FFFFFF !important;

    &.header-search-icon{
      max-width:28px;
      max-height:29px;
      width:100%;

      .icon{
        width: 28px;
        height: 29px;
        position: relative;
        top: 0;
      }

      img{
        max-width: 100%;
        max-height:100%;
        height:auto;
      }

    }

  }

  /* MISC from customizer */
  /* INDEX
  * 1 - Desktop Navigation Styling
  */

  /* CSS CHANGES
  * 1 - Desktop Navigation Styling */


  /* Remove the header submenu arrows */
  .site-header .sub-arrow { display: none; }

  /* Add side border on the desktop menu items */
  .main-header-menu > .menu-item:not(:first-child):after {
    display: block;
    width: 1px;
    height: 20px;
    background-color: #BBBDBF;
    content: "";
    position: absolute;
    opacity: .35;
  }

  /* Fix the issue with the header nav */
  .header-main-layout-1 .main-navigation {
    padding-left: 0;
  }
  .main-navigation > ul {
    height: 70px;
  }

  /* Increase the horizontal padding between the menu items */
  .main-header-menu a {
    padding: 0 21px;
  }

  /* Style the desktop header search section */
  .main-header-bar .ast-masthead-custom-menu-items {
    background-color: #84628D;
    display: block;
    margin: 0;
    padding-left: 28px;
    padding-right: 29px;
  }

  /* Style the right desktop nav join a retreat */
  .main-header-menu .menu-item.join-a-retreat {
    background-color: #84628D;
  }

  .main-header-menu .menu-item.join-a-retreat .menu-link {
    padding-left: 18px;
    padding-right: 15px;
  }

  .main-header-menu .menu-item.join-a-retreat a {
    color: #FFFFFF;
  }

  .main-header-menu .menu-item.join-a-retreat:after {
    display: none;
  }

  /* Style the phone icon on the desktop menu */
  .main-header-menu .menu-item.menu-phone .menu-link {
    padding-right: 10px; /* 35px */
  }

  .ast-desktop .main-header-menu.submenu-with-border .sub-menu a{
    padding:0;
  }


  // Make the phone menu item have a custom style on hover
  .main-header-menu .menu-item.menu-phone.menu-item-has-children.active .sub-menu,
  .main-header-menu .menu-item.menu-phone.menu-item-has-children.active:hover .sub-menu {
    left: auto;
    width: 130px;
  }

  .main-header-menu .menu-item.menu-phone .fa-phone {
    color: #84628d;
    opacity: 1;
    margin-right: 15px;
    font-size: 16px;
  }

  /* Style the right desktop nav search icon */
  .main-header-bar .ast-masthead-custom-menu-items {
    width: 60px;
    position: relative;
  }

  .main-header-bar .ast-masthead-custom-menu-items .header-search-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 28px;
    margin: auto 14px auto 18px;
    height: 29px;
  }


  .main-header-bar .ast-masthead-custom-menu-items:before {
    display: block;
    width: 1px;
    height: 20px;
    background-color: #D8D8D8;
    content: "";
    position: absolute;
    opacity: .2;
    left: 0;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
  }
}

@media screen and (min-width: 1921px) {
  /* Offset the mega menu a bit from right like the original menu */
  .ast-desktop .main-header-menu > .menu-item-has-children.active .sub-menu.astra-megamenu,
  .ast-desktop .main-header-menu > .menu-item-has-children.active:hover .sub-menu.astra-megamenu {
    left: calc(60% - 475px) !important;
  }
}


@media screen and (min-width: 900px) {
  .header-events-search-bar .events-search-bar{

    .ast-container {
      position: relative;
    }

    #close_events_bar_desktop {
      display: block;
      width: 15px;
      height: 15px;
      font-size: 25px;
      line-height: 8px;
      position: absolute;
      top: 18px;
      right: 37px;
      z-index: 4;
    }
  }


}

/*------------------------------------------------------------
  1.1.2  New Mega menu style
-------------------------------------------------------------*/
@media screen and (min-width: $desktop-min){

  .main-header-menu > li > ul.sub-menu{
    .mega-submenu{
      h4{
        font-family: $font-primary-title;
        font-size: 20px;
        color: $brand-primary;
        a{
          font-size: 20px;
          color: $brand-primary;
        }
      }
      .elementor-widget-text-editor{
        .elementor-widget-container{
          margin: 0;
          padding: 0;
          p{
            margin:0 0 35px 0;
            font-size: 17px;
            font-weight: 400;
            font-family: $font-primary;
            line-height: 1.35;
          }
          a{
            display: inline-block;
            padding-right: 0 !important;
          }
        }
      }
    }
    .elementor-widget-text-editor + .elementor-widget-image{
      margin-bottom: 45px;
      margin-top:-35px;
    }
    .elementor-widget-icon-list{
      .elementor-icon-list-item{
        a{
          &:hover .elementor-icon-list-text{
            color:$brand-primary;
          }
          .elementor-icon-list-icon i {
              color:$brand-primary;
              font-size: 5px;
          }
          .elementor-icon-list-text {
            color: $primary-color;
            padding-left: 10px;
            font-size: 17px;
            margin-bottom: 3px;
          }
        }
      }
    }
    .elementor-widget-button{
      position: absolute;
      z-index:1;
      text-align: center;
      width:100%;
      bottom:0;

      a.elementor-button{
        font-family: $font-primary;
        font-size: 10px;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 1.3em;
        letter-spacing: 1.1px;
        color: #fff;
        background-color: #3c3247;
        padding: 11px;
        width:100%;
          .elementor-align-icon-right{
            margin-left: 10px;
          }
      }
    }
  }

}

/*------------------------------------------------------------
  1.2.  Astra Tablet Menu
-------------------------------------------------------------*/
@media screen and (min-width: $desktop-min) and (max-width: $desktop-small-max) {

  // Menu wrapper
  #masthead .ast-container {
    padding-left: 10px !important; // Fix to prevent the cache from removing this override
  }

  // Regular menu items

    .main-header-menu a {
      padding: 0 10px;
    }


  // Phone link
  .main-header-menu .menu-item.menu-phone .menu-link {
    padding-right: 16px;
  }

  .main-header-menu .menu-item.menu-phone .fa-phone {
    margin-right: 5px;
  }

  // Join a retreat link
  .main-header-menu .menu-item.join-a-retreat .menu-link {
    padding-left: 16px;
    padding-right: 10px;
  }

  // Calendar icon button
  .main-header-bar .ast-masthead-custom-menu-items  {
    padding-left: 5px;
    padding-right: 5px;
    width: 41px;
  }

  // Icon of the calendar
  .main-header-bar .ast-masthead-custom-menu-items .header-search-icon {
    margin-left: 9px;
  }

  // Mega Menu
  /* Offset the mega menu a bit from right like the original menu */
  .ast-desktop .main-header-menu > .menu-item-has-children.active .sub-menu.astra-megamenu,
  .ast-desktop .main-header-menu > .menu-item-has-children.active:hover .sub-menu.astra-megamenu {
    left: calc(50% - 425px) !important;
    width: 850px !important;
  }

  .breadcrumb{
    top:57px;
  }
  .admin-bar .breadcrumb{
    top:25px;
  }
}

@media screen and (min-width: 1280px) and (max-width:1320px){
  // Logo with location
  .ast-logo-title-inline .ast-site-identity{

    .location{
      margin-left:12px;
      padding-left:12px;

      a{
          span.map, .icon{
            width:12px;
            height:16px;
            margin:auto 5px auto 0;
          }

          /*span.loc{
            font-size: .48rem;
          }*/

        }
      }
  }
}

@media screen and (min-width: $desktop-min) and (max-width:1110px){
    // Logo with location
    .ast-logo-title-inline .ast-site-identity{

    .location{
       margin-left:12px;
       padding-left:10px;

       a{
           span.map, .icon{
             width:12px;
             height:16px;
             margin:auto 5px auto 0;
           }

           span.loc{
             font-size: .48rem;
             width:70px;
             line-height: 1.35;
           }

        }
      }
    }
}

@media (max-width: 1535px) and (min-width: $desktop-min){
  #masthead .ast-container{
      padding-left: 10px;
  }
  .main-header-menu > a {
      padding: 0 10px !important;
  }
}

@media (min-width: $desktop-min) and (max-width: 1399px){
  .ast-logo-title-inline .ast-site-identity{
    min-width: 250px;

    .location a span.loc:after{
      content:", NC";
    }

  }
}

@media (max-width: $desktop-min){
  .ast-logo-title-inline .ast-site-identity .location a span.loc:after{
    content:", North Carolina";
  }
  .breadcrumb{
    top:57px;
  }
  .admin-bar .breadcrumb{
    top:25px;
  }
}

@media (min-width: 1400px){
  .ast-logo-title-inline .ast-site-identity .location a span.loc:after{
    content:", North Carolina";
  }
}

@media (max-width: 1135px) and (min-width: 1090px){
  .ast-logo-title-inline .ast-site-identity .location a span.loc {
      font-size: .5rem;
      width: 80px;
      line-height: 1.35;
  }
}


@media (max-width: 1040px) and (min-width: $desktop-min){
  .ast-logo-title-inline .ast-site-identity .location a span.loc {
      font-size: .48rem;
      width: 50px;
      line-height:1.35;
  }
}