.custom-post-authors-after-content {  
  padding: 40px 0 40px 0;
  max-width: 750px;
  margin:80px auto 10px auto;
  clear: both;
  display: block;
  border-top:1px solid #e7e7e7;

  @media screen and (max-width: 1023px) {
    padding: 30px 10px 10px 10px;
    margin-top: 20px;
    margin-bottom: 0;
  }

  h3 {
    text-align: center;
    padding:20px 0;
    color:$hdr-primary;
  }

  .author-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
      
    .author {
      flex: 1; // Make columns same width
      display: flex; // So that columns below use flexbox
      justify-content: flex-start;
      margin-top: 20px;
      margin-bottom: 20px;
  
      div {
        flex: 1; // Make columns same width
        line-height: 30px;
        p{
          line-height: 30px;
        }
      }

      .image-container {
        flex-grow: 1;
        padding-left: 10px;
        padding-right: 20px;
 
        .author_avatar{
          border-radius: 100%;
          border: 3px solid #3C3247;
        }
        .read-bio{
          display: inline-block;
          margin-top:10px;
          text-align: center;
          line-height: 22px;
          padding:3px 12px 1px 12px;
          font-size: 12px;
          color: $primary-color;
          border-radius: 100px;
          border:1px solid $primary-color;
          &:hover{
            border:1px solid $hover-brand-primary;
            color:$hover-brand-primary;
          }
        }
      }

      .content-container {
        flex-grow: 5;
        
        .author_name {
          display: inline-block;
          margin-bottom: 10px;          
          color: $brand-primary;
          font-size: $txt-size-xlg;
          font-weight: 700;
        }

        .read-bio{
          display: inline-block;
          margin-top:20px;
          text-align: center;
          line-height: 22px;
          padding:3px 12px 1px 12px;
          font-size: 12px;
          color: $primary-color;
          border-radius: 100px;
          border:1px solid $primary-color;
          &:hover{
            border:1px solid $hover-brand-primary;
            color:$hover-brand-primary;
          }
        }

        /*.author-bio {
          font-size: 15px;
          color: #676767;
          line-height: 20px;
          font-weight: 500;
        }*/

      }
    }

    
 
  }
  
}


@media screen and (min-width: 768px) {
  .custom-post-authors-after-content .author-list .author .content-container .read-bio{
    display: none;
  }  
}

@media screen and (max-width: 767px) {
  .custom-post-authors-after-content .author-list .author .image-container .read-bio{
    display: none;
  }
}