/*------------------------------------------------------------
  New Simple Navigation
-------------------------------------------------------------*/
#page .site-simple-header{
  display: flex;
  position: relative; /* relative */
  flex-flow: row wrap;
  width: 100%;
  z-index: 101;
  margin-top: 0;
  margin-bottom: 0; /* 25px */  
  background:#ffffff;
  box-shadow: 0 2px 35px 0 rgba(0,0,0,.15);

  &.fixed-header{
    position: fixed;
    animation: headerSlideDown 0.5s;
  }

  .branding-container{
    display: inline-flex;
    vertical-align: middle;
    align-items: center;
    min-width: 330px;

      .site-branding{
        display: block;
        width: 100%;
        
        .logo{
          max-width: 118px;
          width: 100%;
          display: inline-block;
          vertical-align: middle;
            .icon{
              max-width: 118px;
              max-height:62px; /* 55px */
              width: 100%;
            }
        }
      
        .location{
          display: inline-flex;
          margin-left: 15px;
          padding-left: 13px;
          border-left: 1px solid hsla(210,3%,74%,.35);
          vertical-align: middle;

            a{
              display: flex;
              align-items: center;

                .icon{
                  margin: auto 5px auto 0;
                  width: 16px;
                  height:20px;
                }

              span{                
                &.map {
                  margin: auto 8px auto 0;
                  width: 16px;
                  height: 20px;
                  // background: url(../../assets/images/map-ico.svg) no-repeat;
                }
                &.loc{
                  text-transform: uppercase;
                  color: #676767;
                  font-size: .5rem;
                  font-weight: 700;
                  letter-spacing: .38px;
                  font-family: $font-primary;

                    &:after {
                      content: ", North Carolina";
                    }
                }
              }

            }
        }

      }
  }

  .menu-container {
    margin: 0 0 0 auto;
    padding: 0;
    background: #ffffff;
  }

  .hc-nav{
    display:none;
  }

  .menu-mobile {
    display: none;
    padding: 20px;
    &:after {
        content: "\f394";
        font-family: "Ionicons";
        font-size: 2.5rem;
        padding: 0;
        float: right;
        position: relative;
        top: 50%;
        transform: translateY(-25%);
    }
  }

  .menu-dropdown-icon {
    &:before {
        content: "\f489";
        font-family: "Ionicons";
        display: none;
        cursor: pointer;
        float: right;
        padding: 1.5em 2em;
        background: #fff;
        color: #333;
    }
  }

  .menu {
    max-width: 100%;

    > ul {        
        margin: 0 auto;
        width: 100%;
        list-style: none;
        padding: 0;
        position: relative;
        //position: relative;
        /* IF .menu position=relative -> ul = container width, ELSE ul = 100% width */
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        &:before,
        &:after {
            content: "";
            display: table;
        }
        &:after {
            clear: both;
        }
        > li {
            float: left;
            background: #ffffff;
            padding: 0;
            margin: 0;

            &.menu-parent{                       
              > a {
                  text-decoration: none;
                  padding: 1.56em 1.4em;
                  display: block;
                  font-family: $font-primary;
                  letter-spacing: $nav-txt-space; /* .38px;*/
                  /*font-size: 14px;*/
                  font-size: 0.75rem;
                  color: #676767; /* #676767 */
                  text-transform: uppercase;
                  font-weight: 700;

                  &:hover {
                      color: $brand-primary;
                  }
              }
              
              &:hover {                 
                  background: #fafafa;
                  > a{
                    color: $brand-primary;
                  }
                  ul{
                    display: block;
                  }
              }
              
              &.telephone{
                
                .menu-link{
                  .fa-phone {
                    color: $brand-primary;
                    opacity: 1;
                    margin-right: 2px;
                    font-size: 14px;
                  }
                  .pphone-icon, .phone-icon{
                    width:14px;
                    height: 14px;
                  }
                }


              }

              &.search-cale {
                background-color: $brand-primary;
                  a.menu-link{                  
                      padding-left: 18px;
                      padding-right: 15px;
                      padding-top:1.45em;
                      padding-bottom: 1.45em;
                      color:#ffffff;
                      display: flex;
                      align-items: center;               
                  }
                  .search-cale-ico{
                    max-width: 28px;
                    max-height: 29px;
                    width: 100%;
                    margin-left: 8px;            
                  }
                  .icon{
                    width: 28px;
                    height: 29px;
                  }
              }

              &:not(:last-child):after {
                display: inline-block;
                width: 1px;
                height: 20px;
                background-color: #bbbdbf;
                content: "";
                position: absolute;
                opacity: .35;
                top: calc(50% - 10px);
              }

            }
            //

            > ul.normal-sub-nav{
              width: 300px;
            }

            > ul.small-sub-nav{
              width: 130px;
              min-width: 130px;
              padding: 10px;
            }

            > ul {
                display: none;
                width: 100%;
                min-width: 1212px;
                background: #ffffff;
                border-radius: 0 0 4px 4px;
                box-shadow: 0 35px 35px 0 rgba(0,0,0,.15);
                padding: 10px;
                position: absolute;
                z-index: 99;
                right: calc(50% - 475px);
                margin: 0;
                padding:0;
                list-style: none;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
                &:before,
                &:after {
                    content: "";
                    display: table;
                }
                &:after {
                    clear: both;
                }
                > li {
                    margin: 0;
                    padding-bottom: 0;
                    list-style: none;
                    width: 100%;
                    background: none;
                    text-align: left;

                    .elementor-widget-heading{
                      margin-bottom: 15px;
                    }

                    h6.elementor-heading-title, h5.elementor-heading-title, h4.elementor-heading-title, h3.elementor-heading-title, h2.elementor-heading-title{
                      font-family: $font-primary-title;
                      font-size: 20px;
                      font-weight: 600;
                      color: $brand-primary;
                      display: block;
                      text-align: left;
                      line-height: 28px;
                        a{
                          color: $brand-primary;
                          text-decoration: none;
                          &:hover, &:focus{
                            color: $primary-color;
                          }                        
                        }
                    }

                    p{
                      margin: 0 0 35px 0;
                      font-size: $txt-size;
                      font-weight: 400;
                      font-family: $font-primary;
                      line-height: 1.5;
                      text-align:left;
                      color: $primary-color;
                    }

                    // Image
                    .elementor-widget-image{
                      margin-bottom: 45px;
                      margin-top: -35px;
                    }

                    // Button
                    .elementor-absolute, .elementor-widget-button{
                      z-index: 1;
                      text-align: center;
                      width: 100%;
                      bottom: 0;
                      position: absolute;

                      // Btn circle right arrow
                      &.btn-circle-right-arrow{
                        a .elementor-button-text{
                          background-image:url("/wp-content/themes/astra-child/dist/images/spriteicons.svg");
                          background-repeat:no-repeat;
                          background-position: 98% 0;                       
                        }
                      }
                    }                  
                    
                    
                    // Button style
                    a.elementor-button {
                      font-family: $font-primary;
                      font-size: 10px;
                      font-weight: 600;
                      text-transform: uppercase;
                      line-height: 1.3em;
                      letter-spacing: 1.1px;
                      color: #fff;
                      fill: #fff;
                      background-color: #3c3247;
                      padding: 11px;
                      width:100%;
                    }

                  .elementor-row, .elementor-column-gap-wider{
                    .elementor-widget-text-editor + .elementor-widget-icon-list{
                      margin-top: -15px;
                    }
                    .elementor-column-wrap.elementor-element-populated, .elementor-widget-wrap.elementor-element-populated{
                      /*margin:30px 0;
                      padding:0 30px;*/
                      margin:30px 25px;
                      padding:0;
                    }
                    .elementor-column{

                      .elementor-column-wrap, .elementor-widget-wrap{
                        &:after {
                          content: "";
                          position: absolute;
                          top: 0;
                          right: -25px; /* 0 */
                          width: 1px;
                          height: 100%;
                          background: #ebe7e2;
                        }
                        .elementor-widget-wrap{
                          padding:0;
                        }
                      }
                      &:last-child(){
                        .elementor-column-wrap:after, .elementor-widget-wrap:after{
                          opacity: 0;
                        }
                      }

                    }

                    ul.elementor-icon-list-items{                      
                      margin-bottom: 40px;

                      li.elementor-icon-list-item{
                        a{
                          .elementor-icon-list-icon{
                            font-size: 5px;
                            width:1.25em;
                            line-height: 1.5;
                            padding-top:0;
                            text-decoration:none;
                          }
                          .elementor-icon-list-text{
                            color: $primary-color;
                            padding-left: 10px;
                            font-size: $txt-size;
                            letter-spacing: $txt-space;
                            margin-bottom: 3px;
                            line-height: 1.5;
                            text-decoration:none;
                            &:hover{
                              color: $brand-primary;
                            }                                            
                          }
                        }
                      }
                    }
                  }
                  
                  /* Row end */
                    
                    /*> a {
                        color: #777;
                        padding: .2em 0;
                        width: 95%;
                        display: block;
                        border-bottom: 1px solid #ccc;
                    }*/


                    /*> ul {
                        display: block;
                        padding: 0;
                        margin: 10px 0 0;
                        list-style: none;
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        box-sizing: border-box;
                        &:before,
                        &:after {
                            content: "";
                            display: table;
                        }
                        &:after {
                            clear: both;
                        }
                        > li {
                            float: left;
                            width: 100%;
                            padding: 10px 0;
                            margin: 0;
                            font-size: .8em;
                            a {
                                border: 0;
                            }
                        }
                    }*/
                    //

                }                
                /*&.normal-sub {
                    width: 300px;
                    left: auto;
                    padding: 10px 20px;
                    > li {
                        width: 100%;
                        a {
                            border: 0;
                            padding: 1em 0;
                        }
                    }
                }*/
            }
        }
    }
  }

}

/* ––––––––––––––––––––––––––––––––––––––––––––––––––
Mobile style's
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@media (max-width: 767px){
  #page .site-simple-header{
    max-height: 52px;
    .branding-container{
      min-width: 180px !important;
      .site-branding{
        padding-left:5px;
        width: 185px;
        .logo{
          max-height: 48px;
          a{
            display: block;
          }
        }
        .location{
          margin-left: 5px;
          padding-left: 5px;
        }
      }
    }
  }
}

@media only screen and (max-width: 959px) {
  #page .site-simple-header{
    .branding-container .site-branding .location a span.loc:after {
      content: ", NC";
    }
    .menu-container {
        width: 100%;
    }
    .menu-mobile {
        display: block;
    }
    .menu-dropdown-icon {
        &:before {
            display: block;
        }
    }
    .menu {
        > ul {
            display: none;
            > li {
                width: 100%;
                float: none;
                display: block;
                a {
                    padding: 1.5em;
                    width: 100%;
                    display: block;
                }
                > ul {
                    position: relative;
                    &.normal-sub {
                        width: 100%;
                    }
                    > li {
                        float: none;
                        width: 100%;
                        margin-top: 20px;
                        &:first-child {
                            margin: 0;
                        }
                        > ul {
                            position: relative;
                            > li {
                                float: none;
                            }
                        }
                    }
                }
            }
        }
        .show-on-mobile {
            display: block;
        }
    }
  }
}

@media (min-width:767px) and (max-width:1023px){  
  #page .site-simple-header{
    .menu-container{
      display:none;
    }
    .branding-container {
      margin-right:auto;
      min-width:auto;

      .site-branding{
        padding-left:10px;

        .location a span.loc:after {
          content: ", North Carolina";
        }
        
      }
    }
  }
}

@media (max-width: 1279px){
  #page .site-simple-header{
    position:fixed;

    .ast-mobile-menu-buttons{
      display: flex;
      .menu-toggle {
        border-radius: 0;
      }
      .hc-nav-trigger{
        &.hc-nav-1{
          right: 0;
          top: 0; 
        }
      }
    }
    .menu-container{
      display:none;
    }
    .branding-container {
      min-width: 260px;
      margin-right: auto;

      .site-branding {
        padding-left: 10px;

        .logo{
          max-width: 85px;
          .icon{
            max-height: 48px;
          }
        }       
                
      }
    }
    .menu > ul > li.menu-parent > a{
      padding: 1.56em 0.54em;
      font-size: .67777777777778rem;
    }
    .menu>ul>li.menu-parent.search-cale a.menu-link{
      padding: 1.45em 12px 1.45em 12px;
    }
    .mobile-call-button{
      .pphone-icon, .phone-icon{
        width:14px;
        height: 14px;
      }
    }  
  } 

  .home #page .site-simple-header{
    .ast-mobile-menu-buttons{      
      .hc-nav-trigger{
        &.hc-nav-1{
          right: 0;
          top: 0; /* Add/Disable 40px if not using the info bar */
        }
      }
    }
  } 
  
}

/*@media(max-width:1278px){
  .hc-nav.hc-nav-2{
    display:none;
  }
}*/

@media (min-width: 960px){
  #page .site-simple-header {
    .branding-container {
      .site-branding{
          padding-left:20px;
      }
    }
  }
}

@media (min-width:768px) and (max-width:1024px){
  #page .site-simple-header .menu>ul>li>ul{
    min-width: 1000px;
    right: 0;
  }
  .ast-header-break-point .ast-mobile-menu-buttons{
    height:51px !important;
  }
}

@media (min-width: 1025px) and (max-width: 1279px){
  #page .site-simple-header{

    .branding-container {
      margin-right: auto;
      .site-branding{
          padding-left:20px;
          .location a span.loc:after {
            content: ", North Carolina";
          }
      }
    }

    .ast-mobile-menu-buttons{
      display: flex;
        .join-a-retreat{
          line-height: 56px;
        }
        .mobile-call-button{
          line-height: 56px;
        }
        .hc-nav-trigger{
          right:0;
          height: 56px;
          min-height:56px;
        }
        .menu-toggle {
          border-radius: 0;
          .mobile-toggle-offcanvas-wrapper{
            height:56px;
            line-height: 50px;
          }
        }
    }
        
  }

}

@media (min-width: 1280px){
  .ast-header-break-point .ast-mobile-menu-buttons{
    display: none;
  }
}

@media (min-width: 1280px) and (max-width:1439px){ /* 1365px */
  #page .site-simple-header{
    .branding-container{
      min-width: 265px;

      .site-branding{
        padding-left: 15px;
        .location a span.loc:after {
          content: ", NC";
        }
      
      }
    }
    .menu>ul>li.menu-parent>a{
      padding: 1.56em .83em !important;
    }
    .menu>ul>li.menu-parent.telephone>a{
      padding: 1.56em 1.4em;
    }
    .menu>ul>li>ul.small-sub-nav{
      right: 55px;
    }

  }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
  #page .site-simple-header .menu>ul>li.menu-parent.telephone>a{
    padding-left:1.2em;
    padding-right:1.2em;
  }
  #page .site-simple-header .branding-container .site-branding{
    padding-left:10px;
  }
  #page .site-simple-header .branding-container{
    min-width: 220px;
  }
  #page .site-simple-header .menu>ul>li.menu-parent>a{
    padding: 1.8em .65em;
  }
}

@media only screen and (min-device-width : 1024px) and (max-device-width : 1366px) and (orientation : portrait) {
  #page .site-simple-header .menu>ul>li.menu-parent.telephone>a{
    padding-left:1.2em;
    padding-right:1.2em;
  }
  #page .site-simple-header .branding-container .site-branding{
    padding-left:10px;
  }
  #page .site-simple-header .branding-container{
    min-width: 220px;
  }
  #page .site-simple-header .menu>ul>li.menu-parent>a{
    padding: 1.8em .65em;
  }
}

@media only screen and (min-device-width : 1024px) and (max-device-width : 1366px) and (orientation : landscape) {
  #page .site-simple-header .menu>ul>li.menu-parent>a{
    padding: 1.56em 0.83em !important; /* 1em*/
  }
  #page .site-simple-header .branding-container .site-branding{
    padding-left:10px;
  }
  .ast-mobile-menu-buttons .mobile-call-button{
    line-height: 51px;
  }
}
