.box-aside .sub-head-title, .box-aside .widget-title, aside.widget .sub-head-title, aside.widget .widget-title {
  font-weight: 400;
  margin-bottom: 10px;
}

/****************
  New Blog Index
****************/
.recent-post-wrapper{  
  display: flex;
  height: 100%;
  min-height: 500px;
  flex-direction: column;
  justify-content: center;
  align-items: center;  

    .recent-post-overlay{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.35);
    }

    .recent-post-container{
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;

      .meta-container{
        text-align:center;

        ul{
          display: block;
          margin:0 0 20px 0;
          padding:0;
          list-style: none;

            li{
              color: #ffffff;
              font-size: 15px;
              list-style: none;
              display: inline-block;
              font-weight: 600;

                &:before{
                  content: "●";
                  padding-right: 12px;
                  padding-left: 10px;
                  font-size: 10px;
                }

                &:first-child{
                  &:before{
                    content: "";
                    padding:0;
                  }
                }

                a{
                  color:#ffffff;
                  text-decoration: none;
                  font-weight: 600;

                  &:hover{
                    text-decoration: underline;
                  }
                }

            }
        }
      }

      .heading-title{
        text-align: center;
        margin-bottom: 12px;

        h1, h2, h3, h4, h5{
          font-family: $font-primary-title;
          font-size: 42px;
          color:#ffffff;
          font-weight: normal;

          a{
            color:#ffffff;
            text-decoration: none;
          }

        }
      }

      .author-info{
        text-align: center;
          ul{
            margin:0;
            padding:0;
            list-style: none;

              li{
                margin:0;
                padding:0;
                list-style:none;
                  a{
                    text-decoration: none;
                    color:#ffffff;
                    font-size: 18px;
                    font-weight: 500;

                    img{
                      width:49px;
                      height: 49px;
                      border-radius: 100px;
                      border:1px solid #ffffff;
                    }

                    span{
                      display: inline-block;

                      &:nth-child(2){
                        margin-left:15px;
                        line-height: 49px;
                      }
                    }

                  }
              }

          }
      }

      .view-post{
        a{
          display: inline-block;
          text-align: center;
          margin-left: auto;
          margin-right: auto;
          margin-top: 25px;
          text-transform: uppercase;
          font-weight: bold;
          border-radius: 4px;
          font-size: 15px;
          letter-spacing: 1px;
          position: relative;
          padding: 9px 25px;
          color: #ffffff;
          border-color: #84628d;
          background-color: #84628d;
        }
      }

    }
}

/********************
Custom Blog Search 
*********************/

/*******************
Algolia Theme CSS
*******************/
/* Global */

[class^='ais-'] {
  font-size: 0.9rem;
  box-sizing: border-box;
}

a[class^='ais-'] {
  text-decoration: none;  
}

[class^='ais-'][class$='--disabled'] {
  cursor: not-allowed;
}

.ais-Breadcrumb,
.ais-ClearRefinements,
.ais-CurrentRefinements,
.ais-ClearRefinements-button,
.ais-GeoSearch,
.ais-HierarchicalMenu,
.ais-Hits,
.ais-Results,
.ais-HitsPerPage,
.ais-ResultsPerPage,
.ais-InfiniteHits,
.ais-InfiniteResults,
.ais-Menu,
.ais-MenuSelect,
.ais-NumericMenu,
.ais-NumericSelector,
.ais-Pagination,
.ais-Panel,
.ais-PoweredBy,
.ais-RangeInput,
.ais-RangeSlider,
.ais-RatingMenu,
.ais-RefinementList,
.ais-SearchBox,
.ais-SortBy,
.ais-SortBy-select,
.ais-HitsPerPage-select,
.ais-Stats,
.ais-ToggleRefinement {
  color: #21243d;
  font-size: 0.75rem;
}

/* Highlighting */

.ais-Highlight-highlighted,
.ais-Snippet-highlighted {
  background: rgba(226, 164, 0, 0.4);
}

/* Hits */

.ais-Hits-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 40px;
  list-style:none;
  margin:0;
  padding:0;
}

@media (min-width: 680px) {
  .ais-Hits-list {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 900px) {
  .ais-Hits-list {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (min-width: 1200px) {
  .ais-Hits-list {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

/* Menus */

.ais-HierarchicalMenu-link,
.ais-RatingMenu-item,
.ais-RefinementList-item {
  margin-bottom: 1rem;
}

.ais-Breadcrumb-item--selected,
.ais-HierarchicalMenu-item--selected,
.ais-Menu-item--selected {
  font-weight: bold;
}

/* Panel */

.ais-Panel--collapsible {
  position: relative;
}

.ais-Panel--collapsed .ais-Panel-body,
.ais-Panel--collapsed .ais-Panel-footer {
  display: none;
}

.ais-Panel-collapseButton {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  border: none;
  background: none;
}

.ais-Panel-header {
  font-size: 0.7rem;
  font-weight: 600;
  line-height: 1.6;
  letter-spacing: 0.08rem;
  color: #21243d;
  text-transform: uppercase;
  border: none;
  padding-bottom: 1rem;
}

/* Search box */

.ais-SearchBox-input {
  border: none;
  outline: none;
  width: 100%;
  min-height: 50px;
  padding-left: 56px;
  border-radius: 3px;
  font-size: 0.9rem;
  color: rgba(33, 36, 61, 0.8);
  background-color: rgba(65, 66, 71, 0.06);
  font-family: inherit;
}

.ais-SearchBox-input::placeholder {
  color: rgba(33, 36, 61, 0.5);
  opacity: 1; /* Firefox */
  line-height: 50px;
}

.ais-SearchBox-input:-ms-input-placeholder {
  color: rgba(33, 36, 61, 0.5);
  line-height: 50px;
}

.ais-SearchBox-input::-ms-input-placeholder {
  color: rgba(33, 36, 61, 0.5);
  line-height: 50px;
}

.ais-SearchBox-reset,
.ais-SearchBox-loadingIndicator,
.ais-SearchBox-submit {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  padding:0;
  border:0;
  border-radius: 0;
}

.ais-SearchBox-reset:hover{
  background-color: transparent;
}

.ais-SearchBox-submit {
  left: 20px;
  height:20px;
}

.ais-SearchBox-submit svg.ais-SearchBox-submitIcon{
  width:20px;
  height: 20px;
  opacity: 0.5;
}
.ais-SearchBox-submit:hover, .ais-SearchBox-submit:focus{
  background: transparent !important;
  border-color: transparent !important;
}

/* .ais-SearchBox-reset svg,
.ais-SearchBox-loadingIndicator svg,
.ais-SearchBox-submit svg {
  color: #e2a400;
} */

.ais-SearchBox-reset,
.ais-SearchBox-loadingIndicator {
  right: 26px;
}

.ais-SearchBox-form {
  position: relative;
}

/* SFFV search box */

.ais-RefinementList .ais-SearchBox-input {
  min-height: 40px;
  padding-left: 36px;
  border-radius: 3px;
  font-size: 0.8rem;
  color: rgba(33, 36, 61, 0.8);
}

.ais-RefinementList .ais-SearchBox-submit {
  left: 1rem;
}

.ais-RefinementList .ais-SearchBox-loadingIndicator,
.ais-RefinementList .ais-SearchBox-reset {
  right: 1rem;
}

.ais-RefinementList .ais-SearchBox-form {
  margin-bottom: 1rem;
}

/* Menus */

.ais-HierarchicalMenu-link,
.ais-RatingMenu-link,
.ais-RefinementList-label {
  display: flex;
  align-items: center;
}

.ais-RefinementList-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  border-radius: 2px;
  background-color: rgba(65, 66, 71, 0.08);
  width: 1rem;
  height: 1rem;
  margin: 0;
  margin-right: 1rem;
}

.ais-RefinementList-item--selected .ais-RefinementList-checkbox {
  background-color: #e2a400;
}

.ais-RefinementList-item--selected .ais-RefinementList-checkbox::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-2px) translateY(-2px);
  width: 4px;
  height: 4px;
  background-color: #fff;
  border-radius: 4px;
}

.ais-HierarchicalMenu-count,
.ais-Menu-count,
.ais-RefinementList-count,
.ais-ToggleRefinement-count,
.ais-RatingMenu-count {
  margin-left: 8px;
  display: flex;
  align-items: center;
  padding: 0 4px;
  border-radius: 4px;
  font-size: 0.64rem;
  background-color: rgba(65, 66, 71, 0.08);
  font-weight: 600;
  letter-spacing: 1.1px;
  color: rgba(33, 36, 61, 0.8);
}

.ais-HierarchicalMenu-showMore,
.ais-Menu-showMore,
.ais-RefinementList-showMore {
  margin-top: 1rem;
}

.ais-HierarchicalMenu-list {
  font-weight: normal;
}

.ais-HierarchicalMenu-link::before {
  content: '';
  display: inline-block;
  margin-right: 1rem;
  width: 8px;
  height: 12px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='4' viewBox='0 0 8 4'%3E%3Cpath fill='%2390919E' fill-rule='nonzero' d='M0 4l4-4 4 4z'/%3E%3C/svg%3E%0A");
  background-size: 100% 100%;
}

.ais-HierarchicalMenu-item--selected .ais-HierarchicalMenu-link::before {
  transform: rotate(180deg);
}

/* ClearRefinements */

.ais-ClearRefinements,
.ais-ClearRefinements-button {
  color: rgba(33, 36, 61, 0.7);
}

.ais-ClearRefinements-button--disabled {
  color: rgba(33, 36, 61, 0.5);
}

/* ToggleRefinement */

.ais-ToggleRefinement-label {
  display: flex;
}

.ais-ToggleRefinement-checkbox {
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  -webkit-appearance: none;
  width: 30px;
  height: 16px;
  background: rgba(65, 66, 71, 0.08);
  border-radius: 8px;
  transition: background 150ms ease-out;
  margin-right: 16px;
}

.ais-ToggleRefinement-checkbox:checked {
  background: #e2a400;
}

.ais-ToggleRefinement-checkbox::after {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  box-shadow: 0 4px 11px 0 rgba(37, 44, 97, 0.15),
    0 2px 3px 0 rgba(93, 100, 148, 0.2);
  background-image: linear-gradient(to top, #f5f5fa, #fff);
  transition: transform 150ms ease-out;
}

.ais-ToggleRefinement-checkbox:checked::after {
  transform: translateX(100%);
}

/* Selectors */

.ais-SortBy,
.ais-HitsPerPage {
  position: relative;
}

.ais-SortBy::after,
.ais-HitsPerPage::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 1rem;
  height: 1rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='7' viewBox='0 0 12 7'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M14-5v16H-2V-5z'/%3E%3Cpath fill='%23000' stroke='%23FFF' stroke-width='.5' d='M2.228 1.332a.664.664 0 0 0-.942.001.665.665 0 0 0-.002.941l4.247 4.247c.259.26.679.26.938 0l4.247-4.247a.664.664 0 0 0-.002-.94.666.666 0 0 0-.942-.002L6 5.105 2.228 1.332z'/%3E%3C/g%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: right;
}

.ais-SortBy-select,
.ais-HitsPerPage-select {
  font-family: inherit;
  -webkit-appearance: none;
  background: none;
  border: none;
  color: #21243d;
  padding-right: 1rem;
}

/* Sliders */

.ais-RangeSlider .rheostat-horizontal {
  width: calc(100% - 10px);
}

.ais-RangeSlider .rheostat-background {
  height: 3px;
  border-radius: 3px;
  background-color: rgba(65, 66, 71, 0.08);
  border: none;
}

.ais-RangeSlider .rheostat-progress {
  top: 0;
  height: 3px;
  border-radius: 3px;
  background-color: #e2a400;
}

.ais-RangeSlider .rheostat-handle {
  top: -9px;
  margin-left: -5px;
  border: none;
  box-shadow: 0 4px 11px 0 rgba(37, 44, 97, 0.15),
    0 2px 3px 0 rgba(93, 100, 148, 0.2);
  background-image: linear-gradient(to top, #f5f5fa, #fff);
}

.ais-RangeSlider .rheostat-marker {
  background-color: rgba(65, 66, 71, 0.08);
}

/* RangeInput */

.ais-RangeInput-input {
  font-family: inherit;
  background: none;
  border: none;
  border-bottom: 1px solid #ebecf3;
  padding: 0;
  padding-bottom: 3px;
  font-weight: 600;
  color: #21243d;
  min-width: none;
  font-size: 0.875rem;
}

.ais-RangeInput-label:first-of-type {
  margin-right: 6px;
}

.ais-RangeInput-label:last-of-type {
  margin-left: 6px;
}

/* Pagination */

.ais-Pagination-list {
  justify-content: center;
  display: inline-block;
  margin:auto;
  background: #ffffff;
  border-radius: 4px;
  padding:13px 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.ais-Pagination-item,
.ais-Pagination-link {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ais-Pagination-item {
  width: 38px;
  height: 38px;
}

.ais-Pagination-item {
  display:inline-block;
  border-radius: 4px;
  color: #414247;
  font-weight: 500;
  background-color: rgba(65, 66, 71, 0.08);
    a{
      color: #21243d;
    }  
}

.ais-Pagination-item--selected {
  font-weight: bold;
  background-color: #84628c;
  color:#ffffff;  
}

.ais-Pagination-item--firstPage,
.ais-Pagination-item--previousPage,
.ais-Pagination-item--nextPage,
.ais-Pagination-item--lastPage {
  background-color:#efefef;
  border-radius: 4px;
}

.ais-Pagination-item--disabled {
  opacity: 0.33;
}

.ais-Pagination-item--selected a {
  color: #fff;
}

.ais-Pagination-item:not(:last-of-type) {
  margin-right: 4px;
}

.ais-Pagination-item.ais-Pagination-item--previousPage {
  margin-right: 1rem;
  width:100px;  
}

.ais-Pagination-item.ais-Pagination-item--nextPage {
  margin-left: 1rem;
  width:80px;
}

.ais-Pagination-link {
  width: 100%;
  height: 100%;
}

.ais-Pagination-item:hover{
  background-color: #84628c;
  color:#ffffff;
}

.ais-Pagination-item:hover a{
  color:#ffffff;
}

/******* app.css *****/
.ais-ClearRefinements {
  margin: 1em 0;
}

.ais-SearchBox {
  margin: 1em 0;
}

.ais-Pagination {
  margin-top: 5em;
  margin-bottom: 5em;
  text-align:center;
}

.ais-Hits-item {
  margin-bottom: 0;
  /*width: calc(50% - 1rem);*/
  /*width: calc(33% - 1rem); */
  width: 100%;
}

.ais-Hits-item img {
  margin-right: 1em;
}

.hit-name {
  margin-bottom: 0.5em;
}

.hit-description {
  color: #888;
  font-size: 14px;
  margin-bottom: 0.5em;
}


/******** Theme End *******/

.blog-banner{
  .elementor-heading-title{
    font-size: $h2-size;
  }
}

.custom-blog-search{
  display: block;
  width: 100%;  
    .panel{
      .custom-searchbox{
        /*margin-top: -60px;*/
        margin: -60px auto 0 auto;
        background:#ffffff;
        border-radius: 4px;
        padding: 18px 30px;
        max-width: 840px;
        box-shadow: 4px 7px 38px 6px rgba(0, 0, 0, 0.1);

        #menu-select{
          max-width:320px;
          display: inline-block;

          &:before{
            content: "Select Category";
            font-size: $txt-size-s;
            color:$secondary-color;
            text-transform: uppercase;
            font-weight: bold;
            letter-spacing: 0.5px;
          }

          select{
            border: 1px solid #c7aacd;
            border-radius:4px;
            padding: 0 15px;
            box-shadow: 1px 1px 15px 1px rgba(0, 0, 0, 0.1);
            font-size: 17px;
            font-weight: normal;
            height:52px;
            max-width: 290px;
            margin-top:3px;

            option{
              font-size: 18px;
              font-weight: normal;
            }
          }
        }

        .insta-searchbox{
            display: inline-block;
            width: calc(100% - 306px);
            margin: 0 0 0 10px;            

          .ais-SearchBox{
            margin: 0 0 10px 0;

              &:before{
                content: "Search Blog Post";
                font-size: $txt-size-s;
                color:$secondary-color;
                text-transform: uppercase;
                font-weight: bold;
                letter-spacing: 0.5px;
              }

              form{
                margin-top:3px;

                input{
                  font-size: 17px;
                  font-weight: 500;
                  color:#000000;
                  width: calc(100% - 70px);
                  border: 1px solid #c7aacd;
                  border-radius:4px;
                  padding: 0 15px 0 55px;
                  box-shadow: 1px 1px 15px 1px rgba(0, 0, 0, 0.1);
                  -webkit-appearance: none;
                  -moz-appearance: none;
                  appearance: none;

                    &:focus{
                      outline:0;
                    }
                    &::placeholder{                    
                      height:50px;
                      line-height: 50px;
                    }
                  
                }                       
              }

          }
        }

        #refine-list{
          display: block;
          width: 100%;

            .ais-RefinementList{
              display: inline-block;

                ul.ais-RefinementList-list{
                  display: block;
                  width:100%;
                  padding:0;
                  margin:0;
                  
                  &:before{
                    content:"Popular Categories: ";
                    color:$secondary-color;
                    font-size: $txt-size;
                  }

                  li{
                    display: inline-block;
                    list-style:none;
                    padding:0;
                    margin:0;
                    font-size: $txt-size-lg;
                    margin-right:5px;

                      span.seperate{
                        color:$secondary-color;
                      }

                      &:last-child{
                        margin-right:0;

                        span.seperate{
                          display: none;
                        }
                      }
                    
                  }

                }
            }
        }
      }
    }

    .custom-blog-title{
      display: block;
      text-align: center;
      width: 100%;
      padding:30px 30px 10px 30px;

      h2, h3, h4, h5{
        font-family: $font-primary-title;
        font-size: $h4-size;
        font-weight: 500;
        color:#000000;
        text-transform: uppercase;
        margin:0;
      }

      #current-menu-refinements{
        margin-top: 25px;
        margin-bottom: 25px;

        ul.ais-CurrentRefinements-list{
          margin:0;
          padding:0;
          display: block;

          li.ais-CurrentRefinements-item{
            display: inline-block;
            list-style:none;               
            margin-right:5px;

              .ais-CurrentRefinements-label{
                margin-right:5px;
                color:$secondary-color;
                font-weight: normal;
                font-size:17px;
                text-transform: capitalize;
              }
              .ais-CurrentRefinements-delete{
                background:transparent;
                margin:0;
                padding:0;
                border:0;
                font-size:15px;
              }
              .ais-CurrentRefinements-category{
                background:#84628c;
                border-radius: 3px;
                margin-right:6px;
                padding:1px 10px;

                &:first-child{
                  margin-left:10px;
                }

                  .ais-CurrentRefinements-categoryLabel{
                    margin-right:6px;
                    font-weight: normal;
                    font-size:$txt-size-lg;
                    color:#ffffff;
                  }
              }
              

          }
        }
      }


    }

    ol.ais-Hits-list{
      list-style: none;
      margin:0;
      padding:0 30px;
    
      li.ais-Hits-item{
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        border-radius: 4px;

        .thumbnail{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

          .image{
            display: block;
            width: 100%;            
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 0 0;
            border-radius: 4px 4px 0 0;

              a{
                display: block;
                width: 100%;
                min-height: 211px;
              }
          }

          .hit-content{
            padding: 14px 22px;
          }

        }

      }

    }
    
    .hit-category{
      display: block;
      width: 100%;
      margin-bottom: 6px;

        ul{
          margin:0;
          padding:0;
          list-style: none;
          line-height: 20px;

            li{
              display: inline-block;
              margin:0;
              padding:0;
              list-style:none;
                a{
                  display: inline-block;
                  font-weight: 400;
                  font-size: 14px;
                }
            }
        }
    }

    .hit-name{
      display: block;
      margin-bottom:0;

        h3, h4, h5, h6{
          font-family: $font-primary-title;
          color:#000000;
          margin-bottom:5px;

          a{
            display: block;
            font-family: $font-primary-title;
            font-size: 21px;
            line-height: 30px;
            color:#000000;
          }
        }

    }

    .hit-meta{
      display:block;
      margin-bottom: 6px;

        ul{
          margin:0;
          padding:0;
          list-style:none;
            li{
              display: inline-block;
              margin:0;
              padding:0;
              list-style:none;
              color:$secondary-color;
              font-size: $txt-size;
              font-weight: 500;
                &:first-child{
                  padding:0;
                }

                a{
                  font-size: $txt-size;
                  font-weight: 500;
                }

            }
        }
    }

    .hit-description{
      display: block;
      width: 100%;
      font-size: $txt-size;
      line-height: 24px;
      color: $secondary-color; /* #808285 */
    }

    .action{
      display: block;
      margin-bottom: 5px;

      a{
        display: block;
        font-size: $txt-size;
        font-weight: 500;
      }
    }

}
/*******************
Blog Index End
*******************/
/*****************
Blog Inner Page
******************/
.single-post .single-post-container{
  clear: both;
  position: relative;
  width: 100%;
  max-width: 1200px;
  padding: 30px 0 0 0;
  margin:auto;

  h1, h2, h3, h4, h5, h6{
    color:#000000;
  }

  /*h2, h3, h4, h5, h6{
    color:$hdr-primary;
  }*/

  a.backto{
    display: block;
    text-transform: uppercase;
    font-size: 13px;
    margin-bottom: 13px;
  }

  #primary{
    display:block;
    float: none;
    padding:0;
    margin:0 auto;
    width:100%;
    border:0;
    max-width: 750px;

    p, div, span, a, ul, li{
      font-family: $font-primary;
      color:$primary-color; /* #5f6368 */
    }
    p, ul li, ol li{
      margin-bottom: 2.2em; //44px
      line-height: 30px;
      a{
        text-decoration: none;
        border-bottom: 1px dotted #84628d;
      }
    }    
    ul, ol{
      margin:0 0 2.5em 3em;
      padding:0;
    }
    ul li, ol li{
      margin-bottom: 1.5em;
      line-height: 30px;
      padding-left: 16px;
    }
    ul li ul{
      margin-top:1.5em;
    }
    ul.two-coln{
      display:inline-block;
      width:43%;
      vertical-align:top;
    }
    a, a span, p a, p a span, ul li a, ol li a{
      color:$brand-primary;
      /*font-weight: bold;
      text-decoration: underline;*/
    }
     a span.elementor-button-text, a:hover span.elementor-button-text{
      color:#ffffff;
     }

    a:hover, a:hover span, p a:hover, p a:hover span, ul li a:hover, ol li a:hover{
      color: $hover-brand-primary;
    } 

    .elementor-column-gap-default>.elementor-row>.elementor-column>.elementor-element-populated>.elementor-widget-wrap{
      padding:0;
    }

    h2{
      margin-bottom: 40px;
      line-height: 50px;
    }
    h3{
      margin-bottom: 30px;
      line-height: 40px;
    } 
    h4{
      margin-bottom: 30px;
      line-height: 38px;
    }
    h5{
      margin-bottom: 30px;
      line-height: 40px;
      font-weight: normal;
    }
    blockquote{
      border-left:5px solid #84628c;
      margin: 2.75em 1em 2.75em 3em;
      padding: 10px 25px;
      font-size: $txt-size-xlg;
      line-height: inherit;
      position: relative;
      font-family: $font-primary;
      color:#000000;
      &:before, &:after{
        font-family: $font-secondary;
      }
      p{
        font-family: $font-primary;
        font-size: $txt-size-xlg;
        margin:0;
        padding:0;
        color:#000000;
      }      
    }
    .elementor-widget-image{
      margin-bottom: 40px;
    }
    hr{
      margin:50px auto;
      max-width: 500px;
      width: 100%;
      border-top: 3px dotted #83618D;
    }
    p img{
      margin-bottom: 50px;
    }
      
  }

  #primary a.elementor-button-link.elementor-button, #primary a.elementor-cta__button.elementor-button{
    background-color: #83618C;
    color:#ffffff;
    border-color: #83618C;
  }

  //Blog Hero image
  .gutenberg-featured-image{
    display: block;
    margin-bottom: 44px;
    text-align: center;
    max-height: 520px;
    overflow: hidden;
    border-radius: 4px;
    img{
      margin:0;
      padding:0;
      border:0;      
    }  
  }

  .postedin{
    clear: both;
    width:100%;
    max-width: 750px;
    margin:2em auto;
    padding:0;
    .label{
      display: inline-block;
      margin-right: 7px;
      text-transform: uppercase;
      font-size: 15px;
    }
    ul{
      margin:0;
      list-style: none;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      flex-flow: row; 
      flex-wrap: wrap;    
      li{
        display: inline-flex;   
        margin:0 5px;  
        a{
          background:#f3eaf5;
          margin:5px 0;
          padding:3px 15px;
          color: #49344e;
          text-decoration: none;
          border-radius: 100px;
          text-transform: capitalize;
          &:hover{
            color:#ffffff;
            background:$brand-primary;
          }
        }
      }
    }
  }
  
}

.single-post-related-articles{
  clear: both;
  margin: 0 auto;
  padding:0 0 45px 0;    
  .htitle{
    margin:0 auto;
    text-align: center;
    border-top:1px solid #e7e7e7;
    padding-top:70px;
    max-width: 750px;
  }
  .events-recently-viewed-wrapper{
    margin:auto;
  }
}


.custom-events-slider{
  margin:0;
  .events-recently-viewed-wrapper{
    margin:auto;
    max-width: 980px;

    .blog-custom-events, .signature-custom-events, .cat-by-events, .relevent{
      overflow: hidden;
      margin: auto;
      max-width: 800px;
      .swiper-slide{
        height: auto !important;
        padding-bottom: 60px;
        .slide-inner{
          height:100%;
          .slide{            
            background: #fff;
            margin: auto;
            padding-bottom: 30px;
            border-radius: 10px;
            border: 1px solid #dbdbdb;
            max-width: 378px;
            height:100%;
            display: flex;
            flex-direction: column;
            position: relative;
              .image, .content{
                max-width: 378px;
              }
              .image{
                overflow: hidden;
                width: 100%;
                margin:0;
                min-height: 212px;
                max-height: 212px;
                background-size: cover;
                border-radius: 10px 10px 0 0;
                img{
                  width: 100%;
                }
              }
              .content{
                margin:0;
                padding: 25px 25px;
                font-size: $txt-size;
                line-height: $text-size-lheight;

                .post-date{
                  display: block;
                  margin-bottom: 15px;
                }
      
                .event-title{
                  font-family: $font-blog-primary;
                  font-size: $h5-size;
                  line-height: 1.76rem;
                  letter-spacing: .5px;
                  margin: 0 0 15px 0;
                  padding: 0;
                  font-weight: 700;
                  color: #000000;
                  a{
                    border:0;
                    background:transparent;
                  }
                }                                           
                .excerpt{
                  display: block;
                  margin-bottom: 15px;
                }                           
              }
              .btn{
                clear: both;
                display: block;
                text-align: center;
                margin-top: 25px;
                margin-bottom: 10px;
                position: absolute;
                bottom: 25px;
                width: 100%;

                a{
                  background-color:$brand-primary;
                  text-decoration: none;
                  border-radius: 3px;
                  display: inline-block;
                  margin:auto;
                  color:#ffffff;
                  line-height: 36px;
                  border: 0;
                  font-size: 14px;
                  padding: 0 20px;
                  text-transform: uppercase;
                }
              } 
              &:hover{
                box-shadow: 0 2px 3px 0 rgba(99,90,98,.15), 0 6px 10px 4px rgba(66,60,67,.15);
              }
              a{
                &:hover{
                  box-shadow: 0 0 0;
                }
              }
          }
        }
      } 
    }

    &.sign-wrapper, &.cat-slide-wrapper, &.relevent-slide-wrapper{
      max-width: 800px;
    }
    .signature-custom-events, .cat-by-events, .relevent{
      max-width: 648px;
      overflow: hidden;
      margin: auto;
      .swiper-slide{
        padding-bottom: 40px;
      }
      .swiper-slide .slide-inner .slide{
        padding-bottom: 0;
        max-width: 294px;
        justify-content: space-between;
        .image{
          max-width: 294px;
          min-height: 166px;
          max-height: 166px;
        }
        .content{
          .taxonomies{
            .listtaxo{
              display: flex;
              justify-content: space-around;
              align-items: center;
              img{
                width:20px;
              }
            }
          }
        }
        .btn{
          clear: both;
          display: block;
          text-align: center;
          width: 100%;
          position: relative;
          margin-bottom:30px;
          bottom: 0;
          margin-top: -20px;
        }
      }
    }
  }
  .cswiper-pagination, .siswiper-pagination{
    text-align: center;
  }
}




@media(max-width:767px){

  .recent-post-wrapper{
    .recent-post-container{
      padding:0 25px;
      
      .heading-title{
        margin-bottom: 0;

        h1{
          font-size: 30px;
        }
      }

      .meta-container{
        ul{
          margin-bottom: 10px;
        }
      }

    }
  }

  .group-blog #primary.content-area .blog-banner{
    .elementor-widget-heading{
      .elementor-heading-title{
        font-size: $h5-msize;
      }
    }
  }

  .custom-blog-search{
    .panel {
      .insta-searchbox{
        margin-top:-40px;
          .ais-SearchBox{
            margin:0 30px;
            padding: 15px 20px;
              form {
                input{
                  padding:0 10px 0 45px;
                  width: calc(100% - 55px);
                }
                .ais-SearchBox-submit{
                  left:15px;
                }
              }
          }
      }
    }
    .custom-blog-title h2, .custom-blog-title h3, .custom-blog-title h4, .custom-blog-title h5{
      font-size: 20px;
    }
    .custom-blog-title{
      padding:10px 30px 0 30px;
    }

    #hits{
      margin: 0 30px;
    }
    ol.ais-Hits-list{
      padding:0;
    }
  } 
  .ais-Pagination-item.ais-Pagination-item--previousPage{
    margin-right: 0;
    margin-bottom: 15px;
    width: calc(100% - 42px);
  }
  .ais-Pagination-item{
    width: 34px;
    height:34px;
    margin-bottom: 15px;
  }
  .ais-Pagination-item.ais-Pagination-item--nextPage{
    margin-left: 0;
    width: calc(100% - 42px);
  }
  .ais-Pagination-item--lastPage{
    margin-bottom: 0;
  }
  #pagination{
    margin:0 30px;
  }

  .custom-blog-search{
    .panel{
      .custom-searchbox{
        margin-top: -30px;
        margin-bottom: 15px;

        #menu-select{
          display: block;

            select{
              max-width: 260px;
              font-size: $txt-size-xlg;
              line-height: $txt-size-xlg;
            }
        }
        .insta-searchbox{
          display: block;
          margin: 0;
          width: 100%;

            .ais-SearchBox{
              margin-top:15px;
              padding:0;
              form{
                input{
                  font-size: $txt-size-xlg;
                  line-height: $txt-size-xlg; 
                  &::placeholder{
                    font-size: $txt-size-xlg;
                    line-height: 1.35rem; 
                  }                 
                }
              }
            }
        }

      }

    }
  }

  .custom-post-authors-header ul.author-avatar-list li{
    top:10px;
  }
  .single-post .single-post-container{
    #primary{
      padding:0;
      border:0;
      width: 100%;

      &.no-banner{
        margin-top:-30px;
      }

      .custom-post-authors-header{
        ul.author-avatar-list{
          margin-top:0; 
          margin-right:5px;         
        }
        .post-meta-right{
          ul.author-name-list{
            margin-bottom: 5px;
          }          
        }
      }
    }
    .gutenberg-featured-image{
      margin-bottom: 24px;
    }  
  } 

  .single-post{
    .single-post-container{ 
      padding-top:0;
      #primary {
        .elementor-cta--layout-image-left .elementor-cta{
          flex-direction: column;
        }
        .elementor-cta__content{
          h2{
            margin-top:0;
            line-height: 2.36rem;
          }
          .elementor-cta__description{
            font-size: $txt-size-lg;
          }
        }
        .elementor-text-editor h5, .elementor-text-editor h6{
          margin-bottom: 15px;
        }
      }      
    }
  }
  .single-post .single-post-container .postedin .label{
    text-align: center;
  }
  .single-post-related-articles .htitle{
    margin-top:30px;
    padding-top: 50px;
  }
}

@media(min-width:768px) and (max-width: 1024px){
  .single-post .single-post-container{
    max-width: 100%;
  }
  .blog-banner{
    .elementor-heading-title{
      font-size: 1.7rem;
    }
  }

  .recent-post-wrapper{
    .recent-post-container{
      padding:0 25px;
    }
  }

  .custom-blog-search{
    .panel{
      #hits{
        margin:0 25px;
      }
    }
  }
  .single-post-related-articles .htitle{
    margin-top:30px;
    padding-top: 50px;
  }
}

@media (max-width:1024px){
  /*.single-post .single-post-container{
    #primary{
      h1{
        font-size: $h1-msize;
        line-height: $h1-msize-lheight;
      }
      h2{
        font-size: $h2-msize;
        line-height: $h2-msize-lheight;
      }
    }
  }*/
}