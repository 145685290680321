/***************************************************************************
  1 - AOLRC - Slides 
***************************************************************************/
/**
* AOLRC Slides
*/

/* Make all cards full height */
.elementor-widget-aolrc-slides .elementor-slides .slick-slide-inner,
.elementor-widget-aolrc-slides .elementor-slides .slick-slide-inner .elementor-slide-content {
  height: 100%;
}

/* Prev and next arrows */
.elementor-widget-aolrc-slides .slick-slider .slick-arrow {
  width: 50px;
  height: 50px;
  box-shadow: 3px 8px 16px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff;
  border-radius: 100%;
}

/*
.elementor-widget-aolrc-slides .slick-slider .slick-prev {
  left: -20px;
}

.elementor-widget-aolrc-slides .slick-slider .slick-next {
  right: -20px;
}*/


/* End arrows */

.elementor-widget-aolrc-slides .elementor-slides .slick-slide {
  overflow: visible;
}
.elementor-widget-aolrc-slides .elementor-slides .slick-slide-inner,
.elementor-widget-aolrc-slides .elementor-slides .slick-slide-inner:hover {
  color: #000;
}

.elementor-widget-aolrc-slides .elementor-slides .slick-slide-inner .elementor-slide-content {
  box-shadow: 8px 15px 45px 0 rgba(0, 0, 0, 0.09);
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 60px;
}

/* TOP BG*/
.elementor-widget-aolrc-slides .elementor-slides .slick-slide-bg {
  height: 150px;
  min-height: 150px;
}

/* Space the title */
.elementor-widget-aolrc-slides .elementor-slides .slick-slide-inner .elementor-slide-content .elementor-slide-heading {
  padding:30px 50px 0px 50px;
  color: #7f658a;
  font-family: $font-primary;
  font-size: $txt-size;
  letter-spacing: 0.92px;
  text-transform: uppercase;
  font-weight: bold;
}


/* Space the content */
.elementor-widget-aolrc-slides .elementor-slides .slick-slide-inner .elementor-slide-content .elementor-slide-description,
.elementor-widget-aolrc-slides .elementor-slides .slick-slide-inner .elementor-slide-content .elementor-slide-description-2{
  padding: 0 50px 20px 50px;
  margin-top: 10px;
  font-size: $txt-size;
  line-height: 25px;
  color: #959595;
}

.elementor-widget-aolrc-slides .elementor-slides .slick-slide-inner .elementor-slide-content .elementor-slide-description-2 {
  overflow-y: scroll;
  max-height: 50%;
}

.elementor-widget-aolrc-slides .elementor-slides .slick-slide-inner .elementor-slide-content .elementor-slide-description-2 h4 {
  color: #7f658a;
  font-family: $font-primary;
}


/* Read more button */
.elementor-widget-aolrc-slides .elementor-slides .slick-slide-inner .elementor-slide-content .read-more {
  font-size: $txt-size;
  letter-spacing: 0.62px;
  font-weight: bold;
  padding: 10px 50px 30px 50px;
}

/**
* Expanded content
*/

/*.elementor-widget-aolrc-slides .elementor-slides .slick-slide .close-expand {
  display: none;
}*/

/* Style the expanded slide */
.elementor-widget-aolrc-slides .elementor-slides .slick-slide .close-expand {
  display: none;
}

.elementor-widget-aolrc-slides .elementor-slides .slick-slide.expanded .close-expand {
  display: block;
  width: 25px;
  height: 25px;
  content: "";
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAjCAMAAAApB0NrAAAAyVBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABeXl4AAABRUVEAAAAAAABKSkpHR0dFRUVERERDQ0NBQUEAAAA9PT0AAAA2NjYwMDBOTk7s7Oz19fX39/f19fX29vb09PT19fX09PTy8vLw8PDu7u7///+g3NUuAAAAQnRSTlMAAQIDBAUGBwgJCgwNDg8QEhMUFRYXGBobHB0eHyEiIyQmKCkqKysuMjQ1Nzo7PD0+QENLS1VpirfW19fY2Nna3N6enhd/AAABsUlEQVQ4T42UW3IjIQxFESDolx0nWcPU7H85U7OGJI7dTxD0SLQnlb+gT9XhSlwJwGitFex7zjuHegRwaA2wq5wzoGYKGMlJqC9E0iWfMnjDASonyik9IEaM0dZotScOaI39De9TSkT0gApirTWmf9n/EDOIv7rlY0kxxgIJwwgimva5nf/GCB3iy2u7XWeKgaGcldKaEYe2u/jl7Z2ZFp0/vfr1NlMIkavvrGLQOdudm+3tvoUIjXXOD09NvM4sxEpKSSGH3QXXz3ELgcCxrMf+GeMnQ4FEh49h94TxY4osQ8DtO071FwxSjmjnlBRy8TrxoZAI2AdWYujswn2mo5btTi7cBIlEGXS5KEvxyTAuWRjdDozMQRC5xGFY6enkaFzFn2aw4X70Io7Bw1Un0NmmSXR6QzdBwuE8HNZzT967/mTylHWv030K28a9lOFAGaFm17xvsBv0PvWQxzmuzCRZBVWYAqFrGt/YgRP7SOu2rux6Qf4z4knLjDaDUmPKzCzi1XfmR52KfiruVeFPjc8186qZe83+1OxhzT7XvIu69/XzO6157zX/Rs3/U/GP/QMNlhvauhxFYQAAAABJRU5ErkJggg==");
  background-size: 25px auto;
  position: absolute;
  right: 30px;
  top: 25px;
}

/* Hide the short content */
.elementor-widget-aolrc-slides .elementor-slides .slick-slide.expanded .elementor-slide-description {
  display: none;
}

/* Show the new content */
.elementor-widget-aolrc-slides .elementor-slides .slick-slide .elementor-slide-description-2 {
  display: none;
}

.elementor-widget-aolrc-slides .elementor-slides .slick-slide.expanded .elementor-slide-description-2  {
  display: block;
}



/* Hide the read more */
.elementor-widget-aolrc-slides .elementor-slides .slick-slide.expanded .slick-slide-inner .elementor-slide-content .read-more {
  display: none;
}




/***************************************************************************
  2 - AOLRC - Expanding Cards
***************************************************************************/
/**
* AOLRC Expanding Cards
*/
.aolrc-expanding-cards {
  /* padding: 15px;
  display: flex;
  flex-flow: row wrap; */
  padding: 15px;
  display: flex;    
  width: 100%;    
  flex-direction: row;
  flex-wrap: wrap;
}

.aolrc-expanding-cards .card {
  /*padding: 15px;
  width: calc((100% / 3));
  transition: all 0.2s ease-in-out;*/
  width: calc(100%/3);
  transition: all 0.2s ease-in-out;
  border-radius: 20px;
  flex-direction: column;
  display: flex;
  padding: 15px;
}

.aolrc-expanding-cards .card.is-inactive{
  display:block;
}

.aolrc-expanding-cards .card h4 {
  color: #7f658a;
  font-family: $font-primary;
}

@media screen and (max-width: 991px) {
  .aolrc-expanding-cards .card {
    width: calc((100% / 2));
  }
}
@media screen and (max-width: 767px) {
  .aolrc-expanding-cards .card {
    width: 100%;
  }
}

.aolrc-expanding-cards .card__inner {
  /* width: 100%;
  position: relative;
  background-color: #FFF;
  transition: all 0.2s ease-in-out; */

  /* Card Style */
  /* box-shadow: 8px 15px 45px 0 rgba(0, 0, 0, 0.09);
  border-radius: 20px;
  font-size: 16px;
  line-height: 25px;
  color: #959595; */

  width: 100%;
  position: relative;
  background-color: #FFF;
  transition: all 0.2s ease-in-out;
  box-shadow: 8px 15px 45px 0 rgba(0, 0, 0, 0.09);
  border-radius: 20px;
  font-size: 16px;
  line-height: 25px;
  color: #959595;
  flex: 1;
  padding: 0 0 30px 0;
}

.aolrc-expanding-cards .card__inner .slick-slide-bg {
  height: 150px;
  min-height: 150px;
  border-radius: 20px 20px 0 0;
  background-position: center;
}

.aolrc-expanding-cards .card__inner .elementor-slide-heading {
  padding: 30px 30px 0px 30px;
  color: #7f658a;
  font-family: $font-primary;
  font-size: $txt-size;
  letter-spacing: 0.62px;
  text-transform: uppercase;
  font-weight: bold;
}

.aolrc-expanding-cards .card__inner .elementor-slide-description {
  padding: 0 30px 20px 30px;
  margin-top: 10px;
  font-size: 16px;
  line-height: 25px;
  color: #959595;
}

.aolrc-expanding-cards .card__inner .elementor-slide-description p {
  margin-bottom: 0;
}

.aolrc-expanding-cards .card__inner .read-more {
  font-size: $txt-size;
  letter-spacing: 0.92px;
  font-weight: bold;
  padding: 0 30px 0 30px;
  display: block;
  margin-top:-10px;
}

.aolrc-expanding-cards .card__inner:after {
  transition: all 0.3s ease-in-out;
}
.aolrc-expanding-cards .card__inner .fa {
  width: 100%;
  margin-top: .25em;
}

.aolrc-expanding-cards .card__expander {
  transition: all 0.2s ease-in-out;
  background-color: #FFF;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: left;
  border-radius: 20px;
  box-shadow: 8px 15px 45px 0 rgba(0, 0, 0, 0.09);
  padding-left: 50px;
  padding-right: 50px;
  z-index: 2;
}

/* When the card is expanded */
.aolrc-expanding-cards .card.is-expanded .card__expander {
  padding-top: 40px;
  padding-bottom: 20px;
}

.aolrc-expanding-cards .card__expander .fa {
  font-size: 1.5em;
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
  z-index: 1;
  font-family: "eicons";
}
.aolrc-expanding-cards .card__expander .fa:hover {
  opacity: 0.9;
}
.aolrc-expanding-cards .card.is-collapsed .card__inner:after {
  content: "";
  opacity: 0;
}
.aolrc-expanding-cards .card.is-collapsed .card__expander {
  max-height: 0;
  min-height: 0;
  overflow: hidden;
  margin-top: 0;
  opacity: 0;
}

.aolrc-expanding-cards .card.is-expanded .card__inner:after {
  content: "";
  opacity: 1;
  display: block;
  height: 0;
  width: 0;
  position: absolute;
  bottom: -30px;
  left: calc(50% - 15px);
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #FFF;
  box-shadow: 8px 15px 45px 0 rgba(0, 0, 0, 0.09);
  z-index: 1;
}
.aolrc-expanding-cards .card.is-expanded .card__inner .fa:before {
  content: "\f115";
}
.aolrc-expanding-cards .card.is-expanded .card__expander {
  /*max-height: 1000px;*/
  min-height: 200px;
  overflow: visible;
  margin-top: 30px;
  opacity: 1;
}

/*
.aolrc-expanding-cards .card.is-inactive .card__inner {
  pointer-events: none;
  opacity: 0.5;
}
*/

@media screen and (min-width: 992px) {
  .aolrc-expanding-cards .card:nth-of-type(3n+2) .card__expander {
    margin-left: calc(-100% - 30px);
  }

  .aolrc-expanding-cards .card:nth-of-type(3n+3) .card__expander {
    margin-left: calc(-200% - 60px);
  }

  .aolrc-expanding-cards .card:nth-of-type(3n+4) {
    clear: left;
  }

  .aolrc-expanding-cards .card__expander {
    width: calc(300% + 60px);
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .aolrc-expanding-cards .card:nth-of-type(2n+2) .card__expander {
    margin-left: calc(-100% - 30px);
  }

  .aolrc-expanding-cards .card:nth-of-type(2n+3) {
    clear: left;
  }

  .aolrc-expanding-cards .card__expander {
    width: calc(200% + 30px);
  }
}


/***************************************************************************
  3 - AOLRC - Accordion Slider
***************************************************************************/
/**
* AOLRC Accordion Slider
*/

/* Style the arrows */
.elementor-widget-aolrc-accordion-slider .slick-arrow{
  box-shadow: 3px 8px 16px 0 rgba(0, 0, 0, 0.09);
  border-radius: 100%;
  background-color: #FFF;
  font-size: 0px;
  width: 50px;
  height: 50px;
  padding: 0;
  border: 0;
  position: absolute;
  top: -60px;
}

.elementor-widget-aolrc-accordion-slider .slick-arrow.slick-prev {
  right: 60px;
}

.elementor-widget-aolrc-accordion-slider .slick-arrow.slick-next {
  right: 0;
  position: absolute;
}

.elementor-widget-aolrc-accordion-slider .slick-arrow:before {
  border-color: #979797;
  width: 13px;
  height: 13px;
  border-width: 2px 2px 0 0;
  border-style: solid;
  font-size: 0;
  position: absolute;
  transform: rotate(45deg);
  top: 18px;
  right: 20px;
  content: "";
}

.elementor-widget-aolrc-accordion-slider .slick-arrow.slick-prev:before {
  transform: rotate(-135deg);
  right: 15px;
}

/* Force the content to be shown */
.elementor-widget-aolrc-accordion-slider .elementor-tab-content {
  display: block;
}

/* Hide the icons */
.elementor-widget-aolrc-accordion-slider .elementor-tab-title .elementor-accordion-icon{
  display: none;  
}


/***************************************************************************
  4 - AOLRC - Video Slider
***************************************************************************/
/**
* AOLRC Video Slider
*/

/* Prev and next arrows */
.aolrc-video-slider .slick-slider .slick-arrow {
  width: 50px;
  height: 50px;
  box-shadow: 3px 8px 16px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff;
  border-radius: 100%;
}


.aolrc-video-slider .slick-slider .slick-prev {
  left: calc(10% - 25px);
}

.aolrc-video-slider.elementor-slick-slider .slick-prev:before {
  border-color: #979797;
  width: 13px;
  height: 13px;
  border-width: 2px 2px 0 0;
  border-style: solid;
  font-size: 0;
  position: absolute;
  transform: rotate(-135deg);
  top: 18px;
  right: 17px;
  content: "";
  opacity: 1;
}

.aolrc-video-slider .slick-slider .slick-next {
  right: calc(10% - 25px);
}

.aolrc-video-slider.elementor-slick-slider .slick-next:before {
  border-color: #979797;
  width: 13px;
  height: 13px;
  border-width: 2px 2px 0 0;
  border-style: solid;
  font-size: 0;
  position: absolute;
  transform: rotate(45deg);
  top: 18px;
  right: 20px;
  content: "";
  opacity: 1;
}


/* End arrows */

.aolrc-video-slider .elementor-slides .slick-slide {
  overflow: visible;
}
.aolrc-video-slider .elementor-slides .slick-slide-inner {
  padding: 0 50px;
}

@media screen and (max-width: 767px) {
  .aolrc-video-slider .elementor-slides .slick-slide-inner {
    padding: 0 20px;
  }
}



/***************************************************************************
  4 - AOLRC - Testimonial
***************************************************************************/
/**
* AOLRC Testimonial Skins
*/

/**
* Skin 1
*/

.elementor-testimonial-wrapper{
  margin:5px 0 5px 0;
  overflow:visible;
}

.elementor-testimonial-wrapper .elementor-testimonial-meta.elementor-testimonial-skin-none{
  /*background-color:#ffffff;
  box-shadow: 4px 7px 38px 7px rgba(132, 98, 140, 0.1);
  border-radius: 20px;*/
  padding:35px 33px 15px 33px;
  position: relative;
  display: block;
  max-width: 420px;
  margin:auto;    
}

.elementor-testimonial-wrapper .elementor-testimonial-meta.elementor-testimonial-skin-skin-1{
  background-color:#ffffff;
  box-shadow: 4px 7px 38px 7px rgba(132, 98, 140, 0.1);
  padding:35px 33px 15px 33px;
  position: relative;
  display: block;
  max-width: 420px;
  margin:auto;
  border-radius: 20px;
}

.elementor-testimonial-wrapper .elementor-testimonial-meta.elementor-testimonial-skin-skin-1:before,
.elementor-testimonial-wrapper .elementor-testimonial-meta.elementor-testimonial-skin-none:before{
  content: close-quote;
  content: "”";
  text-align: right;
  position: absolute;
  right: 33px;
  top: 23px;
  font-size: 5.5rem;
  font-family: sans-serif;
  color: #ececed;
  font-weight: 500;
}

.elementor-testimonial-wrapper .elementor-testimonial-meta.elementor-testimonial-skin-skin-1 .elementor-testimonial-image img,
.elementor-testimonial-wrapper .elementor-testimonial-meta.elementor-testimonial-skin-none .elementor-testimonial-image img{
  width:100px;
  height:100px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  margin-top:7px;
}

.elementor-testimonial-wrapper .elementor-testimonial-meta.elementor-testimonial-skin-skin-1.elementor-testimonial-image-position-top .elementor-testimonial-image,
.elementor-testimonial-wrapper .elementor-testimonial-meta.elementor-testimonial-skin-none.elementor-testimonial-image-position-top .elementor-testimonial-image{
  margin-bottom:10px;
}

.elementor-testimonial-wrapper .elementor-testimonial-meta-inner .elementor-testimonial-details .elementor-testimonial-name, .elementor-testimonial-wrapper .elementor-testimonial-meta-inner .elementor-testimonial-details .elementor-testimonial-job{
  font-family: $font-primary; /* $font-secondary */
  font-weight: 500;
  font-style: normal;
}

.elementor-testimonial-wrapper .elementor-testimonial-meta-inner .elementor-testimonial-details .elementor-testimonial-name{
  font-size: 18px;
  color:#84628c;
  line-height: 26px;
}

.elementor-testimonial-wrapper .elementor-testimonial-meta-inner .elementor-testimonial-details .elementor-testimonial-job{
  font-size: 13px;
  display:block;
  margin-top:2px;
  color: $primary-color;
  font-weight: bold;
}

.elementor-testimonial-wrapper .elementor-testimonial-meta.elementor-testimonial-skin-skin-1 .elementor-testimonial-content,
.elementor-testimonial-wrapper .elementor-testimonial-meta.elementor-testimonial-skin-none .elementor-testimonial-content{
  margin-top:15px;
  display: block;
}

.elementor-testimonial-wrapper .elementor-testimonial-meta.elementor-testimonial-skin-skin-1 .elementor-testimonial-content h3,
.elementor-testimonial-wrapper .elementor-testimonial-meta.elementor-testimonial-skin-skin-1 .elementor-testimonial-content h4,
.elementor-testimonial-wrapper .elementor-testimonial-meta.elementor-testimonial-skin-skin-1 .elementor-testimonial-content h5,
.elementor-testimonial-wrapper .elementor-testimonial-meta.elementor-testimonial-skin-skin-1 .elementor-testimonial-content h6,
.elementor-testimonial-wrapper .elementor-testimonial-meta.elementor-testimonial-skin-none .elementor-testimonial-content h3,
.elementor-testimonial-wrapper .elementor-testimonial-meta.elementor-testimonial-skin-none .elementor-testimonial-content h4,
.elementor-testimonial-wrapper .elementor-testimonial-meta.elementor-testimonial-skin-none .elementor-testimonial-content h5,
.elementor-testimonial-wrapper .elementor-testimonial-meta.elementor-testimonial-skin-none .elementor-testimonial-content h6{
  font-size:1.40rem;
  line-height: 1.4;
}

.elementor-testimonial-wrapper .elementor-testimonial-meta.elementor-testimonial-skin-skin-1 .elementor-testimonial-content, 
.elementor-testimonial-wrapper .elementor-testimonial-meta.elementor-testimonial-skin-skin-1 .elementor-testimonial-content p,
.elementor-testimonial-wrapper .elementor-testimonial-meta.elementor-testimonial-skin-none .elementor-testimonial-content, 
.elementor-testimonial-wrapper .elementor-testimonial-meta.elementor-testimonial-skin-none .elementor-testimonial-content p{
  /*font-size:1.113rem;*/
  font-size: $txt-size;
  margin-bottom:18px;
  color: $primary-color; /* 808080*/
  line-height: 25px;
  display: block;
  margin-bottom:20px;
}

.elementor-testimonial-wrapper .elementor-testimonial-meta.elementor-testimonial-skin-skin-1 .elementor-testimonial-divider-style,
.elementor-testimonial-wrapper .elementor-testimonial-meta.elementor-testimonial-skin-none .elementor-testimonial-divider-style{
  display:block;
  max-width: 152px;
  max-height: 16px;
  height:100%;
  text-align:center;
  margin:15px auto 0 auto;
  background:url("../images/grey-border.png") repeat-x center center;
}

.elementor-testimonial-wrapper .elementor-testimonial-meta.elementor-testimonial-skin-skin-1 .elementor-testimonial-divider-style span,
.elementor-testimonial-wrapper .elementor-testimonial-meta.elementor-testimonial-skin-none .elementor-testimonial-divider-style span{
  margin:auto;
  display: inline-block;
  background:#ffffff;
  width:35px;
  height:16px;      
}
.elementor-testimonial-wrapper .elementor-testimonial-meta.elementor-testimonial-skin-skin-1 .elementor-testimonial-divider-style span i,
.elementor-testimonial-wrapper .elementor-testimonial-meta.elementor-testimonial-skin-none .elementor-testimonial-divider-style span i{
  background:url("../images/sun.svg") no-repeat center center; 
  opacity: 0.35;
  width:16px;
  height:16px;
  padding:0; 
  display: block;
  margin:auto;
}

@media (max-width:767px){
  .elementor-testimonial-wrapper{
      margin-top:0;
      margin-bottom: 0;
  }
  .elementor-testimonial-wrapper .elementor-testimonial-meta.elementor-testimonial-skin-skin-1 .elementor-testimonial-content, 
  .elementor-testimonial-wrapper .elementor-testimonial-meta.elementor-testimonial-skin-skin-1 .elementor-testimonial-content p,
  .elementor-testimonial-wrapper .elementor-testimonial-meta.elementor-testimonial-skin-none .elementor-testimonial-content, 
  .elementor-testimonial-wrapper .elementor-testimonial-meta.elementor-testimonial-skin-none .elementor-testimonial-content p{
      font-size: $txt-size;
  }
  .elementor-testimonial-wrapper .elementor-testimonial-meta-inner .elementor-testimonial-details .elementor-testimonial-job{
      font-size: 15px;
  }
  .elementor-testimonial-wrapper .elementor-testimonial-meta-inner .elementor-testimonial-details .elementor-testimonial-name{
      font-size: $txt-size;
      line-height: 1.34rem;
      margin-bottom: 8px;
  }
  .elementor-testimonial-wrapper .elementor-testimonial-meta.elementor-testimonial-skin-skin-1:before,
  .elementor-testimonial-wrapper .elementor-testimonial-meta.elementor-testimonial-skin-none:before{
      right:15px;
      top:5px;
  }
  .elementor-testimonial-wrapper .elementor-testimonial-meta.elementor-testimonial-skin-none{
      padding-left:30px;
      padding-right:30px;
  }
  .elementor-testimonial-wrapper .elementor-testimonial-meta.elementor-testimonial-skin-skin-1 .elementor-testimonial-image img, .elementor-testimonial-wrapper .elementor-testimonial-meta.elementor-testimonial-skin-none .elementor-testimonial-image img{
      width: 60px;
      height: 60px;
  }

}






/***************************************************************************
  5 - AOLRC - Featured Presenter
***************************************************************************/
/**
* AOLRC Featured Presenter
*/

/**
* Styles
*/

.elementor-fpresenter-wrapper .elementor-fpresenter-meta{
    width:100%;
    max-width: 232px;
    box-shadow: 4px 7px 16px 6px rgba(0, 0, 0, 0.1);
}

.elementor-fpresenter-wrapper .elementor-fpresenter-meta .elementor-fpresenter-image{
    margin:0;
    padding:0;
    height: 100%;
    max-height: 232px;
    overflow: hidden;
}

.elementor-fpresenter-wrapper .elementor-fpresenter-meta .elementor-fpresenter-name h1,
.elementor-fpresenter-wrapper .elementor-fpresenter-meta .elementor-fpresenter-name h2, 
.elementor-fpresenter-wrapper .elementor-fpresenter-meta .elementor-fpresenter-name h3, 
.elementor-fpresenter-wrapper .elementor-fpresenter-meta .elementor-fpresenter-name h4, 
.elementor-fpresenter-wrapper .elementor-fpresenter-meta .elementor-fpresenter-name h5, 
.elementor-fpresenter-wrapper .elementor-fpresenter-meta .elementor-fpresenter-name h6{
    font-family: $font-primary ;
    font-size: 17px;
    /*font-size: calc(1.50vh + 1vmin);*/
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0.5px;
    margin:0;
    padding:0;
}

.elementor-fpresenter-wrapper .elementor-fpresenter-meta .elementor-fpresenter-details{
    display: block;
    padding-top: 15px;
    padding-bottom: 5px;
}

.elementor-fpresenter-wrapper .elementor-fpresenter-meta .elementor-fpresenter-details, 
.elementor-fpresenter-wrapper .elementor-fpresenter-meta .fp-divider, 
.elementor-fpresenter-wrapper .elementor-fpresenter-meta ul{
    padding-left: 23px;
    padding-right: 23px;
}

.elementor-fpresenter-wrapper .elementor-fpresenter-meta .fp-divider hr{
    border-top-width: 1px;
    opacity: 0.5;
}

.elementor-fpresenter-wrapper .elementor-fpresenter-meta ul{
    display: flex;
    width:100%;
    list-style:none;
    margin:0;
    padding-top:12px;
    padding-bottom: 12px;
    justify-content: space-between;
}
.elementor-fpresenter-wrapper .elementor-fpresenter-meta ul li{
    display: inline-block;
    font-size: 15px;
    font-weight: 500;
}
.elementor-fpresenter-wrapper .elementor-fpresenter-meta ul li.elementor-fpresenter-label{
    font-size: 15px;
}
.elementor-fpresenter-wrapper .elementor-fpresenter-meta ul li.elementor-fpresenter-content{
    text-transform: uppercase;
    span{
      font-weight: bold;
      font-size: 13px;
    }
}
.elementor-fpresenter-wrapper .elementor-fpresenter-meta .elementor-fpresenter-details .elementor-fpresenter-name{
    min-height: 56px;
}


/***************************************************************************
  6 - AOLRC - Featured Retrevent
***************************************************************************/
/**
* AOLRC Featured Retrevent
*/

/**
* Styles
*/

.elementor-retrevent-wrapper .elementor-retrevent-meta{
  margin:auto;
  width:100%;
  max-width: 235px;
  box-shadow: 4px 7px 38px 6px rgba(0, 0, 0, 0.1);
  padding-bottom: 70px;
}

.elementor-retrevent-wrapper .elementor-retrevent-meta .elementor-retrevent-image{
  display: block;
  max-height: 160px;
  height:100%;
  overflow: hidden;
}

.elementor-retrevent-wrapper .elementor-retrevent-meta .elementor-retrevent-details{
  display: block;
  width: 100%;
  padding-left:20px;
  padding-right: 20px;
  background-color:#84628c;
}

.elementor-retrevent-wrapper .elementor-retrevent-meta .elementor-retrevent-details h1,
.elementor-retrevent-wrapper .elementor-retrevent-meta .elementor-retrevent-details h2,
.elementor-retrevent-wrapper .elementor-retrevent-meta .elementor-retrevent-details h3,
.elementor-retrevent-wrapper .elementor-retrevent-meta .elementor-retrevent-details h4,
.elementor-retrevent-wrapper .elementor-retrevent-meta .elementor-retrevent-details h5,
.elementor-retrevent-wrapper .elementor-retrevent-meta .elementor-retrevent-details h6{
  font-family: $font-primary ;
  font-size: 14px;
  font-weight: bold;
  padding:10px 0 8px 0;
  margin:0;
  line-height: 1.4;
  color:#000000;
  letter-spacing: 0.75px;
  text-transform: uppercase;
}
.elementor-retrevent-wrapper .elementor-retrevent-meta .elementor-retrevent-content{
  display:block;
  margin:0;
  padding-left:0;
  padding-right:0;
    p{
      margin:15px 0 0 0;
      padding:0 20px;
      &:last-child{
        margin-bottom: 15px;
      }
    }
}
.elementor-retrevent-wrapper .elementor-retrevent-meta .elementor-retrevent-content ul{
  display: block;
  margin:5px 0 0 0;
  padding:0;
  list-style: none;
}
.elementor-retrevent-wrapper .elementor-retrevent-meta .elementor-retrevent-content ul li{
  /*padding-left: 15px;*/
  padding:0;    
  line-height: 1.4;
  border-top:1px solid #dbdbdb;
}
.elementor-retrevent-wrapper .elementor-retrevent-meta .elementor-retrevent-content ul li:first-child{
  border:0;
}
.elementor-retrevent-wrapper .elementor-retrevent-meta .elementor-retrevent-content ul li i.fas{
  color:#83618D;
  margin-right:3px;
}
.elementor-retrevent-wrapper .elementor-retrevent-meta .elementor-retrevent-content ul li a{
  color:$primary-color;
  line-height: 1.6;
  display:block;
  padding:11px 20px;
  transition: none;
}
.elementor-retrevent-wrapper .elementor-retrevent-meta .elementor-retrevent-content ul li a:hover{
  color:#84628d;
}
.elementor-retrevent-wrapper .elementor-retrevent-meta .elementor-retrevent-content ul li a img{
  margin-top:0;
  margin-right:9px;
  float:left;
}
.elementor-retrevent-wrapper .elementor-retrevent-meta .elementor-retrevent-content ul li a img + span{
  float: left;
  width: 100%;
  max-width: 159px;
  line-height: 1.2;
}

.elementor-retrevent-wrapper .elementor-retrevent-meta .elementor-retrevent-button{
  display: block;
  width: 100%;
  text-align:center;
  padding: 5px 20px 20px 20px;
  position: absolute;
  bottom: 0;
  max-width:235px;
}
.elementor-retrevent-wrapper .elementor-retrevent-meta .elementor-retrevent-button a{
  display:block;
  margin:0 auto;
  font-family: $font-primary ;
  font-size: 13px;
  padding:8px 10px;
  font-weight: bold;
  letter-spacing: 0.5px;
  transition: none;
}

@media(max-width:767px){
  .elementor-retrevent-wrapper .elementor-retrevent-meta, .elementor-retrevent-wrapper .elementor-retrevent-meta .elementor-retrevent-button{
      max-width:100%;
  }
  .elementor-fpresenter-wrapper .elementor-fpresenter-meta .elementor-fpresenter-image{
      max-height: 232px;
      width:100%;
  }
}

@media(max-width:768px) and (max-width:1024px){
  .elementor-fpresenter-wrapper .elementor-fpresenter-meta .elementor-fpresenter-image{   
    img{
      width:100%;
    }      
  }
}

/***************************************************************************
  6 - AOLRC - Featured MediaBox
***************************************************************************/

/**
* AOLRC Featured MediaBox
*/

/**
* Styles
*/

.custom-featured-image-box{
  box-shadow: 4px 7px 38px 6px rgba(0, 0, 0, 0.1);
}

.custom-featured-image-box .elementor-image-box-content{
  /*padding:22px 25px 30px 15px;*/
  padding: 13px 25px 20px 15px;
}
.custom-featured-image-box .elementor-image-box-content .elementor-image-box-title-label{
  margin-bottom: 5px;
  float:left;
  margin-top:5px;
}
.custom-featured-image-box .elementor-image-box-content .elementor-image-box-title-label span{
  background-color: #ed9124;
}
.custom-featured-image-box .elementor-image-box-content .elementor-image-box-title-label-two span{
  background-color: #00a096;
}
.custom-featured-image-box .elementor-image-box-content .elementor-image-box-title-label-two{
  padding-bottom: 10px;
}

.custom-featured-image-box .elementor-image-box-content .elementor-image-box-title-label span,
.custom-featured-image-box .elementor-image-box-content .elementor-image-box-title-label-two span{
  float:left;
  display: block;
  height: 20px;
  line-height: 20px;
  color:#ffffff;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 0.5px;
  padding: 0 3px 0 5px;
}

.custom-featured-image-box .elementor-image-box-content .elementor-image-box-title-label span.tip,
.custom-featured-image-box .elementor-image-box-content .elementor-image-box-title-label-two span.tip{
  width: 0px;
  height: 0px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #ffffff;
  z-index: 9;
}

.custom-featured-image-box .elementor-image-box-content .elementor-image-box-disc-txt{
  line-height: 22px;
  color:#ce4257;
  display: block;
  padding:0 0 15px 0;
}

.custom-featured-image-box .elementor-image-box-content h1.elementor-image-box-title,
.custom-featured-image-box .elementor-image-box-content h2.elementor-image-box-title,
.custom-featured-image-box .elementor-image-box-content h3.elementor-image-box-title,
.custom-featured-image-box .elementor-image-box-content h4.elementor-image-box-title,
.custom-featured-image-box .elementor-image-box-content h5.elementor-image-box-title,
.custom-featured-image-box .elementor-image-box-content h6.elementor-image-box-title{
  font-weight: 400;
  font-family: $font-primary-title;
  color:#242424;
  margin-bottom: 15px;
  line-height: 34px;
  margin-top:5px;
  display:block;
}

.custom-featured-image-box .elementor-image-box-content h1.elementor-image-box-title a,
.custom-featured-image-box .elementor-image-box-content h2.elementor-image-box-title a,
.custom-featured-image-box .elementor-image-box-content h3.elementor-image-box-title a,
.custom-featured-image-box .elementor-image-box-content h4.elementor-image-box-title a,
.custom-featured-image-box .elementor-image-box-content h5.elementor-image-box-title a,
.custom-featured-image-box .elementor-image-box-content h6.elementor-image-box-title a{
  color:#242424;
  display:block;
  transition: none;
  font-size: 32px;
}

.custom-featured-image-box .elementor-image-box-content h1.elementor-image-box-event-title,
.custom-featured-image-box .elementor-image-box-content h2.elementor-image-box-event-title,
.custom-featured-image-box .elementor-image-box-content h3.elementor-image-box-event-title,
.custom-featured-image-box .elementor-image-box-content h4.elementor-image-box-event-title,
.custom-featured-image-box .elementor-image-box-content h5.elementor-image-box-event-title,
.custom-featured-image-box .elementor-image-box-content h6.elementor-image-box-event-title{
  font-family: $font-primary;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color:#000000;
  margin-bottom: 5px;
  margin-top:0;
}

.custom-featured-image-box .elementor-image-box-content h1.elementor-image-box-event-title a,
.custom-featured-image-box .elementor-image-box-content h2.elementor-image-box-event-title a,
.custom-featured-image-box .elementor-image-box-content h3.elementor-image-box-event-title a,
.custom-featured-image-box .elementor-image-box-content h4.elementor-image-box-event-title a,
.custom-featured-image-box .elementor-image-box-content h5.elementor-image-box-event-title a,
.custom-featured-image-box .elementor-image-box-content h6.elementor-image-box-event-title a{
  color:$brand-primary;
  display: block;
  font-weight:bold;
  transition: none;
  font-size: $txt-size-lg;
}

.custom-featured-image-box .elementor-image-box-content .elementor-image-box-event-sdate{
  display: block;
  font-size: $txt-size-s;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.custom-featured-image-box .elementor-image-box-content .elementor-image-box-description{
  line-height: 22px;
  margin-bottom: 15px;
  color:$primary-color;
}

.custom-featured-image-box .elementor-image-box-content .elementor-image-box-btn{
  display: block;
}

.custom-featured-image-box .elementor-image-box-content .elementor-image-box-btn a{
  background-color:#84628d;
  font-size: $txt-size-s;
  color:#ffffff;
  padding:9px 20px;
  letter-spacing: 0.5px;
  font-weight: bold;
  border-radius: 4px;
  text-transform: uppercase;
  transition: none;
}

@supports (-moz-appearance:none) { 
  .custom-featured-image-box .elementor-image-box-content h1.elementor-image-box-title,
  .custom-featured-image-box .elementor-image-box-content h2.elementor-image-box-title,
  .custom-featured-image-box .elementor-image-box-content h3.elementor-image-box-title,
  .custom-featured-image-box .elementor-image-box-content h4.elementor-image-box-title,
  .custom-featured-image-box .elementor-image-box-content h5.elementor-image-box-title,
  .custom-featured-image-box .elementor-image-box-content h6.elementor-image-box-title{
      margin-top:10px;
      margin-bottom: 10px;
  }    
}

@media (min-width: 768px){
  .elementor-widget-aolrc-featured-imagebox.elementor-position-left .custom-featured-image-box.elementor-image-box-wrapper, 
  .elementor-widget-aolrc-featured-imagebox.elementor-position-right .custom-featured-image-box.elementor-image-box-wrapper {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
  }

  .elementor-widget-aolrc-featured-imagebox.elementor-position-left .custom-featured-image-box.elementor-image-box-wrapper {
      text-align: left;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
  }

  .elementor-widget-aolrc-featured-imagebox.elementor-vertical-align-top .custom-featured-image-box.elementor-image-box-wrapper {
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
  }  
}


/***************************************************************************
  7 - AOLRC - Featured TextBox
***************************************************************************/

/**
* AOLRC Featured TextBox
*/

/**
* Styles
*/
.elementor-widget-aolrc-featured-txt-box, .elementor-widget-aolrc-featured-txt-box .elementor-widget-container{
  text-align: center;
  display: flex;
  margin:0 auto;
  padding: 24px 0 20px 0;

  .coln-container{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 4px;
    margin:25px 0 0 0;
    padding-bottom: 70px;

    .coln-img{
      display: block;
      img{
        border-radius: 4px 4px 0 0;
      }
    }

    h1, h2, h3, h4, h5, h6{
      background-color: #83618d;
      color:#ffffff;
      line-height: 1.3rem;
      font-size: 18px;
      text-align: left;
      padding: 10px 25px;
      min-height: 69px;
      align-items: center;
      justify-content: left;
      display: flex;
    }
    .desc{
      display: block;
      padding: 5px 25px 0 25px;
      p{
        text-align: left;
      } 
    }
    .button{
      position:absolute;
      bottom: 55px;
      width: 100%;
      background-color: transparent;
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;
      flex-wrap: wrap;
      max-width: 294px;
      text-align: center;
      
      a.btn{
        border-radius: 4px;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 16px;
        letter-spacing: 0.91px;
        background-color: #84628d;
        display: inline-block; 
      }
    }
    &.col-four{
      .button{
        max-width: calc(25% - 20px);
      }
    }
    &.col-three{
      .button{
        max-width: calc(33% - 20px);
      }
    }
    &.col-two{
      .button{
        max-width: calc(50% - 20px);
      }
    }
    &.col-one{
      .button{
        max-width: calc(100% - 20px);
      }
    }
  }

  .coln-container.col-one, .coln-container.col-two, .coln-container.col-three,
  .coln-container.col-four,  .coln-container.col-five{
    width:100%;
    max-width: 294px;    
    align-items: center;
    justify-content: space-between;
  }
  .coln-container.col-one{
    margin: auto;
  }
   .coln-container.col-two, .coln-container.col-three{
    margin:0 10px;
  }
   .coln-container.col-four:first-child{
    margin-left:0;
  }
   .coln-container.col-four:last-child{
    margin-right:0;
  }
   .coln-container.col-four{
    margin:0 10px;
  }
}


@media(max-width:767px){
  .elementor-widget-aolrc-featured-txt-box, .elementor-widget-aolrc-featured-txt-box .elementor-widget-container{
    flex-direction: column;
    .coln-container.col-one, .coln-container.col-two, .coln-container.col-three, .coln-container.col-four, .coln-container.col-five{
      margin:0 auto 25px auto;
      &:first-child, &:last-child{
        margin:0 auto 25px auto;
      }
    }
    .coln-container{
      padding-bottom: 25px;
      .button{
        position: relative;
        bottom: 0;
        max-width: 100% !important;
      }
    }
  }
}

@media (min-width:768px) and (max-width:1024px){
  .elementor-widget-aolrc-featured-txt-box, .elementor-widget-aolrc-featured-txt-box .elementor-widget-container{
      .coln-container {
            .button{
              padding:10px 15px; 
              a{
                padding:12px 14px;
              }              
          }
      }
  }
}
