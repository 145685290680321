/* New Home Page - 2020 */
.home-media-container{
    .elementor-container{
        .elementor-row{
            .elementor-widget-html{
                display: flex;
                a{
                    background-image:url("/wp-content/uploads/2020/11/news-media-2020.jpg");
                    background-repeat:  no-repeat;
                    min-height: 50px;
                    text-indent: -99999px;
                    justify-content: center;
                }                
            }
            .cnn .elementor-widget-html a{
                background-position: 0 0;
                width: 100%;
                max-width: 80px;
            }
            .the-washington-post .elementor-widget-html a{
                background-position: 0 -70px;
                width: 100%;
                max-width: 294px;
            }
            .huffpost .elementor-widget-html a{
                background-position: 0 -154px;
                width: 100%;
                max-width: 208px;
            }
            .yoga-journal .elementor-widget-html a{
                background-position:0 -224px;
                width: 100%;
                max-width: 183px;
            }
        }
    }    
}

ul.home-media{
    margin:0;
    padding:0;
    display: block;
    li{
        display: inline-flex;
        list-style: none;
        height:35px;
        a{
            /*background-image:url("/wp-content/uploads/2020/11/news-media-2020.jpg");*/
            background-image: url("/wp-content/themes/astra-child/dist/images/ficons2.svg");
            background-repeat:  no-repeat;
            max-height: 35px;
            text-indent: -99999px;
            justify-content: center;
            display:block;
            width:100%;
        }
        &.cnn{
            width: 14%;
            max-width: 103px;
            a{
                background-position: 0 -216px;               
            }
        }
        &.twp{
            width: 35%;
            max-width: 286px;
            a{
                background-position: 0 -248px;  
                background-image: url("/wp-content/themes/astra-child/dist/images/ficons2.svg#twp");          
            }
        }
        &.hp{
            width: 28%;
            max-width: 217px;
             a{
                background-position: 0 -283px;          
             }
        }
        &.yj{
            width: 22%;
            max-width: 175px;
            a{
                background-position:0 -309px;       
            }
        } 
    } 
}


// New Home Sep 2022
.hmedia, .hmedia .elementor-widget-container{
    display: flex;
    width: 100%;
    margin:25px 0;
}
ul.hmedia-colone{
    margin:0;
    padding:0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    max-width: 870px;
    width: 100%;
    li{
        display: inline-flex;
        list-style: none;
        height:46px;
        width: 100%;
        margin:20px 0;  
        a{
            background-image: url("/wp-content/themes/astra-child/dist/images/pmedia-logo.svg");
            background-repeat:  no-repeat;
            text-indent: -99999px;
            justify-content: center;
            display:block;
            width:100%;
            height: 100%;
            max-height: 46px;
        }
        &.cnn{
            max-width: 100px;                        
            a{
                background-position: 0 -436px;
            }
        }
        &.twp{
            max-width: 250px;            
            a{
                background-position: 0 -547px;
            }
        }

        &.hp{
            max-width: 230px;            
            a{
                background-position: 0 -654px;
            }
        }
        &.yj{
            max-width: 183px;
            a{
                background-position: 0 -764px;
            }
        }
        &.tl{
            max-width: 150px;
            a{
                background-position: 0 -879px;
            }
        }
        &.twt{
            max-width: 262px;
            a{
                background-position: 0 -997px;
            }
        }
        &.nbc{
            max-width: 45px;
            a{
                background-position: 0 -1120px;
            }
        }
        &.sp{
            max-width: 125px;
            a{
                background-position: 0 -1238px;
            }
        }
    }
}

ul.hmedia-coltwo{
    margin:0 0 0 60px;
    padding:0;
    display: inline-flex;
    max-width: 367px;
    width:100%;
    border-left: 1px solid #e9e9e9;

    li{
        list-style: none;
        max-height: 150px;
        height:100%;
        width: 100%;
        margin:0;
        a{
            background-image: url("/wp-content/themes/astra-child/dist/images/pmedia-logo.svg");
            background-repeat:  no-repeat;
            text-indent: -99999px;
            justify-content: center;
            display:block;
            width:100%;
            height: 100%;
            max-height: 150px;            
        }
        &.ta{
            max-width: 131px;
            margin-left:58px;
            margin-right: 25px;                       
            a{
                background-position: 0 0;
            }
        }
        &.usa{
            max-width: 150px;                        
            a{
                background-position: 0 -215px;
            }
        }
    }
}

.simple{
    .elementor-widget-text-editor, .elementor-widget-text-editor .elementor-widget-container{
        h3{
            font-size: 42px;
            line-height: 1.4;
            width: 100%;
            display: block;
            margin-bottom: 30px;
        }
        a.btn{
            display: inline-block;
            font-size: 14px;
            font-weight: 600;
            text-transform: uppercase;
            line-height: 20px;
            letter-spacing: 0.91px;
            background-color: #84628d;
            padding: 12px 24px;
            margin-top:15px;
            border-radius: 3px;
            text-align: center;
            transition: all .3s;
            color:#ffffff;

            &::before{
                clear: both;
            }
        }
    }
    .elementor-widget-video{
        max-width: 783px !important;
        width: 100% !important;
    }
    .divider-bdr.elementor-widget-heading{
        border-top:1px solid #e9e9e9;        
    }
}


.elementor-cta--skin-classic{
    .elementor-cta{
        .elementor-cta__content{
            .elementor-cta__content-item{
                color: $primary-color;
                &.elementor-cta__title{
                    color:$hdr-primary;
                }
            }
        }
    }
} 
/****************************************************************************
    Optimised Banner for DOM & mobile, tablet specific background image
*****************************************************************************/
.hero-banner-bg{
    background-position: top center;
    background-repeat: no-repeat;
    background-size:cover;
    text-align: center;
    color: #FFFFFF;
    background-color: #3b3247;
    /*transition-duration: 20s;*/

    h1{
        font-family: $font-primary-title;        
        font-weight: 400;        
        margin-bottom: 25px;        
    }
    p{
        max-width: 550px;
        display: block;
        font-family: $font-primary;
        margin-bottom: 0; 
        margin-left:auto;
        margin-right: auto;      
    }
    h1, p{
        color:#FFFFFF;
        font-weight: normal;
    }
    &.bg-center-center{
        background-position: center center;
    }
    .btn{
        margin-top:40px;

        &.elementor-button{
            color:#ffffff;
            background-color:$brand-primary;
            font-size: 14px;
            font-weight: 600;
            text-transform: uppercase;
            line-height: 20px;
            letter-spacing: 0.91px;
        }
    }
}

/***********
Testimonial
***********/
.ht-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;

    .ht-card{
        padding: 35px 33px 15px;
        position: relative;
        display: block;
        max-width: 303px;
        margin: auto 10px;
        background-color: #FFFFFF;
        border-style: 1px solid #FFFFFF;
        border-radius: 4px;
        box-shadow: 4px 7px 38px 7px rgba(0, 0, 0, 0.1);

        &:before{
            content: close-quote;
            content: "”";
            text-align: right;
            position: absolute;
            right: 33px;
            top: 50px;
            font-size: 5.5rem;
            font-family: sans-serif;
            color: #ececed;
            font-weight: 500;
        }
        &:first-child{
            margin-left:0;
        }
        &:last-child{
            margin-right: 0;
        }
        ul{
            display: flex;
            margin:0;
            padding:0;
            align-items: center;
            justify-content: left;
            li{
                display: inline-block;
                list-style: none;
                &:first-child{
                    padding-right: 15px;
                }
                img{
                    width: 100px;
                    height: 100px;
                    border-radius: 100%;
                    margin-top: 7px;
                }
                .ht-name{
                    font-size: 18px;
                    color: $brand-primary;
                    line-height: 26px;
                    font-family: $font-primary;
                    font-weight: 500;
                }
                .ht-program{
                    font-size: 13px;
                    display: block;
                    margin-top: 0;
                    color: $primary-color;
                    font-weight: 700;
                }
            }            
        }
        .ht-divider{
            display: block;
            max-width: 152px;
            max-height: 16px;
            height: 100%;
            text-align: center;
            margin: 15px auto 0;
            background: url(../images/grey-border.png) repeat-x 50%;

            span{
                margin: auto;
                display: inline-block;
                background: #fff;
                width: 35px;
                height: 16px;
                i{
                    background: url(../images/sun.svg) no-repeat 50%;
                    opacity: .35;
                    width: 16px;
                    height: 16px;
                    padding: 0;
                    display: block;
                    margin: -8px auto auto auto;
                }
            }
        }
        .ht-content{
            display: block;
            font-size: $txt-size;
            margin-bottom: 18px;
            color: #3f484f;
            line-height: 25px;
            margin: 15px 0 20px 0;
            text-align: center;
        }
    }
    
}

@media(min-width:1025px){
    .no-search{
        .hero-banner-bg{
            padding-top:0;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
    }
    .hero-banner-bg{
        padding-top: 60px;
        min-height: 650px;        
        /*background-image:url("/wp-content/uploads/2021/01/landscape_hp_hero9.jpg");*/

        h1{
            font-size: 60px;
        }
        p{
            margin-top:10px;
            font-size: 30px;
            font-weight: normal;            
        }
        h1, p{
            /*color: #0f4a71;*/
            color: #ffffff;
            text-shadow: 1px 2px 3px rgba(38, 38, 38, 1); 
        }
        &.white{
            h1, p{
                color: #ffffff;
                text-shadow: 1px 2px 3px rgba(38, 38, 38, 1);                  
            }
        }
        .btn{
            &.elementor-button{                
            }
        }
    }
}

@media(min-width:768px) and (max-width:1024px){
    .hero-banner-bg{
        padding:100px 30px 80px 30px;
        min-height: 530px;
        /*background-image:url("/wp-content/uploads/2021/01/landscape_hp_hero9-1024x512.jpg");*/

        h1{
            font-size: $h1-tsize-bold;
            margin-bottom: 25px;
        }
        p{
            font-size: 26px;
            font-weight: normal;            
        }
        h1, p{
            color:#ffffff;
            font-weight: bold;
            text-shadow: 0 0 3px #0f4a71;
        }
    }
}

@media(max-width:767px){
    .hero-banner-bg{
        padding:80px 30px 80px 30px;
        min-height: 288px;
        /*background-image:url("/wp-content/uploads/2021/01/landscape_hp_hero9-300x300.jpg");*/

        h1{
            font-size: $h1-msize-bold;
        }
        p{
            font-size: 15px;
            font-weight: normal;            
        }
        h1, p{
            color:#ffffff;
            font-weight: bold;
            text-shadow: 0 0 3px #262626;
        }
    }
    .ht-wrapper{
        flex-direction: column;
        .ht-card, .ht-card:first-child, .ht-card:last-child{
            margin:auto auto 20px auto;
        }
    }
    .mcoln-reverse, .mcoln-reverse .elementor-container{
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
    }
    .hmedia, .hmedia .elementor-widget-container{
        flex-flow: column wrap;
        ul.hmedia-colone{
            li{
                display: inline-flex;
                list-style: none;
                height:28px;
                width: 100%;
                margin:15px 0;  
                a{
                    background-image: url("/wp-content/themes/astra-child/dist/images/mobile-pmedia-logo.svg");
                    background-repeat:  no-repeat;
                    text-indent: -99999px;
                    justify-content: center;
                    display:block;
                    width:100%;
                    height: 100%;
                    max-height: 28px;
                }
                &.cnn{
                    max-width: 54px; 
                    order:1;                       
                    a{
                        background-position: 0 -436px;
                    }
                }
                &.twp{
                    max-width: 220px; 
                    order:2;           
                    a{
                        background-position: 0 -505px;
                    }
                }
        
                &.hp{
                    max-width: 160px;
                    order:3;           
                    a{
                        background-position: 0 -582px;
                    }
                }
                &.yj{
                    max-width: 150px;
                    order:4;
                    a{
                        background-position: 0 -671px;
                    }
                }
                &.tl{
                    max-width: 140px;
                    order:7;
                    a{
                        background-position: 0 -759px;
                    }
                }
                &.twt{
                    max-width: 220px;
                    order:5;
                    a{
                        background-position: 0 -849px;
                    }
                }
                &.nbc{
                    max-width: 90px;
                    height: 46px;
                    order:6;
                    a{
                        background-position: 0 -930px;
                        max-height: 46px;
                    }
                }
                &.sp{
                    max-width: 150px;
                    order:8;
                    a{
                        background-position: 0 -1027px;
                    }
                }
            }            
        }
        ul.hmedia-coltwo{
            margin:20px 0 35px 0;
            border:0;
            justify-content: space-between;
            li{
                height: 150px;
                a{
                    background-image: url("/wp-content/themes/astra-child/dist/images/mobile-pmedia-logo.svg");
                }
            }
            li.ta{
                margin:0;
            }
            li.usa{
                margin:0;
            }
        }
    }
    .m-coln-img, .in-col-img{
        img{
            width:100% !important;
            max-width: 100% !important;
            height: 100% !important;
            margin-bottom: 25px;
        }
    }

    .simple .elementor-widget.elementor-widget-text-editor{
        h1{
        font-size: $h1-msize;
        }
        h2{
        font-size: $h2-msize;
        }
        h3{
        font-size: $h3-msize;
        }
        h4{
        font-size: $h4-msize;
        }
        h5{
        font-size: $h5-msize;
        }
        h6{
        font-size: $h6-msize;
        }
    }
    
}

@media(max-width:320px){
    .hmedia .elementor-widget-container, .hmedia{
        ul.hmedia-coltwo{
            flex-direction: column;
            li.ta{
                margin-bottom: 30px;
            }
        }
    }
}

@media (min-width:768px) and (max-width:1024px){
    .ht-wrapper{       
        flex-wrap: wrap;
        .ht-card{
            margin:auto auto 30px auto !important;
        }
    }
    .simple{
        .m-coln-content{
            width:48%;           
        }
        .in-coln-content{            
        }
        .m-coln-img{
            width: 50% !important;
            img{
                max-width:100% !important;
                height:100% !important;
            }
        }
        .in-col-img{
            img{
                max-width:100% !important;
                height:100% !important;
            }
        }
    }  
    .simple .elementor-widget.elementor-widget-text-editor{
        h1{
        font-size: $h1-msize;
        }
        h2{
        font-size: $h2-msize;
        }
        h3{
        font-size: $h3-msize;
        }
        h4{
        font-size: $h4-msize;
        }
        h5{
        font-size: $h5-msize;
        }
        h6{
        font-size: $h6-msize;
        }
    }  
    
}

@media (min-width:768px) and (max-width:1180px){
    .simple .elementor-widget-video{
        max-width: 55% !important;
    }
    .hmedia, .hmedia .elementor-widget-container{
        ul.hmedia-coltwo{
            margin:0 0 0 40px;
            li.ta{
                margin-left: 40px;
            }
        }        
    }
    .simple{
        .m-coln-content{
            width:48%;        
        }
        .in-coln-content{            
        }
        .m-coln-img{
            width: 50% !important;
            img{
                max-width:100% !important;
                height:100% !important;
            }
        }
        .in-col-img{
            img{
                max-width:100% !important;
                height:100% !important;
            }
        }
    } 
    .simple .elementor-widget.elementor-widget-text-editor{
        h1{
        font-size: $h1-msize;
        }
        h2{
        font-size: $h2-msize;
        }
        h3{
        font-size: $h3-msize;
        }
        h4{
        font-size: $h4-msize;
        }
        h5{
        font-size: $h5-msize;
        }
        h6{
        font-size: $h6-msize;
        }
    } 
}

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
    
}

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:landscape) {
    
}


