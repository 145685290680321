.slider-size {
  min-height: 520px;
  border-top: 1px solid white;
}
@media screen and (max-width: 640px) {
  .slider-size {
    min-height: 500px;
  }
}
@media screen and (max-width: 320px) {
  .slider-size {
    min-height: 250px;
  }
}



@media screen and (max-width: 640px) {
  .quoteslidersmall {
    display: block !important;
  }

  .quotesliderlarge {
    display: none !important;
  }

  .flexsliderquotes2 .quoteimage {
    text-align: center;
  }

  #top-bar .container {
    display: none;
  }

  .bulletslide .slider-size {
    min-height: 500px;
  }

/*
  .flexslider {
    top: -38px;
  }
*/

  .admin-bar .flexslider {
    top: -84px;
  }

  .hero-unit .bulletslide figcaption {
    width: 100%;
  }

  .hero-unit .bulletslide .title {
    padding: 10px;
  }

  .hero-unit .bulletslide .details {
    padding: 10px;
    margin-bottom: 30px;
  }

  .hero-unit .bulletslide .button-gold {
    position: relative;
    left: 0;
    margin: 20px 10px;
  }

  .quoteslidercontainer {
    margin-top: -24px;
    margin-bottom: 0 !important;
  }
}
.bulletslide .slider-size {
  min-height: 420px;
}

.quoteslidersmall {
  display: none;
}

.quotesliderlarge {
  display: block;
}

.slide-size {
  min-height: 450px;
  width: 100%;
  border-top: 1px solid white;
}
@media screen and (max-width: 640px) {
  .slide-size {
    min-height: 138px;
  }
}

.hero-event-size {
  min-height: 180px;
}
@media screen and (max-width: 320px) {
  .hero-event-size {
    min-height: 90px;
  }
}

/*.slider-gradient:before {
  content: " ";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(75%, rgba(255, 255, 255, 0)), to(white));
  background-image: linear-gradient(rgba(255, 255, 255, 0) 75%, white);
}*/

.bulletslide .slider-gradient:before {
  content: " ";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(50%, rgba(255, 255, 255, 0)), to(#fff));
  background-image: linear-gradient(rgba(255, 255, 255, 0) 50%, #fff);
}